import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
type FormStatus = 'success' | 'error' | null;

export type MessageBarContextState = Readonly<{
  messageBarState: FormMessageBarState;
  setMessageBarState: (state: FormMessageBarState) => void;
  clearMessageBarState: () => void;
  setErrorMessage: (value: string) => void;
  setSuccessMessage: (value: string) => void;
}>;

export type FormMessageBarState = {
  status: FormStatus;
  message: string;
};

export type FormMessageBarAction = '';

const FormMessageBarContext = createContext<MessageBarContextState>(
  {} as MessageBarContextState,
);

export const FormMessageBarProvider: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children }) => {
  const [messageBarState, setMessageBarState] = useState<FormMessageBarState>({
    status: null,
    message: '',
  });

  const clearMessageBarState = () =>
    setMessageBarState({
      status: null,
      message: '',
    });

  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };

  const setSuccessMessage = (value: string) => {
    setMessageBarState({ status: 'success', message: value });
  };

  const value = {
    messageBarState,
    setMessageBarState,
    clearMessageBarState,
    setErrorMessage,
    setSuccessMessage,
  };

  return (
    <FormMessageBarContext.Provider value={value}>
      {children}
    </FormMessageBarContext.Provider>
  );
};

export const useFormMessageBarContext = (): MessageBarContextState => {
  return useContext(FormMessageBarContext);
};
