import CreateTopicModal from '@components/alertManager/CreateTopicModal';
import { EmptyStateCard } from '../../components/alertManager/EmptyStateCard';
import React, { useMemo, useRef } from 'react';
import { List, Stack, TextField, Typography } from '@mui/material';
import { dusk, eggshell, seaglassDarker } from 'src/constants/colors';
import { useNavigate } from 'react-router-dom';
import {
  FusionEventCategory,
  WalletBlockchain,
} from 'src/services/gql/generated';
import { SubscriptionCardConfigItemV1 } from '@components/cardConfig/common/CardConfigItemV1';
import DefineTemplatesDialog from '@components/alertManager/DefineTemplatesDialog';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import { useNotifiEnv } from '@components/auth/NotifiEnvContext';
import useClickOutside from 'src/hooks/useClickOutside';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { chains } from '@components/cardConfig/form/CardConfigSelectChain';
import { CustomFusionEventData } from './UIConfigTab';
import CreateCMTopicDialog from '../../components/alertManager/CreateCMTopicDialog';
import TopicCard from '@components/alertManager/TopicCard';
import TopicFilterSelect from '@components/alertManager/TopicFilterSelect';

type Props = {
  topicsData: CustomFusionEventData[];
  cardConfigData: SubscriptionCardConfigItemV1[];
  isLoading: boolean;
};

export type EditTopicState = {
  isVisible: boolean;
  topic?: CustomFusionEventData;
};

const TopicsTab: React.FC<Props> = ({
  topicsData,
  cardConfigData,
  isLoading,
}: Props) => {
  const navigate = useNavigate();
  const { notifiEnv } = useNotifiEnv();
  const normalPermissionTokenKey = useMemo(
    () => `config-tool:${notifiEnv}:normalPermissionToken`,
    [notifiEnv],
  );
  const editParserTooltipRef = useRef<HTMLDivElement>(null);
  const defineTemplatesTooltipRef = useRef<HTMLDivElement>(null);

  const [isCreateTopicModalOpen, setIsCreateTopicModalOpen] =
    React.useState<boolean>(false);
  const [isEditParserModalOpen, setIsEditParserModalOpen] =
    React.useState<boolean>(false);
  const [isEditParserTooltipOpen, setIsEditParserTooltipOpen] =
    React.useState<boolean>(true);
  const [isDefineTemplatesTooltipOpen, setIsDefineTemplatesTooltipOpen] =
    React.useState<boolean>(true);
  const [copyText, setCopyText] = React.useState<string>('');
  const [isCreateCMTopicDialogOpen, setIsCreateCMTopicDialogOpen] =
    React.useState<EditTopicState>({
      isVisible: false,
    });

  const [selectedTopic, setSelectedTopic] = React.useState<
    CustomFusionEventData | undefined
  >(undefined);

  const [filteredTopics, setFilteredTopics] =
    React.useState<CustomFusionEventData[]>(topicsData);

  const { clearMessageBarState } = useFormMessageBarContext();

  const hasSeenDefineTemplateTooltip = localStorage.getItem(
    'hasSeenDefineTemplateTooltip',
  );

  const hasSeenEditParserTooltip = localStorage.getItem(
    'hasSeenEditParserTooltip',
  );

  useClickOutside(editParserTooltipRef, function () {
    localStorage.setItem('hasSeenEditParserTooltip', JSON.stringify(true));
    setIsEditParserTooltipOpen(false);
  });

  useClickOutside(defineTemplatesTooltipRef, function () {
    localStorage.setItem('hasSeenDefineTemplateTooltip', JSON.stringify(true));
    setIsDefineTemplatesTooltipOpen(false);
  });

  const handleCreateTopic = () => {
    setIsCreateTopicModalOpen(true);
  };

  const handleEditParser = (
    event: React.MouseEvent<HTMLButtonElement>,
    copyText: string,
  ) => {
    setIsEditParserModalOpen(true);
    setCopyText(copyText);
    event.stopPropagation();
  };

  const handleTextCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(copyText).catch((err) => {
        console.error('Clipboard write error:', err);
      });
    } else {
      console.error('Clipboard API not supported');
    }
  };

  const findCardConfig = (id: string) => {
    return cardConfigData.filter((item) => {
      return item.eventTypes.find((eventType) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return eventType?.fusionEventId?.value === id;
      });
    });
  };

  const handleEditTopic = (id: string) => {
    clearMessageBarState();
    if (id) {
      const url = `/alert/edit/${id}`;
      navigate(url);
    }
  };

  const showTopicType = (topic: CustomFusionEventData) => {
    const chain = chains.find(
      (chain) =>
        chain.name?.toLowerCase() ===
        topic?.fusionSource?.blockchainSource?.toLowerCase(),
    );
    if (topic.fusionEventCategory === FusionEventCategory.COMMUNITY_MANAGER) {
      return 'Community Manager';
    } else if (topic.fusionEventCategory === FusionEventCategory.GENERAL) {
      if (
        topic?.fusionSource?.blockchainSource === WalletBlockchain.OFF_CHAIN
      ) {
        if (topic?.fusionSource?.name.startsWith('__')) {
          return 'Sent from my server';
        } else {
          return 'Off-chain';
        }
      } else {
        return (
          <Stack flexDirection="row" alignItems="center" sx={{ gap: '8px' }}>
            <img
              src={chain?.image}
              alt={chain?.name}
              width={22}
              height={22}
              style={{ marginBottom: '4px' }}
            />
            <Typography
              sx={{
                color: dusk,
                fontSize: '18px',
                fontWeight: 800,
                lineHeight: '22px',
              }}
            >
              {chain?.name}
            </Typography>
          </Stack>
        );
      }
    } else {
      return '';
    }
  };

  return (
    <>
      <CreateTopicModal
        isCreateTopicModalOpen={isCreateTopicModalOpen}
        setIsCreateTopicModalOpen={setIsCreateTopicModalOpen}
      />

      {isLoading ? null : !topicsData || topicsData.length === 0 ? (
        <EmptyStateCard
          title="Get started by creating your first topic"
          subtitle="Get started by creating the topics your users can subscribe to."
          buttonText="+ Create Topic"
          handleClick={handleCreateTopic}
        />
      ) : (
        <Stack flexDirection="column" alignItems="end">
          {/* uncomment after addressing feedbacks */}
          <TopicFilterSelect
            setFilteredTopics={setFilteredTopics}
            topicsData={topicsData}
          />
          <List sx={{ mt: ' 0 !important', width: '100%' }}>
            {filteredTopics.map((topic, index) => {
              const isNonNotifiHostedTopic =
                topic?.fusionSource?.name.startsWith('__');
              const normalPermissionToken = localStorage.getItem(
                normalPermissionTokenKey,
              );
              const parserSourceId = topic?.fusionSource?.id || '';
              const envText =
                notifiEnv?.toUpperCase() === 'PRD'
                  ? ''
                  : `NOTIFI_ENV="${notifiEnv?.toUpperCase()}"`;
              const copyText = `${envText} FUSION_SOURCE_ID="${parserSourceId}" NOTIFI_AUTH_TOKEN="${normalPermissionToken}" /bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/notifi-network/notifi-parser-sdk/main/start.sh)"`;
              return (
                <TopicCard
                  key={index}
                  index={index}
                  handleEditTopic={handleEditTopic}
                  findCardConfig={findCardConfig}
                  showTopicType={showTopicType}
                  isNonNotifiHostedTopic={isNonNotifiHostedTopic}
                  setSelectedTopic={setSelectedTopic}
                  topic={topic}
                  hasSeenEditParserTooltip={hasSeenEditParserTooltip}
                  hasSeenDefineTemplateTooltip={hasSeenDefineTemplateTooltip}
                  isEditParserTooltipOpen={isEditParserTooltipOpen}
                  setIsEditParserTooltipOpen={setIsEditParserTooltipOpen}
                  setIsDefineTemplatesTooltipOpen={
                    setIsDefineTemplatesTooltipOpen
                  }
                  isDefineTemplatesTooltipOpen={isDefineTemplatesTooltipOpen}
                  editParserTooltipRef={editParserTooltipRef}
                  defineTemplatesTooltipRef={defineTemplatesTooltipRef}
                  handleEditParser={handleEditParser}
                  copyText={copyText}
                  setIsCreateCMTopicDialogOpen={setIsCreateCMTopicDialogOpen}
                />
              );
            })}
          </List>
        </Stack>
      )}
      {isCreateCMTopicDialogOpen.isVisible && (
        <CreateCMTopicDialog
          topicId={isCreateCMTopicDialogOpen?.topic?.id ?? ''}
          currentTopicName={isCreateCMTopicDialogOpen?.topic?.name ?? ''}
          isEdit={true}
          open={true}
          handleClose={() => {
            setIsCreateCMTopicDialogOpen({ isVisible: false });
            clearMessageBarState();
          }}
          metaData={JSON.parse(
            isCreateCMTopicDialogOpen?.topic?.metadata ?? '{}',
          )}
        />
      )}

      <ConfirmDialog
        title="Terminal Command"
        subText="Paste the command in terminal under a folder which you would like to contain your parser source. If you are on windows, paste this into WSL."
        open={isEditParserModalOpen}
        handleClose={() => setIsEditParserModalOpen(false)}
        handleConfirm={handleTextCopy}
        ctaTitle="Copy Command"
        sx={{ width: '600px' }}
        hideSecondaryAction
        showCloseIcon={true}
      >
        <TextField
          multiline
          rows={11}
          disabled
          value={copyText}
          sx={{
            mt: 2,
            mb: 1,
            '& textarea': {
              backgroundColor: eggshell,
              borderRadius: '4px',
              p: '15px 20px',
              fontFamily: 'Rota',
              lineHeight: '20px',
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: seaglassDarker,
                borderRadius: '10px',
              },
            },
            '& .MuiInputBase-root': {
              p: 0,
              border: 'none',
            },
            width: '100%',
          }}
        />
      </ConfirmDialog>
      <DefineTemplatesDialog
        topic={selectedTopic}
        handleClose={() => setSelectedTopic(undefined)}
        open={!!selectedTopic}
      />
    </>
  );
};

export default TopicsTab;
