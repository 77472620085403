import { gql } from '@apollo/client';

export const TopicsQuery = gql`
  query topics($first: Int, $after: String) {
    topics(first: $first, after: $after) {
      nodes {
        targetCollections
        targetTemplate
        topicName
        name
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
