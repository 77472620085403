import { Box, FormControlLabel, Radio, Stack, Typography } from '@mui/material';
import React from 'react';
import { cloud, dusk, eggshell, blueglass, white } from 'src/constants/colors';
import ToggleButton from '../../assets/toggle-button.png';
import { checkboxCustomCSS } from '@components/cardConfig/common/CheckboxCSS';

type Props = {
  name: string;
  topicDisplayName: string;
  filterDescription: string;
  thresholdDirection: string;
  thresholdValues: string[];
  thresholdType: string;
  allowCustomInput: boolean | undefined;
  prefixAndSuffixValue?: string;
  customizedThresholdType?: 'Prefix' | 'Suffix';
};

export const FilterPreviewCard: React.FC<Props> = ({
  name,
  topicDisplayName,
  filterDescription,
  thresholdDirection,
  thresholdValues,
  allowCustomInput,
  prefixAndSuffixValue,
  customizedThresholdType,
}: Props) => {
  const getSign = (num: string) => {
    if (customizedThresholdType && prefixAndSuffixValue) {
      if (customizedThresholdType === 'Prefix')
        return prefixAndSuffixValue + num;
      if (customizedThresholdType === 'Suffix')
        return num + prefixAndSuffixValue;
    }

    return num;
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        backgroundColor: '#FEFEFE',
        width: '367px',
        borderRadius: '8px',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Stack
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          sx={{
            py: 3,
            pl: 4,
            borderBottom: `2px solid ${cloud}`,
            width: '100%',
          }}
          variant="h2"
        >
          Preview
        </Typography>
        {!topicDisplayName ? (
          <Stack sx={{ p: 4, justifyContent: 'center' }}>
            <Typography color={dusk} fontSize={'18px'} fontWeight={700}>
              Your topic preview will display here
            </Typography>
          </Stack>
        ) : (
          <Box
            sx={{
              backgroundColor: eggshell,
              my: 3,
              width: '333px',
              border: '1px solid rgba(36, 36, 36, 0.10)',
              borderRadius: '8px',
              p: '20px',
            }}
          >
            <Stack flexDirection="row" justifyContent="space-between">
              <Typography>{name}</Typography>
              <img src={ToggleButton} height="16px" width="36px" />
            </Stack>
            <Typography
              sx={{ color: dusk, fontSize: '14px', fontWeight: 800, mt: 1 }}
            >
              {filterDescription}
            </Typography>
            {thresholdDirection === 'aboveOrBelowThreshold' ? (
              <Stack flexDirection="row">
                {' '}
                <FormControlLabel
                  label={
                    <Typography sx={{ fontWeight: 700, mt: '2px' }}>
                      Above
                    </Typography>
                  }
                  control={
                    <Radio
                      checked={true}
                      sx={{
                        ...checkboxCustomCSS,
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={
                    <Typography sx={{ fontWeight: 700, mt: '2px' }}>
                      Below
                    </Typography>
                  }
                  control={<Radio checked={false} />}
                />
              </Stack>
            ) : null}
            <Stack
              flexDirection="row"
              flexWrap={'wrap'}
              gap={2}
              sx={{ mt: 2, fontSize: '16px', fontWeight: 600 }}
            >
              <>
                {thresholdValues[0] && thresholdValues[0].length > 0 ? (
                  <Box
                    sx={{
                      height: '47px',
                      minWidth: '78px',
                      backgroundColor: white,
                      borderRadius: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      opacity: 0.7,
                      fontSize: '16px',
                      fontWeight: 600,
                      p: 1,
                    }}
                  >
                    {getSign(thresholdValues[0])}
                  </Box>
                ) : null}

                {thresholdValues[1] && thresholdValues[1].length > 0 ? (
                  <Box
                    sx={{
                      height: '47px',
                      p: 1,
                      minWidth: '78px',
                      backgroundColor: white,
                      borderRadius: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      opacity: 0.7,
                      color: blueglass,
                      border: `2px solid ${blueglass}`,
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                  >
                    {getSign(thresholdValues[1])}
                  </Box>
                ) : null}
              </>

              {allowCustomInput ? (
                <Box
                  sx={{
                    height: '47px',
                    minWidth: '78px',
                    flexGrow: 1,
                    p: 1,
                    backgroundColor: white,
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: 0.7,
                  }}
                >
                  Custom
                </Box>
              ) : null}
            </Stack>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
