import { ContentCopy } from '@mui/icons-material';
import { IconButton, SxProps } from '@mui/material';
import React from 'react';
import { dusk } from 'src/constants/colors';

type Props = {
  copyText: string;
  color?: string | undefined;
  sxIcon?: SxProps;
  handleCopy?: () => void;
};

export const CopyIcon: React.FC<Props> = ({
  copyText,
  color = dusk,
  sxIcon,
  handleCopy,
}) => {
  return (
    <IconButton
      aria-label="copy id"
      sx={{ color, padding: '0' }}
      onClick={() => {
        navigator.clipboard.writeText(copyText);
        handleCopy && handleCopy();
      }}
    >
      <ContentCopy sx={{ height: '16px', width: '16px', ...sxIcon }} />
    </IconButton>
  );
};
