import React, { useState } from 'react';
import { IconButton, Modal, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { dusk, white } from 'src/constants/colors';
import CreateCMTopicDialog from './CreateCMTopicDialog';
import CreateNonCMTopicDialog from './CreateNonCMTopicDialog';
import CreateOnOffChainTopicDialog from './CreateOnOffChainTopicDialog';
import { useGetFusionTopicsData } from './TopicsDataContext';
import cardHeaderImage from '../../assets/notificationCard.png';
import easySubscribeBannner from '../../assets/easy_subscribe_banner.png';
import CreateUIConfigCard from './CreateUIConfigCard';
import { ReactComponent as AnnouncementIcon } from '../../assets/blueglass-announcement.svg';
import { ReactComponent as TelegramIcon } from '../../assets/bluglass-telegram.svg';

type Props = {
  isUICardModalOpen: boolean;
  setIsUICardModalOpen: (isUiCardModalOpen: boolean) => void;
  isCreatingNotificationCard: boolean;
  handleCreateEasySubsConfig: () => void;
  handleCreateNotificationCardConfig: () => void;
};

const CreateUIConfigModal: React.FC<Props> = ({
  isUICardModalOpen,
  setIsUICardModalOpen,
  isCreatingNotificationCard,
  handleCreateEasySubsConfig,
  handleCreateNotificationCardConfig,
}) => {
  const [isCreateCMTopicDialogOpen, setIsCreateCMTopicDialogOpen] =
    useState<boolean>(false);
  const [isCreateNonCMTopicDialogOpen, setIsCreateNonCMTopicDialogOpen] =
    useState<boolean>(false);
  const [isOnOffChainTopicDialogOpen, setIsOnOffChainTopicDialogOpen] =
    useState<boolean>(false);
  const { refetchFusionTopicsData } = useGetFusionTopicsData();

  return (
    <Modal
      open={isUICardModalOpen}
      onClose={() => {
        setIsUICardModalOpen(false);
      }}
      sx={{
        backgroundColor: 'transparent',
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '100%',
          backgroundColor: 'rgba(100, 99, 141, 0.45) !important',
          backdropFilter: 'blur(17.5px)',
        }}
      >
        <CreateCMTopicDialog
          open={isCreateCMTopicDialogOpen}
          handleClose={() => {
            setIsCreateCMTopicDialogOpen(false);
            setIsUICardModalOpen(false);
            refetchFusionTopicsData();
          }}
        />

        {isCreateNonCMTopicDialogOpen ? (
          <CreateNonCMTopicDialog
            open={isCreateNonCMTopicDialogOpen}
            handleClose={() => {
              setIsCreateNonCMTopicDialogOpen(false);
              setIsUICardModalOpen(false);
              refetchFusionTopicsData();
            }}
          />
        ) : null}

        {isOnOffChainTopicDialogOpen ? (
          <CreateOnOffChainTopicDialog
            open={isOnOffChainTopicDialogOpen}
            handleClose={() => {
              setIsOnOffChainTopicDialogOpen(false);
              setIsUICardModalOpen(false);
              refetchFusionTopicsData();
            }}
          />
        ) : null}

        <Typography variant="h1" sx={{ color: white, mb: 3 }}>
          Create UI Config
        </Typography>

        <Stack direction={'row'} columnGap={4}>
          <CreateUIConfigCard
            title={'Notification Card'}
            headImage={cardHeaderImage}
            supportDetails={[
              {
                text: 'All destinations are supported',
                icon: <Stack sx={{mr:0.3, justifySelf:'center'}}><TelegramIcon /></Stack>,
              },
              {
                text: 'All topics (including on-chain) are supported',
                icon: <Stack sx={{ml:0.3, justifySelf:'center'}}><AnnouncementIcon /></Stack>,
              },
            ]}
            description={
              'In the Notification Card users can select which destinations and topics they are interested in, see their Notification History, & manage their preferences.'
            }
            learnMoreLink='https://docs.notifi.network/docs/next/alert-subscribe/react-card/create-card-id'
            handleNextClick={handleCreateNotificationCardConfig}
            isCreatingNotificationCard={isCreatingNotificationCard}
          />
          <CreateUIConfigCard
            title={'Easy Subscribe'}
            headImage={easySubscribeBannner}
            supportDetails={[
              {
                text: 'Email & Telegram (Discord coming soon!)',
                icon: <Stack sx={{mr:0.3, justifySelf:'center'}}><TelegramIcon /></Stack>,
              },
              {
                text: 'Community Manager topics',
                icon: <Stack sx={{ml:0.3, justifySelf:'center'}}><AnnouncementIcon /></Stack>,
              },
            ]}
            description={
              'Users can easily subscribe to Community Manager topics, without creating a Notifi account, managing preferences, or signing their wallet.'
            }
            learnMoreLink='https://docs.notifi.network/docs/alert-subscribe/easy-subscribe'
            handleNextClick={handleCreateEasySubsConfig}
          />
        </Stack>
        <IconButton
          sx={{
            backgroundColor: white,
            borderRadius: '45px',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 1,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: white,
            },
          }}
          onClick={() => {
            setIsUICardModalOpen(false);
          }}
        >
          <CloseIcon sx={{ color: dusk }} />
        </IconButton>
      </Stack>
    </Modal>
  );
};

export default CreateUIConfigModal;
