import { gql } from '@apollo/client';

export const DeleteCardConfigMutation = gql`
  mutation DeleteCardConfig(
    $id: String!
    $type: TenantConfigType = SUBSCRIPTION_CARD
  ) {
    deleteTenantConfig(deleteTenantConfigInput: { id: $id, type: $type }) {
      id
      type
    }
  }
`;
