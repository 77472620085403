import { gql } from '@apollo/client';

export const TenantUsersQuery = gql`
  query TenantUsers($filterRoles: [String!]) {
    tenantUsers(tenantUsersInput: { filterRoles: $filterRoles }) {
      nodes {
        id
        email
        userName
        roles
      }
    }
  }
`;

export type TenantUsersResponse = Readonly<{
  tenantUsers: Readonly<{
    nodes:
      | ReadonlyArray<
          Readonly<{
            id: string;
            email: string;
            userName: string;
            roles: string[];
          }>
        >
      | undefined
      | null;
  }>;
}>;
