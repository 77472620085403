import { Button, Grid, Stack, SxProps, Typography } from '@mui/material';
import React from 'react';
import { dusk, midnightDarker, white } from '../../constants/colors';

type Props = {
  disabled?: true;
  handleClick: () => void;
  isActive: boolean;
  size: 'sm' | 'lg';
  icon: React.ReactNode;
  customSx?: SxProps;
  textSx?: SxProps;
};

//TODO: Research creating a component library between client and config tool
export const LeftMenuButton: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  disabled,
  handleClick,
  icon = null,
  isActive,
  size,
  customSx,
  textSx,
}) => {
  return (
    <Grid
      alignItems="center"
      container
      flexWrap="nowrap"
      justifyContent="space-between"
      sx={{
        height: size === 'lg' ? 75 : 42,
        my: 1,
        pb: size === 'lg' ? 2 : 0,
        px: 0.5,
        width: '100%',
      }}
    >
      <Button
        disabled={disabled ?? false}
        onClick={handleClick}
        disableRipple
        startIcon={icon}
        sx={{
          '& .MuiButton-startIcon': {
            mx: '12px',
          },
          backgroundColor: isActive ? midnightDarker : 'transparent',
          borderRadius: '8px',
          px: 1.5,
          py: 1.5,
          textAlign: 'left',
          width: '100%',
          ...customSx,
        }}
      >
        <Typography
          noWrap
          sx={{
            color: isActive ? white : dusk,
            fontFamily: 'Rota',
            fontSize: size === 'lg' ? 22 : 18,
            fontWeight: 700,
            mt: '4px',
            pl: 1.5,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            ...textSx,
          }}
          variant="h5"
        >
          <Stack
            alignItems={'center'}
            direction={'row'}
            spacing={1}
            sx={{ width: '100%' }}
          >
            <Stack sx={{ width: '100%' }}>{children}</Stack>
          </Stack>
        </Typography>
      </Button>
    </Grid>
  );
};
