import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { dusk, seaglassDarker } from 'src/constants/colors';

type Props = {
  onCreateParser: () => void;
};

const ParserEmpty: React.FC<Props> = ({ onCreateParser }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#FEFEFE',
        padding: '24px 30px 24px 30px',
        borderRadius: '8px',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ fontSize: '22px', fontWeight: 900, mb: '8px' }}>
        Parser
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          color: dusk,
          mb: '16px',
          textAlign:'center'
        }}
      >
        We’ve created a placeholder parser for you. Edit the parser and then set
        it to active to see details here.
      </Typography>
      <PrimaryButton
        buttonTitle="Continue Development"
        customSx={{ height: '40px' }}
        onClick={onCreateParser}
      />
      <Typography
        sx={{
          color: seaglassDarker,
          fontSize: '16px',
          fontWeight: 900,
          p: 0,
          cursor: 'pointer',
          mt: '20px',
        }}
        onClick={() =>
          window.open(
            'https://docs.notifi.network/docs/next/notifi-hosted-development/on-chain-&-off-chain-development',
            '_blank',
          )
        }
      >
        Learn More
      </Typography>
    </Box>
  );
};

export default ParserEmpty;
