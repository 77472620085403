import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CheckIcon } from '../../assets/icn-check-white.svg';
import { ReactComponent as ErrorIcon } from '../../assets/error-icn-white.svg';
import {
  blueglass,
  midnight,
  rose,
  seaglassDarker,
  white,
} from 'src/constants/colors';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { DestinationsType } from 'src/pages/Community/CreateFusionMessageForm';
import { useGetSubscriptionConfigData } from '@components/cardConfig/CardConfigDataContext';
import { isTopicInConfigs } from 'src/util/topics';
import { activeDestinationList } from 'src/util/target';

type DestinationSelectButtonsProps = Readonly<{
  emailSelected: boolean;
  SMSSelected: boolean;
  telegramSelected: boolean;
  discordSelected: boolean;
  slackSelected?: boolean;
  walletAlertSelected?: boolean;
  browserSelected?: boolean;
  setEmailSelected: (emailSelected: boolean) => void;
  setSMSSelected: (SMSSelected: boolean) => void;
  setTelegramSelected: (telegramSelected: boolean) => void;
  setDiscordSelected: (discordSelected: boolean) => void;
  setAlertHistorySelected: (AlertHistorySelected: boolean) => void;
  setSlackSelected?: (slackSelected: boolean) => void;
  setWalletAlertSelected?: (slackSelected: boolean) => void;
  setBrowserSelected?: (browserSelected: boolean) => void;
  selectedTopicName: string;
  isSelectedTelegramBotError: boolean;
  isSelectedDiscordBotError: boolean;
  isEditBroadCastEnabled?: boolean;
}>;

type ButtonsProps = {
  destination: DestinationsType;
  selected?: boolean;
  setSelected?: (v: boolean) => void;
  disabled: boolean;
};

export const DestinationSelectButtons: React.FC<
  DestinationSelectButtonsProps
> = ({
  emailSelected,
  SMSSelected,
  telegramSelected,
  discordSelected,
  slackSelected,
  walletAlertSelected,
  setEmailSelected,
  setSMSSelected,
  setTelegramSelected,
  setDiscordSelected,
  setAlertHistorySelected,
  setSlackSelected,
  setWalletAlertSelected,
  setBrowserSelected,
  browserSelected,
  selectedTopicName,
  isSelectedTelegramBotError,
  isSelectedDiscordBotError,
  isEditBroadCastEnabled,
}) => {
  const { items: configs } = useGetSubscriptionConfigData();
  const { isAP2Enabled } = useFeatureFlag();

  const isShowAllDestinations = !isTopicInConfigs(configs, selectedTopicName);
  const activeDestinations = activeDestinationList(configs, selectedTopicName);

  const [topicActiveDestinations, setTopicActiveDestinations] = useState<
    ButtonsProps[]
  >([]);

  const buttons: ButtonsProps[] = [
    {
      destination: 'Notification Inbox',
      selected: true,
      setSelected: setAlertHistorySelected,
      disabled: false,
    },
    {
      destination: 'Email',
      selected: emailSelected,
      setSelected: setEmailSelected,
      disabled: false,
    },
    {
      destination: 'SMS',
      selected: SMSSelected,
      setSelected: setSMSSelected,
      disabled: false,
    },
    {
      destination: 'Telegram',
      selected: telegramSelected,
      setSelected: setTelegramSelected,
      disabled: false,
    },
    {
      destination: 'Discord',
      selected: discordSelected,
      setSelected: setDiscordSelected,
      disabled: false,
    },
    {
      destination: 'Slack',
      selected: slackSelected,
      setSelected: setSlackSelected,
      disabled: false,
    },
    {
      destination: 'Wallet',
      selected: walletAlertSelected,
      setSelected: setWalletAlertSelected,
      disabled: false,
    },
    {
      destination: 'Browser',
      selected: browserSelected,
      setSelected: setBrowserSelected,
      disabled: false,
    },
  ];

  useEffect(() => {
    if (isShowAllDestinations) {
      setTopicActiveDestinations(buttons);
    } else {
      const filteredDestinations = buttons.filter((button) =>
        activeDestinations.has(
          button.destination.toLowerCase() as
            | 'email'
            | 'sms'
            | 'telegram'
            | 'discord'
            | 'slack'
            | 'wallet'
            | 'browser',
        ),
      );
      setTopicActiveDestinations([
        {
          destination: 'Notification Inbox',
          selected: true,
          setSelected: setAlertHistorySelected,
          disabled: false,
        },
        ...filteredDestinations,
      ]);
    }
  }, [
    selectedTopicName,
    emailSelected,
    SMSSelected,
    telegramSelected,
    discordSelected,
    slackSelected,
    browserSelected,
    walletAlertSelected,
  ]);

  // Reset all destinations when topic is changed except Notification Inbox
  useEffect(() => {
    if (!isShowAllDestinations && !isEditBroadCastEnabled) {
      [
        setEmailSelected,
        setSMSSelected,
        setTelegramSelected,
        setDiscordSelected,
        setSlackSelected,
        setWalletAlertSelected,
        setBrowserSelected,
      ].forEach((setSelected) => setSelected && setSelected(false));
    }
  }, [selectedTopicName, isEditBroadCastEnabled]);

  return (
    <Stack
      flexDirection="row"
      justifyContent="start"
      alignItems="center"
      flexWrap="wrap"
    >
      {topicActiveDestinations.map((button, index) => {
        const { destination, setSelected } = button;
        const isAP2Destination =
          destination === 'Slack' || destination === 'Wallet';
        const isDiscordOrTelegramBotError =
          (isSelectedTelegramBotError && destination === 'Telegram') ||
          (isSelectedDiscordBotError && destination === 'Discord');
        return (
          <React.Fragment key={index}>
            {!isAP2Enabled && isAP2Destination ? null : button.selected ? (
              <PrimaryButton
                startIcon={
                  isDiscordOrTelegramBotError ? <ErrorIcon /> : <CheckIcon />
                }
                customSx={{
                  boxShadow: 'none',
                  color: white,
                  height: '40px',
                  width: '180px',
                  mr: 3,
                  mb: 3,
                  background: isDiscordOrTelegramBotError ? rose : blueglass,
                  '&:hover': {
                    background: isDiscordOrTelegramBotError
                      ? rose
                      : seaglassDarker,
                  },
                }}
                buttonTitle={destination}
                onClick={() => {
                  if (destination === 'Notification Inbox') {
                    setSelected ? setSelected(true) : null;
                  } else {
                    setSelected ? setSelected(false) : null;
                  }
                }}
              />
            ) : (
              <SecondaryButton
                disabled={button.disabled}
                buttonTitle={`+ ${button.destination}`}
                onClick={() => (setSelected ? setSelected(true) : null)}
                customSx={{
                  height: '40px',
                  mb: 3,
                  ...(isAP2Enabled
                    ? { color: midnight, borderColor: midnight }
                    : null),
                  width: '180px',
                  mr: 3,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default DestinationSelectButtons;
