import { useMutation } from '@apollo/client';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { CustomFusionEventData } from 'src/pages/AlertManager/UIConfigTab';
import {
  FusionEventCategory,
  FusionEventsDocument,
  TemplateType,
  useCreateFusionEventMutation,
  useFusionEventsQuery,
  useListTemplatesQuery,
  useTemplateDataQuery,
  useUpdateFusionEventMutation,
  useCreateFusionSourceMutation,
  type CreateFusionSourceInput,
  type CreateFusionSourceResponse,
  useFusionSourcesQuery,
  type UpdateFusionSourceInput,
  type UpdateFusionSourceResponse,
  type AddFusionEventToFusionSourceInput,
  type AddFusionEventToFusionSourceResponse,
  type RemoveFusionEventFromFusionSourceInput,
  type RemoveFusionEventFromFusionSourceResponse,
  useUpdateFusionSourceMutation,
  useAddFusionEventToFusionSourceMutation,
  useRemoveFusionEventFromFusionSourceMutation,
} from 'src/services/gql/generated';
import { DeleteFusionEventMutation } from 'src/services/gql/mutations/DeleteFusionEvent.gql';

export type CustomCreateFusionSourceResponse = Omit<
  CreateFusionSourceResponse,
  'fusionSourcePutUrl'
>;

type TopicsDataContextState = Readonly<{
  loading: boolean;
  create: (
    name: string,
    isCommunityManagerEvent?: boolean,
    metadataJson?: string,
    refetchQueries?: boolean,
  ) => Promise<CustomFusionEventData>;
  update: (
    name: string,
    fusionEventId: string,
    metadataJson?: string,
  ) => Promise<CustomFusionEventData>;
  deleteFusionEvent: (fusionEventId: string) => Promise<void>;
  createFusionSource: (
    args: Omit<CreateFusionSourceInput, 'maxScheduleIntervalInSeconds'>,
  ) => Promise<CustomCreateFusionSourceResponse>;
  updateFusionSource: (
    args: UpdateFusionSourceInput,
  ) => Promise<UpdateFusionSourceResponse>;
  addFusionEventToFusionSource: (
    args: AddFusionEventToFusionSourceInput,
  ) => Promise<AddFusionEventToFusionSourceResponse>;
  removeFusionEventFromFusionSource: (
    args: RemoveFusionEventFromFusionSourceInput,
  ) => Promise<RemoveFusionEventFromFusionSourceResponse>;
}>;

const TopicsDataContext = createContext<TopicsDataContextState>({
  loading: false,
  create: () => Promise.reject('Uninitialized'),
  update: () => Promise.reject('Uninitialized'),
  deleteFusionEvent: () => Promise.reject('Uninitialized'),
  createFusionSource: () => Promise.reject('Uninitialized'),
  updateFusionSource: () => Promise.reject('Uninitialized'),
  addFusionEventToFusionSource: () => Promise.reject('Uninitialized'),
  removeFusionEventFromFusionSource: () => Promise.reject('Uninitialized'),
});

export const useExistingParserData = (first = 50) => {
  const { data, loading } = useFusionSourcesQuery({
    variables: { first },
    fetchPolicy: 'network-only',
  });
  const parserData = data?.fusionSources?.nodes ?? [];

  return {
    isLoading: loading,
    parserData,
  };
};

export const useGetFusionTopicsData = () => {
  const { data, loading, refetch } = useFusionEventsQuery({
    variables: {
      first: 50,
    },
  });

  const topicsData = useMemo(() => {
    return (data?.fusionEvents?.nodes ?? []).filter(
      (topic) =>
        topic.archivedDate === null &&
        (topic.fusionEventCategory === FusionEventCategory.COMMUNITY_MANAGER ||
          topic.fusionEventCategory === FusionEventCategory.GENERAL),
    );
  }, [data, refetch]);

  return {
    isLoading: loading,
    items: topicsData,
    refetchFusionTopicsData: refetch,
  };
};

export const useGetFusionCMTopicsData = () => {
  const { data, loading, refetch } = useFusionEventsQuery({
    variables: {
      fusionEventCategory: FusionEventCategory.COMMUNITY_MANAGER,
      first: 50,
    },
  });
  const topicsData = (data?.fusionEvents?.nodes ?? []).filter(
    (topic) => topic.archivedDate === null,
  );

  return {
    isLoading: loading,
    items: topicsData,
    refetchCMTopicsData: refetch,
  };
};

export const useGetListTemplates = (templateName: string) => {
  const { data, loading, refetch } = useListTemplatesQuery({
    variables: { ListTemplatesInput: { templateName } },
    fetchPolicy: 'network-only',
  });
  const listTemplates = data?.listTemplates?.templates;

  return {
    isLoading: loading,
    items: listTemplates,
    refetchListTemplates: refetch,
  };
};

export const useGetTemplateData = (
  templateName: string,
  type: TemplateType,
) => {
  const { data, loading } = useTemplateDataQuery({
    variables: { GetTemplateDataInput: { templateName, type } },
  });
  const templateData = data?.templateData;

  return {
    isLoading: loading,
    items: templateData,
  };
};

export const TopicsDataContextProvider: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const [createImpl, { loading: createLoading }] =
    useCreateFusionEventMutation();
  const [updateImpl, { loading: updateLoading }] =
    useUpdateFusionEventMutation();
  const [deleteImpl] = useMutation(DeleteFusionEventMutation);
  const [createFS, { loading: createFSLoading }] =
    useCreateFusionSourceMutation();
  const [updateFS, { loading: updateFSLoading }] =
    useUpdateFusionSourceMutation();
  const [
    fusionEventToFusionSource,
    { loading: fusionEventToFusionSourceLoading },
  ] = useAddFusionEventToFusionSourceMutation();
  const [
    removeFusionEventFromFusionSourceMutation,
    { loading: removeFusionEventFromFusionSourceLoading },
  ] = useRemoveFusionEventFromFusionSourceMutation();

  const loading =
    createLoading ||
    updateLoading ||
    createFSLoading ||
    updateFSLoading ||
    fusionEventToFusionSourceLoading ||
    removeFusionEventFromFusionSourceLoading;

  const create = useCallback(
    (
      name: string,
      isCommunityManagerEvent?: boolean,
      metadataJson?: string,
      refetchQueries = true,
    ): Promise<CustomFusionEventData> => {
      return createImpl({
        variables: {
          name,
          isCommunityManagerEvent,
          metadataJson,
        },
        refetchQueries: refetchQueries
          ? [
              {
                query: FusionEventsDocument,
              },
            ]
          : [],
      }).then((response) => {
        const item = response.data?.createFusionEvent ?? null;
        if (item === null) {
          return Promise.reject('Failed to create topic');
        }
        return item;
      });
    },
    [createImpl],
  );

  const update = useCallback(
    (
      name: string,
      fusionEventId: string,
      metadataJson?: string,
    ): Promise<CustomFusionEventData> => {
      return updateImpl({
        variables: {
          name,
          fusionEventId,
          metadataJson,
        },
        refetchQueries: [
          {
            query: FusionEventsDocument,
          },
        ],
      }).then((response) => {
        const item = response.data?.updateFusionEvent ?? null;
        if (item === null) {
          return Promise.reject('Failed to update topic');
        }
        return item;
      });
    },
    [updateImpl],
  );

  const deleteFusionEvent = useCallback(
    (fusionEventId: string): Promise<void> => {
      return deleteImpl({
        variables: {
          fusionEventId,
        },
        refetchQueries: [
          {
            query: FusionEventsDocument,
          },
        ],
      }).then(() => {
        return;
      });
    },
    [deleteImpl],
  );

  const createFusionSource = useCallback(
    (
      args: Omit<CreateFusionSourceInput, 'maxScheduleIntervalInSeconds'>,
    ): Promise<CustomCreateFusionSourceResponse> => {
      return createFS({
        variables: {
          ...args,
        },
        refetchQueries: [
          {
            query: FusionEventsDocument,
            fetchPolicy: 'network-only',
          },
        ],
      }).then((response) => {
        const item =
          response.data?.createFusionSource?.createFusionSourceResponse ?? null;
        if (item === null) {
          return Promise.reject('Failed to create fusion source');
        }
        return item;
      });
    },
    [createFS],
  );

  const updateFusionSource = useCallback(
    (args: UpdateFusionSourceInput): Promise<UpdateFusionSourceResponse> => {
      return updateFS({
        variables: {
          ...args,
        },
      }).then((response) => {
        const item = response.data?.updateFusionSource ?? null;
        if (item === null) {
          return Promise.reject('Failed to update fusion source');
        }
        return item;
      });
    },
    [updateFS],
  );

  const addFusionEventToFusionSource = useCallback(
    (
      args: AddFusionEventToFusionSourceInput,
    ): Promise<AddFusionEventToFusionSourceResponse> => {
      return fusionEventToFusionSource({
        variables: {
          ...args,
        },
        refetchQueries: [
          {
            query: FusionEventsDocument,
            fetchPolicy: 'network-only',
          },
        ],
      }).then((response) => {
        const item = response.data?.addFusionEventToFusionSource ?? null;
        if (item === null) {
          return Promise.reject('Failed to add fusion event to fusion source');
        }
        return item;
      });
    },
    [fusionEventToFusionSource],
  );

  const removeFusionEventFromFusionSource = useCallback(
    (
      args: RemoveFusionEventFromFusionSourceInput,
    ): Promise<RemoveFusionEventFromFusionSourceResponse> => {
      return removeFusionEventFromFusionSourceMutation({
        variables: {
          ...args,
        },
      }).then((response) => {
        const item = response.data?.removeFusionEventFromFusionSource ?? null;
        if (item === null) {
          return Promise.reject(
            'Failed to remove fusion event from fusion source',
          );
        }
        return item;
      });
    },
    [removeFusionEventFromFusionSourceMutation],
  );

  return (
    <TopicsDataContext.Provider
      value={{
        loading,
        create,
        update,
        deleteFusionEvent,
        createFusionSource,
        updateFusionSource,
        addFusionEventToFusionSource,
        removeFusionEventFromFusionSource,
      }}
    >
      {children}
    </TopicsDataContext.Provider>
  );
};

export const useTopicsDataContext = (): TopicsDataContextState => {
  return useContext(TopicsDataContext);
};
