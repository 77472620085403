import { gql } from '@apollo/client';

export const is2faQuery = gql`
 query is2fa {
    totp2FAStatus {
      isAdded
      isRequired
      isEnforced
    }
  }
`;
