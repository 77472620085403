import { gql } from '@apollo/client';
export const AddUserMutation = gql`
  mutation AddUser($email: String!, $roles: [String!]!) {
    createOrUpdateUser(
      createOrUpdateUserInput: { email: $email, roles: $roles }
    ) {
      id
      email
      roles
      errors {
        ... on InvalidEmailError {
          message
        }
        ... on RequiresElevatedTokenError {
          code
          message
        }
      }
    }
  }
`;