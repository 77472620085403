import { ApolloClient, InMemoryCache } from '@apollo/client';
import React, {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNotifiEnv } from './NotifiEnvContext';
import { NotifiEnvironment, getApiUrl } from './envUtils';
import { LoggedIn } from './LoggedIn';
import { isEmpty } from 'lodash';
import { isTOTPBackupCode, sanitizeStringInput } from 'src/util/stringUtils';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { LogInMutation } from 'src/services/gql/mutations/LogInMutation.gql';
import {
  SubmitLoginMutation as SubmitLoginMutationResponse,
  CreateTenantUserMutation as CreateTenantUserMutationResponse,
  CreateTenantUserMutationVariables,
  ConfirmEmailMutation as ConfirmEmailMutationResponse,
  GenerateEmailConfirmationTokenMutation as GenerateEmailConfirmationTokenMutationResponse,
  SendResetPasswordEmailMutation as SendResetPasswordEmailMutationResponse,
  ResetPasswordMutation as ResetPasswordMutationResponse,
  ResetPasswordMutationVariables,
} from 'src/services/gql/generated';
import { SignUpMutation } from 'src/services/gql/mutations/SignUpMutation.gql';
import {
  GenerateEmailConfirmationTokenMutation,
  ConfirmEmail,
} from 'src/services/gql/mutations/EmailConfirmationMutation.gql';
import { SendResetPasswordMutation } from 'src/services/gql/mutations/UserSendPasswordResetEmailMutation.gql';
import { ResetPasswordMutation } from 'src/services/gql/mutations/UserPasswordResetMutation.gql';
import { totp2FARequiredForEmailLoginsQuery } from 'src/services/gql/queries/totp2FARequiredForEmailLoginsQuery.gql';
import { GenerateRefreshAuthorization } from 'src/services/gql/mutations/GenerateRefreshAuthorization.gql';
import { GenerateNormalPermissionsAuthorization } from 'src/services/gql/mutations/GenerateNormalPermissionsAuthorization.gql';
import { GenerateElevatedPermissionsAuthorization } from 'src/services/gql/mutations/GenerateElevatedPermissionsAuthorization.gql';
import { useGlobal2FaAuthModal } from './GlobalModalContext';
import { useNavigate } from 'react-router-dom';
import { is2faQuery } from 'src/services/gql/queries/Is2FaQuery.gql';

export type RoleType =
  | 'User'
  | 'UserMessenger'
  | 'TenantConfigAdmin'
  | 'TenantMessenger'
  | 'TenantDeveloper';

export function notNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value != null;
}

export type UninitializedState = Readonly<{
  type: 'uninitialized';
}>;

export type ResumePromise = ({
  twoFactorAnswer,
}: {
  twoFactorAnswer?: string;
}) => void;

export type LoggedOutState = Readonly<{
  type: 'loggedOut';
  notifiEnv: NotifiEnvironment;
  loading: boolean;
  logIn: (email: string, password: string) => Promise<void>;
  generateEmailConfirmationToken: (email: string) => Promise<boolean>;
  confirmEmail: (email: string, token: string) => Promise<boolean>;
  sendResetPassword: (email: string) => Promise<boolean>;
  setNewPassword: ({
    email,
    password,
    tfaCode,
  }: ResetPasswordMutationVariables) => Promise<boolean>;
  signUp: (
    signupUserInput: CreateTenantUserMutationVariables,
  ) => Promise<CreateTenantUserMutationResponse | undefined>;
  generateRefreshToken: (
    initialToken: string,
    twoFactorAnswer: any,
  ) => Promise<string>;
  generateNormalPermissionsToken: (refreshToken: string) => Promise<{
    normalPermissionToken: string;
    refreshToken: string;
    normalPermissionsTokenExpiry: string;
  }>;
  loginWith2FaCode: (twoFactorAnswer: string) => void;
}>;

export type LoggedInState = Readonly<{
  type: 'loggedIn';
  notifiEnv: NotifiEnvironment;
  initialToken: string | null;
  normalPermissionToken: string | null;
  roles: (RoleType | undefined)[] | undefined;
  generateEmailConfirmationToken: (email: string) => Promise<boolean>;
  confirmEmail: (email: string, token: string) => Promise<boolean>;
  signUp: (
    signupUserInput: CreateTenantUserMutationVariables,
  ) => Promise<CreateTenantUserMutationResponse | undefined>;
  logOut: () => void;
  generateRefreshToken: (
    initialToken: string,
    twoFactorAnswer: any,
  ) => Promise<string>;
  generateNormalPermissionsToken: (refreshToken: string) => Promise<{
    normalPermissionToken: string;
    refreshToken: string;
    normalPermissionsTokenExpiry: string;
  }>;
  setNormalPermissionToken: React.Dispatch<React.SetStateAction<string | null>>;
  generateElevatedPermissionsAuthorizationToken: (
    normalPermissionToken: string,
  ) => Promise<{
    elevatedPermissionsToken: string;
    elevatedPermissionsTokenExpiry: string;
  }>;
  reuseElevatedTokenRef: MutableRefObject<boolean>;
}>;

export type AuthState = LoggedOutState | LoggedInState;

const AuthContext = createContext<AuthState>({} as AuthState);

export const AuthProvider: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children }) => {
  const { notifiEnv } = useNotifiEnv();
  const navigate = useNavigate();
  const { setError: setApiValidationError } = useGlobal2FaAuthModal();
  const tokenKey = useMemo(() => `config-tool:${notifiEnv}:token`, [notifiEnv]);
  const initialTokenKey = useMemo(
    () => `config-tool:${notifiEnv}:initialToken`,
    [notifiEnv],
  );
  const refreshTokenKey = useMemo(
    () => `config-tool:${notifiEnv}:refreshToken`,
    [notifiEnv],
  );
  const normalPermissionTokenKey = useMemo(
    () => `config-tool:${notifiEnv}:normalPermissionToken`,
    [notifiEnv],
  );
  const initialTokenExpiryKey = useMemo(
    () => `${initialTokenKey}:expiry`,
    [initialTokenKey],
  );
  const normalPermissionsTokenExpiryKey = useMemo(
    () => `${normalPermissionTokenKey}:expiry`,
    [normalPermissionTokenKey],
  );

  const { clearMessageBarState } = useFormMessageBarContext();
  const [loading, setLoading] = useState<boolean>(false);

  const [token, setToken] = useState<string | null>(() =>
    localStorage.getItem(tokenKey),
  );

  const [initialToken, setInitialToken] = useState<string | null>(() =>
    localStorage.getItem(initialTokenKey),
  );
  const [normalPermissionToken, setNormalPermissionToken] = useState<
    string | null
  >(() => localStorage.getItem('normalPermissionToken'));
  const [roles, setRoles] = useState<(RoleType | undefined)[] | undefined>([]);
  const reuseElevatedTokenRef = useRef(false);

  const globalModalState = useGlobal2FaAuthModal();

  useEffect(() => {
    setNormalPermissionToken(localStorage.getItem(normalPermissionTokenKey));
    const roles = localStorage.getItem('roles');
    if (roles !== null) {
      setRoles(JSON.parse(roles));
    }
  }, [normalPermissionTokenKey]);

  const unauthenticatedApolloClient = useMemo(() => {
    return new ApolloClient({
      uri: getApiUrl(notifiEnv),
      cache: new InMemoryCache(),
    });
  }, [notifiEnv]);

  const logOut = useCallback(() => {
    localStorage.removeItem(initialTokenKey);
    localStorage.removeItem(refreshTokenKey);
    localStorage.removeItem(normalPermissionTokenKey);
    localStorage.removeItem('roles');
    setToken(null);
    setInitialToken(null);
    setNormalPermissionToken(null);
    setRoles([]);
  }, [initialTokenKey, refreshTokenKey, normalPermissionTokenKey]);

  const generateEmailConfirmationToken = useCallback(
    (email: string) => {
      return unauthenticatedApolloClient
        .mutate<GenerateEmailConfirmationTokenMutationResponse>({
          mutation: GenerateEmailConfirmationTokenMutation,
          variables: { email },
        })
        .then((result) => {
          return result.data?.generateEmailConfirmationToken ?? false;
        })
        .catch((e) => {
          console.log('Failed to generate email confirmation token', e);
          return false;
        });
    },
    [unauthenticatedApolloClient],
  );

  const confirmEmail = useCallback(
    (email: string, token: string) => {
      return unauthenticatedApolloClient
        .mutate<ConfirmEmailMutationResponse>({
          mutation: ConfirmEmail,
          variables: { email, token },
        })
        .then((result) => {
          return result.data?.confirmEmail ?? false;
        })
        .catch((e) => {
          console.log('Failed email confirmation', e);
          return false;
        });
    },
    [unauthenticatedApolloClient],
  );

  const check2FAStatus = (token: string) => {
    return unauthenticatedApolloClient
      .query({
        query: totp2FARequiredForEmailLoginsQuery,
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        return result?.data?.totp2FARequiredForEmailLogins?.totp2faRequired;
      })
      .catch((error) => {
        console.error('Error checking 2FA status:', error);
        throw error;
      });
  };

  const generateRefreshToken = (
    token: string,
    twoFactorAnswer?: any,
  ): Promise<string> => {
    return unauthenticatedApolloClient
      .mutate({
        mutation: GenerateRefreshAuthorization,
        variables: {
          input: {
            twoFactorAnswer: twoFactorAnswer
              ? { totpAnswer: { totpCode: twoFactorAnswer } }
              : undefined,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          fetchPolicy: 'no-cache',
        },
      })
      .then((result) => {
        const refreshToken =
          result?.data?.generateRefreshAuthorization?.authorization?.token;
        if (!refreshToken) {
          return Promise.reject(new Error('Failed to fetch refresh token'));
        }
        return refreshToken;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const generateNormalPermissionsToken = (
    refreshToken: string,
  ): Promise<{
    normalPermissionToken: string;
    refreshToken: string;
    normalPermissionsTokenExpiry: string;
  }> => {
    return unauthenticatedApolloClient
      .mutate({
        mutation: GenerateNormalPermissionsAuthorization,
        variables: {},
        context: {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
          fetchPolicy: 'no-cache',
        },
      })
      .then((result) => {
        const refreshToken =
          result.data.generateNormalPermissionsAuthorization
            .newRefreshAuthorization.token;
        const normalPermissionToken =
          result.data.generateNormalPermissionsAuthorization
            .normalPermissionsAuthorization.token;
        const normalPermissionsTokenExpiry =
          result.data.generateNormalPermissionsAuthorization
            .normalPermissionsAuthorization.expiry;
        if (!normalPermissionToken) {
          return Promise.reject(new Error('Failed to fetch refresh token'));
        }
        return {
          normalPermissionToken,
          refreshToken,
          normalPermissionsTokenExpiry,
        };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const generateElevatedPermissionsAuthorizationToken = (
    normalPermissionToken: string,
  ): Promise<{
    elevatedPermissionsToken: string;
    elevatedPermissionsTokenExpiry: string;
  }> => {
    return new Promise((resolve, reject) => {
      check2FAStatus(normalPermissionToken)
        .then((is2FARequired) => {
          if (is2FARequired) {
            globalModalState.openModal?.((twoFactorAnswer, isCanceled) => {
              if (isCanceled) {
                globalModalState.setIsLoading(false);
                reject(new Error('User canceled two-factor authentication'));
                return;
              } else if (!isCanceled && twoFactorAnswer) {
                globalModalState.setIsLoading(true);
                fetchElevatedPermissionsToken(
                  normalPermissionToken,
                  twoFactorAnswer,
                )
                  .then(
                    ({
                      elevatedPermissionsToken,
                      elevatedPermissionsTokenExpiry,
                    }) => {
                      resolve({
                        elevatedPermissionsToken,
                        elevatedPermissionsTokenExpiry,
                      });
                      globalModalState.setIsLoading(false);
                      globalModalState.closeModal?.();
                    },
                  )
                  .catch((error) => {
                    globalModalState.setIsLoading(false);
                    globalModalState.setError?.(
                      error?.networkError?.result?.errors[0]?.message,
                    );
                  });
              }
            });
          } else {
            fetchElevatedPermissionsToken(normalPermissionToken)
              .then(resolve)
              .catch(reject);
          }
        })
        .catch(reject);
    });
  };

  const fetchElevatedPermissionsToken = (
    normalPermissionToken: string,
    twoFactorAnswer?: any,
  ): Promise<{
    elevatedPermissionsToken: string;
    elevatedPermissionsTokenExpiry: string;
  }> => {
    return unauthenticatedApolloClient
      .mutate({
        mutation: GenerateElevatedPermissionsAuthorization,
        variables: {
          input: {
            twoFactorAnswer:
              twoFactorAnswer && isTOTPBackupCode(twoFactorAnswer)
                ? { backupCodeAnswer: { backupCode: twoFactorAnswer } }
                : twoFactorAnswer && !isTOTPBackupCode(twoFactorAnswer)
                ? { totpAnswer: { totpCode: twoFactorAnswer } }
                : undefined,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${normalPermissionToken}`,
          },
        },
      })
      .then((result) => {
        const elevatedPermissionsToken =
          result.data.generateElevatedPermissionsAuthorization.authorization
            .token;
        const elevatedPermissionsTokenExpiry =
          result.data.generateElevatedPermissionsAuthorization.authorization
            .expiry;
        if (!elevatedPermissionsToken) {
          return Promise.reject(
            new Error('Failed to fetch elevated permission token'),
          );
        }
        return { elevatedPermissionsToken, elevatedPermissionsTokenExpiry };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const check2FASetup = (refreshToken: string) => {
    return unauthenticatedApolloClient
      .query({
        query: is2faQuery,
        variables: {},
        context: {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        return {
          isRequired: result.data.totp2FAStatus.isRequired,
          isAdded: result.data.totp2FAStatus.isAdded,
        };
      })
      .catch((error) => {
        console.error('Error checking 2FA setup:', error);
        throw error;
      });
  };

  const logIn = (email: string, password: string) => {
    setLoading(true);
    let isTOTP2FaRequired: boolean;
    let isTOTP2FaSetup: boolean;
    let roles: (RoleType | undefined)[] | undefined;
    let initialToken: string;
    let refreshToken: string;
    let normalPermissionToken: string;
    let normalPermissionsTokenExpiry: string;
    return unauthenticatedApolloClient
      .mutate<SubmitLoginMutationResponse>({
        mutation: LogInMutation,
        variables: {
          email,
          password,
        },
      })
      .then((result) => {
        const initialtoken =
          result.data?.logInByEmailAddress?.authorization?.token;
        const initialTokenExpiry =
          result.data?.logInByEmailAddress?.authorization?.expiry;
        const rolesData = result.data?.logInByEmailAddress?.roles ?? undefined;
        const parsedRoles =
          rolesData != null && rolesData.length > 0
            ? rolesData
                .filter(notNullOrUndefined)
                .map((role) => role as RoleType)
            : [];

        if (!initialtoken || !initialTokenExpiry) {
          return Promise.reject(new Error('Failed to log in'));
        }

        localStorage.setItem(initialTokenKey, initialtoken);
        localStorage.setItem(`${initialTokenKey}:expiry`, initialTokenExpiry);
        localStorage.setItem('roles', JSON.stringify(parsedRoles));
        initialToken = initialtoken;
        roles = rolesData as (RoleType | undefined)[];
        setRoles(roles);
        setInitialToken(initialToken);
        return;
      })
      .then(() => {
        return check2FASetup(initialToken).then(({ isRequired, isAdded }) => {
          isTOTP2FaRequired = isRequired;
          isTOTP2FaSetup = isAdded;
          return;
        });
      })
      .then(() => {
        if (isTOTP2FaRequired && !isTOTP2FaSetup) {
          navigate('/2fa-setup', { state: { email } });
          throw new Error('LOGIN_MOVED_TO_loginWith2FaCode_HANDLER');
          // TODO: Replace this exception based control flow with better aleternative
          // Check https://notifi.atlassian.net/browse/MVP-5024
        }
        return;
      })
      .then(() => {
        return check2FASetup(initialToken).then(({ isRequired, isAdded }) => {
          isTOTP2FaRequired = isRequired;
          isTOTP2FaSetup = isAdded;
          return;
        });
      })
      .then(() => {
        if (isTOTP2FaRequired && isTOTP2FaSetup) {
          navigate('/2fa-login');
          throw new Error('LOGIN_MOVED_TO_loginWith2FaCode_HANDLER');
          // TODO: Replace this exception based control flow with better aleternative
          // Check https://notifi.atlassian.net/browse/MVP-5024
        }
        return;
      })
      .then(() => {
        return generateRefreshToken(initialToken).then((token) => {
          if (!token) {
            return Promise.reject(new Error('Failed to log in'));
          }
          refreshToken = token;
          return;
        });
      })
      .then(() => {
        return generateNormalPermissionsToken(refreshToken).then((result) => {
          if (!result.normalPermissionToken && !result.refreshToken) {
            return Promise.reject(new Error('Failed to log in'));
          }
          normalPermissionToken = result.normalPermissionToken;
          refreshToken = result.refreshToken;
          normalPermissionsTokenExpiry = result.normalPermissionsTokenExpiry;
          return;
        });
      })
      .then(() => {
        localStorage.setItem(refreshTokenKey, refreshToken);
        localStorage.setItem(normalPermissionTokenKey, normalPermissionToken);
        localStorage.setItem(
          normalPermissionsTokenExpiryKey,
          normalPermissionsTokenExpiry,
        );
        setNormalPermissionToken(normalPermissionToken);
        setLoading(false);
        clearMessageBarState();
      })
      .catch((e) => {
        setLoading(false);
        if (e.message !== 'LOGIN_MOVED_TO_loginWith2FaCode_HANDLER') {
          // Prevent displaying an error message if the user moves to the 2FA section to continue logging in.
          throw e;
        }
      });
  };

  const loginWith2FaCode = (twoFactorAnswer: string) => {
    setLoading(true);
    setApiValidationError && setApiValidationError('');
    if (!initialToken) {
      navigate('/login');
      return;
    }
    let refreshToken: string;
    let normalPermissionToken: string;
    let normalPermissionsTokenExpiry: string;

    generateRefreshToken(initialToken, twoFactorAnswer)
      .then((token) => {
        if (!token) {
          return Promise.reject(new Error('Failed to log in'));
        }
        refreshToken = token;
        return;
      })
      .then(() => {
        return generateNormalPermissionsToken(refreshToken).then((result) => {
          if (!result.normalPermissionToken && !result.refreshToken) {
            return Promise.reject(new Error('Failed to log in'));
          }
          normalPermissionToken = result.normalPermissionToken;
          refreshToken = result.refreshToken;
          normalPermissionsTokenExpiry = result.normalPermissionsTokenExpiry;
          return;
        });
      })
      .then(() => {
        localStorage.setItem(refreshTokenKey, refreshToken);
        localStorage.setItem(normalPermissionTokenKey, normalPermissionToken);
        localStorage.setItem(
          normalPermissionsTokenExpiryKey,
          normalPermissionsTokenExpiry,
        );
        setNormalPermissionToken(normalPermissionToken);
        setLoading(false);
        clearMessageBarState();
      })
      .catch((err) => {
        setLoading(false);
        if (setApiValidationError) {
          setApiValidationError(err?.networkError?.result?.errors[0]?.message);
        }
      });
  };

  const signUp = useCallback(
    (signupInput: CreateTenantUserMutationVariables) => {
      const { email, password } = signupInput;
      setLoading(true);

      const sanitizedEmail = sanitizeStringInput(email);

      if (isEmpty(sanitizedEmail)) {
        return Promise.reject('Email cannot be empty');
      }

      if (isEmpty(password)) {
        return Promise.reject('Password cannot be empty');
      }

      return unauthenticatedApolloClient
        .mutate<CreateTenantUserMutationResponse>({
          mutation: SignUpMutation,
          variables: { ...signupInput, email: sanitizedEmail },
        })
        .then((response) => {
          setLoading(false);
          const result = response.data;

          if (result === null) {
            return Promise.reject('Failed to sign up user');
          }

          return result;
        })
        .catch((e) => {
          setLoading(false);
          throw e;
        });
    },
    [unauthenticatedApolloClient],
  );

  // Sends password reset with token which needs to be used in 'setNewPassword'
  const sendResetPassword = useCallback(
    (email: string) => {
      setLoading(true);

      const sanitizedEmail = sanitizeStringInput(email);

      if (isEmpty(sanitizedEmail)) {
        return Promise.reject('Email cannot be empty');
      }

      return unauthenticatedApolloClient
        .mutate<SendResetPasswordEmailMutationResponse>({
          mutation: SendResetPasswordMutation,
          variables: { email: sanitizedEmail },
        })
        .then((response) => {
          setLoading(false);
          if (response === null) {
            return Promise.reject('Failed to set new password');
          }
          return response.data?.sendResetPasswordEmail ?? false;
        })
        .catch((e) => {
          setLoading(false);
          throw e;
        });
    },
    [unauthenticatedApolloClient],
  );

  const setNewPassword = useCallback(
    (resetPasswordInput: ResetPasswordMutationVariables) => {
      const { email, password, tfaCode } = resetPasswordInput;
      const sanitizedEmail = sanitizeStringInput(email);

      if (isEmpty(sanitizedEmail)) {
        return Promise.reject('Email cannot be empty');
      }

      if (isEmpty(password)) {
        return Promise.reject('Password is empty');
      }

      if (isEmpty(tfaCode)) {
        return Promise.reject('tfaCode is empty');
      }

      return unauthenticatedApolloClient
        .mutate<ResetPasswordMutationResponse>({
          mutation: ResetPasswordMutation,
          variables: resetPasswordInput,
        })
        .then((response) => {
          setLoading(false);
          if (response === null) {
            return Promise.reject('Failed to reset password');
          }
          return response.data?.resetPassword ?? false;
        })
        .catch((e) => {
          setLoading(false);
          throw e;
        });
    },
    [unauthenticatedApolloClient],
  );

  const state = useMemo<AuthState>(() => {
    if (token !== null || normalPermissionToken !== null) {
      return {
        type: 'loggedIn',
        notifiEnv,
        roles,
        initialToken,
        normalPermissionToken,
        logOut,
        generateEmailConfirmationToken,
        confirmEmail,
        signUp,
        generateRefreshToken,
        setNormalPermissionToken,
        generateNormalPermissionsToken,
        generateElevatedPermissionsAuthorizationToken,
        reuseElevatedTokenRef,
      };
    } else {
      return {
        type: 'loggedOut',
        notifiEnv,
        loading,
        generateEmailConfirmationToken,
        confirmEmail,
        logIn,
        signUp,
        setNewPassword,
        sendResetPassword,
        generateRefreshToken,
        generateNormalPermissionsToken,
        generateElevatedPermissionsAuthorizationToken,
        loginWith2FaCode,
      };
    }
  }, [
    initialTokenExpiryKey,
    initialToken,
    token,
    unauthenticatedApolloClient,
    initialTokenKey,
    notifiEnv,
    roles,
    normalPermissionToken,
    logOut,
    generateEmailConfirmationToken,
    confirmEmail,
    signUp,
    loading,
    logIn,
    setNewPassword,
    sendResetPassword,
    setNormalPermissionToken,
    generateNormalPermissionsToken,
    loginWith2FaCode,
  ]);

  let contents = children;
  if (state.type === 'loggedIn') {
    contents = (
      <LoggedIn normalPermissionToken={state.normalPermissionToken}>
        {children}
      </LoggedIn>
    );
  }

  return <AuthContext.Provider value={state}>{contents}</AuthContext.Provider>;
};

export const useAuthContext = (): AuthState => {
  return useContext(AuthContext);
};
