import { Box, Stack, SxProps, Typography } from '@mui/material';
import React from 'react';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { dusk } from 'src/constants/colors';

type Props = {
  title: string;
  subtitle: string;
  buttonText: string;
  titleSx?: SxProps;
  subtitleSx?: SxProps;
  modeLabel?: string;
  handleClick: () => void;
  disabled?: boolean;
};

export const EmptyStateCard: React.FC<Props> = ({
  disabled,
  title,
  subtitle,
  buttonText,
  titleSx,
  subtitleSx,
  modeLabel,
  handleClick,
}: Props) => {
  return (
    <Box
      onClick={handleClick}
      sx={{
        mt: 1,
        backgroundColor: '#FEFEFE',
        width: '100%',
        padding: '24px 40px 24px 40px',
        borderRadius: '8px',
        minWidth: '700px',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
        cursor: 'pointer',
        height: '110px',
      }}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack flexDirection="column" sx={{ padding: 0 }}>
          {modeLabel ? (
            <Typography
              sx={{ fontSize: '12px', color: '#6E79EC', fontWeight: 800 }}
            >
              {modeLabel}
            </Typography>
          ) : null}
          <Typography variant="h2" sx={{ ...titleSx }}>
            {title}
          </Typography>
          <Typography variant="h3" sx={{ ...subtitleSx, color: dusk, mt: 1 }}>
            {subtitle}
          </Typography>
        </Stack>

        <PrimaryButton
          disabled={disabled}
          buttonTitle={buttonText}
          customSx={{ height: '40px', width: '146px', mt: '5px' }}
          onClick={handleClick}
        />
      </Stack>
    </Box>
  );
};
