import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  dusk,
  eggshell,
  midnight,
  midnightDarker,
  midnightLight,
  white,
} from 'src/constants/colors';
type Props = {
  title: string;
  disabled?: boolean;
  handleClose?: () => void;
  handleConfirm: () => void;
  loading?: boolean;
  open: boolean;
  url: string;
  text?: string;
  setText: (text: string) => void;
  setUrl: (url: string) => void;
  label?: string;
  secondLable?: string;
  message?: string;
  placeholder?: string;
  primaryButtonText?: string;
  noTextInput?: boolean;
  tipText?: string;
};
const InsertModal: React.FC<React.PropsWithChildren<Props>> = ({
  disabled,
  handleClose,
  handleConfirm,
  label,
  secondLable,
  message,
  noTextInput = false,
  open,
  placeholder,
  primaryButtonText = 'Ok',
  setText,
  setUrl,
  text,
  title,
  url,
  tipText,
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  return (
    <Dialog
      fullWidth={false}
      maxWidth="md"
      onClose={handleClose}
      open={open}
      sx={{
        width: '100%',
        '& .MuiPaper-root': {
          borderRadius: '15px',
        },
      }}
    >
      <Stack
        flexDirection="column"
        justifyContent="center"
        sx={{
          p: 3,
          width: '513px',
          backgroundColor: isAP2Enabled ? white : midnightLight,
        }}
      >
        {' '}
        <Grid
          container
          flexWrap="nowrap"
          alignItems="start"
          justifyContent="center"
        >
          <DialogTitle
            sx={{
              color: isAP2Enabled ? midnight : eggshell,
              fontFamily: 'Rota',
              fontSize: '18px',
              fontWeight: 700,
              p: 1,
              textAlign: 'center',
              width: '100%',
            }}
          >
            {title}
          </DialogTitle>
          <Box
            sx={{
              width: '25px',
              height: '25px',
              cursor: 'pointer',
              position: 'absolute',
              right: '20px',
              top: '34px',
            }}
            onClick={handleClose}
          >
            <Close sx={{ color: dusk }} />
          </Box>
        </Grid>
        <DialogContent
          sx={{
            alignItems: 'center',
            color: midnight,
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'NeueMontreal',
            fontSize: '16px',
            fontWeight: 400,
            justifyContent: 'center',
            lineHeight: '22px',
            mb: 1,
            mt: 2,
            textAlign: 'center',
          }}
        >
          {noTextInput ? null : (
            <>
              <Stack alignItems="start" flexDirection="column">
                <Typography sx={{ color: dusk, fontSize: '14px' }}>
                  {label}
                </Typography>
                <TextField
                  onChange={(e) => setText(e.target.value)}
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    '& .MuiOutlinedInput-root': {
                      padding: 0,
                    },
                    '& .MuiOutlinedInput-input': {
                      backgroundColor: isAP2Enabled ? eggshell : midnightDarker,
                      borderRadius: '4px',
                    },
                    border: 0,
                    height: '49px',
                    width: '359px',
                  }}
                  value={text}
                  variant="outlined"
                />
              </Stack>
              <Typography
                sx={{
                  display: 'inline',
                  fontFamily: 'RotaBold',
                  fontSize: '18px',
                  lineHeight: '22px',
                  my: 3,
                }}
              >
                {message}
              </Typography>
            </>
          )}
          {tipText ? (
            <Typography
              sx={{
                display: 'inline',
                fontFamily: 'RotaBold',
                fontSize: '18px',
                lineHeight: '22px',
                my: 3,
              }}
            >
              {tipText}
            </Typography>
          ) : null}
          <Stack alignItems="start" flexDirection="column">
            <Typography sx={{ color: dusk, fontSize: '14px' }}>
              {secondLable}
            </Typography>
            <TextField
              onChange={(e) => setUrl(e.target.value)}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ ml: 1 }} position="start">
                    {placeholder}
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
                '& .MuiOutlinedInput-root': {
                  padding: 0,
                },
                '& .MuiOutlinedInput-input': {
                  backgroundColor: isAP2Enabled ? eggshell : midnightDarker,
                  borderRadius: '4px',
                },
                border: 0,
                height: '49px',
                width: '359px',
              }}
              value={url}
              variant="outlined"
            />
          </Stack>
        </DialogContent>
        <Stack
          alignItems="center"
          direction={'row'}
          gap={3}
          justifyContent="center"
        >
          <SecondaryButton
            onClick={handleClose}
            customSx={{
              height: '40px',
              width: '127px',
            }}
            buttonTitle="Cancel"
          />
          <PrimaryButton
            disabled={disabled}
            onClick={handleConfirm}
            customSx={{
              height: '40px',
              mb: '0px',
              width: '127px',
            }}
            buttonTitle={primaryButtonText}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};
export default InsertModal;
