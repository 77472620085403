import React, { useState } from 'react';
import { IconButton, Modal, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CreateTopicCard from './CreateTopicCard';
import { dusk, white } from 'src/constants/colors';
import { ReactComponent as LightningImg } from '../../assets/img-lightning.svg';
import { ReactComponent as CubeImg } from '../../assets/img-cube.svg';
import { ReactComponent as TrumpetImg } from '../../assets/img-trumpet-topic.svg';
import CreateCMTopicDialog from './CreateCMTopicDialog';
import CreateNonCMTopicDialog from './CreateNonCMTopicDialog';
import CreateOnOffChainTopicDialog from './CreateOnOffChainTopicDialog';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { useGetFusionTopicsData } from './TopicsDataContext';

type Props = {
  isCreateTopicModalOpen: boolean;
  setIsCreateTopicModalOpen: (isCreateTopicModalOpen: boolean) => void;
};

const CreateTopicModal: React.FC<Props> = ({
  isCreateTopicModalOpen,
  setIsCreateTopicModalOpen,
}) => {
  const { isFlagVersion19, isFlagVersion195 } = useFeatureFlag();
  const [isCreateCMTopicDialogOpen, setIsCreateCMTopicDialogOpen] =
    useState<boolean>(false);
  const [isCreateNonCMTopicDialogOpen, setIsCreateNonCMTopicDialogOpen] =
    useState<boolean>(false);
  const [isOnOffChainTopicDialogOpen, setIsOnOffChainTopicDialogOpen] =
    useState<boolean>(false);

  const handleCreateCMTopicClick = () => {
    setIsCreateCMTopicDialogOpen(true);
  };

  const { refetchFusionTopicsData } = useGetFusionTopicsData();

  const handleCreateNonCMTopicClick = () => {
    if (isFlagVersion19) {
      return;
    }
    setIsCreateNonCMTopicDialogOpen(true);
  };

  const handleCreateOnOffChainTopicClick = () => {
    if (isFlagVersion19 || isFlagVersion195) {
      return;
    }
    setIsOnOffChainTopicDialogOpen(true);
  };

  return (
    <Modal
      open={isCreateTopicModalOpen}
      onClose={() => {
        setIsCreateTopicModalOpen(false);
      }}
      sx={{
        backgroundColor: 'transparent',
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '100%',
          backgroundColor: 'rgba(100, 99, 141, 0.45) !important',
          backdropFilter: 'blur(17.5px)',
        }}
      >
        <CreateCMTopicDialog
          open={isCreateCMTopicDialogOpen}
          handleClose={() => {
            setIsCreateCMTopicDialogOpen(false);
            setIsCreateTopicModalOpen(false);
            refetchFusionTopicsData();
          }}
        />

        {isCreateNonCMTopicDialogOpen ? <CreateNonCMTopicDialog
          open={isCreateNonCMTopicDialogOpen}
          handleClose={() => {
            setIsCreateNonCMTopicDialogOpen(false);
            setIsCreateTopicModalOpen(false);
            refetchFusionTopicsData();
          }}
        /> : null}

        {isOnOffChainTopicDialogOpen ? <CreateOnOffChainTopicDialog
          open={isOnOffChainTopicDialogOpen}
          handleClose={() => {
            setIsOnOffChainTopicDialogOpen(false);
            setIsCreateTopicModalOpen(false);
            refetchFusionTopicsData();
          }}
        /> : null}

        <Typography variant="h1" sx={{ color: white, mb: 3 }}>
          Create Topic
        </Typography>
        <CreateTopicCard
          title="Send announcements from Community Manager"
          img={TrumpetImg}
          handleClick={handleCreateCMTopicClick}
        />
        <CreateTopicCard
          isFlagVersion19={isFlagVersion19}
          title="Send an alert triggered by an API call from my server"
          img={LightningImg}
          handleClick={handleCreateNonCMTopicClick}
        />
        <CreateTopicCard
          isFlagVersion19={isFlagVersion19}
          isFlagVersion195={isFlagVersion195}
          title="Have Notifi monitor on or off-chain activity to send alerts"
          img={CubeImg}
          handleClick={handleCreateOnOffChainTopicClick}
        />
        <IconButton
          sx={{
            backgroundColor: white,
            borderRadius: '45px',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 1,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: white,
            },
          }}
          onClick={() => {
            setIsCreateTopicModalOpen(false);
          }}
        >
          <CloseIcon sx={{ color: dusk }} />
        </IconButton>
      </Stack>
    </Modal>
  );
};

export default CreateTopicModal;
