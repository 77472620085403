import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import NotifiLogo from './assets/notifi-logo-new.svg';
import { dusk } from './constants/colors';
import { useGetUserProfile } from './pages/AccountSettings/AccountSettingsDataContext';

export const AppMainLogo: React.FC = () => {
  const { data } = useGetUserProfile();
  return (
    <Stack>
      <Box
        sx={{
          paddingX: '20%',
          display: 'flex',
          justifyContent: 'center',
          mb: '16px',
        }}
      >
        <img
          src={NotifiLogo}
          alt="Notifi Logo"
          style={{ width: '175px', height: '60px' }}
        />
      </Box>
      <Typography
        sx={{
          fontFamily: 'Rota',
          fontWeight: '900px',
          fontSize: '18px',
          paddingBottom: '50px',
          color: dusk,
          marginX: 'auto',
          height: '50px',
        }}
      >
        {data?.userProfile?.tenantInfo?.name ?? ''}
      </Typography>
    </Stack>
  );
};
