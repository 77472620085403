import { SubscriptionCardConfigItemV1 } from '@components/cardConfig/common/CardConfigItemV1';

export const isTopicInConfigs = (
    configs: SubscriptionCardConfigItemV1[], 
    selectTopicName: string
  ): boolean => {
    return configs.some(config =>
      config.eventTypes.some(eventType => eventType.name === selectTopicName)
    );
  };

  export const isTopicInConfig = (
    config: SubscriptionCardConfigItemV1, 
    selectTopicName: string
  ): boolean => {
      return config.eventTypes.some(eventType => eventType.name === selectTopicName);
  };
