import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import {
    Box,
    Dialog,
    DialogContent,
    Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect } from 'react';
import {
    dusk,
    midnight,
    midnightLight,
    white,
} from 'src/constants/colors';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { TwoFactorCodeInput } from '@components/auth/SecureAccount';
import useAuthenticatorCode from '@components/auth/useAuthenticatorCode';
import { useGlobal2FaAuthModal } from '@components/auth/GlobalModalContext';

export const TwoFAInputModal: React.FC = () => {
    const { isAP2Enabled } = useFeatureFlag();
    const { code, error, handleChange, clearState } = useAuthenticatorCode();
    const { isModalOpen, closeModal, onSubmit, isLoading } = useGlobal2FaAuthModal();
    const hasEmptyFields = code === ''
    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit(code);
        }
    };

    const handleClose = () => {
        if (onSubmit) {
            onSubmit(code, true);
        }
        closeModal?.();
    }

    useEffect(() => {
        if (isModalOpen) {
            clearState()
        }
    }, [isModalOpen])

    return (
        <Dialog
            fullWidth={false}
            maxWidth="lg"
            open={isModalOpen}
            sx={{
                '& .MuiPaper-root': {
                    backgroundColor: isAP2Enabled ? white : midnightLight,
                    borderRadius: '4px',
                    width: '620px',
                },
            }}
        >

            <DialogContent
                sx={{
                    backgroundColor: isAP2Enabled ? white : midnightLight,
                    fontFamily: 'Rota',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 4,
                    px: 15,
                }}>
                <Typography textAlign={'center'} fontWeight={900} fontSize={18} color={midnight} mb={3.5}>
                    This action requires authentication
                </Typography>

                <Typography textAlign={'center'} fontWeight={600} fontSize={16} color={dusk} mb={3.5}>
                    Enter your two-factor authentication code
                </Typography>

                <Box sx={{ width: '100%', mb: 3 }}>
                    <TwoFactorCodeInput code={code} handleChange={handleChange} error={error} />
                </Box>
                <Stack
                    alignItems="center"
                    direction={'row'}
                    gap={3}
                    sx={{
                        width: '100%'
                    }}
                >
                    <SecondaryButton
                        customSx={{
                            width: '50%',
                        }}
                        buttonTitle="Cancel"
                        onClick={handleClose}
                    />

                    <PrimaryButton
                        disabled={
                            isLoading || hasEmptyFields
                        }
                        buttonTitle="Confirm"
                        onClick={handleSubmit}
                        customSx={{
                            color: white,
                            marginX: 'auto',
                            width: '50%',
                            backgroundColor: midnight,
                            '&:hover': {
                                backgroundColor: midnight,
                            },
                            '&:disabled': {
                                backgroundColor: midnight,
                                color: white,
                                opacity: 0.4,
                            },
                        }}
                    />
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
