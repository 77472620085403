import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  TopicsQuery as TopicsQueryResponse,
  WalletBlockchain,
} from 'src/services/gql/generated';
import { TopicsQuery } from 'src/services/gql/queries/TopicsQuery.gql';
import { useGetFusionCMTopicsData } from '@components/alertManager/TopicsDataContext';
import { CustomFusionEventData } from '../AlertManager/UIConfigTab';

export type BroadcastTopic = Readonly<{
  walletBlockchain: WalletBlockchain.OFF_CHAIN;
  targetCollections: ReadonlyArray<string>;
  targetTemplate?: string;
  topicName: string;
  name: string;
}>;

export type ClientTopic = BroadcastTopic;

export const useTopics = (): Readonly<Record<string, ClientTopic>> => {
  const { data } = useQuery<TopicsQueryResponse>(TopicsQuery);
  const broadcastTopics = useMemo(() => {
    const items: BroadcastTopic[] =
      data?.topics?.nodes
        ?.map((node): BroadcastTopic | null => {
          if (
            node !== undefined &&
            node !== null &&
            node.name !== undefined &&
            node.topicName !== undefined
          ) {
            return {
              name: node.name,
              targetCollections: node.targetCollections ?? [],
              targetTemplate: node.targetTemplate,
              topicName: node.topicName,
              walletBlockchain: WalletBlockchain.OFF_CHAIN,
            };
          }
          return null;
        })
        ?.filter((topic): topic is BroadcastTopic => topic !== null) ?? [];

    return items;
  }, [data]);

  const topics = useMemo(() => {
    const items: Record<string, ClientTopic> = {};
    broadcastTopics.forEach((topic) => {
      const key = topic.topicName;
      items[key] = topic;
    });
    return items;
  }, [broadcastTopics]);

  return topics;
};

export const useFusionTopics = (): Readonly<
  Record<string, CustomFusionEventData>
> => {
  const { items: topicsData } = useGetFusionCMTopicsData();

  const fusionTopics = useMemo(() => {
    const items: Record<string, CustomFusionEventData> = {};
    topicsData.forEach((topic) => {
      const key = topic.id;
      items[key] = topic;
    });
    return items;
  }, [topicsData]);

  return fusionTopics;
};
