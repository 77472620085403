import { gql } from '@apollo/client';

export const GenerateNormalPermissionsAuthorization = gql`
mutation GenerateNormalPermissionsAuthorization {
  generateNormalPermissionsAuthorization {
    newRefreshAuthorization {
      expiry
      serialNumber
      tenantInfo {
        allowedDomains {
          originAddress
        }
      }
      token
    }
    normalPermissionsAuthorization {
      expiry
      serialNumber
      tenantInfo {
        allowedDomains {
          originAddress
        }
      }
      token
    }
  }
}
`;
