import { createContext, Dispatch, useContext } from 'react';
import {
  WalletChainEditAction,
  WalletChainEditState,
} from '../cardConfigEditReducer';

type WalletChainEditContextState = Readonly<{
  state: WalletChainEditState;
  dispatch: Dispatch<WalletChainEditAction>;
}>;

export const WalletChainEditContext =
  createContext<WalletChainEditContextState>({
    state: {} as WalletChainEditState,
    dispatch: () => {
      throw new Error('Unimplemented');
    },
  });

export const useWalletChainEditContext = (): WalletChainEditContextState => {
  return useContext(WalletChainEditContext);
};
