import { gql } from '@apollo/client';

export const LogInMutation = gql`
  mutation submitLogin($email: String!, $password: String!) {
    logInByEmailAddress(
      logInByEmailAddressInput: { emailAddress: $email, password: $password }
    ) {
      email
      emailConfirmed
      roles
      authorization {
        token
        expiry
      }
    }
  }
`;
