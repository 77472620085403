import React, { useState } from 'react';
import { MenuItem, Select, SxProps, TextField } from '@mui/material';
import { dusk, eggshell, midnight } from 'src/constants/colors';

import { ReactComponent as IconChevronDown } from '../../assets/icn-chevron-down.svg';
import { ReactComponent as UpArrowIcon } from '../../assets/icn-uparrow.svg';
import { ReactComponent as CheckIcon } from '../../assets/icn-check-dusk.svg';
import { ReactComponent as DownArrowIcon } from '../../assets/icn-downarrow.svg';
import { ReactComponent as RatioCheckIcon } from '../../assets/icn-percent.svg';
import { ReactComponent as StarIcon } from '../../assets/icn-star.svg';
import { ReactComponent as AnnouncementIcon } from '../../assets/icn-announcement.svg';
import { ReactComponent as ClockIcon } from '../../assets/icn-clock.svg';
import { ReactComponent as ChartIcon } from '../../assets/icn-chart.svg';
import { ReactComponent as DaoIcon } from '../../assets/icn-dao.svg';
import { ReactComponent as FlagIcon } from '../../assets/icn-flag.svg';
import { ReactComponent as GraphIcon } from '../../assets/icn-graph.svg';
import { ReactComponent as SwapIcon } from '../../assets/icn-swap.svg';
import { ReactComponent as UrgentIcon } from '../../assets/icn-urgent.svg';
import { ReactComponent as WatchIcon } from '../../assets/icn-eye.svg';
import { GenericEventIconHint } from 'src/services/gql/generated';

export const Icons: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  UpArrow: UpArrowIcon,
  DownArrow: DownArrowIcon,
  Percent: RatioCheckIcon,
  Clock: ClockIcon,
  Checkmark: CheckIcon,
  Star: StarIcon,
  Megaphone: AnnouncementIcon,
  Chart: ChartIcon,
  Dao: DaoIcon,
  Flag: FlagIcon,
  Graph: GraphIcon,
  Swap: SwapIcon,
  Urgent: UrgentIcon,
  Watch: WatchIcon,
};

const icons = [
  'UpArrow',
  'DownArrow',
  'Percent',
  'Clock',
  'Checkmark',
  'Star',
  'Megaphone',
  'Chart',
  'Dao',
  'Flag',
  'Graph',
  'Swap',
  'Urgent',
  'Watch',
];

type Props = {
  customIconUrlSx?: SxProps;
  iconUrl?: string;
  setIconUrl: (iconUrl: string) => void;
  customIcon?: GenericEventIconHint | string;
  setCustomIcon: (customIcon: GenericEventIconHint | string) => void;
  selectSx?: SxProps;
};

const CustomIconSelect: React.FC<Props> = ({
  customIcon,
  setCustomIcon,
  iconUrl,
  setIconUrl,
  customIconUrlSx,
  selectSx,
}) => {
  const [isUseCustomIconUrl, setIsUseCustomIconUrl] = useState<boolean>(
    (iconUrl || '').length > 0 ? true : false,
  );

  return (
    <div>
      <Select
        value={isUseCustomIconUrl || iconUrl ? 'Custom Icon' : customIcon}
        label="Type"
        onChange={(e) => {
          if (e.target.value === 'custom icon') {
            setIsUseCustomIconUrl(true);
            setCustomIcon(GenericEventIconHint.CHART);
          } else {
            setIsUseCustomIconUrl(false);
            setIconUrl('');
            setCustomIcon(e.target.value as GenericEventIconHint);
          }
        }}
        // renderValue={(selected) => {
        //   if (!selected) {
        //     return '';
        //   }
        //   if (selected === 'Custom Icon') {
        //     return selected;
        //   } else {
        //     const IconEle = Icons[selected];
        //     return (
        //       <span className="svg-color-heather">
        //         <IconEle />
        //       </span>
        //     );
        //   }
        // }}
        IconComponent={(props) => {
          return (
            <IconChevronDown
              {...props}
              style={{
                marginRight: '12px',
                top: 'calc(50% - .7em)',
                fill: dusk,
              }}
            />
          );
        }}
        sx={{
          height: '47px',
          display: 'flex',
          backgroundColor: eggshell,
          color: midnight,
          width: '100%',
          ...selectSx,
        }}
      >
        <MenuItem value="custom icon">Custom Icon</MenuItem>
        {icons.map((icon, index) => {
          const IconEle = Icons[icon];
          return (
            <MenuItem value={icon} key={index}>
              <span className="svg-color-heather">
                <IconEle />
              </span>
            </MenuItem>
          );
        })}
      </Select>
      {isUseCustomIconUrl || iconUrl ? (
        <TextField
          sx={{
            mt: '24px',
            ...customIconUrlSx,
          }}
          fullWidth
          label="URL for custom icon"
          value={iconUrl}
          onChange={(e) => setIconUrl(e.target.value)}
        />
      ) : null}
    </div>
  );
};

export default CustomIconSelect;
