import { gql } from '@apollo/client';

export const UserProfileQuery = gql`
  query UserProfile {
    userProfile {
      tenantInfo {
        dappId
        name
      }
      id
    }
  }
`;
