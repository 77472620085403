import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { fromStringOrNull, NotifiEnvironment } from './envUtils';

export type NotifiEnvState = Readonly<{
  notifiEnv: NotifiEnvironment;
  setNotifiEnv: (newEnv: NotifiEnvironment) => void;
}>;

const NotifiEnvContext = createContext<NotifiEnvState>({
  notifiEnv: 'dev',
  setNotifiEnv: () => {
    /* Unimplemented */
  },
});

const ENV_KEY = 'config-tool:ENV';

export const NotifiEnvProvider: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children }) => {
  const [notifiEnv, setNotifiEnvImpl] = useState<NotifiEnvironment>(() => {
    return fromStringOrNull(localStorage.getItem(ENV_KEY));
  });

  const setNotifiEnv = useCallback((env: NotifiEnvironment) => {
    localStorage.setItem(ENV_KEY, env);
    setNotifiEnvImpl(env);
  }, []);

  return (
    <NotifiEnvContext.Provider
      value={{
        notifiEnv,
        setNotifiEnv,
      }}
    >
      {children}
    </NotifiEnvContext.Provider>
  );
};

export const useNotifiEnv = (): NotifiEnvState => {
  return useContext(NotifiEnvContext);
};
