import React, { useState } from 'react';
import ContentEditor from '@components/content-editor';
import { Divider, Stack, TextField, Typography } from '@mui/material';
import { cloud, dusk, eggshell, midnight } from 'src/constants/colors';
import { isEmptyContent } from 'src/pages/Community/CreateMessageForm';
import { MessageVariables } from 'src/pages/Community/MessageService';
import PreviewDialog from 'src/pages/Community/PreviewDialog';
import {
  TemplateType,
  useFusionPreviewCommunityManagerContentLazyQuery,
} from 'src/services/gql/generated';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { PreviewButton } from '@components/buttons/PreviewButton';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { handleBrowserPreviewUi } from 'src/util/helper';

type SlackComposerProps = Readonly<{
  browserMessageContent: string;
  setBrowserMessageContent: (browserMessageContent: string) => void;
  isDefineTemplate?: boolean;
  isCommunityManager: boolean;
  browserSubject: string;
  setBrowserSubject: (browserSubject: string) => void;
  selectedTopicKey?: string;
}>;

export const BrowserComposer: React.FC<SlackComposerProps> = ({
  browserMessageContent,
  setBrowserMessageContent,
  isDefineTemplate,
  isCommunityManager,
  browserSubject,
  setBrowserSubject,
  selectedTopicKey = '',
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [browserTemplate, setBrowserTemplate] = useState<any>();
  const [getFusionPreviewContent] =
    useFusionPreviewCommunityManagerContentLazyQuery();
  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();
  const isEnabled =
    !isEmptyContent(browserMessageContent) &&
    browserSubject.length > 1 &&
    selectedTopicKey !== '';

  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };

  const handleFusionPreview = async () => {
    clearMessageBarState();
    const variables: MessageVariables[] = [
      {
        key: 'subject',
        value: browserSubject,
      },
      {
        key: 'message',
        value: browserMessageContent,
      },
    ];
    const queryVariables = {
      fusionCommunityManagerPreviewInput: {
        previewTarget: TemplateType.BROWSER_PUSH,
        targetTemplateId: selectedTopicKey ?? '',
        variables,
      },
    };
    const { data, error, loading } = await getFusionPreviewContent({
      variables: queryVariables,
    });
    if (!loading && data) {
      setIsPreviewModalOpen(true);
      const { fusionPreviewCommunityManagerContent } = data;

      setBrowserTemplate(
        handleBrowserPreviewUi(
          fusionPreviewCommunityManagerContent.content,
          browserSubject,
        ),
      );
    } else if (error) {
      setErrorMessage('There was an error generating a preview. Try again!');
    }
  };

  const handleClosePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  return (
    <>
      {isDefineTemplate ? (
        <>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Stack
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  sx={{
                    fontFamily: 'Rota',
                    fontSize: '18px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    mt: isDefineTemplate ? 3 : 2,
                    mr: '5px',
                  }}
                >
                  Browser
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                mt: 2,
              }}
            >
              Subject / Title
            </Typography>
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '12px',
                fontWeight: 800,
                lineHeight: '18px',
                mt: 2,
              }}
            >
              max 50 characters
            </Typography>
          </Stack>
          <TextField
            autoFocus
            color="secondary"
            disabled={false}
            fullWidth
            hiddenLabel
            inputProps={{
              autoComplete: 'off',
              maxLength: 50,
            }}
            onChange={(e) => {
              setBrowserSubject(e.target.value);
            }}
            placeholder="Subject"
            size="small"
            sx={{
              '& input': {
                fontFamily: 'Rota',
                fontWeight: 600,
                color: midnight,
                padding: '13px 20px',
                lineHeight: 'normal',
              },
              '& input::placeholder': {
                color: dusk,
                opacity: 1,
              },
              backgroundColor: eggshell,
            }}
            value={browserSubject}
            variant="outlined"
          />
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
          >
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
              }}
            >
              Plain Text Content
            </Typography>
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '12px',
                fontWeight: 800,
                lineHeight: '18px',
              }}
            >
              max 150 characters
            </Typography>
          </Stack>
          <TextField
            color="secondary"
            disabled={false}
            fullWidth
            hiddenLabel
            id="standard-multiline-static"
            inputProps={{
              py: 1.5,
            }}
            multiline
            onChange={(e) => {
              setBrowserMessageContent(e.target.value);
            }}
            placeholder="Insert your content"
            minRows={5}
            size="small"
            sx={{
              '& fieldset': { borderRadius: '4px' },
              '& textarea': {
                fontFamily: 'Rota',
                fontWeight: 600,
                fontSize: '16px',
              },

              '& .MuiInputBase-root': {
                color: midnight,
              },
              mb: 3,
              backgroundColor: eggshell,
            }}
            value={browserMessageContent}
            variant="outlined"
          />
        </>
      ) : (
        <>
          <Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                sx={{
                  fontFamily: 'Rota',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  mt: isDefineTemplate ? 3 : 2,
                  mr: '5px',
                }}
              >
                Browser
              </Typography>

              <PreviewButton
                disabled={!isEnabled}
                handlePreview={handleFusionPreview}
              />
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                sx={{
                  color: dusk,
                  fontFamily: 'Rota',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  mt: 2,
                }}
              >
                Subject / Title
              </Typography>
              <Typography
                sx={{
                  color: dusk,
                  fontFamily: 'Rota',
                  fontSize: '12px',
                  fontWeight: 800,
                  lineHeight: '18px',
                  mt: 2,
                }}
              >
                max 50 characters
              </Typography>
            </Stack>
          </Stack>
          <TextField
            autoFocus
            color="secondary"
            disabled={false}
            fullWidth
            hiddenLabel
            inputProps={{
              autoComplete: 'off',
              maxLength: 50,
            }}
            onChange={(e) => {
              setBrowserSubject(e.target.value);
            }}
            placeholder="Subject"
            size="small"
            sx={{
              '& input': {
                fontFamily: 'Rota',
                fontWeight: 600,
                color: midnight,
                padding: '13px 20px',
                lineHeight: 'normal',
              },
              '& input::placeholder': {
                color: dusk,
                opacity: 1,
              },
              backgroundColor: eggshell,
            }}
            value={browserSubject}
            variant="outlined"
          />
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
          >
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
              }}
            >
              Content
            </Typography>
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '12px',
                fontWeight: 800,
                lineHeight: '18px',
              }}
            >
              max 150 characters
            </Typography>
          </Stack>
          <ContentEditor
            setValue={setBrowserMessageContent}
            value={browserMessageContent}
            destination="browser"
            isCommunityManager={isCommunityManager}
            maxLimit={16000}
          />
          <Divider
            sx={{
              borderColor: cloud,
              borderWidth: '1.5px',
            }}
          />
          <PreviewDialog
            destination="browser"
            template={browserTemplate}
            handleClose={handleClosePreviewModal}
            open={isPreviewModalOpen}
            isInsertHtmlContent={isAP2Enabled ? false : true}
          />
        </>
      )}
    </>
  );
};
