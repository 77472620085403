import { gql } from '@apollo/client';

export const GenerateElevatedPermissionsAuthorization = gql`
mutation GenerateElevatedPermissionsAuthorization($input: GenerateElevatedPermissionsAuthorizationInput!) {
    generateElevatedPermissionsAuthorization(input: $input){
    authorization {
      expiry
      serialNumber
      tenantInfo {
        allowedDomains {
          originAddress
        }
      }
      token
    }
  }
}
`;


