import { LabelType } from '@components/cardConfig/common/LabelType';

type LabelItemConfigs<Config> = Readonly<{
  [K in LabelType]: Config;
}>;

export const LABEL_TYPE_MENU_LABELS: LabelItemConfigs<string> = {
  ChatCompanyName: 'Chat Company Name',
  ChatFTUTitle: 'Chat FTU Title',
  ChatFTUDescription: 'Chat FTU Description',
  ChatFTUSubTitle: 'Chat FTU Sub Title',
  ChatBannerTitle: 'Chat Banner Title',
  ChatIntroQuestion: 'Chat Intro Question',
};

export const LABEL_TYPES = Object.keys(LABEL_TYPE_MENU_LABELS).map(
  (key) => key as LabelType,
);

export const emptyLabels = Object.keys(LABEL_TYPE_MENU_LABELS).map((type) => {
  return {
    name: null,
    type: type as LabelType,
    label: LABEL_TYPE_MENU_LABELS[
      type as keyof typeof LABEL_TYPE_MENU_LABELS
    ] as keyof typeof LABEL_TYPE_MENU_LABELS,
  };
});

export const DEFAULT_LABEL_CONFIG = [...emptyLabels];
