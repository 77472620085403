import { PrimaryButton } from '@components/buttons/PrimaryButton';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { dusk, eggshell, white } from 'src/constants/colors';
import { useState } from 'react';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { FormMessageBar } from '@components/FormMessageBar';
import { Footer } from '@components/footer';
import CreateNonCMTopicStep1Card from './CreateNonCMTopicStep1Card';
import CreateNonNotifiHostedTopicStep2 from './CreateNonNotifiHostedTopicStep2';
import {
  useGetFusionTopicsData,
  useTopicsDataContext,
} from './TopicsDataContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  useCardConfigDataContext,
  useGetSubscriptionConfigData,
} from '@components/cardConfig/CardConfigDataContext';
import { FusionTopicTypeItem } from '@components/cardConfig/common/TopicTypeItem';
import { CustomFusionEventData } from 'src/pages/AlertManager/UIConfigTab';
import { GenericEventIconHint } from 'src/services/gql/generated';
import { ValueOrRef } from '@components/cardConfig/common/ValueOrRef';
import { AlertFiltersChangeModal } from '@components/modal/AlertFiltersChangeModal';
import useUpdateEffect from 'src/hooks/useUpdateHook';
import { useAppContext } from 'src/context/AppContext';

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// import from @notifi-network/notifi-frontend-client when we release the latest sdk version
export type FusionEventMetadata = {
  uiConfigOverride: {
    topicDisplayName: string;
    historyDisplayName: string;
    icon?: GenericEventIconHint | string;
    customIconUrl?: string;
    isSubscriptionValueInputable?: boolean;
    subscriptionValueOrRef: ValueOrRef<InputObject[]>;
  };
  filters: Array<Filter>;
};

export type InputObject = {
  label: string;
  value: string;
};

/**
 * @param name - `string` unique name
 */
export type Filter = AlertFilter | FrequencyFilter;
export type FilterBase = {
  name: string;
  type: FilterType;
  executionPriority: number;
  description: string;
};

export type FrequencyFilter = FilterBase & {
  minimumDurationBetweenTriggersInMinutes: number;
  requiredParserVariables: Array<RequiredParserVariable>;
};

export type AlertFilter = FilterBase & {
  userInputParams: UserInputParam<UiType>[];
  staticFilterParams?: Record<string, object | string | number>;
  requiredParserVariables: Array<RequiredParserVariable>;
};

export type RequiredParserVariable = {
  variableName: string;
  variableType: ValueType;
  variableDescription: string;
};

export type ValueType = 'integer' | 'price' | 'percentage' | 'string' | 'float';

export type PrefixAndSuffix = {
  prefix: string;
  suffix: string;
};

export type CustomInputConstraints = {
  maxDecimalPlaces?: number;
  upperBound?: number;
  lowerBound?: number;
};

/**
 * @param UiType - `radio` or `button` (scalable). Define what component should be rendered in Card topic subscription view.
 * @param defaultValue - The value for default alert subscription
 */
export type UserInputParam<T extends UiType> = {
  name: string;
  kind: ValueType;
  uiType: T;
  description: string;
  options: (string | number)[];
  defaultValue: string | number;
  allowCustomInput?: boolean;
  prefixAndSuffix: PrefixAndSuffix;
  customInputConstraints?: CustomInputConstraints;
};

export type UiType = 'radio' | 'button';
export type FilterType = 'AlertFilter' | 'FrequencyAlertFilter';

export const convertOldThresholdToNewThresholdType: { [x: string]: string } = {
  integer: 'integer',
  float: 'float',
  percentage: 'float',
  price: 'float',
};

type Props = {
  handleClose: () => void;
  open: boolean;
  isEdit?: boolean;
  topic?: CustomFusionEventData;
  isEditNonNotifiHosted?: boolean;
};

const CreateNonCMTopicDialog = ({
  handleClose,
  open,
  isEdit,
  topic,
  isEditNonNotifiHosted,
}: Props) => {
  const metaData = JSON.parse(topic?.metadata ?? '{}');
  const editFilter = metaData?.filters?.find((obj: Filter) =>
    Object.keys(obj).includes('userInputParams'),
  );
  const editFrequency = metaData?.filters?.find((obj: Filter) =>
    Object.keys(obj).includes('minimumDurationBetweenTriggersInMinutes'),
  );
  const isUserInputParamsWithRadioTypeandCorrectOrder = editFilter
    ? editFilter?.userInputParams[0]?.uiType === 'radio'
    : true;

  const thresholdType = convertOldThresholdToNewThresholdType[
    editFilter?.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].kind
  ] as ValueType;

  const variableType = convertOldThresholdToNewThresholdType[
    editFilter?.requiredParserVariables[0].variableType
  ] as ValueType;

  if (editFilter) {
    editFilter.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].kind = thresholdType;
    editFilter.requiredParserVariables[0].variableType = variableType;
  }

  const defaultFilters: AlertFilter = {
    name: '',
    description: '',
    type: 'AlertFilter' as FilterType,
    executionPriority: 20,
    userInputParams: [
      {
        name: 'thresholdDirection',
        kind: 'string',
        uiType: 'radio',
        options: [],
        allowCustomInput: false,
        defaultValue: 'above',
        description: 'Alert me when value is above:',
        prefixAndSuffix: { prefix: '', suffix: '' },
      },
      {
        name: 'threshold',
        kind: 'integer',
        uiType: 'button',
        options: [],
        allowCustomInput: false,
        defaultValue: '',
        description: '',
        prefixAndSuffix: { prefix: '', suffix: '' },
        customInputConstraints: {
          maxDecimalPlaces: undefined,
          upperBound: undefined,
          lowerBound: undefined,
        },
      },
    ],
    requiredParserVariables: [
      {
        variableName: 'thresholdComparisonValue',
        variableType: '' as ValueType,
        variableDescription:
          'This value must be output by the parser, as it is the value to be compared against the threshold value provided by the user in the alert filter stage to decide on sending out the notification or not.',
      },
    ],
  };

  const defaultFrequency: FrequencyFilter = {
    name: 'frequency',
    minimumDurationBetweenTriggersInMinutes: isEdit
      ? editFrequency?.minimumDurationBetweenTriggersInMinutes ?? -1
      : -1,
    executionPriority: 10,
    description:
      'Filter used to debounce or set a max frequency of alerts. Max period is 24 hours.',
    type: 'FrequencyAlertFilter',
    requiredParserVariables: [
      {
        variableName: 'debounceKey',
        variableType: 'string',
        variableDescription:
          'This is the key used to debounce alerts. If this is a normal case where an alert is triggered by a single condition such as a particular wallet balance being above a certain threshold, then you can simply pass *. If the alert is triggered for multiple cases, and you would like the debounce applied per case, pass in the account id, index or anything that would differentiate one alert trigger from another. Max character length is 16 chars.',
      },
    ],
  };
  const defaultUiConfigOverride: FusionEventMetadata['uiConfigOverride'] = {
    topicDisplayName: '',
    historyDisplayName: '',
    subscriptionValueOrRef: {
      type: 'ref',
      ref: 'walletAddress',
    },
  };
  const [topicName, setTopicName] = useState<string>(
    isEdit ? topic?.name ?? '' : '',
  );
  const [step, setStep] = useState<number>(1);
  const [userPresetThresholdsEnabled, setUserPresetThresholdsEnabled] =
    React.useState(false);
  const [filter, setFilter] = useState<AlertFilter>(
    isEdit ? editFilter ?? defaultFilters : defaultFilters,
  );
  const [frequency, setFrequency] = useState<FrequencyFilter>(
    isEdit ? editFrequency ?? defaultFrequency : defaultFrequency,
  );
  const [uiConfigOverride, setUiConfigOverride] = useState<
    FusionEventMetadata['uiConfigOverride']
  >(
    isEdit
      ? metaData.uiConfigOverride ?? defaultUiConfigOverride
      : defaultUiConfigOverride,
  );
  const { setRefetchTopicsData } = useAppContext();
  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();
  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };
  const { create, update, addFusionEventToFusionSource } =
    useTopicsDataContext();
  const { updateItem } = useCardConfigDataContext();
  const { items: configs } = useGetSubscriptionConfigData();
  const { refetchFusionTopicsData } = useGetFusionTopicsData();

  const upperBound =
    filter.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].customInputConstraints?.upperBound ?? undefined;

  const lowerBound =
    filter.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].customInputConstraints?.lowerBound ?? undefined;

  const isUserRequiredInfoComplete = () => {
    if (uiConfigOverride.subscriptionValueOrRef.type === 'value') {
      return true;
    } else if (uiConfigOverride.subscriptionValueOrRef.type === 'ref') {
      return (
        uiConfigOverride.subscriptionValueOrRef.ref &&
        uiConfigOverride.subscriptionValueOrRef.ref.length > 0
      );
    } else {
      return false;
    }
  };
  const [isAlertModalChangeVisible, setIsAlertModalChangeVisible] =
    useState(false);
  const hasUserAcknowledged = React.useRef(false);

  useUpdateEffect(() => {
    if (isEdit && !hasUserAcknowledged.current) {
      setIsAlertModalChangeVisible(true);
    }
  }, [filter]);

  const handleAlertModalChangeClose = () => {
    setIsAlertModalChangeVisible(false);
    hasUserAcknowledged.current = true;
  };

  const isBoundValueValid = () => {
    if (
      filter.userInputParams[
        isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
      ].allowCustomInput
    ) {
      return typeof upperBound === 'number' && typeof lowerBound === 'number';
    }
    return true;
  };

  const isSaveTopicEnabled =
    topicName.length > 0 &&
    uiConfigOverride.topicDisplayName?.length > 0 &&
    uiConfigOverride.historyDisplayName?.length > 0 &&
    isUserRequiredInfoComplete() &&
    isBoundValueValid() &&
    (filter.name.length === 0 ||
      (filter.name.length > 0 &&
        filter.description.length > 0 &&
        (userPresetThresholdsEnabled
          ? filter.userInputParams[
              isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
            ].options.length >= 2 &&
            filter.userInputParams[
              isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
            ].options[1] &&
            filter.userInputParams[
              isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
            ].options[1]
          : true)));

  const handleSaveTopic = async () => {
    clearMessageBarState();
    isBoundValueValid() ? null : setErrorMessage('Please enter valid bounds');
    let newMetaData = {};
    if (frequency.minimumDurationBetweenTriggersInMinutes === -1) {
      if (filter.name.length === 0) {
        newMetaData = { uiConfigOverride, filters: [] };
      } else {
        newMetaData = { uiConfigOverride, filters: [filter] };
      }
    } else {
      if (filter.name.length === 0) {
        newMetaData = { uiConfigOverride, filters: [frequency] };
      } else {
        newMetaData = { uiConfigOverride, filters: [filter, frequency] };
      }
    }
    if (!isEdit) {
      try {
        const metaDataJson = JSON.stringify(newMetaData);
        const { id } = await create(topicName, undefined, metaDataJson);
        await addFusionEventToFusionSource({
          fusionEventId: id,
          fusionSourceId: '00000000000000000000000000000000',
        });
        refetchFusionTopicsData();
      } catch (err) {
        setErrorMessage((err as Error).message);
        return;
      }
      setStep(2);
    } else {
      const metaDataNew = { ...metaData, ...newMetaData };
      const filter = metaDataNew?.filters?.find((obj: Filter) =>
        Object.keys(obj).includes('userInputParams'),
      );
      try {
        const metaDataJson = JSON.stringify(metaDataNew);
        await update(topicName, topic?.id ?? '', metaDataJson);
        refetchFusionTopicsData();

        const configCards = configs.filter((config) =>
          config.eventTypes.some((event) => event.name === topic?.name),
        );
        if (!configCards) {
          refetchFusionTopicsData();

          handleClose();
          return;
        }
        configCards.map(async (config) => {
          const index = config.eventTypes.findIndex((event) => {
            return event.name === topic?.name;
          });

          let newTopic: FusionTopicTypeItem = {
            type: 'fusion',
            name: '',
            tooltipContent: config.eventTypes[index].tooltipContent ?? '',
            selectedUIType: 'TOGGLE',
            fusionEventId: {
              type: 'value',
              value: '',
            },
            sourceAddress: {
              type: 'value',
              value: '',
            },
            useCustomIcon: false,
            topicGroupName: '',
            optOutAtSignup: false,
          };
          if (metaDataNew?.filters?.length > 0) {
            newTopic = {
              type: 'fusion',
              name: topicName,
              topicGroupName: config.eventTypes[index].topicGroupName ?? '',
              tooltipContent: config.eventTypes[index].tooltipContent ?? '',
              selectedUIType: 'HEALTH_CHECK',
              fusionEventId: {
                type: 'value',
                value: topic?.id ?? '',
              },
              sourceAddress: { type: 'value', value: '*' },
              useCustomIcon: false,
              healthCheckSubtitle: filter?.description ?? '',
              numberType:
                filter?.userInputParams[
                  isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
                ]?.kind ?? '',
              optOutAtSignup: config.eventTypes[index].optOutAtSignup ?? false,
              checkRatios: [
                {
                  type: filter?.name === 'aboveThreshold' ? 'above' : 'below',
                  ratio:
                    filter?.userInputParams[
                      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
                    ]?.options[0] ?? 0,
                },
                {
                  type: filter?.name === 'aboveThreshold' ? 'above' : 'below',
                  ratio:
                    filter?.userInputParams[
                      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
                    ]?.options[1] ?? 0,
                },
              ],
            };
          } else {
            newTopic = {
              type: 'fusion',
              name: topicName,
              tooltipContent: config.eventTypes[index].tooltipContent ?? '',
              topicGroupName: config.eventTypes[index].topicGroupName ?? '',
              optOutAtSignup: config.eventTypes[index].optOutAtSignup ?? false,
              selectedUIType: 'TOGGLE',
              fusionEventId: {
                type: 'value',
                value: topic?.id ?? '',
              },
              sourceAddress: { type: 'value', value: '*' },
              useCustomIcon: false,
            };
          }
          const newEvent = {
            ...newTopic,
          };

          const newEventTypes = [
            ...config.eventTypes.slice(0, index),
            newEvent,
            ...config.eventTypes.slice(index + 1),
          ];
          const newConfigItem = {
            ...config,
            eventTypes: newEventTypes,
          };
          await updateItem(newConfigItem);
          refetchFusionTopicsData();
        });
      } catch (err) {
        setErrorMessage((err as Error).message);
        return;
      }
      refetchFusionTopicsData();
      handleClose();
    }
  };

  React.useEffect(() => {
    const options =
      editFilter?.userInputParams?.[
        isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
      ]?.options ?? [];
    if (isEdit && options[0] && options[1])
      setUserPresetThresholdsEnabled(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const handleDone = () => {
    handleClose();
    setRefetchTopicsData(true);
  };

  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        fullScreen
        onClose={handleClose}
        open={open}
        sx={{
          '&.MuiPaper-root &.MuiDialog-paper': {
            backgroundColor: 'transparent',
          },
          backgroundColor: '#F3F3FA',
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: '#F3F3FA',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 0,
          }}
        >
          <Stack
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Stack width={'100%'} mt={6} mb={5}>
              <Stack
                display="flex"
                flexDirection="row"
                alignItems="center"
                width="100%"
                gap="20px"
              >
                <IconButton
                  sx={{
                    visibility: 'hidden',
                    flexShrink: 0,
                    backgroundColor: white,
                    borderRadius: '45px',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: white,
                    },
                  }}
                  onClick={() => setStep(step - 1)}
                >
                  <ArrowBackIcon sx={{ color: dusk }} />
                </IconButton>

                <Stack
                  alignItems="center"
                  justifyContent="center"
                  flexGrow="1"
                  flexShrink="1"
                >
                  <Typography variant="h1">
                    {isEdit ? 'Edit topic' : 'Create a new topic'}
                  </Typography>
                </Stack>

                <IconButton
                  sx={{
                    flexShrink: 0,
                    backgroundColor: white,
                    borderRadius: '45px',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: white,
                    },
                  }}
                  onClick={() => {
                    handleClose();
                    clearMessageBarState();
                    setFilter(defaultFilters);
                    setFrequency(defaultFrequency);
                    refetchFusionTopicsData();
                  }}
                >
                  <CloseIcon sx={{ color: dusk }} />
                </IconButton>
              </Stack>
              <Stack
                alignItems="center"
                justifyContent="center"
                flexGrow="1"
                flexShrink="1"
              >
                <Typography variant="h2">
                  {isEdit
                    ? isEditNonNotifiHosted
                      ? 'Send an alert triggered by an API call from my server'
                      : 'Notifi monitors on or off-chain activity to send alert'
                    : 'Send an alert triggered by an API call from my server'}
                </Typography>
              </Stack>

              {isEdit ? null : (
                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent={'center'}
                  width="100%"
                  gap="20px"
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 700,
                      color: dusk,
                      mt: '4px',
                    }}
                  >
                    {step === 1 ? 'Step 1 of 2' : 'Step 2 of 2'}
                  </Typography>
                </Stack>
              )}
            </Stack>

            <FormMessageBar />

            {step === 1 ? (
              <CreateNonCMTopicStep1Card
                topicName={topicName}
                setTopicName={setTopicName}
                filter={filter}
                setFilter={setFilter}
                frequency={frequency}
                setFrequency={setFrequency}
                uiConfigOverride={uiConfigOverride}
                setUiConfigOverride={setUiConfigOverride}
                userPresetThresholdsEnabled={userPresetThresholdsEnabled}
                setUserPresetThresholdsEnabled={setUserPresetThresholdsEnabled}
                isEdit={isEdit}
              />
            ) : (
              <CreateNonNotifiHostedTopicStep2 filter={filter} />
            )}

            <PrimaryButton
              customSx={{ width: '156px', mb: 4 }}
              disabled={!isSaveTopicEnabled}
              buttonTitle={step === 1 ? 'Save Topic' : 'Done'}
              onClick={step === 1 ? handleSaveTopic : handleDone}
            />
          </Stack>
          <Footer sx={{ background: eggshell }} />
        </DialogContent>
      </Dialog>
      <AlertFiltersChangeModal
        open={isAlertModalChangeVisible}
        handleClose={handleAlertModalChangeClose}
      />
    </div>
  );
};

export default CreateNonCMTopicDialog;
