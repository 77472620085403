export type AppState = Readonly<{
  hasUnsavedChange: boolean;
}>;

export type SetHasUnsavedChangeAction = Readonly<{
  type: 'SetHasUnsavedChangeAction';
  hasUnsavedChange: boolean;
}>;

export type AppContextAction = SetHasUnsavedChangeAction;

export const appContextReducer = (
  state: AppState,
  action: AppContextAction,
): AppState => {
  switch (action.type) {
    case 'SetHasUnsavedChangeAction':
      return {
        ...state,
        hasUnsavedChange: action.hasUnsavedChange,
      };
  }
};
