import { PrimaryButton } from '@components/buttons/PrimaryButton';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { dusk, midnight, newBlueglass } from 'src/constants/colors';

type SupportDetail = {
  text: string;
  icon: ReactElement;
};

type Props = {
  title: string;
  description: string;
  supportDetails: SupportDetail[];
  isCreatingNotificationCard?: boolean;
  headImage: string;
  learnMoreLink: string;
  handleNextClick: () => void;
};

const CreateUIConfigCard: React.FC<Props> = ({
  title,
  description,
  supportDetails,
  isCreatingNotificationCard,
  headImage,
  learnMoreLink,
  handleNextClick,
}) => {
  return (
    <Box
      sx={{
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '16px',
        overflow: 'hidden',
      }}
    >
      <img src={headImage} style={{ width: '100%' }} />
      <Stack spacing={2} sx={{ px: 4, py: 2 }}>
        <Typography fontSize={'22px'} color={midnight}>
          {title}
        </Typography>
        <Typography fontSize={'16px'} color={dusk}>
          {description}
        </Typography>
        <List>
          {supportDetails.map((detail, index) => (
            <ListItem key={index} sx={{ p: 0 }}>
              <ListItemIcon
                style={{
                  minWidth: 'auto',
                  marginRight: '10px',
                }}
              >
                {detail.icon}
              </ListItemIcon>
              <ListItemText
                primary={detail.text}
                primaryTypographyProps={{
                  fontSize: '14px',
                  color: midnight,
                }}
              />
            </ListItem>
          ))}
        </List>
        <Stack direction="row" alignItems={'center'}>
          <PrimaryButton
            customSx={{ width: '150px' }}
            buttonTitle="Next"
            onClick={handleNextClick}
            disabled={isCreatingNotificationCard}
          />
          <Typography
            color={newBlueglass}
            fontSize={'16px'}
            style={{ marginLeft: '50px', cursor: 'pointer' }}
            onClick={() => window.open(learnMoreLink, '_blank')}
          >
            Learn More
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CreateUIConfigCard;
