import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { dusk, eggshell, rose, white } from 'src/constants/colors';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { Close } from '@mui/icons-material';
import { InputField } from '@components/InputField';
import { useActivateFusionSourceMutation } from 'src/services/gql/generated';

type Props = {
  open: boolean;
  setIsOpen: (open: boolean) => void;
  fusionSourceId: string;
};

const TestParserModal: React.FC<Props> = ({
  open,
  setIsOpen,
  fusionSourceId,
}) => {
  const [activateFusionSource] = useActivateFusionSourceMutation();
  const [cursorValue, setCursorValue] = useState<string>('');
  const [validationError, setValidationError] = useState<string | null>(null);
  return (
    <div>
      <Dialog
        fullWidth={false}
        maxWidth="lg"
        open={open}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: white,
            borderRadius: '4px',
            maxWidth: '600px',
          },
        }}
      >
        <Stack
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          sx={{
            backgroundColor: white,
            fontFamily: 'Rota',
            py: 4,
            px: 10,
          }}
        >
          <Grid
            container
            flexWrap="nowrap"
            alignItems="start"
            justifyContent="center"
          >
            <DialogTitle
              sx={{
                fontSize: '18px',
                lineHeight: '22px',
                p: 0,
                textAlign: 'center',
                width: '100%',
                flex: '1 1 auto',
                mx: '42px',
              }}
            >
              Test Parser
            </DialogTitle>

            <Box
              sx={{
                width: '25px',
                height: '25px',
                cursor: 'pointer',
                position: 'absolute',
                right: '24px',
              }}
              onClick={() => {
                setCursorValue('');
              }}
            >
              <Close
                sx={{ color: dusk }}
                onClick={() => {
                  setIsOpen(false);
                  setValidationError(null);
                }}
              />
            </Box>
          </Grid>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              maxHeight: '550px',
              overflow: 'auto',
              width: '100%',
            }}
          >
            <DialogContent
              sx={{
                maxHeight: '550px',
                width: '400px',
                overflow: 'unset',
                pb: 0,
                px: 0,
              }}
            >
              <InputField
                label="Latest Block"
                value={cursorValue}
                onChange={(e) => {
                  setCursorValue((e.target as HTMLInputElement).value);
                  setValidationError(null);
                }}
                customSx={{
                  backgroundColor: eggshell,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor:
                        validationError !== null ? rose : 'transparent',
                    },
                  },
                }}
              />
              {validationError !== null ? (
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 700,
                    fontSize: '14px',
                    color: rose,
                    padding: '8px 0',
                  }}
                >
                  {validationError}
                </Typography>
              ) : null}
            </DialogContent>
          </Stack>
          <Stack
            alignItems="center"
            direction={'row'}
            gap={3}
            sx={{ mt: 4, width: '100%' }}
          >
            <SecondaryButton
              customSx={{
                width: '50%',
              }}
              buttonTitle="Cancel"
              onClick={() => setCursorValue('')}
            />

            <PrimaryButton
              customSx={{
                width: '50%',
              }}
              //   disabled={sendInviteDisabled}
              buttonTitle="Test"
              onClick={async () => {
                try {
                  await await activateFusionSource({
                    variables: {
                      fusionSourceId,
                      cursorValue,
                      isActivatingForTest: true,
                    },
                  });
                  setCursorValue('');
                } catch (e) {
                  setValidationError(
                    'There is an error for testing the parser, please try again.',
                  );
                }
              }}
            />
          </Stack>
        </Stack>
      </Dialog>
    </div>
  );
};

export default TestParserModal;
