import { createTheme, PaletteMode, ThemeProvider } from '@mui/material';
import React from 'react';
import {
  cloud,
  cloudLight,
  dusk,
  eggshell,
  heather,
  midnight,
  midnightDarker,
  midnightLight,
  Sapphire,
  seaglassDarker,
  white,
} from '../constants/colors';
import {
  FeatureFlagState,
  useFeatureFlag,
} from '@components/auth/FeatureFlagContext';

declare module '@mui/material/styles' {
  interface Theme {
    custom?: {
      isAP2Enabled?: boolean;
    };
  }

  interface ThemeOptions {
    custom?: {
      isAP2Enabled?: boolean;
    };
  }
}

const typography = {
  fontFamily: 'Rota',
  allVariants: {
    fontFamily: 'Rota',
  },
};

const getDesignTokens = (mode: PaletteMode, isAP2Enabled: boolean) => ({
  custom: {
    isAP2Enabled,
  },
  typograpy: typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      laptop: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: () => ({
          fontFamily: 'Rota',
          ...(isAP2Enabled
            ? {
                boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
                backgroundColor: white,
                borderRadius: '8px',
              }
            : { borderRadius: '15px' }),
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Rota',
          fontWeight: 700,
          borderRadius: '4px',
          margin: '8px',
          padding: '10px',
          '.&MuiTypography-root': {
            color: midnight,
          },
          '&:hover, &.Mui-selected': {
            backgroundColor: isAP2Enabled
              ? `${cloudLight} !important`
              : midnightDarker,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Rota',
          borderColor: isAP2Enabled ? cloudLight : '#383b63',
          fontWeight: 600,
          borderBottom: isAP2Enabled
            ? '1px solid rgba(232, 235, 245, 1)'
            : '1px solid rgb(56, 59, 99);',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '40px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            backgroundColor: seaglassDarker,
          },
        },
        contained: {
          backgroundColor: isAP2Enabled ? midnight : seaglassDarker,
          color: isAP2Enabled ? white : midnight,
          fontFamily: 'Rota',
          fontSize: '16px',
          fontWeight: 700,
          '&:hover': {
            backgroundColor: isAP2Enabled ? midnightDarker : Sapphire,
          },
          '&:active': {
            backgroundColor: seaglassDarker,
          },
          '&:disabled': {
            color: isAP2Enabled ? white : midnightDarker,
            backgroundColor: isAP2Enabled
              ? 'rgba(38, 41, 73, 0.4)'
              : 'rgba(154, 163, 255, 0.4)',
          },
        },
        outlined: {
          borderColor: isAP2Enabled ? midnight : seaglassDarker,
          color: isAP2Enabled ? midnight : seaglassDarker,
          '&:hover': {
            borderColor: isAP2Enabled ? seaglassDarker : '#90CAF9',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: isAP2Enabled ? seaglassDarker : '#90CAF9',
          fontWeight: 600,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: seaglassDarker,
          '&.Mui-checked': {
            color: seaglassDarker,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: 'Rota',
          fontWeight: 700,
          '&:hover fieldset': {
            borderColor: 'transparent !important',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'transparent !important',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            '&:hover': {
              borderColor: isAP2Enabled ? 'transparent !important' : white,
            },
            '&:before': {
              borderColor: isAP2Enabled ? 'transparent !important' : white,
            },
          },
        },
        icon: {
          fill: isAP2Enabled ? dusk : seaglassDarker,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Rota',
          fontWeight: 700,
          backgroundColor: isAP2Enabled ? eggshell : midnightDarker,
          color: isAP2Enabled ? midnight : white,
          borderRadius: '4px',
          '&.Mui-disabled': {
            backgroundColor: isAP2Enabled ? white : 'transparent',
            border: isAP2Enabled ? '1px solid #E8EBF5' : 'none',
          },
          '& legend': {
            width: 0,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${heather} !important`,
          },
          '& .MuiOutlinedInput-input.Mui-disabled': {
            WebkitTextFillColor: 'unset',
            color: dusk
          }
        },
        input: {
          fontWeight: 600,
          '&:-webkit-autofill': {
            backgroundColor: `${
              isAP2Enabled ? eggshell : midnightDarker
            } !important`,
            WebkitBoxShadow: `0 0 0 100px ${
              isAP2Enabled ? eggshell : midnightDarker
            } inset !important`,
            WebkitTextFillColor: `${
              isAP2Enabled ? midnight : white
            } !important`,
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Rota',
          fontWeight: 600,
          '&.MuiInputLabel-shrink': {
            fontWeight: 700,
            left: '-12px',
            top: '-12px',
            fontSize: '18px',
          },
          '&.Mui-focused': {
            color: dusk,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontFamily: 'Rota',
          fontWeight: 700,
          '&.MuiTextField-root': {
            backgroundColor: isAP2Enabled ? white : midnightLight,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: 'Rota',
          fontWeight: 700,
          '&:hover fieldset': {
            borderColor: 'transparent !important',
          },
          '&.MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: isAP2Enabled ? heather : seaglassDarker,
            },
          },
        },

      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.10) !important',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05))',
        },
        list: {
          backgroundColor: isAP2Enabled ? white : midnight,
          '&.MuiMenuItem': {
            backgroundColor: isAP2Enabled ? white : midnight,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
          fontWeight: 700,
          color: dusk,
          backgroundColor: white,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: isAP2Enabled ? midnight : heather,
          fontFamily: 'Rota',
          fontSize: 16,
          fontWeight: 600,
          lineHeight: '20px',
        },
        h1: {
          color: isAP2Enabled ? midnight : eggshell,
          fontFamily: 'Rota',
          fontSize: 30,
          fontWeight: 900,
          lineHeight: '37px',
        },
        h2: {
          color: isAP2Enabled ? midnight : eggshell,
          fontFamily: 'Rota',
          fontSize: 22,
          fontWeight: 900,
          lineHeight: '27px',
        },
        h3: {
          color: isAP2Enabled ? midnight : eggshell,
          fontFamily: 'Rota',
          fontSize: 18,
          fontWeight: 700,
          lineHeight: '22px',
        },
        h4: {
          color: isAP2Enabled ? dusk : heather,
          fontFamily: 'Rota',
          fontSize: 16,
          fontWeight: 600,
          lineHeight: '20px',
        },
        h6: {
          fontFamily: 'Rota',
          fontWeight: 700,
        },
      },
    },
  },
  palette: {
    mode,
    ...(mode === 'dark' && {
      // palette values for dark mode
      background: {
        default: isAP2Enabled ? cloud : midnightDarker,
        paper: isAP2Enabled ? white : midnight,
      },
      text: {
        primary: isAP2Enabled ? midnight : white,
        secondary: isAP2Enabled ? dusk : heather,
      },
    }),
  },
});

export const darkModeTheme = ({ isAP2Enabled }: FeatureFlagState) =>
  createTheme(getDesignTokens('dark', isAP2Enabled));

export const lightModeTheme = ({ isAP2Enabled }: FeatureFlagState) =>
  createTheme(getDesignTokens('light', isAP2Enabled));

interface ConfigToolThemeOption {
  children: React.ReactNode | React.ReactNode[];
}

export const ConfigToolThemeProvider = (props: ConfigToolThemeOption) => {
  const featureFlags = useFeatureFlag();
  return (
    <ThemeProvider theme={darkModeTheme(featureFlags)}>
      {props?.children}
    </ThemeProvider>
  );
};
