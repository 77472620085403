import { gql } from '@apollo/client';

export const BotsByTypeQuery = gql`
  query BotsByType($GetBotsByTypeInput: GetBotsByTypeInput!) {
    botsByType(getBotsByTypeInput: $GetBotsByTypeInput) {
      botType
      name
      id
      status
    }
  }
`;
