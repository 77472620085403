import { format, parseISO } from 'date-fns';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';

export const formatDateUTC = (
  date: string | number | Date | undefined,
  f = 'yyyy-MM-dd',
) => {
  if (!date) return '';
  return formatInTimeZone(new Date(date), 'UTC', f);
};

export const formatDate = (
  date: string | number | Date | undefined,
  f = 'yyyy-MM-dd',
) => {
  if (!date) return '';
  const d = new Date(date);
  return format(d, f);
};

export function isTokenExpired(
  expiryDateStr: string,
  currentDate: Date = new Date(),
): boolean {
  const expiryDate = new Date(expiryDateStr);
  return currentDate > expiryDate;
}

export function getUserTimeZone() {
  const date = new Date();
  const timeZoneOffset = date.toTimeString().match(/\(([^)]+)\)/);
  const timeZoneName = timeZoneOffset ? timeZoneOffset[1] : 'GMT';
  const timeZoneArea = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const abbreviation = timeZoneName
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase();

  return timeZoneArea === 'America/Mexico_City' ? 'CST' : abbreviation;
}

export const convertUTCtoLocalDate = (
  date: string | number | Date | undefined,
  formatVal = 'EEE, MMM dd, HH:mm',
) => {
  if (!date) return '';

  const utcDate = parseISO(date as string);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = toZonedTime(utcDate, userTimeZone);

  return format(zonedDate, formatVal);
};
