import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { blueglass, cloudLight, dusk } from 'src/constants/colors';

import { ReactComponent as IconChevronDown } from '../../assets/icn-chevron-down.svg';
import { availableBlockchain } from './OnOffChainTopicLayout';
import { CustomFusionEventData } from 'src/pages/AlertManager/UIConfigTab';

type Props = {
  setFilteredTopics: (filteredTopics: CustomFusionEventData[]) => void;
  topicsData: CustomFusionEventData[];
};

const TopicFilterSelect: React.FC<Props> = ({
  setFilteredTopics,
  topicsData,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const availableBlockchainLabels = availableBlockchain.map(
    (blockchain) => blockchain.label,
  );

  const getAvailableFilters = () => {
    const newAvailableFilters = new Set<string>(); // Use a Set to ensure uniqueness

    topicsData.forEach((topic) => {
      if (topic.fusionEventCategory === 'COMMUNITY_MANAGER') {
        newAvailableFilters.add('Community Manager');
      } else if (
        topic.fusionEventCategory === 'GENERAL' &&
        topic.fusionSource?.blockchainSource === 'OFF_CHAIN'
      ) {
        newAvailableFilters.add('Off_chain');
      } else if (topic.fusionEventCategory === 'GENERAL') {
        const blockchain = availableBlockchain.find(
          (item) => item.value === topic.fusionSource?.blockchainSource,
        );
        if (blockchain) {
          newAvailableFilters.add(blockchain.label);
        }
      }
    });

    return Array.from(newAvailableFilters);
  };

  useEffect(() => {
    if (selectedFilters.length === 0) {
      setFilteredTopics(topicsData);
      return;
    } else {
      const topics: CustomFusionEventData[] = [];

      selectedFilters.forEach((filter) => {
        if (filter === 'Community Manager') {
          topics.push(
            ...topicsData.filter(
              (topic) => topic.fusionEventCategory === 'COMMUNITY_MANAGER',
            ),
          );
        } else if (filter === 'Off_chain') {
          topics.push(
            ...topicsData.filter(
              (topic) =>
                topic?.fusionSource?.blockchainSource === 'OFF_CHAIN' &&
                topic.fusionEventCategory === 'GENERAL',
            ),
          );
        } else {
          const blockchain = availableBlockchain.find(
            (item) => item.label.toUpperCase() === filter.toUpperCase(),
          );

          if (blockchain) {
            topics.push(
              ...topicsData.filter(
                (topic) =>
                  topic?.fusionSource?.blockchainSource === blockchain.value &&
                  topic.fusionEventCategory === 'GENERAL',
              ),
            );
          }
        }
      });

      setFilteredTopics(topics);
    }
  }, [selectedFilters, setFilteredTopics, topicsData, setSelectedFilters]);

  const availableFilters = [
    'Community Manager',
    'Off_chain',
    ...availableBlockchainLabels,
  ];

  const handleSelectedFiltersChange = (value: string | string[]) => {
    setSelectedFilters(typeof value === 'string' ? value.split(',') : value);
  };

  const handleClear = () => {
    setSelectedFilters([]);
    setFilteredTopics(topicsData);
  };

  const handleSelectAll = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelectedFilters([...availableFilters]);
  };

  return (
    <Stack flexDirection="column" sx={{ width: '123px', borderRadius: '4px' }}>
      <Select
        sx={{
          backgroundColor: cloudLight,
          borderRadius: '4px',
          height: '35px',
        }}
        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
        multiple
        value={selectedFilters}
        onChange={(e) => {
          handleSelectedFiltersChange(e.target.value);
        }}
        displayEmpty
        renderValue={() => {
          return (
            <Typography
              sx={{
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 600,
                color: dusk,
                mt: '2px',
              }}
            >
              Filter Topics
            </Typography>
          );
        }}
        IconComponent={(props) => {
          return (
            <IconChevronDown
              {...props}
              style={{
                top: 'calc(50% - .7em)',
                fill: dusk,
              }}
            />
          );
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ mx: 0.5, mt: 1 }}
        >
          <Button
            disabled={selectedFilters.length === 0}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
              fontSize: '14px',
              fontWeight: 700,
              height: '20px',
              color: blueglass,
              textTransform: 'capitalize',
            }}
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
              fontSize: '14px',
              fontWeight: 700,
              height: '20px',
              color: blueglass,
              textTransform: 'capitalize',
            }}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              handleSelectAll(e)
            }
          >
            Select All
          </Button>
        </Stack>
        {getAvailableFilters().map((filter, index) => (
          <MenuItem
            key={index}
            value={filter}
            sx={{
              my: 0.5,
              py: 0.5,
              pl: 0,
              '&.Mui-selected': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Checkbox
              checked={selectedFilters.indexOf(filter) > -1}
              sx={{
                py: 0,
              }}
            />
            <Typography
              sx={{
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 700,
                color: blueglass,
              }}
            >
              {filter.replace(/_/g, '-')}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default TopicFilterSelect;
