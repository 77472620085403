import { useEffect, useMemo } from 'react';
import { useNotifiEnv } from '@components/auth/NotifiEnvContext';
import useQueryParam from './useQueryParam';
import { NotifiEnvironment } from '@components/auth/envUtils';

export const useEnvFromQueryParam = () => {
  const { notifiEnv: storedEnv, setNotifiEnv } = useNotifiEnv();

  const env = useQueryParam('environment');

  const notifiEnv: NotifiEnvironment = useMemo(
    () => (env === 'dev' || env === 'prd' ? env : storedEnv),
    [env, storedEnv],
  );

  useEffect(() => {
    setNotifiEnv(notifiEnv);
  }, [notifiEnv, setNotifiEnv]);

  return notifiEnv;
};
