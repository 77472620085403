import { gql } from '@apollo/client';

export const AllEmailSendersQuery = gql`
  query allEmailSenders {
    allEmailSenders {
      dkimTokens
      dnsVerificationToken
      domain
      id
      verificationStatus
    }
  }
`;
