import React from 'react';
import { Stack } from '@mui/system';
import { coral, midnightLightest } from 'src/constants/colors';
import { useFormMessageBarContext } from './cardConfig/form/context/FormMessageBarContext';

import ErrorIcn from '../assets/error-icn.svg';
import CheckIcn from '../assets/icn-check.svg';
import { Box, SxProps, Typography } from '@mui/material';
import { useFeatureFlag } from './auth/FeatureFlagContext';

type Props = {
  customSx?: SxProps;
};

export const FormMessageBar: React.FC<Props> = ({ customSx }) => {
  const { isAP2Enabled } = useFeatureFlag();
  const { messageBarState } = useFormMessageBarContext();

  const { message, status } = messageBarState;

  const iconToShow = (): string | undefined => {
    switch (status) {
      case 'success':
        return CheckIcn;
      case 'error':
        return ErrorIcn;

      default:
        return CheckIcn;
    }
  };

  const showMessageBar = message !== '';

  return showMessageBar ? (
    <Stack
      sx={{
        backgroundColor: isAP2Enabled ? 'transparent' : midnightLightest,
        justifyContent: 'center',
        borderRadius: '4px',
        py: 2,
        mb: 4,
        ...customSx,
      }}
    >
      <Stack flexDirection="row" sx={{ px: 2, alignItems: 'center' }}>
        <Box sx={{ paddingRight: 2, alignItems: 'center', display: 'flex' }}>
          {iconToShow !== undefined ? <img src={iconToShow()} /> : null}
        </Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '14px',
            color: status === 'error' ? coral : undefined,
          }}
        >
          {message}
        </Typography>
      </Stack>
    </Stack>
  ) : null;
};
