import { blueglass, dusk } from 'src/constants/colors';

export const checkboxCustomCSS = {
  '&, &.Mui-checked': {
    color: blueglass,
  },
  '&, &.Mui-not-checked': {
    color: dusk,
  },
};
