import { gql } from '@apollo/client';

export const PersistentStorageQuery = gql`
  query PersistentStorage(
    $PersistentStorageQueryInput: PersistentStorageQueryInput
    $first: Int
    $after: String
  ) {
    persistentStorage(
      persistentStorageQueryInput: $PersistentStorageQueryInput
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        createdDate {
          calculateSize
          nanos
          seconds
          toDateTime
          toDateTimeOffset
          toDiagnosticString
        }
        key
        updatedDate {
          calculateSize
          nanos
          seconds
          toDateTime
          toDateTimeOffset
          toDiagnosticString
        }
        value
        version
      }
    }
  }
`;
