import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Stack,
  Box,
} from '@mui/material';
import React, { useCallback } from 'react';
import {
  dusk,
  eggshell,
  midnight,
  midnightDarker,
  midnightLight,
  white,
} from 'src/constants/colors';
import { Close } from '@mui/icons-material';
import { ReactComponent as IconChevronDown } from '../../assets/icn-chevron-down.svg';
import {
  useUpdateEphemeralStorageMutation,
  useUpdatePersistentStorageMutation,
} from 'src/services/gql/generated';

import { PersistentStorageQuery } from 'src/services/gql/queries/PersistentStorageQuery.gql';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';

export enum KeyTypes {
  PERSISTANT = 'Persistant',
  EPHEMERAL = 'Ephemeral',
}

type Props = {
  open: boolean;
  handleClose: () => void;
};

const AddKeyDialog: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  handleClose,
}) => {
  const [type, setType] = React.useState<string>(KeyTypes.PERSISTANT);
  const { isAP2Enabled } = useFeatureFlag();
  const [key, setKey] = React.useState('');
  const [value, setValue] = React.useState('');

  const { setErrorMessage } = useFormMessageBarContext();
  const [updatePersistentStorageImpl, { loading: persistantLoading }] =
    useUpdatePersistentStorageMutation();
  const [updateEphemeralStorageImpl, { loading: ephemeralLoading }] =
    useUpdateEphemeralStorageMutation();

  const resetStorageState = useCallback(() => {
    handleClose();
    setValue('');
    setKey('');
    setType(KeyTypes.PERSISTANT);
  }, [handleClose]);

  const submit = useCallback(async () => {
    try {
      if (type === KeyTypes.EPHEMERAL) {
        await updateEphemeralStorageImpl({
          variables: {
            updateEphemeralStorageInput: {
              entries: [{ key, value }],
            },
          },
        });
        return;
      }
      await updatePersistentStorageImpl({
        variables: {
          updatePersistentStorageInput: {
            entries: [{ key, value }],
          },
        },
        refetchQueries: [
          {
            query: PersistentStorageQuery,
          },
        ],
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
        resetStorageState();
      }
    }
  }, [
    key,
    resetStorageState,
    setErrorMessage,
    type,
    updateEphemeralStorageImpl,
    updatePersistentStorageImpl,
    value,
  ]);

  return (
    <Dialog
      fullWidth={false}
      maxWidth="lg"
      open={open}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: isAP2Enabled ? white : midnightLight,
          borderRadius: '4px',
          maxWidth: '513px',
        },
      }}
    >
      <Stack
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        sx={{
          backgroundColor: isAP2Enabled ? white : midnightLight,
          fontFamily: 'Rota',
          p: 4,
        }}
      >
        <Grid
          container
          flexWrap="nowrap"
          alignItems="start"
          justifyContent="center"
        >
          <DialogTitle
            sx={{
              fontSize: '18px',
              lineHeight: '22px',
              p: 0,
              textAlign: 'center',
              width: '100%',
              flex: '1 1 auto',
              ml: '64px',
              mr: '42px',
            }}
          >
            Add Key
          </DialogTitle>

          <Box
            sx={{ width: '25px', height: '25px', cursor: 'pointer' }}
            onClick={() => {
              resetStorageState();
            }}
          >
            <Close sx={{ color: dusk }} />
          </Box>
        </Grid>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            maxHeight: '550px',
            overflow: 'auto',
            width: '100%',
          }}
        >
          <DialogContent
            sx={{
              maxHeight: '550px',
              width: '400px',
              overflow: 'unset',
              pb: 0,
              px: 0,
            }}
          >
            <InputLabel sx={{ mb: 0.5, fontSize: '14px' }}>
              Type of Key
            </InputLabel>
            <Select
              value={type}
              label="Type"
              onChange={(e) => setType(e.target.value)}
              renderValue={(selected) => {
                return selected;
              }}
              IconComponent={(props) => {
                return (
                  <IconChevronDown
                    {...props}
                    style={{
                      marginRight: '12px',
                      top: 'calc(50% - .7em)',
                      fill: isAP2Enabled ? dusk : white,
                    }}
                  />
                );
              }}
              sx={{
                display: 'flex',
                flex: 1,
                backgroundColor: isAP2Enabled ? eggshell : midnightDarker,
                color: isAP2Enabled ? midnight : white,
                ':before': { border: 'none' },
                '&.MuiSelect-iconStandard': { color: 'red' },
              }}
            >
              {[KeyTypes.PERSISTANT, KeyTypes.EPHEMERAL].map((r) => {
                return (
                  <MenuItem value={r} key={r}>
                    <ListItemText primary={r} />
                  </MenuItem>
                );
              })}
            </Select>
            <TextField
              fullWidth
              value={key}
              label="Key"
              sx={{
                mt: '31px',
                backgroundColor: isAP2Enabled ? eggshell : midnightDarker,
              }}
              onChange={(e) => setKey((e.target as HTMLInputElement).value)}
            />
            <TextField
              fullWidth
              value={value}
              label="Value"
              sx={{
                mt: '31px',
                backgroundColor: isAP2Enabled ? eggshell : midnightDarker,
              }}
              onChange={(e) => setValue((e.target as HTMLInputElement).value)}
            />
          </DialogContent>
        </Stack>
        <Stack
          alignItems="center"
          direction={'row'}
          gap={3}
          sx={{ mt: 4, width: '100%' }}
        >
          <SecondaryButton
            customSx={{
              width: '50%',
            }}
            buttonTitle="Cancel"
            onClick={resetStorageState}
          />

          <PrimaryButton
            customSx={{
              width: '50%',
            }}
            disabled={persistantLoading || ephemeralLoading}
            buttonTitle="Save"
            onClick={async () => {
              await submit();
              resetStorageState();
            }}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default AddKeyDialog;
