/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Stack, TextField, Tooltip, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import {
  dusk,
  eggshell,
  midnight,
  seaglassDarker,
  white,
} from 'src/constants/colors';
import { useParams } from 'react-router-dom';
import {
  useGetFusionTopicsData,
  useGetListTemplates,
} from '@components/alertManager/TopicsDataContext';

const convertFilterTypeLable = (filterType: string) => {
  switch (filterType) {
    case 'aboveThreshold':
      return 'Above Threshold';
    case 'belowThreshold':
      return 'Below Threshold';
    case 'aboveOrBelowThreshold':
      return 'Above or Below Threshold';
    default:
      return '';
  }
};
import DefineTemplatesDialog from '@components/alertManager/DefineTemplatesDialog';
import TemplateLearnMoreDialog from '@components/alertManager/TemplateLearnMoreDialog';
import EmptyTemplates from '@components/alertManager/TemplatesCard/EmptyTemplates';
import {
  WalletBlockchain,
  useFusionSourceStateQuery,
} from 'src/services/gql/generated';

export type fusionSourceFilterType = {
  TopicHashes: string[];
  ContractAddresses: string[];
};
import ParserCard from './ParserCard/ParserCard';
import ParserEmpty from './ParserCard/ParserEmpty';
import TemplatesCard from '@components/alertManager/TemplatesCard/TemplatesCard';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import { useNotifiEnv } from '@components/auth/NotifiEnvContext';
import { toEllipse } from 'src/util/stringUtils';
import CreateNonCMTopicDialog, {
  AlertFilter,
  Filter,
  FusionEventMetadata,
  UiType,
  UserInputParam,
} from '@components/alertManager/CreateNonCMTopicDialog';
import { getSign } from '../../util/CardDisplayUtils';

export const GeneralEditTopicView: React.FC = () => {
  const {
    items: topics,
    isLoading: topicsIsLoading,
    refetchFusionTopicsData,
  } = useGetFusionTopicsData();
  const { notifiEnv } = useNotifiEnv();
  const normalPermissionTokenKey = useMemo(
    () => `config-tool:${notifiEnv}:normalPermissionToken`,
    [notifiEnv],
  );
  const [isUploadParserModalOpen, setIsUploadParserModalOpen] =
    React.useState<boolean>(false);
  const [isDefineTemplatesDialogOpen, setIsDefineTemplatesDialogOpen] =
    React.useState<boolean>(false);
  const [isTemplateLearnMoreDialogOpen, setIsTemplateLearnMoreDialogOpen] =
    React.useState<boolean>(false);
  const [isCreateNonCMTopicDialogOpen, setIsCreateNonCMTopicDialogOpen] =
    useState<boolean>(false);

  const params = useParams();
  const topic = topics.find((item) => item?.id === params.cardId);

  const { items: listTemplates, isLoading: listTemplatesLoading } =
    useGetListTemplates(topic?.id ?? '');
  const uploadedTemplateTypes = listTemplates?.map(
    (template) => template.templateType,
  );

  const metaData: FusionEventMetadata = JSON.parse(topic?.metadata ?? '{}');
  const topicDisplayName = metaData?.uiConfigOverride?.topicDisplayName ?? '';
  const historyDisplayName =
    metaData?.uiConfigOverride?.historyDisplayName ?? '';
  const subscriptionValueOrRef =
    metaData?.uiConfigOverride?.subscriptionValueOrRef ?? {};
  let filterName = '';
  let filterValue: UserInputParam<UiType> | undefined;
  const thresholdFilter = metaData?.filters?.find((obj: Filter) =>
    Object.keys(obj).includes('userInputParams'),
  ) as AlertFilter;

  if (thresholdFilter) {
    filterName = convertFilterTypeLable(thresholdFilter.name);
    filterValue = thresholdFilter?.userInputParams?.find(
      (item) => item.name === 'threshold',
    );
  }

  const { data, loading } = useFusionSourceStateQuery({
    variables: { input: { fusionSourceId: topic?.fusionSource?.id || '' } },
  });

  const isNonNotifiHostedTopic = topic?.fusionSource?.name.startsWith('__');
  const isParserStateLoading = loading || topicsIsLoading;
  const isParserUploaded = data?.fusionSourceState?.version > 0;

  const fusionSourceFilter: fusionSourceFilterType = JSON.parse(
    topic?.fusionSource?.filter || '{}',
  );

  const normalPermissionToken = localStorage.getItem(normalPermissionTokenKey);
  const parserSourceId = topic?.fusionSource?.id || '';
  const envText =
    notifiEnv?.toUpperCase() === 'PRD'
      ? ''
      : `NOTIFI_ENV="${notifiEnv?.toUpperCase()}"`;
  const copyText = `${envText} FUSION_SOURCE_ID="${parserSourceId}" NOTIFI_AUTH_TOKEN="${normalPermissionToken}" /bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/notifi-network/notifi-parser-sdk/main/start.sh)"`;
  const handleUploadParser = () => {
    setIsUploadParserModalOpen(true);
  };

  const handleTextCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(copyText).catch((err) => {
        console.error('Clipboard write error:', err);
      });
    } else {
      console.error('Clipboard API not supported');
    }
  };

  const getUserInputValue = () => {
    if (subscriptionValueOrRef) {
      if (subscriptionValueOrRef.type === 'value') {
        return 'No Input From User';
      } else if (
        subscriptionValueOrRef.type === 'ref' &&
        subscriptionValueOrRef.ref === 'walletAddress'
      ) {
        return 'Wallet Address';
      } else {
        return subscriptionValueOrRef.ref;
      }
    } else {
      return '';
    }
  };

  return (
    <>
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          flexDirection: { xs: 'column', lg: 'row' },
          width: '100%',
          columnGap: 3,
        }}
      >
        <Stack gap={3} width="60%">
          <Box
            sx={{
              backgroundColor: '#FEFEFE',
              width: '100%',
              padding: '24px 30px 28px 30px',
              borderRadius: '8px',
              minWidth: '600px',
              boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
              mr: 3,
            }}
          >
            <Stack flexDirection="column" sx={{ padding: 0 }}>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%', mb: '10px' }}
              >
                <Typography sx={{ fontSize: '22px', fontWeight: 900 }}>
                  Topic Details
                </Typography>
                <PrimaryButton
                  buttonTitle="Edit"
                  customSx={{ height: '28px', fontSize: '14px' }}
                  onClick={() => setIsCreateNonCMTopicDialogOpen(true)}
                />
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                  Information
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ borderTop: '2px solid #E8EBF5', pt: '8px' }}
              >
                <Typography
                  sx={{
                    color: dusk,
                    fontSize: '12px',
                    fontWeight: 800,
                    mt: 1,
                    flex: '1 1 35%',
                  }}
                >
                  Topic Name
                </Typography>
                <Typography
                  sx={{
                    color: midnight,
                    fontSize: '16px',
                    fontWeight: 600,
                    mt: 1,
                    flex: '1 1 65%',
                  }}
                >
                  {topic?.name ?? ''}
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  sx={{
                    color: dusk,
                    fontSize: '12px',
                    fontWeight: 800,
                    mt: 1,
                    flex: '1 1 35%',
                  }}
                >
                  Topic Display Title in Card
                </Typography>
                <Typography
                  sx={{
                    color: midnight,
                    fontSize: '16px',
                    fontWeight: 600,
                    mt: 1,
                    flex: '1 1 65%',
                  }}
                >
                  {topicsIsLoading ? null : topicDisplayName}
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  sx={{
                    color: dusk,
                    fontSize: '12px',
                    fontWeight: 800,
                    mt: 1,
                    flex: '1 1 35%',
                  }}
                >
                  Topic Display Title in Alert
                </Typography>
                <Typography
                  sx={{
                    color: midnight,
                    fontSize: '16px',
                    fontWeight: 600,
                    mt: 1,
                    flex: '1 1 65%',
                  }}
                >
                  {topicsIsLoading ? null : historyDisplayName}
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  sx={{
                    color: dusk,
                    fontSize: '12px',
                    fontWeight: 800,
                    mt: 1,
                    flex: '1 1 35%',
                  }}
                >
                  Topic Type
                </Typography>
                <Typography
                  sx={{
                    color: midnight,
                    fontSize: '16px',
                    fontWeight: 600,
                    mt: 1,
                    flex: '1 1 65%',
                  }}
                >
                  {topicsIsLoading
                    ? null
                    : isNonNotifiHostedTopic
                    ? 'Sent from my server'
                    : 'Notifi monitors on or off-chain activity'}
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 700, pt: '25px' }}
                >
                  Subscriber Data & Filters
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ borderTop: '2px solid #E8EBF5', pt: '8px' }}
              >
                <Typography
                  sx={{
                    color: dusk,
                    fontSize: '12px',
                    fontWeight: 800,
                    mt: 1,
                    flex: '1 1 35%',
                  }}
                >
                  User Information Required
                </Typography>
                <Typography
                  sx={{
                    color: midnight,
                    fontSize: '16px',
                    fontWeight: 600,
                    mt: 1,
                    flex: '1 1 65%',
                  }}
                >
                  {getUserInputValue()}
                </Typography>
              </Stack>

              {thresholdFilter ? (
                <>
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        color: dusk,
                        fontSize: '12px',
                        fontWeight: 800,
                        mt: 1,
                        flex: '1 1 35%',
                      }}
                    >
                      Filter & Name
                    </Typography>
                    <Typography
                      sx={{
                        color: midnight,
                        fontSize: '16px',
                        fontWeight: 600,
                        mt: 1,
                        flex: '1 1 65%',
                      }}
                    >
                      {filterName}
                    </Typography>
                  </Stack>
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ pt: '8px' }}
                  >
                    <Typography
                      sx={{
                        color: dusk,
                        fontSize: '12px',
                        fontWeight: 800,
                        mt: 1,
                        flex: '1 1 35%',
                      }}
                    >
                      Filter Detail
                    </Typography>
                    <div
                      style={{
                        flex: '1 1 65%',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {filterValue?.options
                        .filter(Boolean)
                        .map((item, index) => (
                          <Typography
                            key={index}
                            sx={{
                              color: midnight,
                              fontSize: '16px',
                              fontWeight: 600,
                              mt: 1,
                              marginRight: '10px',
                            }}
                          >
                            {getSign(
                              thresholdFilter.userInputParams[0].kind,
                              item.toString(),
                            )}
                            {index <= filterValue!.options!.length && ','}
                          </Typography>
                        ))}
                      {filterValue?.allowCustomInput && (
                        <Typography
                          sx={{
                            color: midnight,
                            fontSize: '16px',
                            fontWeight: 600,
                            mt: 1,
                            marginRight: '10px',
                          }}
                        >
                          Custom
                        </Typography>
                      )}
                    </div>
                  </Stack>
                </>
              ) : null}
            </Stack>
          </Box>

          {isNonNotifiHostedTopic && (
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                background: white,
                p: '24px 40px',
                width: '100%',
                borderRadius: '8px',
              }}
            >
              <div>
                <Typography variant="h3" sx={{ mb: 1 }}>
                  How to publish messages to this topic
                </Typography>
                <Typography sx={{ fontSize: '14px', color: dusk }}>
                  Go to the Notifi SDK to learn how to publish messages and see
                  an example API call with the parameters filled out for
                  reference.
                </Typography>
              </div>
              <PrimaryButton
                customSx={{ width: '200px' }}
                buttonTitle="Notifi SDK"
                onClick={() =>
                  window.open(
                    'https://docs.notifi.network/docs/alert-trigger/node-client',
                    '_blank',
                  )
                }
              />
            </Stack>
          )}
        </Stack>

        <Stack sx={{ gap: '12px', width: '40%' }}>
          {isParserStateLoading ||
          isNonNotifiHostedTopic ? null : isParserUploaded &&
            data?.fusionSourceState ? (
            <ParserCard
              fusionSource={data?.fusionSourceState}
              fusionEventId={topic?.id || ''}
              onCreateParser={handleUploadParser}
            />
          ) : (
            <ParserEmpty onCreateParser={handleUploadParser} />
          )}

          {listTemplatesLoading ? null : listTemplates &&
            listTemplates.length > 0 ? (
            <TemplatesCard
              uploadedTemplateTypes={uploadedTemplateTypes}
              setIsDefineTemplatesDialogOpen={setIsDefineTemplatesDialogOpen}
            />
          ) : (
            <EmptyTemplates
              setIsDefineTemplatesDialogOpen={setIsDefineTemplatesDialogOpen}
              setIsTemplateLearnMoreDialogOpen={
                setIsTemplateLearnMoreDialogOpen
              }
            />
          )}
        </Stack>
      </Stack>
      <DefineTemplatesDialog
        topic={topic}
        handleClose={() => setIsDefineTemplatesDialogOpen(false)}
        open={isDefineTemplatesDialogOpen}
      />
      <TemplateLearnMoreDialog
        handleClose={() => setIsTemplateLearnMoreDialogOpen(false)}
        open={isTemplateLearnMoreDialogOpen}
      />

      <ConfirmDialog
        title="Terminal Command"
        subText="Paste the command in terminal under a folder which you would like to contain your parser source. If you are on windows, paste this into WSL."
        open={isUploadParserModalOpen}
        handleClose={() => setIsUploadParserModalOpen(false)}
        handleConfirm={handleTextCopy}
        ctaTitle="Copy Command"
        sx={{ width: '600px' }}
        hideSecondaryAction
        showCloseIcon={true}
      >
        <TextField
          multiline
          rows={11}
          disabled
          value={copyText}
          sx={{
            mt: 2,
            mb: 1,
            '& textarea': {
              backgroundColor: eggshell,
              borderRadius: '4px',
              p: '15px 20px',
              fontFamily: 'Rota',
              lineHeight: '20px',
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: seaglassDarker,
                borderRadius: '10px',
              },
            },
            '& .MuiInputBase-root': {
              p: 0,
              border: 'none',
            },
            width: '100%',
          }}
        />
      </ConfirmDialog>
      {isCreateNonCMTopicDialogOpen ? (
        <CreateNonCMTopicDialog
          topic={topic}
          isEdit={true}
          isEditNonNotifiHosted={isNonNotifiHostedTopic}
          open={isCreateNonCMTopicDialogOpen}
          handleClose={() => {
            setIsCreateNonCMTopicDialogOpen(false);
          }}
        />
      ) : null}
    </>
  );
};
