import { Stack, SxProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { midnight } from 'src/constants/colors';

type DefaultContainerProps = {
  sx?: SxProps;
};

export const BaseContainer: React.FC<
  PropsWithChildren<DefaultContainerProps>
> = ({ sx, children }) => {
  return (
    <Stack
      direction="row"
      paddingLeft="60px"
      marginTop="2%"
      borderRadius="8px"
      sx={{ backgroundColor: midnight, ...sx }}
    >
      {children}
    </Stack>
  );
};
