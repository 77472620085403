import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { dusk, seaglassDarker } from 'src/constants/colors';
import { ReactComponent as TemplateEmpty } from '../../../assets/template-empty.svg';

type Props = {
  setIsDefineTemplatesDialogOpen: (value: boolean) => void;
  setIsTemplateLearnMoreDialogOpen: (value: boolean) => void;
};

const EmptyTemplates = ({
  setIsDefineTemplatesDialogOpen,
  setIsTemplateLearnMoreDialogOpen,
}: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: '#FEFEFE',
        padding: '24px 30px 24px 30px',
        borderRadius: '8px',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TemplateEmpty />
      <Typography
        sx={{
          fontSize: '22px',
          fontWeight: 900,
          mb: '8px',
          mt: '16px',
        }}
      >
        Templates
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          color: dusk,
          mb: '16px',
          textAlign: 'center',
        }}
      >
        Configure your alert templates to use this topic. The templates are what
        your users will see when they receive alerts to their chosen
        destinations.
      </Typography>
      <PrimaryButton
        onClick={() => setIsDefineTemplatesDialogOpen(true)}
        buttonTitle="Configure Templates"
        customSx={{ height: '40px' }}
      />
      <Typography
        onClick={() => setIsTemplateLearnMoreDialogOpen(true)}
        sx={{
          color: seaglassDarker,
          fontSize: '16px',
          fontWeight: 900,
          p: 0,
          cursor: 'pointer',
          mt: '20px',
        }}
      >
        Learn More
      </Typography>
    </Box>
  );
};

export default EmptyTemplates;
