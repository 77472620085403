import { gql } from '@apollo/client';

export const EmailSenderDefaultsQuery = gql`
  query GetEmailSenderDefaults {
    emailSenderDefaults {
      defaultEmailSenderName
      defaultEmailSenderDisplayName
      defaultEmailSenderDomainId
    }
  }
`;
