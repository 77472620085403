import { useState, useMemo } from 'react';

export const unitOptions = [
  'Seconds',
  'Minutes',
  'Hours',
  'Days',
  'Weeks',
] as const;
export type Unit = typeof unitOptions[number];

export const getCronExecutionInterval = (args: {
  unit: Unit;
  frequency: string;
}) => {
  const { unit, frequency } = args;
  let seconds = '00';
  let minutes = '00';
  let hours = '00';
  let days: number | string = '0';

  if (unit === 'Seconds') {
    minutes = Math.floor(+frequency / 60)
      .toString()
      .padStart(2, '0');
    seconds = (+frequency % 60).toString().padStart(2, '0');
  }
  if (unit === 'Minutes') {
    hours = Math.floor(+frequency / 60)
      .toString()
      .padStart(2, '0');
    minutes = (+frequency % 60).toString().padStart(2, '0');
  }
  if (unit === 'Hours') {
    days = Math.floor(+frequency / 24);
    hours = (+frequency % 24).toString().padStart(2, '0');
  }
  if (unit === 'Days') days = frequency || '0';
  if (unit === 'Weeks') days = Number(frequency) * 7;

  return `${days}.${hours}:${minutes}:${seconds}`;
};

export const covertCronToFrequencyAndUnit = (cron: string) => {
  // cron format would be 0.00:00:00
  // It will represent - days:hours:minutes:seconds

  let cronValue = cron?.split('.');
  cronValue = [cronValue[0], ...(cronValue?.[1]?.split(':') || [])];

  const days = +cronValue[0];
  const hours = +cronValue[1];
  const minutes = +cronValue[2];
  const seconds = +cronValue[3];
  let unit: Unit;
  let frequency: number;

  if (seconds > 0) {
    unit = 'Seconds';
    if (minutes > 0) frequency = minutes * 60 + seconds;
    else frequency = seconds;
  } else if (minutes > 0) {
    unit = 'Minutes';
    if (hours > 0) frequency = hours * 60 + minutes;
    else frequency = minutes;
  } else if (hours > 0) {
    unit = 'Hours';
    if (days > 0) frequency = days * 24 + hours;
    else frequency = hours;
  } else {
    frequency = days;
    unit = 'Days';
  }

  return { unit, frequency };
};

const useFrequencyByUnit = () => {
  const [frequency, setFrequency] = useState<string>('');
  const [unit, setUnit] = useState<Unit>('Seconds');

  const cronExecutionInterval = useMemo(
    () => getCronExecutionInterval({ unit, frequency }),
    [unit, frequency],
  );

  const cronToFrequencyAndUnit = (cron: string) => {
    const { unit, frequency } = covertCronToFrequencyAndUnit(cron);
    setUnit(unit);
    setFrequency(frequency.toString());
  };

  return {
    frequency,
    setFrequency,
    unit,
    setUnit,
    cronExecutionInterval,
    cronToFrequencyAndUnit,
  };
};

export default useFrequencyByUnit;
