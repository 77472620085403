import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import {
  Box,
  Fade,
  Popper,
  PopperPlacementType,
  Stack,
  Typography,
} from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';
import { dusk, white } from 'src/constants/colors';

type CalloutTooltipProps = {
  title: string;
  title2?: string;
  subtitle?: string;
  placement: PopperPlacementType;
  onClick?: () => Promise<void>;
  onSecondaryButtonClick?: () => Promise<void>;
  onBlur?: () => Promise<void>;
  buttonText?: string;
  isTooltipOpen: boolean;
  secondaryButtonText?: string;
};

/*
 * A tooltip that persists until you dismiss it. Useful for calling attention to new features.
 */
export const CalloutTooltip: React.FC<
  PropsWithChildren<CalloutTooltipProps>
> = ({
  children,
  title,
  subtitle,
  buttonText,
  isTooltipOpen,
  title2,
  onClick,
  onSecondaryButtonClick,
  placement,
  onBlur,
  secondaryButtonText,
}) => {
  const [anchorRef, setAnchorRef] = useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = useState<null | HTMLElement>(null);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const arrowBefore: any = {
    backgroundColor: white,
    borderBottom: 'solid 1px #B6B8D5',
    borderLeft: 'solid 1px #B6B8D5',
    content: '""',
    display: 'block',
    height: '14px',
    margin: 'auto',
    width: '14px',
  };
  const arrow: any = {
    '&::before': arrowBefore,
    fontSize: 7,
    height: '3em',
    marginTop: '.5em',
    position: 'absolute',
    width: '1em',
  };

  switch (placement) {
    case 'left':
      arrowBefore.transform = 'rotate(225deg)';
      arrow.marginRight = '0.1em';
      arrow.right = 0;
      break;
    case 'right':
      arrowBefore.transform = 'rotate(45deg)';
      arrow.left = 0;
      arrow.marginLeft = '-0.9em';
      break;
    case 'top-end':
      arrowBefore.transform = 'translate3d(110px, 0px, 0px) rotate(-45deg)';
      arrow.bottom = '-15px';
      arrow.marginbottom = '0.1em';
      break;
    case 'bottom-start':
      arrowBefore.transform = 'translate3d(-110px, 0px, 0px) rotate(135deg)';
      arrow.top = '-10px';
      arrow.marginbottom = '0.1em';
      break;
    case 'bottom':
      arrowBefore.transform = 'translate3d(0, 0px, 0px) rotate(135deg)';
      arrow.top = '-10px';
      arrow.marginbottom = '0.1em';
      break;
    case 'bottom-end':
      arrowBefore.transform = 'translate3d(110px, 0px, 0px) rotate(135deg)';
      arrow.top = '-10px';
      arrow.marginbottom = '0.1em';
      break;
    default:
      // TODO: support more placement types as needed
      break;
  }

  return (
    <>
      <span ref={setAnchorRef}>{children}</span>
      <Fade in={isTooltipOpen && !!anchorRef}>
        <Popper
          className="tooltip"
          anchorEl={anchorRef}
          modifiers={[
            {
              name: 'arrow',
              options: {
                element: arrowRef,
              },
            },
          ]}
          open={true}
          placement={placement}
          onBlur={onBlur}
          sx={{ zIndex: 1200 }}
          onClick={(e) => e.stopPropagation()}
        >
          <Box component="span" ref={setArrowRef} sx={() => arrow} />
          <Box
            sx={{
              backgroundColor: white,
              border: 'solid 1px #B6B8D5',
              borderRadius: '5px',
              px: 3,
              py: 2,
              textAlign: 'center',
              width: '220px',
              zIndex: 1200,
            }}
          >
            <Stack
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                sx={{
                  color: '#6E79EC',
                  fontSize: '12px',
                  mb: '4px',
                  width: '100%',
                  textAlign: 'left',
                }}
              >
                Next step
              </Typography>
              <Typography
                variant="h3"
                sx={{ mb: '8px', fontWeight: 900, textAlign: 'left' }}
              >
                {title}
              </Typography>
              {title2 ? (
                <>
                  <Typography
                    variant="h2"
                    sx={{
                      mb: '8px',
                      color: dusk,
                      textAlign: 'center',
                      width: '100%',
                      pr: '10px',
                    }}
                  >
                    - or -
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ mb: '8px', fontWeight: 900, textAlign: 'left' }}
                  >
                    {title2}
                  </Typography>
                </>
              ) : null}
              <Typography variant="h4" sx={{ mb: '15px', textAlign: 'left' }}>
                {subtitle}
              </Typography>
              {buttonText ? (
                <PrimaryButton
                  onClick={onClick}
                  buttonTitle={buttonText}
                  customSx={{ width: '170px', px: 0 }}
                />
              ) : null}
              {secondaryButtonText ? (
                <SecondaryButton
                  onClick={onSecondaryButtonClick}
                  buttonTitle={secondaryButtonText}
                  customSx={{ width: '170px', mt: 2 }}
                />
              ) : null}
            </Stack>
          </Box>
        </Popper>
      </Fade>
    </>
  );
};
