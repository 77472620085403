import React, { useMemo } from 'react';
import { Box, CircularProgress, Stack, Tab, Tabs } from '@mui/material';
import {
  useCardConfigDataContext,
  useGetSubscriptionConfigData,
} from '@components/cardConfig/CardConfigDataContext';
import UIConfigTab from './UIConfigTab';
import { useGetFusionTopicsData } from '@components/alertManager/TopicsDataContext';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { PageHeaderSection } from '@components/section/Header';
import { useNavigate } from 'react-router-dom';
import { midnight } from 'src/constants/colors';
import { TABS } from '../AccountSettings/constants';
import CreateUIConfigModal from '@components/alertManager/CreateUIConfigModal';
import CreateEasySubscriptionUICardModal from '@components/alertManager/CreateEasySubscriptionUICardModal';
import {
  TenantConfigType,
  useGetOpenUserRegistrationsQuery,
} from 'src/services/gql/generated';
import { getCardNavigationUrl } from 'src/util/CardDisplayUtils';
import { useAuthContext } from '@components/auth/AuthContext';

export const AlertManagerConfigsViewContainer: React.FC = () => {
  const [isUICardModalOpen, setIsUICardModalOpen] =
    React.useState<boolean>(false);
  const [isEasySubsConfigModalOpen, setIsEasySubsConfigModalOpen] =
    React.useState<boolean>(false);
  const [isCreatingNotificationCard, setIsCreatingNotificationCard] =
    React.useState<boolean>(false);
  const navigate = useNavigate();
  const { items: topicsData, isLoading } = useGetFusionTopicsData();
  const { items: cardConfigData, isLoading: loadingSubscriptionConfigData } =
    useGetSubscriptionConfigData();
  const { data: easySubsCardConfigData } = useGetOpenUserRegistrationsQuery({
    variables: {},
  });
  const authState = useAuthContext();
  const isMarketerRole = useMemo(() => {
    if (authState.type === 'loggedIn') {
      return Boolean(
        authState.roles?.includes('UserMessenger') &&
          authState.roles?.length === 2,
      );
    }
    return false;
  }, [authState]);
  const easySubsCardConfigNodes =
    easySubsCardConfigData?.openUserRegistrations?.nodes;

  const { create } = useCardConfigDataContext();

  const handleChange = (_: React.SyntheticEvent, value: number) => {
    switch (value) {
      case TABS.storage.value:
        navigate('/alert/storage');
        break;
      default:
        navigate('/alert');
        break;
    }
  };

  const createConfig = () => {
    setIsUICardModalOpen(true);
  };

  const createEasySubsConfig = () => {
    setIsUICardModalOpen(false);
    setIsEasySubsConfigModalOpen(true);
  };

  const createNotificationCardConfig = () => {
    setIsCreatingNotificationCard(true);
    const configType = TenantConfigType.SUBSCRIPTION_CARD;
    create('', configType)
      .then((result) => {
        if (result.id) {
          const url = getCardNavigationUrl(configType, result.id);
          navigate(url);
        }
      })
      .finally(() => setIsCreatingNotificationCard(false));
  };

  if (isLoading || loadingSubscriptionConfigData)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: '200px',
          width: '100%',
        }}
      >
        <CircularProgress size="50px" thickness={2} />
      </Box>
    );

  return (
    <Stack
      sx={{
        height: '100vh',
        overflow: 'auto',
        padding: '5%',
        position: 'relative',
      }}
    >
      <Stack>
        <PageHeaderSection
          title="Alert Manager"
          subTitle="Welcome to the Notifi Network Admin Portal"
        />
        {(cardConfigData && cardConfigData.length > 0) ||
        (easySubsCardConfigNodes && easySubsCardConfigNodes.length > 0) ? (
          <PrimaryButton
            buttonTitle="+ UI Config"
            customSx={{
              height: '40px',
              padding: '10px',
              width: '100%',
              maxWidth: '110px',
              position: 'absolute',
              top: 'calc(5% + 80px)',
              right: '5%',
              zIndex: 10,
            }}
            onClick={createConfig}
          />
        ) : null}
      </Stack>

      <Box
        sx={{
          borderBottom: '1px solid #B6B8D5',
          mt: 2,
          position: 'relative',
        }}
      >
        <Tabs value={1} onChange={handleChange}>
          <Tab
            disableRipple
            label="Topics"
            className=""
            sx={{
              '&.MuiButtonBase-root': {
                padding: 0,
                minWidth: '40px',
                '&.Mui-selected': {
                  color: midnight,
                },
              },
            }}
          />
          <Tab
            disableRipple
            label="UI Config"
            sx={{
              '&.MuiButtonBase-root': {
                padding: 0,
                ml: 4,
                minWidth: '40px',
                '&.Mui-selected': {
                  color: midnight,
                },
              },
            }}
          />
          {isMarketerRole ? null : (
            <Tab
              disableRipple
              label="Storage"
              sx={{
                '&.MuiButtonBase-root': {
                  padding: 0,
                  ml: 4,
                  minWidth: '40px',
                  '&.Mui-selected': {
                    color: midnight,
                  },
                },
              }}
            />
          )}
        </Tabs>
      </Box>

      <Stack spacing={2} mt="40px">
        <UIConfigTab
          topicsData={topicsData}
          cardConfigData={cardConfigData}
          isCreatingNotificationCard={isCreatingNotificationCard}
          handleCreateEasySubsConfig={createEasySubsConfig}
          handleCreateNotificationCardConfig={createNotificationCardConfig}
        />
      </Stack>
      {isUICardModalOpen && (
        <CreateUIConfigModal
          isUICardModalOpen={isUICardModalOpen}
          setIsUICardModalOpen={setIsUICardModalOpen}
          isCreatingNotificationCard={isCreatingNotificationCard}
          handleCreateEasySubsConfig={createEasySubsConfig}
          handleCreateNotificationCardConfig={createNotificationCardConfig}
        />
      )}
      {isEasySubsConfigModalOpen && (
        <CreateEasySubscriptionUICardModal
          open={isEasySubsConfigModalOpen}
          handleClose={() => setIsEasySubsConfigModalOpen(false)}
        />
      )}
    </Stack>
  );
};
