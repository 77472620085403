import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  CardConfigItem,
  cardConfigItemFromJson,
  createDefaultCardConfigItem,
} from '@components/cardConfig/common/CardConfigItem';
import { useMutation, useQuery } from '@apollo/client';
import { getTypeFromVersion } from 'src/util/CardDisplayUtils';
import {
  IntercomCardConfigItemV1,
  SubscriptionCardConfigItemV1,
} from './common/CardConfigItemV1';
import { CardConfigQuery } from 'src/services/gql/queries/CardConfigQuery.gql';
import {
  CardConfigQuery as CardConfigQueryResponse,
  TenantConfigType,
  CreateCardConfigMutation as CreateCardConfigMutationResponse,
  CreateCardConfigMutationVariables,
  DeleteCardConfigMutation as DeleteCardConfigMutationResponse,
  DeleteCardConfigMutationVariables,
} from 'src/services/gql/generated';
import { CreateCardConfigMutation } from 'src/services/gql/mutations/CreateCardConfigMutation.gql';
import { DeleteCardConfigMutation } from 'src/services/gql/mutations/DeleteCardConfigMutation.gql';

type CardConfigDataContextState = Readonly<{
  loading: boolean;
  create: (name: string, type: TenantConfigType) => Promise<CardConfigItem>;
  deleteItem: (id: string, type: TenantConfigType) => Promise<void>;
  updateItem: (item: CardConfigItem) => Promise<CardConfigItem>;
}>;

const CardConfigDataContext = createContext<CardConfigDataContextState>({
  loading: false,
  create: () => Promise.reject('Uninitialized'),
  deleteItem: () => Promise.reject('Uninitialized'),
  updateItem: () => Promise.reject('Uninitialized'),
});

export const useGetSubscriptionConfigData = () => {
  const { data, loading: queryLoading } = useQuery<CardConfigQueryResponse>(
    CardConfigQuery,
    { variables: { type: TenantConfigType.SUBSCRIPTION_CARD } },
  );

  const cardData = useMemo(
    () =>
      data?.tenantConfigs?.nodes
        ?.map((it) => {
          const { id, dataJson } = it;
          if (
            id === undefined ||
            id === null ||
            dataJson === undefined ||
            dataJson === null
          ) {
            return null;
          }
          const parsed = cardConfigItemFromJson(id, dataJson);
          return parsed;
        })
        ?.filter((it): it is SubscriptionCardConfigItemV1 => it !== null) ?? [],
    [data?.tenantConfigs?.nodes],
  );
  return { isLoading: queryLoading, items: cardData };
};

export const useGetIntercomConfigData = () => {
  const { data, loading: queryLoading } = useQuery<CardConfigQueryResponse>(
    CardConfigQuery,
    { variables: { type: TenantConfigType.INTERCOM_CARD } },
  );

  const cardData = useMemo(
    () =>
      data?.tenantConfigs?.nodes
        ?.map((it) => {
          const { id, dataJson } = it;
          if (
            id === undefined ||
            id === null ||
            dataJson === undefined ||
            dataJson === null
          ) {
            return null;
          }
          const parsed = cardConfigItemFromJson(id, dataJson);
          return parsed;
        })
        ?.filter((it): it is IntercomCardConfigItemV1 => it !== null) ?? [],
    [data?.tenantConfigs?.nodes],
  );
  return { isLoading: queryLoading, items: cardData };
};

export const CardConfigDataContextProvider: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const [createImpl, { loading: createLoading }] = useMutation<
    CreateCardConfigMutationResponse,
    CreateCardConfigMutationVariables
  >(CreateCardConfigMutation);

  const [deleteImpl, { loading: deleteLoading }] = useMutation<
    DeleteCardConfigMutationResponse,
    DeleteCardConfigMutationVariables
  >(DeleteCardConfigMutation);

  const loading = createLoading || deleteLoading;

  const create = useCallback(
    (name: string, type: TenantConfigType): Promise<CardConfigItem> => {
      const cardConfigItem = createDefaultCardConfigItem(name, type);
      return createImpl({
        variables: {
          json: JSON.stringify(cardConfigItem),
          type,
        },
        refetchQueries: [{ query: CardConfigQuery, variables: { type } }],
      }).then((response) => {
        const { id, dataJson } = response.data?.createTenantConfig ?? {};
        const item = cardConfigItemFromJson(id ?? null, dataJson ?? null);
        if (item === null) {
          return Promise.reject('Failed to create card');
        }

        return item;
      });
    },
    [createImpl],
  );

  const deleteItem = useCallback(
    (id: string, type: TenantConfigType): Promise<void> => {
      return deleteImpl({
        variables: {
          id,
          type,
        },
        refetchQueries: [{ query: CardConfigQuery, variables: { type } }],
      }).then((response) => {
        const id = response.data?.deleteTenantConfig?.id;
        if (id === undefined || id === null) {
          return Promise.reject('Failed to delete card');
        }
      });
    },
    [deleteImpl],
  );

  const updateItem = useCallback(
    (item: CardConfigItem): Promise<CardConfigItem> => {
      if (item.id === null) {
        return Promise.reject('id cannot be null');
      }

      const dataJson = JSON.stringify(item);

      const type = getTypeFromVersion(item.version);

      return createImpl({
        variables: {
          id: item.id,
          json: dataJson,
          type,
        },
      }).then((response) => {
        const { id, dataJson } = response.data?.createTenantConfig ?? {};
        const dataItem = cardConfigItemFromJson(id ?? null, dataJson ?? null);
        if (dataItem === null) {
          return Promise.reject('Failed to create card');
        }
        return item;
      });
    },
    [createImpl],
  );

  return (
    <CardConfigDataContext.Provider
      value={{ loading, create, deleteItem, updateItem }}
    >
      {children}
    </CardConfigDataContext.Provider>
  );
};

export const useCardConfigDataContext = (): CardConfigDataContextState => {
  return useContext(CardConfigDataContext);
};
