import { gql } from '@apollo/client';

export const ResetPasswordMutation = gql`
  mutation resetPassword(
    $email: String!
    $password: String!
    $tfaCode: String!
  ) {
    resetPassword(email: $email, password: $password, tfaCode: $tfaCode)
  }
`;
