import * as React from 'react';
import {
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { dusk, eggshell, midnight } from 'src/constants/colors';
import { ReactComponent as IconChevronDown } from '../../assets/icn-chevron-down.svg';
import { Unit, unitOptions } from 'src/hooks/useFrequencyByUnit';

type Props = {
  unit: Unit;
  frequency: string;
  setUnit: (unit: Unit) => void;
  setFrequency: (value: string) => void;
  disabled?: boolean;
};

const CronInput = ({
  frequency,
  unit,
  setFrequency,
  setUnit,
  disabled,
}: Props) => {
  return (
    <div>
      <Typography
        sx={{
          my: 1.5,
          fontSize: '16px',
          fontWeight: 700,
          fontFamily: 'Rota',
          color: midnight,
        }}
      >
        How often would you like your parser to run?
      </Typography>

      <Stack flexDirection={'row'} gap={1.25} justifyContent={'space-between'}>
        <Stack width={'50%'}>
          <InputLabel
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              fontFamily: 'Rota',
              color: dusk,
              textAlign: 'left',
              mb: 0.125,
            }}
          >
            Select frequency
          </InputLabel>

          <TextField
            autoComplete="off"
            disabled={disabled}
            value={frequency}
            onChange={(e) => {
              const value = e.target.value;
              if (
                (/^[1-9]\d*$/.test(value) || value === '') &&
                value.length < 4
              )
                setFrequency(value);
            }}
          />
        </Stack>

        {/* Unit */}
        <Stack width={'50%'}>
          <InputLabel
            sx={{
              mb: '1px',
              fontFamily: 'Rota',
              fontWeight: 700,
              fontSize: '14px',
            }}
          >
            Unit
          </InputLabel>

          <Select
            value={unit}
            disabled={disabled}
            onChange={(e) => {
              setUnit(e.target.value as Unit);
            }}
            IconComponent={(props) => {
              return (
                <IconChevronDown
                  {...props}
                  style={{
                    marginRight: '12px',
                    top: 'calc(50% - .7em)',
                    fill: dusk,
                  }}
                />
              );
            }}
            sx={{
              backgroundColor: eggshell,
              ':before': { border: 'none' },
              '&.MuiSelect-iconStandard': { color: 'red' },
              '&.Mui-disabled': disabled
                ? {
                    backgroundColor: eggshell,
                    cursor: 'not-allowed',
                    border: 'none',
                  }
                : {},
            }}
          >
            {unitOptions.map((u, i) => (
              <MenuItem key={i} value={u}>
                <Typography
                  sx={{
                    fontFamily: 'Rota',
                    fontSize: '16px',
                    fontWeight: 600,
                    color: midnight,
                  }}
                >
                  {u}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
    </div>
  );
};

export default CronInput;
