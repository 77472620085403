import { Stack, Typography } from '@mui/material';
import React from 'react';
import { dusk, heather, midnight, white } from '../../constants/colors';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';

type Props = {
  subTitle?: string;
  title: string;
};

export const PageHeaderSection: React.FC<Props> = ({
  subTitle,
  title,
}: Props) => {
  const { isAP2Enabled } = useFeatureFlag();
  return (
    <Stack>
      <Typography
        sx={{
          fontFamily: 'Rota',
          color: isAP2Enabled ? midnight : white,
          fontSize: '30px',
          fontWeight: 900,
          lineHeight: '37px',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Rota',
          color: isAP2Enabled ? dusk : heather,
          fontSize: '18px',
          fontWeight: 700,
          marginTop: '8px',
        }}
      >
        {subTitle}
      </Typography>
    </Stack>
  );
};
