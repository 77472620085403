import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  Stack,
  IconButton,
} from '@mui/material';
import { dusk, midnight, white } from 'src/constants/colors';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as TelegramIcon } from '../../assets/icn-telegram-white.svg';

type Props = {
  handleClose: () => void;
  link?: string;
  open: boolean;
};

const AddTelegramToGroupDialog = ({ open, link, handleClose }: Props) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 2 }}
      >
        <IconButton
          sx={{
            backgroundColor: white,
            borderRadius: '45px',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            visibility: 'hidden',
            '&:hover': {
              backgroundColor: white,
            },
          }}
        >
          <CloseIcon sx={{ color: dusk }} />
        </IconButton>
        <DialogTitle>Add to Telegram Groups</DialogTitle>
        <IconButton
          sx={{
            backgroundColor: white,
            borderRadius: '45px',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            mr: 2,
            '&:hover': {
              backgroundColor: white,
            },
          }}
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon sx={{ color: dusk }} />
        </IconButton>
      </Stack>

      <DialogContent>
        <Stack justifyContent={'center'} alignItems={'center'}>
          <Typography
            color={dusk}
            sx={{ mb: 2 }}
            fontSize={'16px'}
            fontWeight={600}
          >
            Allow the topics in this config to publish alerts to Telegram
            groups.
          </Typography>

          <Typography color={midnight}>
            Add alerts to Telegram groups directly
          </Typography>

          <Box
            sx={{ display: 'flex', justifyContent: 'center', mt: 1.5, mb: 2 }}
          >
            {link && (
              <Button
                variant="contained"
                color="primary"
                href={link}
                target="_blank"
              >
                <Stack
                  direction={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  columnGap={1}
                >
                  <TelegramIcon />
                  <Typography
                    color={white}
                    sx={{ mt: 0.2, textTransform: 'capitalize' }}
                  >
                    Open Telegram
                  </Typography>
                </Stack>
              </Button>
            )}
          </Box>
          <Stack
            alignItems={'center'}
            justifyContent={'center'}
            direction={'row'}
            width={'100%'}
            sx={{ mb: 2 }}
          >
            <Box
              sx={{
                height: '1px',
                backgroundColor: dusk,
                width: '35%',
                opacity: 0.3,
              }}
            />
            <Typography
              fontSize={'10px'}
              fontWeight={900}
              color={dusk}
              sx={{ ml: 1, mr: 1 }}
            >
              or
            </Typography>
            <Box
              sx={{
                height: '1px',
                backgroundColor: dusk,
                width: '35%',
                opacity: 0.3,
              }}
            />
          </Stack>

          <Typography color={midnight}>
            Share this URL so others can add alerts to Telegram groups
          </Typography>

          <Box
            sx={{ display: 'flex', justifyContent: 'center', mt: 1.5, mb: 2 }}
          >
            {link && (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textTransform: 'capitalize',
                }}
                onClick={() => navigator.clipboard.writeText(link)}
              >
                Copy Bot URL
              </Button>
            )}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AddTelegramToGroupDialog;
