import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  Decimal: number;
  JSON: any;
  Long: any;
};

export enum AccountBalanceChangeDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING'
}

export type AccountBalanceChangeRow = {
  __typename?: 'AccountBalanceChangeRow';
  newValue: Scalars['Decimal'];
  previousValue: Scalars['Decimal'];
  tokenSymbol: Scalars['String'];
};

export type AccountBalanceChangedEventDetails = {
  __typename?: 'AccountBalanceChangedEventDetails';
  direction: AccountBalanceChangeDirection;
  explorerName: Scalars['String'];
  explorerUrl: Scalars['String'];
  isWhaleWatch: Scalars['Boolean'];
  newValue: Scalars['Decimal'];
  previousValue: Scalars['Decimal'];
  tokenChanges: Array<AccountBalanceChangeRow>;
  tokenSymbol: Scalars['String'];
  walletBlockchain: WalletBlockchain;
};

export type ActivateFusionSourceInput = {
  /** The blockchain cursor value for the fusion source id. This is usually the starting block that the parser will run on. If none is provided, the latest block/sequence will be used. If IsActivatingForTest is true, then any block height can be provided, no matter how far back. */
  cursorValue?: InputMaybe<Scalars['String']>;
  /** Filter in JSON */
  filter?: InputMaybe<Scalars['String']>;
  /** Activate the fusion source with this id. */
  fusionSourceId: Scalars['String'];
  /** Activate this for testing a specific block/sequence. Source will be deactivated after a successful execution of the value. */
  isActivatingForTest?: InputMaybe<Scalars['Boolean']>;
};

export type ActivateFusionSourcePayload = {
  __typename?: 'ActivateFusionSourcePayload';
  activateFusionSourceResponse?: Maybe<ActivateFusionSourceResponse>;
};

export type ActivateFusionSourceResponse = {
  __typename?: 'ActivateFusionSourceResponse';
  /** Operation ID that will be set on success of queuing the operation. You can use this ID in the future to either poll for status or listen to updates from subscriptions */
  asyncOperationId?: Maybe<Scalars['String']>;
  /**
   * The updated fusion source
   * @deprecated Do not use this anymore, as the operation is async and eventually consistent. Check for queued success by looking at AsyncOperationId, and then periodically poll or listen for async updates via subscriptions
   */
  fusionSource: FusionSourceWithCursor;
};

export type ActivateServiceIdentifierInput = {
  sid: Scalars['String'];
};

export type ActivateServiceIdentifierPayload = {
  __typename?: 'ActivateServiceIdentifierPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** This is an active alert in the system that has been configured by the user */
export type ActiveAlert = {
  __typename?: 'ActiveAlert';
  createdTime?: Maybe<Timestamp>;
  filterOptionsJson?: Maybe<Scalars['String']>;
  fusionEventId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  subscriptionValue?: Maybe<Scalars['String']>;
  user: ActiveAlertOwner;
};

/** An event emitted when an alert is created in the tenant */
export type ActiveAlertCreatedEvent = {
  __typename?: 'ActiveAlertCreatedEvent';
  activeAlert?: Maybe<ActiveAlert>;
};

/** An event emitted when an alert is deleted in the tenant */
export type ActiveAlertDeletedEvent = {
  __typename?: 'ActiveAlertDeletedEvent';
  alertId: Scalars['String'];
  timestamp: Scalars['DateTime'];
  userId: Scalars['String'];
};

/** User/Owner of the active alert */
export type ActiveAlertOwner = {
  __typename?: 'ActiveAlertOwner';
  connectedWallets?: Maybe<Array<Maybe<AlertOwnerConnectedWallet>>>;
  id: Scalars['String'];
};

/** A connection to a list of items. */
export type ActiveAlertsConnection = {
  __typename?: 'ActiveAlertsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ActiveAlertsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ActiveAlert>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ActiveAlertsEdge = {
  __typename?: 'ActiveAlertsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ActiveAlert;
};

/** Object used to query active alerts for a tenant */
export type ActiveAlertsInput = {
  /** FusionEvent/Topic id to filter response to */
  fusionEventId: Scalars['String'];
};

/** Object used to add a new bot */
export type AddBotInput = {
  /** Bot credentials to be used when sending messages */
  botCredentials: Scalars['String'];
  /** Type of bot to be added */
  botType: BotType;
  /** Unique name of the bot. This may also be used when users are shown the bot name such as in Telegram or Discord */
  name: Scalars['String'];
};

export type AddCustomIconUrlInput = {
  iconUrl: Scalars['String'];
};

/** Object used to add an email sender domain for a tenant */
export type AddEmailSenderDomainInput = {
  /** Name of the domain that is being added */
  domainName: Scalars['String'];
};

export type AddFusionEventToFusionSourceInput = {
  /** Fusion event id to add to the fusion source */
  fusionEventId: Scalars['String'];
  /** Fusion source id that the fusion event will be added to */
  fusionSourceId: Scalars['String'];
};

export type AddFusionEventToFusionSourceResponse = {
  __typename?: 'AddFusionEventToFusionSourceResponse';
  /** The updated fusion source after the fusion event has been added. */
  fusionSource: FusionSourceWithCursor;
};

export type AddOidcProviderError = ArgumentError;

/** Object used to add an OIDC provider for a tenant */
export type AddOidcProviderInput = {
  /** Client ID for the OIDC provider */
  clientId: Scalars['String'];
  /** Custom OIDC provider configuration. Ignored for all other Issuers */
  customConfig?: InputMaybe<CustomOidcProviderConfigInput>;
  /** Issuer of the token used for OIDC */
  issuer: OidcProvider;
  requireVerifiedEmailAddress: Scalars['Boolean'];
};

export type AddOidcProviderPayload = {
  __typename?: 'AddOidcProviderPayload';
  errors?: Maybe<Array<AddOidcProviderError>>;
  oidcProviderConfiguration?: Maybe<OidcProviderConfiguration>;
};

/** Object used to create an FCM target. */
export type AddOrUpdateMobileFcmTargetInput = {
  /** The Project number of the corresponding Firebase project. */
  appId: Scalars['String'];
  /** An identifier unique to this device. */
  deviceId: Scalars['String'];
  /** Optional friendly name to identity the device. */
  name?: InputMaybe<Scalars['String']>;
  /** Optional array of TargetGroups to add this  FcmTarget to */
  targetGroupIds?: InputMaybe<Array<Scalars['String']>>;
  /** The client platform. */
  targetPlatform: FcmTargetPlatform;
  /** The actual token from Firebase. */
  token: Scalars['String'];
};

/** Add a Source to a SourceGroup. Id and AccountAddress/WalletBlockchain are mutually exclusive. If Id is set, other parameters will be ignored. Setting WalletAddress requires SourceType to be set */
export type AddSourceToSourceGroupInput = {
  /** Fusion event type Id for fusion sources */
  fusionEventTypeId?: InputMaybe<Scalars['String']>;
  /** Id of the SourceGroup object returned from CreateSourceGroup mutation or SourceGroup query */
  sourceGroupId: Scalars['String'];
  /** Id of the Source object returned from CreateSource or Sources */
  sourceId?: InputMaybe<Scalars['String']>;
  /** SourceType associated to WalletAddress */
  sourceType?: InputMaybe<SourceType>;
  /** For chains where public keys are rotatable and not tied to the lifetime of the account, use the accountId. Use public key for all other chains */
  walletAddress?: InputMaybe<Scalars['String']>;
};

/** Notifi Alert */
export type Alert = {
  __typename?: 'Alert';
  createdDate: Scalars['DateTime'];
  filter: Filter;
  filterOptions: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  sourceGroup: SourceGroup;
  /** SubscriptionValue for the Alert. If the alert was configured with multiple Sources in the SourceGroup, this will be null */
  subscriptionValue?: Maybe<Scalars['String']>;
  targetGroup: TargetGroup;
  updatedDate: Scalars['DateTime'];
};

/** An event emitted when an alert is created in the tenant */
export type AlertCreatedEvent = {
  __typename?: 'AlertCreatedEvent';
  alert?: Maybe<TenantUserAlert>;
  user?: Maybe<TenantUser>;
};

/** An event emitted when an alert is deleted in the tenant */
export type AlertDeletedEvent = {
  __typename?: 'AlertDeletedEvent';
  alertId?: Maybe<Scalars['String']>;
  user?: Maybe<TenantUser>;
};

/** Notifi Tenant Connected Wallet */
export type AlertOwnerConnectedWallet = {
  __typename?: 'AlertOwnerConnectedWallet';
  address?: Maybe<Scalars['String']>;
  walletBlockchain: BlockchainType;
};

export enum ApplyPolicy {
  AFTER_RESOLVER = 'AFTER_RESOLVER',
  BEFORE_RESOLVER = 'BEFORE_RESOLVER',
  VALIDATION = 'VALIDATION'
}

export enum ApprovalStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING'
}

export type ArchiveOpenRegistrationResponse = {
  __typename?: 'ArchiveOpenRegistrationResponse';
  archivedCampaign: Scalars['Boolean'];
  calculateSize: Scalars['Int'];
  clone?: Maybe<ArchiveOpenRegistrationResponse>;
};

export type ArchiveOpenUserRegistrationError = ArgumentError;

/** Object used to Archive an existing open user registration campaign allowing tenants to halt users from signing up for notifications to an existing campaign */
export type ArchiveOpenUserRegistrationInput = {
  /** ID of the campaign/link. This is used to help identify the respective campaign */
  openUserRegistrationCampaignId: Scalars['String'];
};

export type ArchiveOpenUserRegistrationPayload = {
  __typename?: 'ArchiveOpenUserRegistrationPayload';
  archiveOpenRegistrationResponse?: Maybe<ArchiveOpenRegistrationResponse>;
  errors?: Maybe<Array<ArchiveOpenUserRegistrationError>>;
};

export type ArgumentError = Error & {
  __typename?: 'ArgumentError';
  message: Scalars['String'];
  paramName?: Maybe<Scalars['String']>;
};

export type ArgumentNullError = Error & {
  __typename?: 'ArgumentNullError';
  message: Scalars['String'];
  paramName?: Maybe<Scalars['String']>;
};

export type ArgumentOutOfRangeError = Error & {
  __typename?: 'ArgumentOutOfRangeError';
  message: Scalars['String'];
  paramName?: Maybe<Scalars['String']>;
};

/** Authorization */
export type Authorization = {
  __typename?: 'Authorization';
  expiry: Scalars['DateTime'];
  serialNumber: Scalars['String'];
  tenantInfo?: Maybe<CorsPolicy>;
  token: Scalars['String'];
};

/** A connection to a list of items. */
export type AvailableTenantStatisticalReportsConnection = {
  __typename?: 'AvailableTenantStatisticalReportsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AvailableTenantStatisticalReportsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TenantStatisticalReportOutput>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AvailableTenantStatisticalReportsEdge = {
  __typename?: 'AvailableTenantStatisticalReportsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TenantStatisticalReportOutput;
};

export enum AvatarDataType {
  STOCK_NOTIFI = 'STOCK_NOTIFI',
  URL = 'URL'
}

export type BackupCodeTwoFactorChallengeAnswerInput = {
  backupCode: Scalars['String'];
};

/** Contains the message to broadcast. */
export type BatchBroadcastMessageInput = {
  /** Optional identifier used to dedupe messages for idempotency. If this is specified, messages sent after the first appearance of the value will be dropped. The initial request to queue this broadcast will still succeed. We recommend using UUID generators. */
  idempotencyKey?: InputMaybe<Scalars['String']>;
  /** A dictionary of variables for each target destination. These values are used to render each target destiation. */
  targetDestinationVariables: Array<KeyValuePairOfTargetTypeAndDictionaryOfStringAndStringInput>;
  /** Topic name to publish to. Users that subscribed to this topic name, will receive the alert. */
  topicName: Scalars['String'];
};

export type BatchBroadcastMessageResult = {
  __typename?: 'BatchBroadcastMessageResult';
  /** Id of the message scheduled */
  id: Scalars['String'];
};

export type BeginAddTotp2FaPayload = {
  __typename?: 'BeginAddTOTP2FAPayload';
  secret: Scalars['String'];
};

export type BeginConnectWalletToCurrentUserError = ArgumentError;

export type BeginConnectWalletToCurrentUserInput = {
  /** The blockchain address for the authenticating account */
  authAddress: Scalars['String'];
  /** The authentication flow to use */
  authType: Web3AuthType;
  /** BlockchainType ordinal for the authenticating account */
  blockchainType: WalletBlockchain;
  /** The tenant which this account is registering under */
  dappAddress: Scalars['String'];
  /** The pubkey of authenticating account - this is only required for some flows (e.g. Sui) */
  walletPubkey?: InputMaybe<Scalars['String']>;
};

export type BeginConnectWalletToCurrentUserPayload = {
  __typename?: 'BeginConnectWalletToCurrentUserPayload';
  beginConnectWalletToCurrentUserResponse?: Maybe<BeginConnectWalletToCurrentUserResponse>;
  errors?: Maybe<Array<BeginConnectWalletToCurrentUserError>>;
};

export type BeginConnectWalletToCurrentUserResponse = {
  __typename?: 'BeginConnectWalletToCurrentUserResponse';
  /** The nonce to use during public hash generation */
  nonce: Scalars['String'];
};

/** Object used to setup a QR code for login */
export type BeginLogInByQrCodeResult = {
  __typename?: 'BeginLogInByQRCodeResult';
  /** The nonce to use in the QR code. Code is invalid after 2 minutes */
  nonce: Scalars['String'];
};

export type BeginLogInByTransactionInput = {
  /** The dapp id for this tenant */
  dappAddress: Scalars['String'];
  /** Address of wallet logging in with */
  walletAddress: Scalars['String'];
  /** Blockchain of the wallet */
  walletBlockchain: WalletBlockchain;
};

export type BeginLogInByTransactionResult = {
  __typename?: 'BeginLogInByTransactionResult';
  /** The nonce to use during public hash generation */
  nonce: Scalars['String'];
};

export type BeginLogInWithWeb3Error = ArgumentError;

export type BeginLogInWithWeb3Input = {
  /** The blockchain address for the authenticating account */
  authAddress: Scalars['String'];
  /** The authentication flow to use */
  authType: Web3AuthType;
  /** BlockchainType ordinal for the authenticating account */
  blockchainType: WalletBlockchain;
  /** The tenant which this account is registering under */
  dappAddress: Scalars['String'];
  /** The pubkey of authenticating account - this is only required for some flows (e.g. Sui) */
  walletPubkey?: InputMaybe<Scalars['String']>;
};

export type BeginLogInWithWeb3Payload = {
  __typename?: 'BeginLogInWithWeb3Payload';
  beginLogInWithWeb3Response?: Maybe<BeginLogInWithWeb3Response>;
  errors?: Maybe<Array<BeginLogInWithWeb3Error>>;
};

export type BeginLogInWithWeb3Response = {
  __typename?: 'BeginLogInWithWeb3Response';
  /** The nonce to use during public hash generation */
  nonce: Scalars['String'];
};

/** Billing plan description */
export type BillingPlan = {
  __typename?: 'BillingPlan';
  /** BillingPlan type. This is Free by default. Enterprise plans must be created via sales@notifi.network */
  billingPlanType: BillingPlanType;
  /** Cost per message after included quota if a specific overrage value wasn't provided */
  defaultPerNotificationOverrage: Scalars['Float'];
  /** Included quantity for a month */
  includedDiscordNotifications: Scalars['Long'];
  /** Included quantity for a month */
  includedEmailNotifications: Scalars['Long'];
  /** Included quantity for a month */
  includedSlackNotifications: Scalars['Long'];
  /** Included quantity for a month */
  includedSmsNotifications: Scalars['Long'];
  /** Included quantity for a month */
  includedTelegramNotifications: Scalars['Long'];
  /** Included quantity for a month */
  includedWalletNotifications: Scalars['Long'];
  /** Included quantity for a month */
  includedWebhookNotifications: Scalars['Long'];
  /** Specifies that this tenant's bill will be paid by another tenant */
  payerTenantId?: Maybe<Scalars['String']>;
  /** Cost per message in USD after included quota */
  perDiscordOverrage: Scalars['Float'];
  /** Cost per message in USD after included quota */
  perEmailOverrage: Scalars['Float'];
  /** Cost per message in USD after included quota */
  perSlackOverrage: Scalars['Float'];
  /** Cost per message in USD after included quota */
  perSmsOverrage: Scalars['Float'];
  /** Cost per message in USD after included quota */
  perTelegramOverrage: Scalars['Float'];
  /** Cost per message in USD after included quota */
  perWalletOverrage: Scalars['Float'];
  /** Cost per message in USD after included quota */
  perWebhookOverrage: Scalars['Float'];
};

export enum BillingPlanType {
  ENTERPRISE = 'ENTERPRISE',
  FREE = 'FREE',
  UNSPECIFIED = 'UNSPECIFIED'
}

export enum BlockchainType {
  ACALA = 'ACALA',
  AGORIC = 'AGORIC',
  APTOS = 'APTOS',
  ARBITRUM = 'ARBITRUM',
  ARCH = 'ARCH',
  ARCHWAY = 'ARCHWAY',
  AVALANCHE = 'AVALANCHE',
  AXELAR = 'AXELAR',
  BASE = 'BASE',
  BERACHAIN = 'BERACHAIN',
  BINANCE = 'BINANCE',
  BITCOIN = 'BITCOIN',
  BLAST = 'BLAST',
  CELESTIA = 'CELESTIA',
  CELO = 'CELO',
  COSMOS = 'COSMOS',
  DYDX = 'DYDX',
  DYMENSION = 'DYMENSION',
  ELYS = 'ELYS',
  ETHEREUM = 'ETHEREUM',
  EVMOS = 'EVMOS',
  INJECTIVE = 'INJECTIVE',
  KAVA = 'KAVA',
  LINEA = 'LINEA',
  MANTA = 'MANTA',
  MANTLE = 'MANTLE',
  MONAD = 'MONAD',
  MOVEMENT = 'MOVEMENT',
  NEAR = 'NEAR',
  NEUTRON = 'NEUTRON',
  NIBIRU = 'NIBIRU',
  OFF_CHAIN = 'OFF_CHAIN',
  OPTIMISM = 'OPTIMISM',
  ORAI = 'ORAI',
  OSMOSIS = 'OSMOSIS',
  PERSISTENCE = 'PERSISTENCE',
  POLYGON = 'POLYGON',
  SCROLL = 'SCROLL',
  SOLANA = 'SOLANA',
  SUI = 'SUI',
  THE_ROOT_NETWORK = 'THE_ROOT_NETWORK',
  UNSPECIFIED = 'UNSPECIFIED',
  XION = 'XION',
  ZKSYNC = 'ZKSYNC'
}

/** Bot */
export type Bot = {
  __typename?: 'Bot';
  botType: BotType;
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  status: BotStatus;
  updatedDate: Scalars['DateTime'];
};

export enum BotStatus {
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  UNSPECIFIED = 'UNSPECIFIED'
}

export enum BotType {
  DISCORD = 'DISCORD',
  EMAIL = 'EMAIL',
  FCM = 'FCM',
  SMS = 'SMS',
  TELEGRAM = 'TELEGRAM',
  VAPID = 'VAPID',
  XMTP = 'XMTP'
}

export type BroadcastMessageEventDetails = {
  __typename?: 'BroadcastMessageEventDetails';
  message?: Maybe<Scalars['String']>;
  messageHtml?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Contains the message to broadcast. */
export type BroadcastMessageInput = {
  /** Email payload for rendering custom html without a template. If provided, it will override all templates for email. */
  customHtmlPayload?: InputMaybe<CustomHtmlPayloadInput>;
  /** Optional identifier used to dedupe messages for idempotency. If this is specified, messages sent after the first appearance of the value will be dropped. The initial request to queue this broadcast will still succeed. We recommend using UUID generators. */
  idempotencyKey?: InputMaybe<Scalars['String']>;
  /** Source address to publish from. This address should be connected to this user account that's publishing. */
  sourceAddress?: InputMaybe<Scalars['String']>;
  /** Optionally specify the templates that should be used for each TargetType. */
  targetTemplates?: InputMaybe<Array<KeyValuePairOfTargetTypeAndStringInput>>;
  /** Timestamp in seconds since Unix epoch. January 1, 1970 (midnight UTC/GMT) */
  timestamp: Scalars['Long'];
  /** Variables that recipients can use to filter on, along with templates used to expand/replace on. Default templates require 'subject' and 'body'. Custom templates will require their own variable sets. */
  variables?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  /** Blockchain the SourceAddress is on. */
  walletBlockchain: WalletBlockchain;
};

export type BroadcastMessageResult = {
  __typename?: 'BroadcastMessageResult';
  /** Id of the message scheduled */
  id?: Maybe<Scalars['String']>;
};

/** Represents a community manager publish campaign. */
export type CmDashboardCampaignDetails = {
  __typename?: 'CMDashboardCampaignDetails';
  /** The unique identifier for the campaign. */
  campaignId: Scalars['String'];
  /** The time the campaign was created. */
  createdDate: Scalars['DateTime'];
  /** The number of delivered messages. */
  delivered: Scalars['Int'];
  /** The time the campaign was executed. */
  executionTime: Scalars['DateTime'];
  /** The Topic ID that was selected for the campaign. */
  fusionEventTypeId: Scalars['String'];
  /** The unique identifier of this particular campaign detail entry from the CommunityManagerPublish entity */
  id: Scalars['String'];
  /** The number of times the campaign content has been opened by subscribers. */
  opened: Scalars['Int'];
  /** The number of sent messages. */
  sent: Scalars['Int'];
  /** The current status of the campaign. */
  status: Scalars['String'];
};

/** Detailed information about a campaign. */
export type CampaignDetails = {
  __typename?: 'CampaignDetails';
  /** The unique identifier of the campaign. */
  campaignId: Scalars['String'];
  /** The timestamp of when the campaign was created. */
  created: Scalars['DateTime'];
  /** The execution timestamp of when the campaign was executed. */
  executionTime?: Maybe<Scalars['DateTime']>;
  /** The identifier of the topic associated with the campaign. */
  fusionEventTypeId: Scalars['String'];
  /** The identifier of a campaign in the CommunityManagerPublish table. */
  id: Scalars['String'];
  /** The timestamp of when the campaign was last updated. */
  lastUpdated: Scalars['DateTime'];
  /** Statistics for each target type in the campaign. */
  statisticsByTarget: Array<Maybe<StatisticsByTarget>>;
  /** The current status of the campaign. */
  status: Scalars['String'];
};

/** Connection type for campaigns grouped by status. */
export type CampaignStatusConnection = {
  __typename?: 'CampaignStatusConnection';
  /** Campaigns with Canceled status. */
  canceled: Array<CmDashboardCampaignDetails>;
  /** Campaigns with Completed status. */
  completed: Array<CmDashboardCampaignDetails>;
  /** Campaigns with Executing status. */
  executing: Array<CmDashboardCampaignDetails>;
  pageInfo: PageInfo;
  /** Campaigns with Scheduled status. */
  scheduled: Array<CmDashboardCampaignDetails>;
};

export type CancelCommunityManagerPublishTaskError = ArgumentError;

export type CancelCommunityManagerPublishTaskInput = {
  id: Scalars['String'];
};

export type CancelCommunityManagerPublishTaskPayload = {
  __typename?: 'CancelCommunityManagerPublishTaskPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<CancelCommunityManagerPublishTaskError>>;
};

export type ChangeExecutionTimeCmPublishTaskError = ArgumentError;

export type ChangeExecutionTimeCmPublishTaskInput = {
  id: Scalars['String'];
  newExecutionTime: Scalars['DateTime'];
};

export type ChangeExecutionTimeCmPublishTaskPayload = {
  __typename?: 'ChangeExecutionTimeCMPublishTaskPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<ChangeExecutionTimeCmPublishTaskError>>;
};

export type ChatMessageReceivedEventDetails = {
  __typename?: 'ChatMessageReceivedEventDetails';
  conversationId: Scalars['String'];
  messageBody: Scalars['String'];
  messageId: Scalars['String'];
  senderBlockchain: WalletBlockchain;
  senderId: Scalars['String'];
  senderName: Scalars['String'];
};

/** Configuration values for Notifi. */
export type ClientConfiguration = {
  __typename?: 'ClientConfiguration';
  /** The country codes supported for SMS, as ISO 3166-1 alpha-2 codes (two-letter codes) */
  supportedSmsCountryCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The supported target types */
  supportedTargetTypes?: Maybe<Array<TargetType>>;
};

export enum CommunityManagerMessageStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  EXECUTING = 'EXECUTING',
  NONE = 'NONE',
  SCHEDULED = 'SCHEDULED'
}

export type CommunityManagerPreviewInput = {
  /** Payload for rendering custom html without a template. If provided, it will override all templates. */
  customHtmlPayload?: InputMaybe<CustomHtmlPayloadInput>;
  /** The target format that the template will rendered */
  previewTarget: TemplateType;
  /** User topic name that the user owns */
  sourceAddress: Scalars['String'];
  /** Timestamp in seconds since Unix epoch. January 1, 1970 (midnight UTC/GMT) */
  timestamp: Scalars['Long'];
  /** Variables that recipients can use to filter on, along with templates used to expand/replace on. Default templates require 'subject' and 'body'. Custom templates will require their own variable sets. */
  variables: Array<KeyValuePairOfStringAndStringInput>;
  /** Must be Offchain for user topics */
  walletBlockchain: WalletBlockchain;
};

export type CommunityManagerPreviewResult = {
  __typename?: 'CommunityManagerPreviewResult';
  /** Preview result. Most likely in the form of HTML */
  content: Scalars['String'];
};

export type CommunityManagerPublish = {
  __typename?: 'CommunityManagerPublish';
  campaignId: Scalars['String'];
  createdDate: Scalars['DateTime'];
  executionTime: Scalars['DateTime'];
  fusionEventTypeId: Scalars['String'];
  id: Scalars['String'];
  modifiedDate: Scalars['DateTime'];
  specificWallets: Array<SpecificWalletTarget>;
  status: CommunityManagerMessageStatus;
  variablesJson: Scalars['String'];
};

export type CompleteAddTotp2FaInput = {
  totpCode: Scalars['String'];
};

export type CompleteAddTotp2FaPayload = {
  __typename?: 'CompleteAddTOTP2FAPayload';
  backupCodes: Array<Scalars['String']>;
  elevatedPermissionsAuthorization: Authorization;
  normalPermissionsAuthorization: Authorization;
  refreshAuthorization: Authorization;
};

export type CompleteConnectWalletToCurrentUserError = ArgumentError;

/** Object used to claim a wallet for the signed in account. */
export type CompleteConnectWalletToCurrentUserInput = {
  /** Describes how to deal with conflicts when connecting a wallet */
  connectWalletConflictResolutionTechnique?: InputMaybe<ConnectWalletConflictResolutionTechnique>;
  /** The Nonce associated with this ConnectWallet flow (from BeginConnectWallet) */
  nonce: Scalars['String'];
  /** Serialized signature over the `SignedMessage` */
  signature: Scalars['String'];
  /** Message that is signed by authenticating account - must include nonce */
  signedMessage: Scalars['String'];
  /** Signing address */
  signingAddress: Scalars['String'];
  /** Optional field for public key of the signing account */
  signingPubkey?: InputMaybe<Scalars['String']>;
};

export type CompleteConnectWalletToCurrentUserPayload = {
  __typename?: 'CompleteConnectWalletToCurrentUserPayload';
  connectedWallet?: Maybe<ConnectedWallet>;
  errors?: Maybe<Array<CompleteConnectWalletToCurrentUserError>>;
};

export type CompleteLogInByQrCodeInput = {
  /** DappId of the tenant being accessed */
  dappId: Scalars['String'];
  /** Nonce encoded in the QR code */
  nonce: Scalars['String'];
};

export type CompleteLogInByTransactionInput = {
  /** The dapp id for this tenant */
  dappAddress: Scalars['String'];
  /** Random client generated UUID used in hash generation of nonce+uuid. Recommend using UUIDv4 */
  randomUuid: Scalars['String'];
  /** Timestamp in seconds since Unix epoch. Required for Aptos chain. This will be the timestamp on the transaction. */
  timestamp?: InputMaybe<Scalars['Long']>;
  /** Transaction containing the HEX(SHA256(nonce+uuid)) printed to 'Notifi Auth: 0x<value>' */
  transactionSignature: Scalars['String'];
  /** Address of wallet attempting to log in with */
  walletAddress: Scalars['String'];
  /** Blockchain of the wallet */
  walletBlockchain: WalletBlockchain;
  /** Public key of wallet attempting to log in with. Required for Aptos chain. */
  walletPublicKey?: InputMaybe<Scalars['String']>;
};

export type CompleteLogInWithWeb3Error = ArgumentError;

/** Input for logging in with web3 authentication */
export type CompleteLogInWithWeb3Input = {
  /** The nonce obtained from BeginLoginWithWeb3 */
  nonce: Scalars['String'];
  /** Serialized signature over the `SignedMessage` */
  signature: Scalars['String'];
  /** Message that is signed by authenticating account - must include nonce */
  signedMessage: Scalars['String'];
  /** Signing address */
  signingAddress: Scalars['String'];
  /** Optional field for public key of the signing account */
  signingPubkey?: InputMaybe<Scalars['String']>;
};

export type CompleteLogInWithWeb3Payload = {
  __typename?: 'CompleteLogInWithWeb3Payload';
  errors?: Maybe<Array<CompleteLogInWithWeb3Error>>;
  user?: Maybe<User>;
};

export enum ConditionDetailsOneofCase {
  NONE = 'NONE',
  NOTIFICATIONS = 'NOTIFICATIONS',
  OFF_CHAIN = 'OFF_CHAIN',
  USER_ACTIONS = 'USER_ACTIONS',
  USER_INTERACTIONS = 'USER_INTERACTIONS'
}

export type ConfirmChannelForSlackChannelTargetInput = {
  slackChannelId: Scalars['String'];
  slackChannelTargetId: Scalars['String'];
};

export type ConfirmChannelForSlackChannelTargetResponse = {
  __typename?: 'ConfirmChannelForSlackChannelTargetResponse';
  slackChannelTarget: SlackChannelTarget;
};

/** Describes how to deal with conflicts when connecting a wallet */
export enum ConnectWalletConflictResolutionTechnique {
  /** Continue if wallet is connected to another user account, but can be safely disconnected from the old user and moved. If this is the last wallet on the account, fail and report the error without making any changes */
  DISCONNECT = 'DISCONNECT',
  /** Disconnects from the old user account even if it was the last wallet for that account. If this was the last wallet for the old account, then the account will be disabled and closed */
  DISCONNECT_AND_CLOSE_OLD_ACCOUNT = 'DISCONNECT_AND_CLOSE_OLD_ACCOUNT',
  /** If there's any conflict, fail and report back the error without making any changes */
  FAIL = 'FAIL'
}

/** Object used to claim a wallet for the signed in account. */
export type ConnectWalletInput = {
  /** Account ID if this chain supports it (required for Near and Aptos) */
  accountId?: InputMaybe<Scalars['String']>;
  /** Describes how to deal with conflicts when connecting a wallet */
  connectWalletConflictResolutionTechnique?: InputMaybe<ConnectWalletConflictResolutionTechnique>;
  /** Timestamp in seconds since Unix epoch. January 1, 1970 (midnight UTC/GMT) */
  timestamp: Scalars['Long'];
  /** Blockchain the wallet belongs to. */
  walletBlockchain: WalletBlockchain;
  /** User's wallet address. */
  walletPublicKey?: InputMaybe<Scalars['String']>;
};

/** Notifi Connected Wallet */
export type ConnectedWallet = {
  __typename?: 'ConnectedWallet';
  address?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  walletBlockchain: WalletBlockchain;
};

/** Notifi Conversation object */
export type Conversation = {
  __typename?: 'Conversation';
  backgroundImageUrl?: Maybe<Scalars['String']>;
  conversationGates?: Maybe<Array<Maybe<ConversationGate>>>;
  conversationType: ConversationType;
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  lastConversationMessage?: Maybe<ConversationMessage>;
  name: Scalars['String'];
  participants?: Maybe<Array<Maybe<ConversationParticipant>>>;
  updatedDate: Scalars['DateTime'];
};

export type ConversationGate = {
  __typename?: 'ConversationGate';
  conversationGateTokenType: ConversationGateTokenType;
  conversations?: Maybe<Array<Maybe<Conversation>>>;
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  minRequiredTokens: Scalars['Long'];
  name: Scalars['String'];
  participantCount: Scalars['Long'];
  tokenAddress: Scalars['String'];
  updatedDate: Scalars['DateTime'];
  walletBlockchain: WalletBlockchain;
};

export enum ConversationGateTokenType {
  FUNGIBLE_TOKEN = 'FUNGIBLE_TOKEN',
  NFT_AUTHORITY = 'NFT_AUTHORITY',
  NFT_COLLECTION = 'NFT_COLLECTION',
  NFT_COLLECTION_NAME_LTE3_DIGITS = 'NFT_COLLECTION_NAME_LTE3_DIGITS',
  NFT_COLLECTION_NAME_LTE4_DIGITS = 'NFT_COLLECTION_NAME_LTE4_DIGITS',
  NFT_CREATOR = 'NFT_CREATOR'
}

/** Notifi Conversation message object */
export type ConversationMessage = {
  __typename?: 'ConversationMessage';
  conversationId: Scalars['String'];
  conversationParticipant?: Maybe<ConversationParticipant>;
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  message: Scalars['String'];
  updatedDate: Scalars['DateTime'];
  userId: Scalars['String'];
};

/** A connection to a list of items. */
export type ConversationMessagesConnection = {
  __typename?: 'ConversationMessagesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ConversationMessagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ConversationMessage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ConversationMessagesEdge = {
  __typename?: 'ConversationMessagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ConversationMessage;
};

/** Notifi Conversation object */
export type ConversationParticipant = {
  __typename?: 'ConversationParticipant';
  conversationId: Scalars['String'];
  conversationParticipantType: ConversationParticipantType;
  profile: UserProfile;
  resolvedName?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  walletAddress: Scalars['String'];
  walletBlockchain: WalletBlockchain;
};

/** Object used to add a participant to a conversation */
export type ConversationParticipantInput = {
  /** Blockchain the wallet belongs to. */
  walletBlockchain: WalletBlockchain;
  /** User's wallet address. */
  walletPublicKey: Scalars['String'];
};

export enum ConversationParticipantType {
  ADMIN = 'ADMIN',
  BANNED = 'BANNED',
  MEMBER = 'MEMBER',
  SUPPORT = 'SUPPORT'
}

export enum ConversationType {
  CHANNEL = 'CHANNEL',
  GENERAL_PRIVATE = 'GENERAL_PRIVATE',
  GENERAL_PUBLIC = 'GENERAL_PUBLIC',
  SUPPORT = 'SUPPORT'
}

/** A connection to a list of items. */
export type ConversationsConnection = {
  __typename?: 'ConversationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ConversationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Conversation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ConversationsEdge = {
  __typename?: 'ConversationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Conversation;
};

/** CorsPolicy for tenant */
export type CorsPolicy = {
  __typename?: 'CorsPolicy';
  allowedDomains?: Maybe<Array<Maybe<CorsPolicyEntry>>>;
};

export type CorsPolicyEntry = {
  __typename?: 'CorsPolicyEntry';
  originAddress: Scalars['String'];
};

/** Object used to create an alert that listens to blockchain events. */
export type CreateAlertInput = {
  /** Filter Id of generic or previously created Filter. */
  filterId?: InputMaybe<Scalars['String']>;
  /** JSON object defining the options to use for Filter defined by FilterId */
  filterOptions?: InputMaybe<Scalars['String']>;
  /** Name of group to attach this Alert to. */
  groupName?: InputMaybe<Scalars['String']>;
  /** Friendly name that's displayed for the Alert. */
  name?: InputMaybe<Scalars['String']>;
  /** SourceGroup Id from a previously created SourceGroup that the alert should watch. */
  sourceGroupId?: InputMaybe<Scalars['String']>;
  /** TargetGroup Id from a previously created TargetGroup where messages should be sent. */
  targetGroupId?: InputMaybe<Scalars['String']>;
};

/** Object used to create a new conversation */
export type CreateConversationInput = {
  participants: Array<ConversationParticipantInput>;
};

/** Object used to create an Alert used by a tenant owner to push messages directly to users. Use this from dapp services on behalf of Users */
export type CreateDirectPushAlertInput = {
  emailAddresses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** JSON object defining the options to use for Filter defined by FilterId */
  filterOptions?: InputMaybe<Scalars['String']>;
  /** FusionEvent/Topic ID to use to subscribe */
  fusionEventId?: InputMaybe<Scalars['String']>;
  phoneNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** SubscriptionValue to use if FusionEventId was provided. If not provided, the default value of * will be used */
  subscriptionValue?: InputMaybe<Scalars['String']>;
  /** ID of the User obtained when calling CreateTenantUser */
  userId?: InputMaybe<Scalars['String']>;
};

/** Object used to create the tenant's discord bot configuration. */
export type CreateDiscordBotConfigurationInput = {
  /** The tenant's Dicord client id from Discord's developer portal. Used for OAuth2 */
  discordClientId: Scalars['String'];
  /** The tenant's Dicord client secret from Discord's developer portal. Used for OAuth2 */
  discordClientSecret: Scalars['String'];
  /** The Discord server id in which the Discord bot resides */
  discordGuildId: Scalars['String'];
  /** The invite link to join the tenant's Discord server. */
  discordServerInviteLink: Scalars['String'];
  /** The redirect site for OAuth2. This url is configured in Discord's developer portal */
  redirectUrl: Scalars['String'];
};

/** Object used to create an FCM target. */
export type CreateFcmTargetInput = {
  /** The Project number of the corresponding Firebase project. */
  appId: Scalars['String'];
  /** An identifier unique to this device. */
  deviceId: Scalars['String'];
  /** Optional friendly name to identity the device. */
  name?: InputMaybe<Scalars['String']>;
  /** Optional array of TargetGroups to add this  FcmTarget to */
  targetGroupIds?: InputMaybe<Array<Scalars['String']>>;
  /** The client platform. */
  targetPlatform: FcmTargetPlatform;
  /** The actual token from Firebase. */
  token: Scalars['String'];
};

/** Object used to create an alert triggered by a topic defined by a FusionEvent */
export type CreateFusionAlertInput = {
  /** JSON object defining the options to use for filtering. Options must be supported by the FusionEventId's supported Filters found on the FusionEvent metadata */
  filterOptions?: InputMaybe<Scalars['String']>;
  /** FusionEvent identifier that describes the topic that this alert should be triggered on */
  fusionEventId: Scalars['String'];
  /** Friendly name that's displayed for the Alert. */
  name?: InputMaybe<Scalars['String']>;
  /** The value to subscribe to. This is the value that will be used to filter the FusionEvent's messages. Typically, this is the user's wallet/vault/account/etc address of interest. This helps narrow down specific instances of the FusionEvent described by FusionEventId to only those that are important to this user */
  subscriptionValue: Scalars['String'];
  /** Target Group ID containing the targets that should have alert notifications delivered to */
  targetGroupId: Scalars['String'];
};

export type CreateFusionAlertsError = ArgumentError | ArgumentNullError | ArgumentOutOfRangeError;

export type CreateFusionAlertsInput = {
  alerts: Array<CreateFusionAlertInput>;
};

export type CreateFusionAlertsPayload = {
  __typename?: 'CreateFusionAlertsPayload';
  alerts?: Maybe<Array<Alert>>;
  errors?: Maybe<Array<CreateFusionAlertsError>>;
};

export type CreateFusionEventInput = {
  id?: InputMaybe<Scalars['String']>;
  isCommunityManagerEvent?: InputMaybe<Scalars['Boolean']>;
  metadataJson?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateFusionModuleInput = {
  /** Expected output schema in json */
  expectedMetadataOutputSchema?: InputMaybe<Scalars['String']>;
  fusionEventTypeId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  moduleType: FusionModuleType;
  storageType: StorageType;
  validateMetadataOutput: Scalars['Boolean'];
};

export type CreateFusionModuleResponse = {
  __typename?: 'CreateFusionModuleResponse';
  fusionModule: FusionModuleData;
  fusionModulePutUrl: Scalars['String'];
};

export type CreateFusionSourceError = ArgumentError;

export type CreateFusionSourceInput = {
  /** The type of blockchain this Fusion Source will be using. */
  blockchainType: WalletBlockchain;
  /** Filter in JSON */
  filter: Scalars['String'];
  /** List of FusionEvent IDs that will be emitted from here */
  fusionEventTypeIds: Array<Scalars['String']>;
  /** The name of the Fusion Source. */
  name: Scalars['String'];
};

export type CreateFusionSourcePayload = {
  __typename?: 'CreateFusionSourcePayload';
  createFusionSourceResponse?: Maybe<CreateFusionSourceResponse>;
  errors?: Maybe<Array<CreateFusionSourceError>>;
};

export type CreateFusionSourceResponse = {
  __typename?: 'CreateFusionSourceResponse';
  fusionSource: FusionSourceWithCursor;
  /** @deprecated Use CreateFusionSourceUploadUrl instead */
  fusionSourcePutUrl: Scalars['String'];
};

export type CreateFusionSourceUploadUrlError = ArgumentError;

export type CreateFusionSourceUploadUrlInput = {
  /** Total byte count of the file being uploaded. This must match what's actually uploaded later */
  byteCount: Scalars['Long'];
  /** The id of the Fusion Source to update */
  fusionSourceId: Scalars['String'];
};

export type CreateFusionSourceUploadUrlPayload = {
  __typename?: 'CreateFusionSourceUploadUrlPayload';
  createFusionSourceUploadUrlResponse?: Maybe<CreateFusionSourceUploadUrlResponse>;
  errors?: Maybe<Array<CreateFusionSourceUploadUrlError>>;
};

export type CreateFusionSourceUploadUrlResponse = {
  __typename?: 'CreateFusionSourceUploadUrlResponse';
  fusionSourcePutUrl: Scalars['String'];
  version: Scalars['Long'];
};

export type CreateOpenUserRegistrationError = ArgumentError;

/** Object used to create a new open user registration campaign allowing users to sign up for any notifications */
export type CreateOpenUserRegistrationInput = {
  beginTime: Scalars['DateTime'];
  endTime?: InputMaybe<Scalars['DateTime']>;
  fusionEventIds: Array<Scalars['String']>;
  /** Name of the registration campaign/link. This is used to help identify in Admin Portal */
  name: Scalars['String'];
};

export type CreateOpenUserRegistrationPayload = {
  __typename?: 'CreateOpenUserRegistrationPayload';
  errors?: Maybe<Array<CreateOpenUserRegistrationError>>;
  openUserRegistration?: Maybe<OpenUserRegistration>;
};

export type CreateOrUpdateUserError = InvalidEmailError | LimitedResourceTypeError | RequiresElevatedTokenError;

/** Object used to create a new user for the admin panel. */
export type CreateOrUpdateUserInput = {
  /** Primary email address for account. This will be used for verification and password resets. */
  email: Scalars['String'];
  /** List of roles to assign to the user */
  roles: Array<Scalars['String']>;
};

export type CreateServiceIdentifierError = RequiresElevatedTokenError;

export type CreateServiceIdentifierPayload = {
  __typename?: 'CreateServiceIdentifierPayload';
  errors?: Maybe<Array<CreateServiceIdentifierError>>;
  secret?: Maybe<Scalars['String']>;
  sid?: Maybe<Scalars['String']>;
};

export type CreateSlackChannelTargetError = DuplicateSlackChannelTargetNamesError | TargetLimitExceededError | UnexpectedError;

export type CreateSlackChannelTargetResponse = {
  __typename?: 'CreateSlackChannelTargetResponse';
  errors?: Maybe<Array<CreateSlackChannelTargetError>>;
  slackChannelTarget?: Maybe<SlackChannelTarget>;
};

/** Object used to create a source that can be used on alerts to listen for blockchain events. */
export type CreateSourceInput = {
  /** Account address on blockchain. */
  blockchainAddress?: InputMaybe<Scalars['String']>;
  /** Fusion event type Id for fusion sources */
  fusionEventTypeId?: InputMaybe<Scalars['String']>;
  /** Friendly name for this Source. */
  name?: InputMaybe<Scalars['String']>;
  /** Type of the BlockAddress being referenced. Can be SolanaWallet, EthereumWallet, TerraWallet, etc. */
  type: SourceType;
};

/** Object used to create individual targets. */
export type CreateTargetInput = {
  /** Optional friendly name to identity the email address. */
  name?: InputMaybe<Scalars['String']>;
  /** Target value to be used. For SMS it would be the phone number. For email it would be the email address. For Telegram, the Telegram id. Fore Discord, the value will be empty. */
  value?: InputMaybe<Scalars['String']>;
};

/** Object used to create a tenant config. */
export type CreateTenantConfigInput = {
  /** JSON object defining the payload to store */
  data: Scalars['String'];
  /** Id of the Tenant Config. */
  id?: InputMaybe<Scalars['String']>;
  /** The type of config to create */
  type: TenantConfigType;
};

/** Object used to create a new tenant */
export type CreateTenantInput = {
  /** Captcha answer */
  captchaResponse: Scalars['String'];
  /** DappId that uniquely identifies this tenant. Must be >= 5 and <= 20 characters, only contain lowercase alpha-numeric, and up to a single '.' not preceeding or trailing */
  dappId?: InputMaybe<Scalars['String']>;
  /** Primary email address for tenant. This will be used for verification, updates and contact */
  email: Scalars['String'];
  /** Password used for account. Must be at least 10 characters with at least 1 capital, 1 lower, 1 numeric and one symbol */
  password: Scalars['String'];
  /** Tenant name */
  tenantName: Scalars['String'];
};

/** Object used to create a new User from a tenant's off-chain service. */
export type CreateTenantUserInput = {
  /** Blockchain the wallet belongs to. */
  walletBlockchain: WalletBlockchain;
  /** User's wallet address. */
  walletPublicKey?: InputMaybe<Scalars['String']>;
};

/** Object used to create a webhook target. */
export type CreateWeb3TargetInput = {
  /** Account ID or address. Use public key unless on a chain with accounts that can rotate keys, at which point use accountId */
  accountId?: InputMaybe<Scalars['String']>;
  /** Optional friendly name to identity the webhook. If specifying one, it must be unique for this type */
  name?: InputMaybe<Scalars['String']>;
  /** Web3 protocol to use. The AccountId must already be initialized to receive messages on that protocol */
  protocol: Web3TargetProtocol;
  /** Blockchain of the accountId */
  walletBlockchain: WalletBlockchain;
};

export type CreateWebPushTargetError = TargetLimitExceededError | UnexpectedError;

export type CreateWebPushTargetInput = {
  auth: Scalars['String'];
  endpoint: Scalars['String'];
  p256dh: Scalars['String'];
  vapidPublicKey: Scalars['String'];
};

export type CreateWebPushTargetResponse = {
  __typename?: 'CreateWebPushTargetResponse';
  errors?: Maybe<Array<CreateWebPushTargetError>>;
  webPushTarget?: Maybe<WebPushTarget>;
};

/** Object used to create a webhook target. */
export type CreateWebhookTargetInput = {
  /** The format of the payload sent to the Url */
  format: WebhookPayloadFormat;
  /** HTTP headers to add to the webhook requests */
  headers?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  /** Optional friendly name to identity the webhook. */
  name?: InputMaybe<Scalars['String']>;
  /** The Url to POST updates to. */
  url: Scalars['String'];
};

export type CursorEntry = {
  __typename?: 'CursorEntry';
  cursorValue: Scalars['String'];
  lastExecutedTimestamp: Scalars['DateTime'];
  sourceState: FusionSourceState;
};

export type CustomHtmlPayloadInput = {
  htmlMessage: Scalars['String'];
  unstyledMessage?: InputMaybe<Scalars['String']>;
};

export type CustomOidcProviderConfigInput = {
  /** Claim to use for the user id */
  claimToUseForUserId?: InputMaybe<Scalars['String']>;
  /** Issuer (iss claim) to use if no OidcConfigurationUrl is provided */
  issuer?: InputMaybe<Scalars['String']>;
  /** JWKS URL to use if no OidcConfigurationUrl is provided */
  jwksUrl?: InputMaybe<Scalars['String']>;
  /** URL of the OIDC configuration endpoint for Custom Issuer. Ignored for non custom */
  oidcConfigurationUrl?: InputMaybe<Scalars['String']>;
  /**
   * Prefix added to unique id of user (typically pulled from sub claim) on the Custom Issuer. Ignored for non custom. Each provider has a default, where Custom providers are the only ones that can be modified here.
   *
   * Maximum of 5 characters
   * Default for Custom is 'oidc:'
   */
  userIdPrefix?: InputMaybe<Scalars['String']>;
};

/** CustomPluginStorage */
export type CustomPluginStorageEntity = {
  __typename?: 'CustomPluginStorageEntity';
  createdDate: Scalars['DateTime'];
  key?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
  value?: Maybe<Scalars['String']>;
  version: Scalars['Long'];
};

export type DaoProposalChangedEventDetails = {
  __typename?: 'DAOProposalChangedEventDetails';
  daoUrl?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  proposalUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tenantName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type DAppSubscription = {
  __typename?: 'DAppSubscription';
  dAppName: Scalars['String'];
  id: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  targetGroup: TargetGroup;
};

/** Object used by Dapps to allow their members to log in to Notifi. */
export type DappLogInInput = {
  /** Account ID if this chain supports it (required for Near and Aptos) */
  accountId?: InputMaybe<Scalars['String']>;
  /** Dapp address/id */
  dappAddress?: InputMaybe<Scalars['String']>;
  /** Timestamp in seconds since Unix epoch. January 1, 1970 (midnight UTC/GMT) */
  timestamp: Scalars['Long'];
  /** WalletPublicKey chain */
  walletBlockchain?: InputMaybe<WalletBlockchain>;
  /** Dapp member's wallet public key */
  walletPublicKey?: InputMaybe<Scalars['String']>;
};

export type DeactivateFusionSourceInput = {
  /** Deactivate the fusion source with this id. */
  fusionSourceId: Scalars['String'];
};

export type DeactivateFusionSourcePayload = {
  __typename?: 'DeactivateFusionSourcePayload';
  deactivateFusionSourceResponse?: Maybe<DeactivateFusionSourceResponse>;
};

export type DeactivateFusionSourceResponse = {
  __typename?: 'DeactivateFusionSourceResponse';
  /** Operation ID that will be set on success of queuing the operation. You can use this ID in the future to either poll for status or listen to updates from subscriptions */
  asyncOperationId?: Maybe<Scalars['String']>;
  /**
   * The updated fusion source
   * @deprecated Do not use this anymore, as the operation is async and eventually consistent. Check for queued success by looking at AsyncOperationId, and then periodically poll or listen for async updates via subscriptions
   */
  fusionSource: FusionSourceWithCursor;
};

export type DeactivateServiceIdentifierInput = {
  sid: Scalars['String'];
};

export type DeactivateServiceIdentifierPayload = {
  __typename?: 'DeactivateServiceIdentifierPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteAlertsError = ArgumentError | ArgumentNullError | ArgumentOutOfRangeError;

/** Object used to delete one or more alerts */
export type DeleteAlertsInput = {
  /** Alert IDs to be deleted */
  alertIds: Array<Scalars['String']>;
};

export type DeleteAlertsPayload = {
  __typename?: 'DeleteAlertsPayload';
  deleteAlertsResponse?: Maybe<DeleteAlertsResponse>;
  errors?: Maybe<Array<DeleteAlertsError>>;
};

export type DeleteAlertsResponse = {
  __typename?: 'DeleteAlertsResponse';
  /** Alert IDs that were deleted */
  alertsIds: Array<Scalars['String']>;
};

/** Object used to delete an Alert used by a tenant owner to push messages directly to users. Use this from dapp services on behalf of Users */
export type DeleteDirectPushAlertInput = {
  /** ID of the Alert obtained when calling CreateDirectPushAlert */
  alertId?: InputMaybe<Scalars['String']>;
};

export type DeleteFusionSourceInput = {
  fusionSourceId: Scalars['String'];
};

export type DeleteFusionSourceResponse = {
  __typename?: 'DeleteFusionSourceResponse';
  success: Scalars['Boolean'];
};

export type DeleteSlackChannelTargetError = TargetAssignedToExistingTargetGroupError | UnexpectedError;

export type DeleteSlackChannelTargetResponse = {
  __typename?: 'DeleteSlackChannelTargetResponse';
  errors?: Maybe<Array<DeleteSlackChannelTargetError>>;
  slackChannelTarget?: Maybe<SlackChannelTarget>;
};

/** Object used to delete individual targets. */
export type DeleteTargetInput = {
  /** Id of the target requesting to be deleted. */
  id?: InputMaybe<Scalars['String']>;
};

/** Object used to delete a tenant config. */
export type DeleteTenantConfigInput = {
  /** Id of the Tenant Config. */
  id: Scalars['String'];
  /** The type of config to delete */
  type: TenantConfigType;
};

export type DeleteWebPushTargetError = TargetDoesNotExistError | UnexpectedError;

export type DeleteWebPushTargetInput = {
  id: Scalars['String'];
};

export type DeleteWebPushTargetResponse = {
  __typename?: 'DeleteWebPushTargetResponse';
  errors?: Maybe<Array<DeleteWebPushTargetError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DirectTenantMessageEventDetails = {
  __typename?: 'DirectTenantMessageEventDetails';
  targetTemplatesJson?: Maybe<Scalars['String']>;
  templateVariablesJson?: Maybe<Scalars['String']>;
  tenantName: Scalars['String'];
};

export type DisableTotp2FaRequiredForEmailLoginsError = RequiresElevatedTokenError;

export type DisableTotp2FaRequiredForEmailLoginsPayload = {
  __typename?: 'DisableTOTP2FARequiredForEmailLoginsPayload';
  errors?: Maybe<Array<DisableTotp2FaRequiredForEmailLoginsError>>;
  totp2faRequired?: Maybe<Scalars['Boolean']>;
};

/** Object used to disconnect a wallet for the signed in account. */
export type DisconnectWalletInput = {
  /** Blockchain the wallet belongs to. */
  walletBlockchain: WalletBlockchain;
  /** User's wallet address. */
  walletPublicKey?: InputMaybe<Scalars['String']>;
};

/** DiscordBotConfiguration */
export type DiscordBotConfiguration = {
  __typename?: 'DiscordBotConfiguration';
  discordClientId?: Maybe<Scalars['String']>;
  discordGuildId?: Maybe<Scalars['String']>;
  discordServerInviteLink?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

/** Discord Target */
export type DiscordTarget = {
  __typename?: 'DiscordTarget';
  createdDate: Scalars['DateTime'];
  discordAccountId?: Maybe<Scalars['String']>;
  discordServerInviteLink?: Maybe<Scalars['String']>;
  discriminator?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isConfirmed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
  userStatus: DiscordTargetStatus;
  username?: Maybe<Scalars['String']>;
  verificationLink?: Maybe<Scalars['String']>;
};

export enum DiscordTargetStatus {
  COMPLETE = 'COMPLETE',
  DISCORD_SERVER_NOT_JOINED = 'DISCORD_SERVER_NOT_JOINED',
  UNVERIFIED = 'UNVERIFIED'
}

export type DuplicateSlackChannelTargetNamesError = Error & {
  __typename?: 'DuplicateSlackChannelTargetNamesError';
  message: Scalars['String'];
};

/** Email setting defaults for the tenant */
export type EmailSenderDefaults = {
  __typename?: 'EmailSenderDefaults';
  allowedDomains?: Maybe<Array<Maybe<CorsPolicyEntry>>>;
  defaultEmailSenderDisplayName?: Maybe<Scalars['String']>;
  /** @deprecated Use defaultEmailSenderDomainId */
  defaultEmailSenderDomain?: Maybe<EmailSenderDomain>;
  defaultEmailSenderDomainId?: Maybe<Scalars['String']>;
  defaultEmailSenderName?: Maybe<Scalars['String']>;
};

/** Custom email domain that can be used to send email from */
export type EmailSenderDomain = {
  __typename?: 'EmailSenderDomain';
  /** Create a CNAME for each of these tokens */
  dkimTokens?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use DKIM instead */
  dnsVerificationToken?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  verificationStatus: EmailSenderDomainStatus;
};

export enum EmailSenderDomainStatus {
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  UNSPECIFIED = 'UNSPECIFIED',
  VERIFIED = 'VERIFIED'
}

export type EmailTarget = {
  __typename?: 'EmailTarget';
  createdDate: Scalars['DateTime'];
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAccountOwner: Scalars['Boolean'];
  isConfirmed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
};

export type EmailUserRegistrationExecutionEndpoint = {
  __typename?: 'EmailUserRegistrationExecutionEndpoint';
  postUrl: Scalars['String'];
  targetType: TargetType;
};

/** Object used to enable or disable FCM targets for a specific device */
export type EnableOrDisableMobileFcmTargetsInput = {
  /** An identifier unique to this device. */
  deviceId: Scalars['String'];
  /** Enable or disable Inbox mobile FCM targets for this device */
  inboxEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Enable or disable xMS mobile FCM targets for this device */
  xmsEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type EnableTotp2FaRequiredForEmailLoginsError = RequiresElevatedTokenError;

export type EnableTotp2FaRequiredForEmailLoginsPayload = {
  __typename?: 'EnableTOTP2FARequiredForEmailLoginsPayload';
  errors?: Maybe<Array<EnableTotp2FaRequiredForEmailLoginsError>>;
  totp2faRequired?: Maybe<Scalars['Boolean']>;
};

/** Object used to enqueue a new offchain event */
export type EnqueueOffChainEventInput = {
  /** The bucket in which the event should be stored */
  bucketName: Scalars['String'];
  /** The data that should be placed into the queue */
  offChainEvent: Scalars['String'];
};

/** Response object from queueing an offchain event */
export type EnqueueOffChainEventResult = {
  __typename?: 'EnqueueOffChainEventResult';
  /** The bucket in which the event should be stored */
  bucketName: Scalars['String'];
};

/** A connection to a list of items. */
export type EphemeralStorageConnection = {
  __typename?: 'EphemeralStorageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<EphemeralStorageEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<EphemeralStorageEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EphemeralStorageEdge = {
  __typename?: 'EphemeralStorageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: EphemeralStorageEntry;
};

/** Ephemeral storage entry used by the Fusion system */
export type EphemeralStorageEntry = {
  __typename?: 'EphemeralStorageEntry';
  /** Key of the entry */
  key?: Maybe<Scalars['String']>;
  /** Value of the entry */
  value?: Maybe<Scalars['String']>;
};

/** Represents an individual ephemeral storage entry to be created/updated */
export type EphemeralStorageEntryInput = {
  /** Key of the entry to update */
  key: Scalars['String'];
  /** Value to update the entry to. Null values will cause the entry to be deleted */
  value?: InputMaybe<Scalars['String']>;
};

/** Object used to query ephemeral storage used by the Fusion system */
export type EphemeralStorageQueryInput = {
  /** Keys of the entries to be returned. Max of 50 per query */
  keys?: InputMaybe<Array<Scalars['String']>>;
};

export type Error = {
  message: Scalars['String'];
};

export type EventDetails = AccountBalanceChangedEventDetails | BroadcastMessageEventDetails | ChatMessageReceivedEventDetails | DaoProposalChangedEventDetails | DirectTenantMessageEventDetails | GenericEventDetails | HealthValueOverThresholdEventDetails | NftAuctionChangedEventDetails | NftCollectionsReportEventDetails | WalletsActivityReportEventDetails;

export type FcmTarget = {
  __typename?: 'FcmTarget';
  appId?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  deviceId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isConfirmed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  targetPlatform: FcmTargetPlatform;
  token?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
};

export enum FcmTargetPlatform {
  ANDROID = 'ANDROID',
  I_OS = 'I_OS'
}

/** Notifi Filter */
export type Filter = {
  __typename?: 'Filter';
  createdDate: Scalars['DateTime'];
  filterType: FilterType;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
};

export enum FilterType {
  ASSET_BUYER_EVENTS = 'ASSET_BUYER_EVENTS',
  ASSET_CATALOG_EVENTS = 'ASSET_CATALOG_EVENTS',
  ASSET_SELLER_EVENTS = 'ASSET_SELLER_EVENTS',
  BALANCE = 'BALANCE',
  BENQI_LENDING_HEALTH_EVENTS = 'BENQI_LENDING_HEALTH_EVENTS',
  BROADCAST_MESSAGES = 'BROADCAST_MESSAGES',
  COIN_PRICE_CHANGE_EVENTS = 'COIN_PRICE_CHANGE_EVENTS',
  DAO_PROPOSAL_EVENTS = 'DAO_PROPOSAL_EVENTS',
  DELTA_PRIME_BORROW_RATE_EVENTS = 'DELTA_PRIME_BORROW_RATE_EVENTS',
  DELTA_PRIME_LENDING_HEALTH_EVENTS = 'DELTA_PRIME_LENDING_HEALTH_EVENTS',
  DELTA_PRIME_SUPPLY_RATE_EVENTS = 'DELTA_PRIME_SUPPLY_RATE_EVENTS',
  DEPOSITS = 'DEPOSITS',
  DE_FI_YIELD_STRATEGIES = 'DE_FI_YIELD_STRATEGIES',
  DIRECT_TENANT_MESSAGES = 'DIRECT_TENANT_MESSAGES',
  FUSION_SOURCE = 'FUSION_SOURCE',
  LIQUIDATIONS = 'LIQUIDATIONS',
  LIQUIDITY_POOL_PRICE_CHANGES = 'LIQUIDITY_POOL_PRICE_CHANGES',
  MULTI_SIG_SAFE_EVENTS = 'MULTI_SIG_SAFE_EVENTS',
  NFT_AUCTIONS = 'NFT_AUCTIONS',
  NFT_BACKED_LOANS = 'NFT_BACKED_LOANS',
  NOTIFI_CHAT_MESSAGES = 'NOTIFI_CHAT_MESSAGES',
  REALM_DAO_PROPOSAL_STATES = 'REALM_DAO_PROPOSAL_STATES',
  STAKING_CONFIRMATION_EVENTS = 'STAKING_CONFIRMATION_EVENTS',
  STAKING_REMINDER_EVENTS = 'STAKING_REMINDER_EVENTS',
  TRIBECA_DAO_PROPOSAL_STATES = 'TRIBECA_DAO_PROPOSAL_STATES',
  VALUE_THRESHOLD = 'VALUE_THRESHOLD',
  WEB3_CHAT_MESSAGES = 'WEB3_CHAT_MESSAGES',
  WITHDRAWALS = 'WITHDRAWALS'
}

/** Object used to find tenant configs. */
export type FindTenantConfigInput = {
  /** The ID of the config to find */
  id: Scalars['String'];
  /** The tenant identifier */
  tenant: Scalars['String'];
  /** The type of config to query */
  type: TenantConfigType;
};

/** Contains the message to broadcast. */
export type FusionBroadcastMessageInput = {
  /** Topic's fusion event id. Users that subscribed to this topic id, will receive the alert. */
  fusionTopicEventId: Scalars['String'];
  /** Optional identifier used to dedupe messages for idempotency. If this is specified, messages sent after the first appearance of the value will be dropped. The initial request to queue this broadcast will still succeed. We recommend using UUID generators. */
  idempotencyKey?: InputMaybe<Scalars['String']>;
  /** The specific wallets to target, if any */
  specificWallets?: InputMaybe<Array<KeyValuePairOfStringAndWalletBlockchainInput>>;
  /** A dictionary of variables for each target destination. These values are used to render each target destiation. */
  targetDestinationVariables: Array<KeyValuePairOfTargetTypeAndDictionaryOfStringAndStringInput>;
};

export type FusionBroadcastMessageResult = {
  __typename?: 'FusionBroadcastMessageResult';
  /** Id of the message scheduled */
  id: Scalars['String'];
};

export type FusionCommunityManagerPreviewInput = {
  /** The target format that the template will rendered */
  previewTarget: TemplateType;
  /** Template id for rendering. Id will be the topic's fusion event id for most cases. */
  targetTemplateId: Scalars['String'];
  /** Variables that recipients can use to filter on, along with templates used to expand/replace on. Default templates require 'subject' and 'body'. Custom templates will require their own variable sets. */
  variables: Array<KeyValuePairOfStringAndStringInput>;
};

export type FusionCommunityManagerPreviewResult = {
  __typename?: 'FusionCommunityManagerPreviewResult';
  /** Preview result. Most likely in the form of HTML */
  content: Scalars['String'];
};

export enum FusionEventCategory {
  COMMUNITY_MANAGER = 'COMMUNITY_MANAGER',
  GENERAL = 'GENERAL',
  TENANT_ADMIN = 'TENANT_ADMIN',
  TENANT_DEVELOPER = 'TENANT_DEVELOPER',
  TENANT_MARKETER = 'TENANT_MARKETER',
  UNSPECIFIED = 'UNSPECIFIED',
  USER = 'USER'
}

export type FusionEventData = {
  __typename?: 'FusionEventData';
  alertFilters?: Maybe<Scalars['JSON']>;
  archivedDate?: Maybe<Scalars['DateTime']>;
  createdDate: Scalars['DateTime'];
  fusionEventCategory: FusionEventCategory;
  fusionModules: Array<FusionModuleData>;
  fusionSource?: Maybe<FusionSourceWithCursor>;
  hasAlertFilter: Scalars['Boolean'];
  id: Scalars['String'];
  metadata: Scalars['String'];
  name: Scalars['String'];
  tenantId: Scalars['String'];
  updatedDate: Scalars['DateTime'];
};

/** Notifi FusionEventDescriptor object */
export type FusionEventDescriptor = {
  __typename?: 'FusionEventDescriptor';
  id?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FusionEventRequiredParserVariables = {
  __typename?: 'FusionEventRequiredParserVariables';
  fusionEventId: Scalars['String'];
  requiredVariables: Array<RequiredVariable>;
};

/** A connection to a list of items. */
export type FusionEventsConnection = {
  __typename?: 'FusionEventsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FusionEventsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FusionEventData>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type FusionEventsEdge = {
  __typename?: 'FusionEventsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FusionEventData;
};

export type FusionExecutionDetails = GetFusionModuleExecutionDetailsResult | GetFusionSourceExecutionDetailsResult;

export enum FusionExecutionLogDataType {
  CLEAR_TEXT_DATA_URL = 'CLEAR_TEXT_DATA_URL',
  UNKNOWN = 'UNKNOWN'
}

/** A connection to a list of items. */
export type FusionExecutionLogsConnection = {
  __typename?: 'FusionExecutionLogsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FusionExecutionLogsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FusionExecutionDetails>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type FusionExecutionLogsEdge = {
  __typename?: 'FusionExecutionLogsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FusionExecutionDetails;
};

export enum FusionExecutionStatus {
  FAILED = 'FAILED',
  HOST_EXECUTION_FAILED = 'HOST_EXECUTION_FAILED',
  PROCESSING = 'PROCESSING',
  SUCCEEDED = 'SUCCEEDED'
}

export enum FusionExecutionType {
  /** Return all execution types */
  ALL = 'ALL',
  /** Return Fusion Alert Filter executions */
  FUSION_ALERT_FILTER = 'FUSION_ALERT_FILTER',
  /** Return Fusion Source Filter executions */
  FUSION_SOURCE_FILTER = 'FUSION_SOURCE_FILTER',
  /** Return Fusion Source Parser executions */
  FUSION_SOURCE_PARSER = 'FUSION_SOURCE_PARSER'
}

export type FusionModuleData = {
  __typename?: 'FusionModuleData';
  active: Scalars['Boolean'];
  archivedDate?: Maybe<Scalars['DateTime']>;
  createdDate: Scalars['DateTime'];
  expectedMetadataOutputSchema: Scalars['String'];
  fusionEventId: Scalars['String'];
  id: Scalars['String'];
  moduleType: FusionModuleType;
  storageType: StorageType;
  tenantId: Scalars['String'];
  validateMetadataOutput: Scalars['Boolean'];
};

export enum FusionModuleType {
  ALERT_FILTER = 'ALERT_FILTER',
  SOURCE_FILTER = 'SOURCE_FILTER',
  UNKNOWN = 'UNKNOWN'
}

/** A connection to a list of items. */
export type FusionNotificationHistoryConnection = {
  __typename?: 'FusionNotificationHistoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FusionNotificationHistoryEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FusionNotificationHistoryEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type FusionNotificationHistoryEdge = {
  __typename?: 'FusionNotificationHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FusionNotificationHistoryEntry;
};

export type FusionNotificationHistoryEntry = {
  __typename?: 'FusionNotificationHistoryEntry';
  createdDate: Scalars['DateTime'];
  detail?: Maybe<EventDetails>;
  fusionEventVariables: Scalars['String'];
  id: Scalars['String'];
  read: Scalars['Boolean'];
};

/** Notifi FusionSource object */
export type FusionSource = {
  __typename?: 'FusionSource';
  archivedDate?: Maybe<Scalars['DateTime']>;
  blockchainSource?: Maybe<WalletBlockchain>;
  createdDate?: Maybe<Scalars['DateTime']>;
  filter?: Maybe<Scalars['String']>;
  fusionEventTypeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Long']>;
};

export enum FusionSourceState {
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
  INACTIVE = 'INACTIVE',
  PROCESSING = 'PROCESSING',
  UNKNOWN = 'UNKNOWN'
}

export type FusionSourceWithCursor = {
  __typename?: 'FusionSourceWithCursor';
  archivedDate?: Maybe<Scalars['DateTime']>;
  blockchainSource: WalletBlockchain;
  createdDate: Scalars['DateTime'];
  cursor?: Maybe<CursorEntry>;
  filter: Scalars['String'];
  fusionEventTypeIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  name: Scalars['String'];
  updatedDate: Scalars['DateTime'];
  version: Scalars['Long'];
};

/** A connection to a list of items. */
export type FusionSourcesConnection = {
  __typename?: 'FusionSourcesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FusionSourcesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FusionSourceWithCursor>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type FusionSourcesEdge = {
  __typename?: 'FusionSourcesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FusionSourceWithCursor;
};

export type GenerateElevatedPermissionsAuthorizationInput = {
  twoFactorAnswer?: InputMaybe<TwoFactorChallengeAnswerInput>;
};

export type GenerateElevatedPermissionsAuthorizationPayload = {
  __typename?: 'GenerateElevatedPermissionsAuthorizationPayload';
  authorization?: Maybe<Authorization>;
};

export type GenerateNormalPermissionsAuthorizationPayload = {
  __typename?: 'GenerateNormalPermissionsAuthorizationPayload';
  newRefreshAuthorization?: Maybe<Authorization>;
  normalPermissionsAuthorization?: Maybe<Authorization>;
};

export type GenerateRefreshAuthorizationInput = {
  twoFactorAnswer?: InputMaybe<TwoFactorChallengeAnswerInput>;
};

export type GenerateRefreshAuthorizationPayload = {
  __typename?: 'GenerateRefreshAuthorizationPayload';
  authorization?: Maybe<Authorization>;
};

export type GenericEventAction = {
  __typename?: 'GenericEventAction';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type GenericEventDetails = {
  __typename?: 'GenericEventDetails';
  action?: Maybe<GenericEventAction>;
  customIconUrl?: Maybe<Scalars['String']>;
  eventDetailsJson?: Maybe<Scalars['String']>;
  icon: GenericEventIconHint;
  message: Scalars['String'];
  messageHtml?: Maybe<Scalars['String']>;
  notificationTypeName: Scalars['String'];
  sourceName: Scalars['String'];
  tenantIconUrl?: Maybe<Scalars['String']>;
};

export enum GenericEventIconHint {
  CHART = 'CHART',
  CHECKMARK = 'CHECKMARK',
  CLOCK = 'CLOCK',
  DAO = 'DAO',
  DOWN_ARROW = 'DOWN_ARROW',
  FLAG = 'FLAG',
  GRAPH = 'GRAPH',
  INFO = 'INFO',
  MEGAPHONE = 'MEGAPHONE',
  PERCENT = 'PERCENT',
  STAR = 'STAR',
  SWAP = 'SWAP',
  UP_ARROW = 'UP_ARROW',
  URGENT = 'URGENT',
  WATCH = 'WATCH'
}

/** Object used to query all Alerts or an individual. */
export type GetAlertInput = {
  /** Id of the Alert to return. */
  id?: InputMaybe<Scalars['String']>;
};

export type GetAvailableTenantStatisticalReportsInput = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  reportTypes?: InputMaybe<Array<TenantStatisticalReportType>>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

/** Object used to query bots by bot type */
export type GetBotsByTypeInput = {
  /** Bot type of Bot entity */
  botType: BotType;
};

/** Object used to specify the dapp address to be queried. */
export type GetConfigurationForDappInput = {
  dappAddress?: InputMaybe<Scalars['String']>;
};

/** Object used to query TargetGroups. */
export type GetConnectedWalletsInput = {
  /** Blockchain the wallet address belongs to. */
  connectedWalletBlockchain?: InputMaybe<WalletBlockchain>;
};

/** Parameters for getting a specific conversation */
export type GetConversationInput = {
  conversationId: Scalars['String'];
};

/** Object used to query messages for a conversation */
export type GetConversationMessagesInput = {
  conversationId: Scalars['String'];
};

/** Object used to query all Filters or an individual. */
export type GetFilterInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** Object used to query Fusion executions for this tenant */
export type GetFusionExecutionLogsInput = {
  /** Optional parameter to specify which status of executions to return */
  fusionExecutionStatus?: InputMaybe<FusionExecutionStatus>;
  /** Optional parameter to specify which types of executions to return */
  fusionExecutionType?: InputMaybe<FusionExecutionType>;
  /** Optional parameter to specify which FusionSourceId or FusionModuleId to return executions for */
  fusionSourceOrModuleId?: InputMaybe<Scalars['String']>;
};

export type GetFusionModuleExecutionDetailsResult = {
  __typename?: 'GetFusionModuleExecutionDetailsResult';
  executionTimestamp: Scalars['DateTime'];
  fusionModuleId: Scalars['String'];
  fusionModuleType: FusionModuleType;
  logData: Scalars['String'];
  logDataType: FusionExecutionLogDataType;
  originatingFusionSourceId: Scalars['String'];
  status: FusionExecutionStatus;
};

export type GetFusionModulePutUrlResponse = {
  __typename?: 'GetFusionModulePutUrlResponse';
  fusionModulePutUrl: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Object to query the params needed for setting up a developer environment to build a Fusion Source parser */
export type GetFusionSourceDevParamsInput = {
  /** FusionSource ID of the params */
  id: Scalars['String'];
};

export type GetFusionSourceDevParamsResponse = {
  __typename?: 'GetFusionSourceDevParamsResponse';
  /** Filter that when compared against a block and its tx's, will conditionally trigger this FusionSource to execute */
  blockFilter?: Maybe<Scalars['String']>;
  /** Blockchain this parser is meant for */
  blockchain: BlockchainType;
  /** Optional variables that if provided, are required by the FusionSource to have returned in the output metadata in a top-level object named 'requiredParserVariables' */
  requiredParserVariablesPerEvent?: Maybe<Array<Maybe<FusionEventRequiredParserVariables>>>;
};

export type GetFusionSourceExecutionDetailsResult = {
  __typename?: 'GetFusionSourceExecutionDetailsResult';
  cursorId: Scalars['String'];
  executionTimestamp: Scalars['DateTime'];
  fusionSourceId: Scalars['String'];
  logData: Scalars['String'];
  logDataType: FusionExecutionLogDataType;
  status: FusionExecutionStatus;
};

export type GetFusionSourcePutUrlResponse = {
  __typename?: 'GetFusionSourcePutUrlResponse';
  fusionParserPutUrl: Scalars['String'];
  success: Scalars['Boolean'];
};

export type GetFusionSourceStateInput = {
  fusionSourceId: Scalars['String'];
};

/** Object used to query jabber messages for a thread */
export type GetJabberMessagesInput = {
  threadId: Scalars['String'];
};

export type GetMessageDeliveryStatisticsResult = {
  __typename?: 'GetMessageDeliveryStatisticsResult';
  messagesByAttributes: MessageStats;
  messagesByTarget: MessageStats;
};

export type GetPublicDappMetadataInput = {
  dappId: Scalars['String'];
};

/** Object used to query the public topics */
export type GetPublicTopicsInput = {
  /** Collections to use for filtering relevant topics */
  collectionName: Scalars['String'];
};

/** Object used to query previous public UserTopic posts */
export type GetPublicUserTopicPostInput = {
  /** Topic name to get previous posts for */
  topicName: Scalars['String'];
};

export type GetSlackChannelBotInfoInput = {
  slackChannelTargetId: Scalars['String'];
};

export type GetSlackChannelBotInfoResponse = {
  __typename?: 'GetSlackChannelBotInfoResponse';
  botName: Scalars['String'];
  iconUrl: Scalars['String'];
};

export type GetSlackWorkspaceIconInput = {
  slackChannelTargetId: Scalars['String'];
};

export type GetSlackWorkspaceIconResponse = {
  __typename?: 'GetSlackWorkspaceIconResponse';
  /** Slack workspace icon url */
  iconUrl: Scalars['String'];
};

/** Object used to query SourceGroups. */
export type GetSourceGroupInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** Object used to query Sources. Optional fields are mutually exclusive. */
export type GetSourceInput = {
  /** Wallet address to query specific DAO Sources for. Memberships to DAOs will be verified and populated for connected wallet. */
  connectedWalletAddress?: InputMaybe<Scalars['String']>;
  /** Blockchain the wallet address belongs to. */
  connectedWalletBlockchain?: InputMaybe<WalletBlockchain>;
  /** ID of the Source to be queried. */
  id?: InputMaybe<Scalars['String']>;
};

/** Object used to query Sources */
export type GetSourcesInput = {
  /** IDs of the Sources to be queried. */
  ids?: InputMaybe<Array<Scalars['String']>>;
  /** ID of the SourceGroup to be used. */
  sourceGroupId?: InputMaybe<Scalars['String']>;
};

/** Object used to specify the dapp address to be queried. */
export type GetSupportedTargetTypesForDappInput = {
  dappAddress?: InputMaybe<Scalars['String']>;
};

/** Object used to query TargetGroups. */
export type GetTargetGroupInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** Object used to query Targets. */
export type GetTargetInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** Object used to query Targets. */
export type GetTargetsInput = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  /** IDs of the targets to be queried. */
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type GetTemplateDataInput = {
  templateName: Scalars['String'];
  type: TemplateType;
};

export type GetTemplateResponse = {
  __typename?: 'GetTemplateResponse';
  template?: Maybe<Template>;
};

export type GetTenantAvailableReportsResult = {
  __typename?: 'GetTenantAvailableReportsResult';
  reports: Array<Scalars['String']>;
};

/** Object used to query tenant configs. */
export type GetTenantConfigInput = {
  /** The type of config to query */
  type: TenantConfigType;
};

/** Object used to query ConnectedWallets that belong to a Tenant's Users. This can only be used from a service caller. */
export type GetTenantConnectedWalletInput = {
  userWallets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userWalletsBlockchain: WalletBlockchain;
};

export type GetTenantDeliveryReportRequestInput = {
  monthYears: Array<Scalars['String']>;
};

export type GetTenantDeliveryReportResult = {
  __typename?: 'GetTenantDeliveryReportResult';
  preSignedUrls: Array<KeyValuePairOfStringAndString>;
};

export type GetTotalMessagesPerMonthByTenantResult = {
  __typename?: 'GetTotalMessagesPerMonthByTenantResult';
  messagesPerMonth: Array<KeyValuePairOfStringAndInt32>;
};

export type GetTotalMessagesPerTopicByTenantInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type GetTotalMessagesPerTopicByTenantPayload = {
  __typename?: 'GetTotalMessagesPerTopicByTenantPayload';
  /** The unique ID representing this topic */
  eventTypeId: Scalars['String'];
  /** The total number of messages sent on this topic */
  messageCount: Scalars['Long'];
  /** The total number of subscribers on this topic */
  subscriberCount: Scalars['Long'];
  /** The display name of this topic */
  topicName: Scalars['String'];
};

/** Object used to query GetUnreadNotificationHistoryCount, optionally specifying a cardId to filter the count to. */
export type GetUnreadNotificationHistoryCountInput = {
  /** CardId that should be used to select which topics/fusionEventsIds should be used to filter the count to. */
  cardId?: InputMaybe<Scalars['String']>;
};

export type GetUnreadNotificationHistoryCountResponse = {
  __typename?: 'GetUnreadNotificationHistoryCountResponse';
  count: Scalars['Int'];
};

export type GetUrlsForTenantStatisticalReportsInput = {
  reportIds: Array<Scalars['String']>;
};

export type GetUrlsForTenantStatisticalReportsOutput = {
  __typename?: 'GetUrlsForTenantStatisticalReportsOutput';
  preSignedUrlsForReportIds: Array<KeyValuePairOfStringAndString>;
};

/** Object used to query an individual user profile */
export type GetUserProfileInput = {
  /** UserId of the profile to query */
  id: Scalars['String'];
};

export type GetUsersByTopicResult = {
  __typename?: 'GetUsersByTopicResult';
  usersByTopic: Array<KeyValuePairOfStringAndInt32>;
};

/** Object used to query Web3Targets */
export type GetWeb3TargetsInput = {
  /** IDs of the Web3Targets to be queried. */
  ids?: InputMaybe<Array<Scalars['String']>>;
  /** ID of the TargetGroup to be used. */
  targetGroupId?: InputMaybe<Scalars['String']>;
};

export type HealthValueOverThresholdEventDetails = {
  __typename?: 'HealthValueOverThresholdEventDetails';
  name: Scalars['String'];
  threshold: Scalars['String'];
  url: Scalars['String'];
  value: Scalars['String'];
};

export type InvalidEmailError = Error & {
  __typename?: 'InvalidEmailError';
  message: Scalars['String'];
};

export type InvalidXmtpConsentProofError = Error & {
  __typename?: 'InvalidXmtpConsentProofError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type JabberMessagesConnection = {
  __typename?: 'JabberMessagesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<JabberMessagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<JabberV1Message>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type JabberMessagesEdge = {
  __typename?: 'JabberMessagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<JabberV1Message>;
};

/** A connection to a list of items. */
export type JabberThreadsConnection = {
  __typename?: 'JabberThreadsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<JabberThreadsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<JabberV1Thread>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type JabberThreadsEdge = {
  __typename?: 'JabberThreadsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: JabberV1Thread;
};

export type JabberV1Message = {
  __typename?: 'JabberV1Message';
  id: Scalars['String'];
  isEncrypted: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  messageBase64: Scalars['String'];
  senderWalletAddress: Scalars['String'];
  senderWalletBlockchain: WalletBlockchain;
  threadId: Scalars['String'];
  timestamp?: Maybe<Scalars['DateTime']>;
};

/** A Jabber chat participant */
export type JabberV1Participant = {
  __typename?: 'JabberV1Participant';
  notifiProfile?: Maybe<UserProfile>;
  resolvedName?: Maybe<Scalars['String']>;
  solPerMessage?: Maybe<Scalars['Float']>;
  walletAddress: Scalars['String'];
  walletBlockchain: WalletBlockchain;
};

/** A Jabber Conversation object */
export type JabberV1Thread = {
  __typename?: 'JabberV1Thread';
  id: Scalars['String'];
  lastConversationMessage?: Maybe<JabberV1Message>;
  participants: Array<JabberV1Participant>;
};

export type KeyValuePairOfStringAndInt32 = {
  __typename?: 'KeyValuePairOfStringAndInt32';
  key: Scalars['String'];
  value: Scalars['Int'];
};

export type KeyValuePairOfStringAndMessageDetails = {
  __typename?: 'KeyValuePairOfStringAndMessageDetails';
  key: Scalars['String'];
  value: MessageDetails;
};

export type KeyValuePairOfStringAndString = {
  __typename?: 'KeyValuePairOfStringAndString';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValuePairOfStringAndWalletBlockchainInput = {
  key: Scalars['String'];
  value: WalletBlockchain;
};

export type KeyValuePairOfTargetTypeAndDictionaryOfStringAndStringInput = {
  key: TargetType;
  value: Array<KeyValuePairOfStringAndStringInput>;
};

export type KeyValuePairOfTargetTypeAndStringInput = {
  key: TargetType;
  value: Scalars['String'];
};

/** Object used to allow a user to leave conversation */
export type LeaveConversationInput = {
  /** Conversation id */
  conversationId: Scalars['String'];
};

export type LimitedResourceTypeError = Error & {
  __typename?: 'LimitedResourceTypeError';
  message: Scalars['String'];
};

export type ListAvailableSlackChannelInput = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  slackChannelTargetId: Scalars['String'];
};

export type ListAvailableSlackChannelResponse = {
  __typename?: 'ListAvailableSlackChannelResponse';
  nextCursor?: Maybe<Scalars['String']>;
  slackChannels?: Maybe<Array<SlackChannel>>;
};

export type ListTemplatesInput = {
  templateName: Scalars['String'];
};

export type ListTemplatesResponse = {
  __typename?: 'ListTemplatesResponse';
  templates: Array<Template>;
};

/** Object used to log in to an existing account via email/password pair */
export type LogInByEmailAddressInput = {
  /** Optional dappId to log in to. If not provided, this is assumed to be a Notifi Admin Portal login attempt */
  dappId?: InputMaybe<Scalars['String']>;
  /** Email address */
  emailAddress: Scalars['String'];
  /** Account password */
  password: Scalars['String'];
};

/** Describes the issuer of the OAuth token being used */
export type LogInByOAuthInput = {
  dappId: Scalars['String'];
  oAuthIssuer: OAuthIssuer;
  token: Scalars['String'];
};

export type LogInByOidcError = ArgumentError | UnauthorizedAccessError;

/** Object to complete login using OIDC id_token */
export type LogInByOidcInput = {
  dappId: Scalars['String'];
  idToken: Scalars['String'];
  oidcProvider: OidcProvider;
};

export type LogInByOidcPayload = {
  __typename?: 'LogInByOidcPayload';
  errors?: Maybe<Array<LogInByOidcError>>;
  user?: Maybe<User>;
};

export type MarkFusionNotificationHistoryAsReadInput = {
  /** Marks the given notification id and all the notifications that came before the id as read */
  beforeId?: InputMaybe<Scalars['String']>;
  /** List of ids to mark as read, leave as empty if using markNotificationAsReadBeforeId */
  ids: Array<Scalars['String']>;
  readState?: InputMaybe<NotificationHistoryReadState>;
};

export type MarkNotificationsAsReadInput = {
  /** List of Ids to update, at least 1 must be specified */
  ids: Array<Scalars['String']>;
};

export type MessageDetails = {
  __typename?: 'MessageDetails';
  messages: Scalars['Int'];
  users: Scalars['Int'];
};

export type MessageStats = {
  __typename?: 'MessageStats';
  stats?: Maybe<Array<KeyValuePairOfStringAndMessageDetails>>;
};

export enum MessageType {
  DIRECT_TENANT_MESSAGE = 'DIRECT_TENANT_MESSAGE',
  RAW_MESSAGE = 'RAW_MESSAGE',
  SIMPLE_HEALTH_THRESHOLD = 'SIMPLE_HEALTH_THRESHOLD'
}

export type MobilePushSettings = {
  __typename?: 'MobilePushSettings';
  inboxEnabled: Scalars['Boolean'];
  xmsEnabled: Scalars['Boolean'];
};

/** Input for retrieving device specific mobile push options */
export type MobilePushSettingsInput = {
  /** Device ID */
  deviceId: Scalars['String'];
};

export type NftAuctionChangedEventDetails = {
  __typename?: 'NftAuctionChangedEventDetails';
  auctionUrl?: Maybe<Scalars['String']>;
  highBidAmount: Scalars['Float'];
  highBidSymbol?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  tenantName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  walletBlockchain: WalletBlockchain;
};

export type NftCollectionStats = {
  __typename?: 'NftCollectionStats';
  collectionId: Scalars['String'];
  imgUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  volume1Day?: Maybe<Scalars['String']>;
  volume1DayChange?: Maybe<Scalars['String']>;
};

export type NftCollectionsReportEventDetails = {
  __typename?: 'NftCollectionsReportEventDetails';
  collections: Array<NftCollectionStats>;
  providerName: Scalars['String'];
  sourceLink: Scalars['String'];
  type: NftCollectionsReportType;
};

export enum NftCollectionsReportType {
  HOT = 'HOT',
  MOST_TRADED = 'MOST_TRADED'
}

export type NotifiMutation = {
  __typename?: 'NotifiMutation';
  activateFusionModule: Scalars['Boolean'];
  /** Activate a fusion source. A valid source must be provided by first calling CreateFusionSourceUploadUrl and uploading using the returned url. A new cursor position and filter can also be provided here during activation */
  activateFusionSource: ActivateFusionSourcePayload;
  /** Activate a SID for your Dapp account, allowing it to be used for authentication */
  activateServiceIdentifier: ActivateServiceIdentifierPayload;
  addBot: Bot;
  /** Add a custom icon url */
  addCustomIconUrl: PublicDappInfo;
  /** Add a custom sender domain that can be used for emails sent by this tenant custom_default@customdomain.com */
  addEmailSenderDomain?: Maybe<EmailSenderDomain>;
  addFusionEventToFusionSource: AddFusionEventToFusionSourceResponse;
  addOidcProvider: AddOidcProviderPayload;
  /** Add or update a mobile FcmTarget. This will add the FcmTarget to all TargetGroups */
  addOrUpdateMobileFcmTarget: FcmTarget;
  /** Add a source to a SourceGroup */
  addSourceToSourceGroup: SourceGroup;
  archiveOpenUserRegistration: ArchiveOpenUserRegistrationPayload;
  /** Allows a user to publish a message from a Source that they own for multiple destinations. */
  batchBroadcastMessage?: Maybe<BatchBroadcastMessageResult>;
  /** Begin adding TOTP two-factor authentication to your user account by generating an authenticator secret */
  beginAddTOTP2FA: BeginAddTotp2FaPayload;
  /** Mutation that allows users to obtain the nonce required for connecting a wallet to an existing account. */
  beginConnectWalletToCurrentUser: BeginConnectWalletToCurrentUserPayload;
  /** Mutation that allows users to begin flow for logging in via a QR code */
  beginLogInByQRCode: BeginLogInByQrCodeResult;
  /** Mutation to begin login flow via signed transaction */
  beginLogInByTransaction: BeginLogInByTransactionResult;
  /** Mutation that allows users to obtain the nonce required for a Web3 login */
  beginLogInWithWeb3: BeginLogInWithWeb3Payload;
  /** Allows a user to publish a message from a Source that they own. */
  broadcastMessage?: Maybe<BroadcastMessageResult>;
  cancelCommunityManagerPublishTask: CancelCommunityManagerPublishTaskPayload;
  changeExecutionTimeCMPublishTask: ChangeExecutionTimeCmPublishTaskPayload;
  /** Complete adding TOTP two-factor authentication to your user account by providing a TOTP code */
  completeAddTOTP2FA: CompleteAddTotp2FaPayload;
  /** Claim a wallet that belongs to this account. If another account was associated to this wallet, it will be removed. */
  completeConnectWalletToCurrentUser: CompleteConnectWalletToCurrentUserPayload;
  completeLogInByQRCode?: Maybe<User>;
  /** Mutation to complete login flow via signed transaction */
  completeLogInByTransaction?: Maybe<User>;
  /** Mutation facilitates account login via wallet signatures */
  completeLogInWithWeb3: CompleteLogInWithWeb3Payload;
  confirmChannelForSlackChannelTarget: ConfirmChannelForSlackChannelTargetResponse;
  /** Confirm email with token. */
  confirmEmail: Scalars['Boolean'];
  /**
   * Claim a wallet that belongs to this account. If another account was associated to this wallet, it will be removed.
   * @deprecated Please migrate to the `ConnectWalletToCurrentUser` mutation which uses the `BeginConnectWalletToCurrentUser` to generate the nonce.
   */
  connectWallet?: Maybe<ConnectedWallet>;
  /**
   * Mutation to create a new user or direct access account.
   * @deprecated This functionality is being removed for individual users
   */
  create?: Maybe<User>;
  /** Create a new Alert to watch for blockchain account changes. */
  createAlert?: Maybe<Alert>;
  /**
   * Create a new conversation
   * @deprecated Chat has been deprecated
   */
  createConversation: Conversation;
  /** Mutation to create a new Direct Push Alert for a User that's part of a this Tenant. */
  createDirectPushAlert?: Maybe<Alert>;
  createDiscordBotConfiguration: DiscordBotConfiguration;
  /** Create a DiscordTarget. */
  createDiscordTarget: DiscordTarget;
  /** Create an EmailTarget pointing to a destination that future Alerts can use to send messages to. */
  createEmailTarget?: Maybe<EmailTarget>;
  /** Create an Fcm pointing to a destination that future Alerts can use to send messages to. */
  createFcmTarget?: Maybe<FcmTarget>;
  /** Creates new Alerts to watch on topics created by CreateFusionEvents */
  createFusionAlerts: CreateFusionAlertsPayload;
  createFusionEvent: FusionEventData;
  createFusionModule: CreateFusionModuleResponse;
  createFusionSource: CreateFusionSourcePayload;
  /** Create a signed url for uploading a fusion source file. Once the new file has been accepted, the returned version on this object will be reflected on the FusionSource query object */
  createFusionSourceUploadUrl: CreateFusionSourceUploadUrlPayload;
  createOpenUserRegistration: CreateOpenUserRegistrationPayload;
  /** Mutation to create or update a user for the admin panel. */
  createOrUpdateUser: User;
  /** Add credentials to your Dapp account for a service to log in */
  createServiceIdentifier: CreateServiceIdentifierPayload;
  createSlackChannelTarget: CreateSlackChannelTargetResponse;
  /** Create an SmsTarget pointing to a destination that future Alerts can use to send messages to. */
  createSmsTarget?: Maybe<SmsTarget>;
  /** Create a Source pointing to a blockchain address that should be used for future Alerts. */
  createSource?: Maybe<Source>;
  /** Create a SourceGroup pointing to one or more Sources. */
  createSourceGroup?: Maybe<SourceGroup>;
  /**
   * Create or get the support conversation for this user
   * @deprecated Chat has been deprecated
   */
  createSupportConversation: Conversation;
  /** Create a TargetGroup pointing to one or more Targets that should be used for future Alerts. */
  createTargetGroup?: Maybe<TargetGroup>;
  /** Create an TelegramTarget pointing to a destination that future Alerts can use to send messages to. */
  createTelegramTarget?: Maybe<TelegramTarget>;
  /** Mutation to create a new tenant. Returned User is the tenant admin, associated with the provided email. Only 1 tenant can be assigned to an email */
  createTenant?: Maybe<User>;
  /** Create or update a tenant config */
  createTenantConfig: TenantConfig;
  /** Mutation to create a new User from a dapp off-chain service. */
  createTenantUser?: Maybe<TenantUser>;
  /** Create a Web3Target pointing to a destination that future Alerts can use to send messages to. */
  createWeb3Target?: Maybe<Web3Target>;
  createWebPushTarget: CreateWebPushTargetResponse;
  /** Create a WebhookTarget pointing to a destination that future Alerts can use to send messages to. */
  createWebhookTarget?: Maybe<WebhookTarget>;
  /** Deactivate a fusion source. This will stop processing and set the processing state to Inactive */
  deactivateFusionSource: DeactivateFusionSourcePayload;
  /** Deactivate a SID for your Dapp account, disallowing it from being used for authentication */
  deactivateServiceIdentifier: DeactivateServiceIdentifierPayload;
  /** Delete the Alert. */
  deleteAlert?: Maybe<Alert>;
  /** Delete one or more alerts by their respective IDs */
  deleteAlerts: DeleteAlertsPayload;
  /** Delete DApp subscription by ID. */
  deleteDAppSubscription?: Maybe<DAppSubscription>;
  /** Mutation to create a new Direct Push Alert for a User that's part of a this Tenant. */
  deleteDirectPushAlert?: Maybe<Alert>;
  deleteDiscordBotConfiguration?: Maybe<DiscordBotConfiguration>;
  /** Delete a DiscordTarget. */
  deleteDiscordTarget: DiscordTarget;
  /** Delete an EmailTarget. */
  deleteEmailTarget?: Maybe<EmailTarget>;
  /** Delete an FcmTarget. */
  deleteFcmTarget?: Maybe<FcmTarget>;
  deleteFusionEvent: Scalars['Boolean'];
  deleteFusionModule: Scalars['Boolean'];
  /** Delete a fusion source. This will set the source to archived, and no longer be allowed to be associated with any FusionEvents or activated */
  deleteFusionSource: DeleteFusionSourceResponse;
  deleteSlackChannelTarget: DeleteSlackChannelTargetResponse;
  /** Delete an SmsTarget. */
  deleteSmsTarget?: Maybe<SmsTarget>;
  /** Delete the Source. */
  deleteSource?: Maybe<Source>;
  /** Delete a SourceGroup. */
  deleteSourceGroup?: Maybe<SourceGroup>;
  /** Delete a TargetGroup. */
  deleteTargetGroup?: Maybe<TargetGroup>;
  /** Delete a TelegramTarget. */
  deleteTelegramTarget?: Maybe<TelegramTarget>;
  /** Delete a tenant config */
  deleteTenantConfig: TenantConfig;
  /** Delete an alert on behalf of a user that belongs to the tenant. */
  deleteUserAlert?: Maybe<Alert>;
  /** Delete a Web3Target */
  deleteWeb3Target?: Maybe<Web3Target>;
  deleteWebPushTarget: DeleteWebPushTargetResponse;
  /** Delete a WebhookTarget. */
  deleteWebhookTarget?: Maybe<WebhookTarget>;
  /** Stop requiring TOTP 2FA sign-in for all users of your Notifi Dapp account that sign in with an email address. */
  disableTOTP2FARequiredForEmailLogins: DisableTotp2FaRequiredForEmailLoginsPayload;
  /** Disconnect a wallet that belongs to this account. If this the last wallet on an account with no recovery email to sign in with, this will fail. */
  disconnectWallet?: Maybe<ConnectedWallet>;
  /** Disable or enable mobile FcmTargets for a particular device */
  enableOrDisableMobileFcmTargets: Array<FcmTarget>;
  /** Require TOTP 2FA sign-in for all users of your Notifi Dapp account that sign in with an email address. */
  enableTOTP2FARequiredForEmailLogins: EnableTotp2FaRequiredForEmailLoginsPayload;
  /** @deprecated Use publishFusionMessage endpoint on dpapi.notifi.network endpoint instead. See docs for more details. */
  enqueueOffChainEvent: EnqueueOffChainEventResult;
  /**
   * Allows a user to publish a message to a fusion event topic for multiple destinations.
   * @deprecated Use dpapi endpoint instead
   */
  fusionBroadcastMessage: FusionBroadcastMessageResult;
  /** Get a very short-lived JWT authorized specifically for performing sensitive actions on your account */
  generateElevatedPermissionsAuthorization: GenerateElevatedPermissionsAuthorizationPayload;
  /** Sends an email to confirm a newly created account. */
  generateEmailConfirmationToken: Scalars['Boolean'];
  /** Get a short-lived authorization token for performing normal user actions */
  generateNormalPermissionsAuthorization: GenerateNormalPermissionsAuthorizationPayload;
  /** Get a long-lived authorization token for fetching normal use credentials */
  generateRefreshAuthorization: GenerateRefreshAuthorizationPayload;
  /**
   * Create a new conversation
   * @deprecated Chat has been deprecated
   */
  leaveConversation: Conversation;
  /**
   * Mutation to log in.
   * @deprecated Please use LogInByEmailAddress
   */
  logIn?: Maybe<User>;
  /** Mutation to log in by email address */
  logInByEmailAddress?: Maybe<User>;
  /**
   * Mutation to log in after completing OAuth2 flow and obtaining an id_token. The tenant must be configured for the respective OIDC providers
   * @deprecated Please use LogInByOidc
   */
  logInByOAuth?: Maybe<User>;
  /** Mutation to log in after completing OAuth2 flow and obtaining an id_token. The tenant must be configured for the respective OIDC providers */
  logInByOidc: LogInByOidcPayload;
  /** Mutation that allows DAOs to log in via user wallet. */
  logInFromDapp?: Maybe<User>;
  /** Mutation to allow a service to log in. This is used to call other APIs on the tenant with elevated permissions. Note, this login can't be used for non service related APIs. */
  logInFromService?: Maybe<Authorization>;
  /** Log out of your current user session. (Calling this API will specifically invalidate the token you used to authorize it.) */
  logOut: Scalars['Boolean'];
  /** Log out of all sessions on your user account */
  logOutAllSessions: Scalars['Boolean'];
  /** Mark given notifications as read by the current user. */
  markFusionNotificationHistoryAsRead: Array<Scalars['String']>;
  /** Mark given notifications as read by the current user. */
  markNotificationsAsRead?: Maybe<Array<NotificationHistoryEntry>>;
  /** @deprecated Use dpapi endpoint instead */
  publishFusionMessage: PublishFusionMessageResult;
  /**
   * Use this API to get a refreshed JWT to prevent expiry.
   * @deprecated Use GenerateNormalPermissionsAuthorization with the Refresh JWT obtained from GenerateRefreshAuthorization instead
   */
  refreshAuthorization?: Maybe<Authorization>;
  /** Regenerate your two-factor backup codes. Requires an elevated permissions token. */
  regenerate2FABackupCodes: Regenerate2FaBackupCodesPayload;
  removeBot: Bot;
  /** Remove a custom sender domain */
  removeEmailSenderDomain?: Maybe<EmailSenderDomain>;
  removeFusionEventFromFusionSource: RemoveFusionEventFromFusionSourceResponse;
  /** Remove the service identifier with the given sid from your Dapp account */
  removeServiceIdentifier: RemoveServiceIdentifierPayload;
  /** Remove a source from a SourceGroup */
  removeSourceFromSourceGroup: SourceGroup;
  /** Remove TOTP two-factor authentication from your user account. Requires an elevated permissions token. This action also logs you out from all devices. */
  removeTOTP2FA: RemoveTotp2FaPayload;
  /** Mutation to remove a user from the admin panel. */
  removeTenantUserRoles?: Maybe<User>;
  /** Sets a new password for account owned by email if 2FA code is correct. */
  resetPassword: Scalars['Boolean'];
  /**
   * Send a message to a conversation
   * @deprecated Chat has been deprecated
   */
  sendConversationMessage: ConversationMessage;
  /** Send Target confirmation. Currently only EmailTarget is supported. */
  sendEmailTargetVerificationRequest?: Maybe<EmailTarget>;
  /** Directly send a message to a particular wallet address. The message will be sent to any destinations the wallet owner has setup. */
  sendMessage: Scalars['Boolean'];
  /** Sends a password reset email with a 2FA code embedded in the link. */
  sendResetPasswordEmail: Scalars['Boolean'];
  /** Unsubscribe from an alert for a single target type */
  unsubscribe: UnsubscribeResult;
  updateBot: Bot;
  /** Update the CORS policy for this tenant that any users calls from this dapp will be applied to */
  updateCorsPolicy?: Maybe<CorsPolicy>;
  /** Update the default display name that will be used for emails sent by this tenant Custom Default <custom_default>@mailer.notifi.network */
  updateDefaultEmailSenderDisplayName?: Maybe<EmailSenderDefaults>;
  /** Update the default sender domain that will be used for emails sent by this tenant custom_default@customdomain.com */
  updateDefaultEmailSenderDomain?: Maybe<EmailSenderDefaults>;
  /** Update the default sender name that will be used for emails sent by this tenant custom_default@mailer.notifi.network */
  updateDefaultEmailSenderName?: Maybe<EmailSenderDefaults>;
  updateDiscordBotConfiguration: DiscordBotConfiguration;
  /** Update tenant's ephemeral storage used by the Fusion system */
  updateEphemeralStorage: UpdateEphemeralStorageResult;
  updateFusionEvent: FusionEventData;
  updateFusionSource: UpdateFusionSourceResponse;
  updateOpenUserRegistration: UpdateOpenUserRegistrationPayload;
  /** Update tenant's persistent storage used by the Fusion system */
  updatePersistentStorage: UpdatePersistentStorageResult;
  /** Mutation to update current user profile. */
  updateUserProfile?: Maybe<UserProfile>;
  /** Mutation to update current user settings. */
  updateUserSettings?: Maybe<UserSettings>;
  updateWebPushTarget: UpdateWebPushTargetResponse;
  uploadTemplate: Scalars['Boolean'];
  verifyCbwTarget: VerifyCbwTargetResponse;
  /** Verify a DiscordTarget. */
  verifyDiscordTarget: DiscordTarget;
  verifySlackChannelForWebhook: VerifySlackChannelTargetForWebhookResponse;
  verifySlackChannelTarget: VerifySlackChannelTargetResponse;
  /** Verify EmailTarget for a user. */
  verifyTarget?: Maybe<EmailTarget>;
  verifyXmtpTarget: VerifyXmtpTargetResponse;
  verifyXmtpTargetViaXip42: VerifyXmtpTargetViaXip42Response;
};


export type NotifiMutationActivateFusionModuleArgs = {
  fusionModuleId: Scalars['String'];
};


export type NotifiMutationActivateFusionSourceArgs = {
  input: ActivateFusionSourceInput;
};


export type NotifiMutationActivateServiceIdentifierArgs = {
  input: ActivateServiceIdentifierInput;
};


export type NotifiMutationAddBotArgs = {
  addBotInput: AddBotInput;
};


export type NotifiMutationAddCustomIconUrlArgs = {
  input: AddCustomIconUrlInput;
};


export type NotifiMutationAddEmailSenderDomainArgs = {
  addEmailSenderDomainInput: AddEmailSenderDomainInput;
};


export type NotifiMutationAddFusionEventToFusionSourceArgs = {
  input: AddFusionEventToFusionSourceInput;
};


export type NotifiMutationAddOidcProviderArgs = {
  input: AddOidcProviderInput;
};


export type NotifiMutationAddOrUpdateMobileFcmTargetArgs = {
  addOrUpdateMobileFcmTargetInput: AddOrUpdateMobileFcmTargetInput;
};


export type NotifiMutationAddSourceToSourceGroupArgs = {
  addSourceToSourceGroupInput: AddSourceToSourceGroupInput;
};


export type NotifiMutationArchiveOpenUserRegistrationArgs = {
  input: ArchiveOpenUserRegistrationInput;
};


export type NotifiMutationBatchBroadcastMessageArgs = {
  broadcastMessageInput?: InputMaybe<BatchBroadcastMessageInput>;
};


export type NotifiMutationBeginConnectWalletToCurrentUserArgs = {
  beginConnectWalletInput: BeginConnectWalletToCurrentUserInput;
};


export type NotifiMutationBeginLogInByTransactionArgs = {
  beginLogInByTransactionInput: BeginLogInByTransactionInput;
};


export type NotifiMutationBeginLogInWithWeb3Args = {
  beginLogInWithWeb3Input: BeginLogInWithWeb3Input;
};


export type NotifiMutationBroadcastMessageArgs = {
  broadcastMessageInput?: InputMaybe<BroadcastMessageInput>;
  signature?: InputMaybe<Scalars['String']>;
};


export type NotifiMutationCancelCommunityManagerPublishTaskArgs = {
  input: CancelCommunityManagerPublishTaskInput;
};


export type NotifiMutationChangeExecutionTimeCmPublishTaskArgs = {
  input: ChangeExecutionTimeCmPublishTaskInput;
};


export type NotifiMutationCompleteAddTotp2FaArgs = {
  input: CompleteAddTotp2FaInput;
};


export type NotifiMutationCompleteConnectWalletToCurrentUserArgs = {
  completeConnectWalletToCurrentUserInput?: InputMaybe<CompleteConnectWalletToCurrentUserInput>;
};


export type NotifiMutationCompleteLogInByQrCodeArgs = {
  completeLogInByQRCodeInput: CompleteLogInByQrCodeInput;
};


export type NotifiMutationCompleteLogInByTransactionArgs = {
  completeLogInByTransactionInput: CompleteLogInByTransactionInput;
};


export type NotifiMutationCompleteLogInWithWeb3Args = {
  completeLogInWithWeb3Input: CompleteLogInWithWeb3Input;
};


export type NotifiMutationConfirmChannelForSlackChannelTargetArgs = {
  input: ConfirmChannelForSlackChannelTargetInput;
};


export type NotifiMutationConfirmEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};


export type NotifiMutationConnectWalletArgs = {
  connectWalletInput?: InputMaybe<ConnectWalletInput>;
  signature?: InputMaybe<Scalars['String']>;
};


export type NotifiMutationCreateArgs = {
  user?: InputMaybe<UserInput>;
};


export type NotifiMutationCreateAlertArgs = {
  alertInput?: InputMaybe<CreateAlertInput>;
};


export type NotifiMutationCreateConversationArgs = {
  createConversationInput: CreateConversationInput;
};


export type NotifiMutationCreateDirectPushAlertArgs = {
  createDirectPushAlertInput?: InputMaybe<CreateDirectPushAlertInput>;
};


export type NotifiMutationCreateDiscordBotConfigurationArgs = {
  createDiscordBotConfigurationInput: CreateDiscordBotConfigurationInput;
};


export type NotifiMutationCreateDiscordTargetArgs = {
  createTargetInput?: InputMaybe<CreateTargetInput>;
};


export type NotifiMutationCreateEmailTargetArgs = {
  createTargetInput?: InputMaybe<CreateTargetInput>;
};


export type NotifiMutationCreateFcmTargetArgs = {
  createFcmTargetInput: CreateFcmTargetInput;
};


export type NotifiMutationCreateFusionAlertsArgs = {
  input: CreateFusionAlertsInput;
};


export type NotifiMutationCreateFusionEventArgs = {
  input: CreateFusionEventInput;
};


export type NotifiMutationCreateFusionModuleArgs = {
  input: CreateFusionModuleInput;
};


export type NotifiMutationCreateFusionSourceArgs = {
  input: CreateFusionSourceInput;
};


export type NotifiMutationCreateFusionSourceUploadUrlArgs = {
  input: CreateFusionSourceUploadUrlInput;
};


export type NotifiMutationCreateOpenUserRegistrationArgs = {
  input: CreateOpenUserRegistrationInput;
};


export type NotifiMutationCreateOrUpdateUserArgs = {
  createOrUpdateUserInput: CreateOrUpdateUserInput;
};


export type NotifiMutationCreateSlackChannelTargetArgs = {
  input: CreateTargetInput;
};


export type NotifiMutationCreateSmsTargetArgs = {
  createTargetInput?: InputMaybe<CreateTargetInput>;
};


export type NotifiMutationCreateSourceArgs = {
  createSourceInput?: InputMaybe<CreateSourceInput>;
};


export type NotifiMutationCreateSourceGroupArgs = {
  sourceGroupInput?: InputMaybe<SourceGroupInput>;
};


export type NotifiMutationCreateTargetGroupArgs = {
  targetGroupInput?: InputMaybe<TargetGroupInput>;
};


export type NotifiMutationCreateTelegramTargetArgs = {
  createTargetInput?: InputMaybe<CreateTargetInput>;
};


export type NotifiMutationCreateTenantArgs = {
  createTenantInput: CreateTenantInput;
};


export type NotifiMutationCreateTenantConfigArgs = {
  createTenantConfigInput: CreateTenantConfigInput;
};


export type NotifiMutationCreateTenantUserArgs = {
  createTenantUserInput?: InputMaybe<CreateTenantUserInput>;
};


export type NotifiMutationCreateWeb3TargetArgs = {
  createWeb3TargetInput: CreateWeb3TargetInput;
};


export type NotifiMutationCreateWebPushTargetArgs = {
  input: CreateWebPushTargetInput;
};


export type NotifiMutationCreateWebhookTargetArgs = {
  createTargetInput: CreateWebhookTargetInput;
};


export type NotifiMutationDeactivateFusionSourceArgs = {
  input: DeactivateFusionSourceInput;
};


export type NotifiMutationDeactivateServiceIdentifierArgs = {
  input: DeactivateServiceIdentifierInput;
};


export type NotifiMutationDeleteAlertArgs = {
  alertId?: InputMaybe<Scalars['String']>;
};


export type NotifiMutationDeleteAlertsArgs = {
  input: DeleteAlertsInput;
};


export type NotifiMutationDeleteDAppSubscriptionArgs = {
  subscriptionId: Scalars['String'];
};


export type NotifiMutationDeleteDirectPushAlertArgs = {
  deleteDirectPushAlertInput?: InputMaybe<DeleteDirectPushAlertInput>;
};


export type NotifiMutationDeleteDiscordTargetArgs = {
  deleteTargetInput?: InputMaybe<DeleteTargetInput>;
};


export type NotifiMutationDeleteEmailTargetArgs = {
  deleteTargetInput?: InputMaybe<DeleteTargetInput>;
};


export type NotifiMutationDeleteFcmTargetArgs = {
  deleteTargetInput?: InputMaybe<DeleteTargetInput>;
};


export type NotifiMutationDeleteFusionEventArgs = {
  fusionEventId: Scalars['String'];
};


export type NotifiMutationDeleteFusionModuleArgs = {
  fusionModuleId: Scalars['String'];
};


export type NotifiMutationDeleteFusionSourceArgs = {
  input: DeleteFusionSourceInput;
};


export type NotifiMutationDeleteSlackChannelTargetArgs = {
  input: DeleteTargetInput;
};


export type NotifiMutationDeleteSmsTargetArgs = {
  deleteTargetInput?: InputMaybe<DeleteTargetInput>;
};


export type NotifiMutationDeleteSourceArgs = {
  sourceId?: InputMaybe<Scalars['String']>;
};


export type NotifiMutationDeleteSourceGroupArgs = {
  sourceGroupInput?: InputMaybe<SourceGroupInput>;
};


export type NotifiMutationDeleteTargetGroupArgs = {
  targetGroupInput?: InputMaybe<TargetGroupInput>;
};


export type NotifiMutationDeleteTelegramTargetArgs = {
  deleteTargetInput?: InputMaybe<DeleteTargetInput>;
};


export type NotifiMutationDeleteTenantConfigArgs = {
  deleteTenantConfigInput: DeleteTenantConfigInput;
};


export type NotifiMutationDeleteUserAlertArgs = {
  alertId?: InputMaybe<Scalars['String']>;
};


export type NotifiMutationDeleteWeb3TargetArgs = {
  deleteTargetInput: DeleteTargetInput;
};


export type NotifiMutationDeleteWebPushTargetArgs = {
  input: DeleteWebPushTargetInput;
};


export type NotifiMutationDeleteWebhookTargetArgs = {
  deleteTargetInput: DeleteTargetInput;
};


export type NotifiMutationDisconnectWalletArgs = {
  disconnectWalletInput?: InputMaybe<DisconnectWalletInput>;
};


export type NotifiMutationEnableOrDisableMobileFcmTargetsArgs = {
  enableOrDisableMobileFcmTargetsInput: EnableOrDisableMobileFcmTargetsInput;
};


export type NotifiMutationEnqueueOffChainEventArgs = {
  enqueueOffChainEventInput: EnqueueOffChainEventInput;
};


export type NotifiMutationFusionBroadcastMessageArgs = {
  fusionBroadcastMessageInput: FusionBroadcastMessageInput;
};


export type NotifiMutationGenerateElevatedPermissionsAuthorizationArgs = {
  input: GenerateElevatedPermissionsAuthorizationInput;
};


export type NotifiMutationGenerateEmailConfirmationTokenArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type NotifiMutationGenerateRefreshAuthorizationArgs = {
  input: GenerateRefreshAuthorizationInput;
};


export type NotifiMutationLeaveConversationArgs = {
  leaveConversationInput: LeaveConversationInput;
};


export type NotifiMutationLogInArgs = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};


export type NotifiMutationLogInByEmailAddressArgs = {
  logInByEmailAddressInput: LogInByEmailAddressInput;
};


export type NotifiMutationLogInByOAuthArgs = {
  logInByOAuthInput: LogInByOAuthInput;
};


export type NotifiMutationLogInByOidcArgs = {
  input: LogInByOidcInput;
};


export type NotifiMutationLogInFromDappArgs = {
  dappLogInInput?: InputMaybe<DappLogInInput>;
  signature?: InputMaybe<Scalars['String']>;
};


export type NotifiMutationLogInFromServiceArgs = {
  serviceLogInInput?: InputMaybe<ServiceLogInInput>;
};


export type NotifiMutationMarkFusionNotificationHistoryAsReadArgs = {
  input: MarkFusionNotificationHistoryAsReadInput;
};


export type NotifiMutationMarkNotificationsAsReadArgs = {
  input: MarkNotificationsAsReadInput;
};


export type NotifiMutationPublishFusionMessageArgs = {
  publishFusionMessageInput: PublishFusionMessageInput;
};


export type NotifiMutationRemoveBotArgs = {
  removeBotInput: RemoveBotInput;
};


export type NotifiMutationRemoveEmailSenderDomainArgs = {
  removeEmailSenderDomainInput: RemoveEmailSenderDomainInput;
};


export type NotifiMutationRemoveFusionEventFromFusionSourceArgs = {
  input: RemoveFusionEventFromFusionSourceInput;
};


export type NotifiMutationRemoveServiceIdentifierArgs = {
  input: RemoveServiceIdentifierInput;
};


export type NotifiMutationRemoveSourceFromSourceGroupArgs = {
  removeSourceFromSourceGroupInput: RemoveSourceFromSourceGroupInput;
};


export type NotifiMutationRemoveTenantUserRolesArgs = {
  removeTenantUserRolesInput: RemoveTenantUserRolesInput;
};


export type NotifiMutationResetPasswordArgs = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  tfaCode?: InputMaybe<Scalars['String']>;
};


export type NotifiMutationSendConversationMessageArgs = {
  sendConversationMessageInput: SendConversationMessageInput;
};


export type NotifiMutationSendEmailTargetVerificationRequestArgs = {
  sendTargetConfirmationRequestInput?: InputMaybe<SendTargetConfirmationRequestInput>;
};


export type NotifiMutationSendMessageArgs = {
  sendMessageInput?: InputMaybe<SendMessageInput>;
};


export type NotifiMutationSendResetPasswordEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type NotifiMutationUnsubscribeArgs = {
  unsubscribeInputType: UnsubscribeInputTypeInput;
};


export type NotifiMutationUpdateBotArgs = {
  updateBotInput: UpdateBotInput;
};


export type NotifiMutationUpdateCorsPolicyArgs = {
  updateCorsPolicyInput: UpdateCorsPolicyInput;
};


export type NotifiMutationUpdateDefaultEmailSenderDisplayNameArgs = {
  updateDefaultEmailSenderDisplayNameInput: UpdateDefaultEmailSenderDisplayNameInput;
};


export type NotifiMutationUpdateDefaultEmailSenderDomainArgs = {
  updateDefaultEmailSenderDomainInput: UpdateDefaultEmailSenderDomainInput;
};


export type NotifiMutationUpdateDefaultEmailSenderNameArgs = {
  updateDefaultEmailSenderNameInput: UpdateDefaultEmailSenderNameInput;
};


export type NotifiMutationUpdateDiscordBotConfigurationArgs = {
  updateDiscordBotConfigurationInput: UpdateDiscordBotConfigurationInput;
};


export type NotifiMutationUpdateEphemeralStorageArgs = {
  updateEphemeralStorageInput: UpdateEphemeralStorageInput;
};


export type NotifiMutationUpdateFusionEventArgs = {
  input: UpdateFusionEventInput;
};


export type NotifiMutationUpdateFusionSourceArgs = {
  input: UpdateFusionSourceInput;
};


export type NotifiMutationUpdateOpenUserRegistrationArgs = {
  input: UpdateOpenUserRegistrationInput;
};


export type NotifiMutationUpdatePersistentStorageArgs = {
  updatePersistentStorageInput: UpdatePersistentStorageInput;
};


export type NotifiMutationUpdateUserProfileArgs = {
  userProfileInput: UserProfileInput;
};


export type NotifiMutationUpdateUserSettingsArgs = {
  userSettings: UserSettingsInput;
};


export type NotifiMutationUpdateWebPushTargetArgs = {
  input: UpdateWebPushTargetInput;
};


export type NotifiMutationUploadTemplateArgs = {
  template: Scalars['String'];
  templateName: Scalars['String'];
  type: TemplateType;
};


export type NotifiMutationVerifyCbwTargetArgs = {
  input: VerifyCbwTargetInput;
};


export type NotifiMutationVerifyDiscordTargetArgs = {
  verifyDiscordTargetInput: VerifyDiscordTargetInput;
};


export type NotifiMutationVerifySlackChannelForWebhookArgs = {
  input: VerifySlackChannelTargetForWebhookInput;
};


export type NotifiMutationVerifySlackChannelTargetArgs = {
  input: VerifySlackChannelTargetInput;
};


export type NotifiMutationVerifyTargetArgs = {
  verifyTargetInput?: InputMaybe<VerifyTargetInput>;
};


export type NotifiMutationVerifyXmtpTargetArgs = {
  input: VerifyXmtpTargetInput;
};


export type NotifiMutationVerifyXmtpTargetViaXip42Args = {
  input: VerifyXmtpTargetViaXip42Input;
};

export type NotifiQuery = {
  __typename?: 'NotifiQuery';
  /** Query current active alerts */
  activeAlerts?: Maybe<ActiveAlertsConnection>;
  /** Query for Alerts. */
  alert?: Maybe<Array<Maybe<Alert>>>;
  /** Get all email senders available for this tenant */
  allEmailSenders: Array<Maybe<EmailSenderDomain>>;
  /** Get the available downloadable statistical reports for this tenant */
  availableTenantStatisticalReports?: Maybe<AvailableTenantStatisticalReportsConnection>;
  billingPlan: BillingPlan;
  /** Get bots by type */
  botsByType: Array<Bot>;
  /** Query for campaign performance for a specific campaign */
  campaignPerformance?: Maybe<CampaignDetails>;
  /** Get campaigns associated with the tenant, grouped by status. */
  campaignsByTenantId: CampaignStatusConnection;
  /** Query for community manager for a specific campaign */
  communityManagerPublishTask: CommunityManagerPublish;
  /** Get the configuration for a dapp */
  configurationForDapp?: Maybe<ClientConfiguration>;
  /** Query for ConnectedWallets. */
  connectedWallet?: Maybe<Array<Maybe<ConnectedWallet>>>;
  /**
   * Get a specific conversation
   * @deprecated It deprecated
   */
  conversation: Conversation;
  /**
   * Get messages for a conversation
   * @deprecated It deprecated
   */
  conversationMessages?: Maybe<ConversationMessagesConnection>;
  /**
   * Get conversations a user is part of
   * @deprecated It deprecated
   */
  conversations?: Maybe<ConversationsConnection>;
  /** DApps subscriptions for all tenants */
  dAppsSubscriptions?: Maybe<Array<DAppSubscription>>;
  /** Query for DiscordTargets. */
  discordTarget?: Maybe<Array<DiscordTarget>>;
  /** Get email sender defaults */
  emailSenderDefaults?: Maybe<EmailSenderDefaults>;
  /** Query for EmailTargets. */
  emailTarget?: Maybe<Array<Maybe<EmailTarget>>>;
  /** Get ephemeral storage entries used by the Fusion parsers and filters. This should only be used to query explicit keys. Wildcards are not allowed */
  ephemeralStorage?: Maybe<EphemeralStorageConnection>;
  /** Query for FcmTargets. */
  fcmTargets?: Maybe<Array<Maybe<FcmTarget>>>;
  /** Query for Filters. */
  filter?: Maybe<Array<Maybe<Filter>>>;
  /** Fetch a specific tenant config */
  findTenantConfig: TenantConfig;
  fusionEvents?: Maybe<FusionEventsConnection>;
  fusionExecutionLogs?: Maybe<FusionExecutionLogsConnection>;
  fusionModulePutUrl: GetFusionModulePutUrlResponse;
  /** Get users fusion notification history */
  fusionNotificationHistory?: Maybe<FusionNotificationHistoryConnection>;
  /** Allows a community manager to preview content for a given target and template */
  fusionPreviewCommunityManagerContent: FusionCommunityManagerPreviewResult;
  fusionSourceDevParams: GetFusionSourceDevParamsResponse;
  /** @deprecated Use CreateFusionSourceUploadUrl mutation instead */
  fusionSourcePutUrl: GetFusionSourcePutUrlResponse;
  fusionSourceState: FusionSourceWithCursor;
  fusionSources?: Maybe<FusionSourcesConnection>;
  /**
   * Get jabber messages for a thread
   * @deprecated It deprecated
   */
  jabberMessages?: Maybe<JabberMessagesConnection>;
  /**
   * Get jabber threads a user is part of
   * @deprecated It deprecated
   */
  jabberThreads?: Maybe<JabberThreadsConnection>;
  listAvailableSlackChannels: ListAvailableSlackChannelResponse;
  listTemplates: ListTemplatesResponse;
  /** Query for the count of messages delivered per target type across this tenant */
  messageDeliveryStatistics: GetMessageDeliveryStatisticsResult;
  /** Fetch a specific tenant config */
  mobilePushSettings: MobilePushSettings;
  /** Get notification by id, returns null if the notification is not found */
  notificationById?: Maybe<NotificationHistoryEntry>;
  /** Get users notification history */
  notificationHistory?: Maybe<NotificationHistoryConnection>;
  openUserRegistrations?: Maybe<OpenUserRegistrationsConnection>;
  /** Get persistent storage entries used by the Fusion parsers and filters */
  persistentStorage?: Maybe<PersistentStorageConnection>;
  /** Allows a community manager to preview content for a UserTopic that they own. */
  previewCommunityManagerContent: CommunityManagerPreviewResult;
  /** Retrieves the public metadata for a specified Dapp. */
  publicDappMetadata?: Maybe<PublicDappInfo>;
  /**
   * Get public topics to subscribe
   * @deprecated This query along with topics used here are no longer supported. This API will be removed in the future.
   */
  publicTopics?: Maybe<PublicTopicsConnection>;
  /** Get public posts to topics */
  publicUserTopicPost?: Maybe<PublicUserTopicPostConnection>;
  /**
   * Resolve name for SNS
   * @deprecated No longer used, and support will be removed from this namespace
   */
  resolveSNSFromAddress?: Maybe<Scalars['String']>;
  /** Query reward offers for users in a tenant, including wallet subscribers */
  rewardOffers?: Maybe<RewardOffersConnection>;
  /**
   * Get Service Identifier associated with a Tenant
   * @deprecated Deprecated - please use ServiceIdentifiers instead
   */
  serviceIdentifier?: Maybe<ServiceIdentifierOutput>;
  /** Get all Service Identifiers associated with a Tenant */
  serviceIdentifiers: Array<ServiceIdentifierOutput>;
  settings?: Maybe<UserSettings>;
  slackChannelBotInfo?: Maybe<GetSlackChannelBotInfoResponse>;
  slackChannelTargets?: Maybe<SlackChannelTargetsConnection>;
  slackWorkspaceIcon: GetSlackWorkspaceIconResponse;
  /** Query for SmsTargets. */
  smsTarget?: Maybe<Array<Maybe<SmsTarget>>>;
  /** Query for Sources. */
  source?: Maybe<Array<Maybe<Source>>>;
  /** Query for Source Groups. */
  sourceGroup?: Maybe<Array<Maybe<SourceGroup>>>;
  /** Query for Sources */
  sources?: Maybe<SourcesConnection>;
  /** Get the supported target types for the calling user (email/sms/telegram/etc) */
  supportedTargetTypes?: Maybe<Array<TargetType>>;
  /** Get the supported target types for a dapp (email/sms/telegram/etc) */
  supportedTargetTypesForDapp?: Maybe<Array<TargetType>>;
  /** Query for TargetGroups. */
  targetGroup?: Maybe<Array<Maybe<TargetGroup>>>;
  /** Query for TelegramTargets. */
  telegramTarget?: Maybe<Array<Maybe<TelegramTarget>>>;
  templateData: GetTemplateResponse;
  /** Get tenant's approval status */
  tenantApprovalStatus: ApprovalStatus;
  /**
   * Query for the count of messages delivered per target type across this tenant
   * @deprecated Use GetAvailableTenantStatisticalReports instead
   */
  tenantAvailableReports: GetTenantAvailableReportsResult;
  /** Get tenant configs */
  tenantConfigs?: Maybe<TenantConfigsConnection>;
  /** Get connected wallets for this tenant. */
  tenantConnectedWallet?: Maybe<TenantConnectedWalletConnection>;
  /**
   * Query for the monthly message delivery report for a tenant
   * @deprecated Use GetAvailableTenantStatisticalReports instead
   */
  tenantDeliveryReport: GetTenantDeliveryReportResult;
  tenantDiscordBotConfiguration?: Maybe<DiscordBotConfiguration>;
  /** Get a tenant's feature flag */
  tenantFeatureFlag: TenantFeatureFlag;
  /**
   * Get users for this tenant.
   * @deprecated Please use TenantUsers
   */
  tenantUser?: Maybe<TenantUserConnection>;
  /** Get users for this tenant. */
  tenantUsers?: Maybe<TenantUsersConnection>;
  /** Get the topics to broadcast */
  topics?: Maybe<TopicsConnection>;
  /** Query for the count of messages delivered per month for this tenant */
  totalMessagesPerMonthByTenant: GetTotalMessagesPerMonthByTenantResult;
  /** Query for the count of messages delivered per topic for this tenant */
  totalMessagesPerTopicByTenant?: Maybe<TotalMessagesPerTopicByTenantConnection>;
  /** Get whether or not 2FA is required to log into your Dapp with an email address (such as for Notifi Admin) */
  totp2FARequiredForEmailLogins: Totp2FaRequiredForEmailLoginsPayload;
  /** Get whether or not your account has 2FA set up and requires it to log in */
  totp2FAStatus: Totp2FaStatusPayload;
  /** Get users unread notification history count */
  unreadNotificationHistoryCount: GetUnreadNotificationHistoryCountResponse;
  /** Get the pre-signed URLs for the given reports */
  urlsForTenantStatisticalReports: GetUrlsForTenantStatisticalReportsOutput;
  /** User feature flags */
  userFeatureFlags?: Maybe<UserFeatureFlags>;
  /** User profile. Omit GetUserProfileInput to retrieve caller's profile */
  userProfile?: Maybe<UserProfile>;
  /** User settings */
  userSettings?: Maybe<UserSettings>;
  /** Query for the count of users per topic across this tenant */
  usersByTopic: GetUsersByTopicResult;
  vapidPublicKeys?: Maybe<VapidPublicKeysConnection>;
  /** Query for Web3Targets */
  web3Targets?: Maybe<Web3TargetsConnection>;
  /** Query for WebPushTargets */
  webPushTargets?: Maybe<WebPushTargetsConnection>;
  /** Query for WebhookTargets. */
  webhookTarget?: Maybe<Array<WebhookTarget>>;
};


export type NotifiQueryActiveAlertsArgs = {
  activeAlertsInput: ActiveAlertsInput;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type NotifiQueryAlertArgs = {
  getAlertInput?: InputMaybe<GetAlertInput>;
};


export type NotifiQueryAvailableTenantStatisticalReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input: GetAvailableTenantStatisticalReportsInput;
  last?: InputMaybe<Scalars['Int']>;
};


export type NotifiQueryBotsByTypeArgs = {
  getBotsByTypeInput: GetBotsByTypeInput;
};


export type NotifiQueryCampaignPerformanceArgs = {
  id: Scalars['String'];
};


export type NotifiQueryCampaignsByTenantIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type NotifiQueryCommunityManagerPublishTaskArgs = {
  id: Scalars['String'];
};


export type NotifiQueryConfigurationForDappArgs = {
  getConfigurationForDappInput?: InputMaybe<GetConfigurationForDappInput>;
};


export type NotifiQueryConnectedWalletArgs = {
  getConnectedWalletsInput?: InputMaybe<GetConnectedWalletsInput>;
};


export type NotifiQueryConversationArgs = {
  getConversationInput: GetConversationInput;
};


export type NotifiQueryConversationMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  getConversationMessagesInput: GetConversationMessagesInput;
};


export type NotifiQueryConversationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type NotifiQueryDiscordTargetArgs = {
  getTargetInput?: InputMaybe<GetTargetInput>;
};


export type NotifiQueryEmailTargetArgs = {
  getTargetInput?: InputMaybe<GetTargetInput>;
};


export type NotifiQueryEphemeralStorageArgs = {
  after?: InputMaybe<Scalars['String']>;
  ephemeralStorageQueryInput?: InputMaybe<EphemeralStorageQueryInput>;
  first?: InputMaybe<Scalars['Int']>;
};


export type NotifiQueryFcmTargetsArgs = {
  getTargetInput?: InputMaybe<GetTargetInput>;
};


export type NotifiQueryFilterArgs = {
  getFilterInput?: InputMaybe<GetFilterInput>;
};


export type NotifiQueryFindTenantConfigArgs = {
  findTenantConfigInput: FindTenantConfigInput;
};


export type NotifiQueryFusionEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fusionEventCategory?: InputMaybe<FusionEventCategory>;
};


export type NotifiQueryFusionExecutionLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  getFusionExecutionLogsInput?: InputMaybe<GetFusionExecutionLogsInput>;
};


export type NotifiQueryFusionModulePutUrlArgs = {
  fusionSourceId: Scalars['String'];
  type: FusionModuleType;
};


export type NotifiQueryFusionNotificationHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeHidden?: InputMaybe<Scalars['Boolean']>;
  includeRead?: InputMaybe<Scalars['Boolean']>;
};


export type NotifiQueryFusionPreviewCommunityManagerContentArgs = {
  fusionCommunityManagerPreviewInput: FusionCommunityManagerPreviewInput;
};


export type NotifiQueryFusionSourceDevParamsArgs = {
  getFusionSourceDevParamsInput: GetFusionSourceDevParamsInput;
};


export type NotifiQueryFusionSourcePutUrlArgs = {
  fusionSourceId: Scalars['String'];
};


export type NotifiQueryFusionSourceStateArgs = {
  input: GetFusionSourceStateInput;
};


export type NotifiQueryFusionSourcesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type NotifiQueryJabberMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  getJabberMessagesInput: GetJabberMessagesInput;
};


export type NotifiQueryJabberThreadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type NotifiQueryListAvailableSlackChannelsArgs = {
  input: ListAvailableSlackChannelInput;
};


export type NotifiQueryListTemplatesArgs = {
  input: ListTemplatesInput;
};


export type NotifiQueryMobilePushSettingsArgs = {
  mobilePushSettingsInput: MobilePushSettingsInput;
};


export type NotifiQueryNotificationByIdArgs = {
  id: Scalars['String'];
};


export type NotifiQueryNotificationHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<NotificationHistoryFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
};


export type NotifiQueryOpenUserRegistrationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type NotifiQueryPersistentStorageArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  persistentStorageQueryInput?: InputMaybe<PersistentStorageQueryInput>;
};


export type NotifiQueryPreviewCommunityManagerContentArgs = {
  communityManagerPreviewInput: CommunityManagerPreviewInput;
};


export type NotifiQueryPublicDappMetadataArgs = {
  input: GetPublicDappMetadataInput;
};


export type NotifiQueryPublicTopicsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<GetPublicTopicsInput>;
};


export type NotifiQueryPublicUserTopicPostArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  getPublicUserTopicPostInput: GetPublicUserTopicPostInput;
};


export type NotifiQueryResolveSnsFromAddressArgs = {
  address: Scalars['String'];
};


export type NotifiQueryRewardOffersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input: RewardOffersInput;
  last?: InputMaybe<Scalars['Int']>;
};


export type NotifiQuerySlackChannelBotInfoArgs = {
  input: GetSlackChannelBotInfoInput;
};


export type NotifiQuerySlackChannelTargetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input: GetTargetsInput;
};


export type NotifiQuerySlackWorkspaceIconArgs = {
  input: GetSlackWorkspaceIconInput;
};


export type NotifiQuerySmsTargetArgs = {
  getTargetInput?: InputMaybe<GetTargetInput>;
};


export type NotifiQuerySourceArgs = {
  getSourceInput?: InputMaybe<GetSourceInput>;
};


export type NotifiQuerySourceGroupArgs = {
  getSourceGroupInput?: InputMaybe<GetSourceGroupInput>;
};


export type NotifiQuerySourcesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  getSourcesInput?: InputMaybe<GetSourcesInput>;
};


export type NotifiQuerySupportedTargetTypesForDappArgs = {
  getSupportedTargetTypesForDappInput?: InputMaybe<GetSupportedTargetTypesForDappInput>;
};


export type NotifiQueryTargetGroupArgs = {
  getTargetGroupInput?: InputMaybe<GetTargetGroupInput>;
};


export type NotifiQueryTelegramTargetArgs = {
  getTargetInput?: InputMaybe<GetTargetInput>;
};


export type NotifiQueryTemplateDataArgs = {
  input: GetTemplateDataInput;
};


export type NotifiQueryTenantConfigsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  getTenantConfigInput: GetTenantConfigInput;
};


export type NotifiQueryTenantConnectedWalletArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  getTenantConnectedWalletInput?: InputMaybe<GetTenantConnectedWalletInput>;
};


export type NotifiQueryTenantDeliveryReportArgs = {
  input: GetTenantDeliveryReportRequestInput;
};


export type NotifiQueryTenantFeatureFlagArgs = {
  input: TenantFeatureFlagInput;
};


export type NotifiQueryTenantUserArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  tenantUsersInput?: InputMaybe<TenantUsersInput>;
};


export type NotifiQueryTenantUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  tenantUsersInput?: InputMaybe<TenantUsersInput>;
};


export type NotifiQueryTopicsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type NotifiQueryTotalMessagesPerTopicByTenantArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input: GetTotalMessagesPerTopicByTenantInput;
};


export type NotifiQueryUnreadNotificationHistoryCountArgs = {
  input?: InputMaybe<GetUnreadNotificationHistoryCountInput>;
};


export type NotifiQueryUrlsForTenantStatisticalReportsArgs = {
  input: GetUrlsForTenantStatisticalReportsInput;
};


export type NotifiQueryUserProfileArgs = {
  getUserProfileInput?: InputMaybe<GetUserProfileInput>;
};


export type NotifiQueryVapidPublicKeysArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type NotifiQueryWeb3TargetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  getWeb3TargetsInput?: InputMaybe<GetWeb3TargetsInput>;
};


export type NotifiQueryWebPushTargetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<GetTargetsInput>;
};


export type NotifiQueryWebhookTargetArgs = {
  getTargetInput?: InputMaybe<GetTargetInput>;
};

export type NotifiSubscription = {
  __typename?: 'NotifiSubscription';
  messageReceived: ConversationMessage;
  stateChanged: StateChangedEvent;
  tenantActiveAlertChanged: TenantActiveAlertChangeEvent;
  tenantEntityChanged: TenantEntityChangeEvent;
};

export enum NotificationCategory {
  BALANCE_CHANGE = 'BALANCE_CHANGE',
  CHAT = 'CHAT',
  COIN_PRICE_CHANGE = 'COIN_PRICE_CHANGE',
  CREATOR_MESSAGE = 'CREATOR_MESSAGE',
  DAO_PROPOSAL = 'DAO_PROPOSAL',
  NFT_AUCTION = 'NFT_AUCTION'
}

/** A connection to a list of items. */
export type NotificationHistoryConnection = {
  __typename?: 'NotificationHistoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<NotificationHistoryEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<NotificationHistoryEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NotificationHistoryEdge = {
  __typename?: 'NotificationHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: NotificationHistoryEntry;
};

export type NotificationHistoryEntry = {
  __typename?: 'NotificationHistoryEntry';
  alertId: Scalars['String'];
  category?: Maybe<NotificationCategory>;
  createdDate: Scalars['DateTime'];
  detail?: Maybe<EventDetails>;
  eventId: Scalars['String'];
  id: Scalars['String'];
  read: Scalars['Boolean'];
  sourceAddress?: Maybe<Scalars['String']>;
  targets: Array<NotificationTarget>;
  transactionSignature?: Maybe<Scalars['String']>;
};

export type NotificationHistoryFilterInput = {
  categories?: InputMaybe<Array<NotificationCategory>>;
};

export enum NotificationHistoryReadState {
  HIDDEN = 'HIDDEN',
  NONE = 'NONE',
  READ = 'READ'
}

export type NotificationHistoryStateChangedEvent = {
  __typename?: 'NotificationHistoryStateChangedEvent';
  timestamp: Scalars['DateTime'];
};

export type NotificationTarget = {
  __typename?: 'NotificationTarget';
  name?: Maybe<Scalars['String']>;
  type: TargetType;
};

/** Data about notifications for reward conditions */
export type NotificationsData = {
  __typename?: 'NotificationsData';
  /** Unique identifier for the fusion event type */
  fusionEventTypeId: Scalars['String'];
  /** Delivery status of the notification target */
  targetDeliveryStatus: TargetDeliveryStatus;
  /** Type of the target for the notification */
  targetType: PointsTargetType;
};

export enum OAuthIssuer {
  APPLE = 'APPLE',
  CUSTOM = 'CUSTOM',
  FACEBOOK = 'FACEBOOK',
  GITHUB = 'GITHUB',
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
  TWITTER = 'TWITTER',
  UNSTOPPABLE_DOMAINS = 'UNSTOPPABLE_DOMAINS'
}

/** Output for off-chain conditions or data */
export type OffChainDataOutput = {
  __typename?: 'OffChainDataOutput';
  /** Placeholder for off-chain data */
  placeholder: Scalars['String'];
};

/** Conditions that need to be met for a reward offer */
export type OfferCondition = {
  __typename?: 'OfferCondition';
  calculateSize: Scalars['Int'];
  clone?: Maybe<OfferCondition>;
  conditionDetailsCase: ConditionDetailsOneofCase;
  /** Notification-related conditions */
  notifications?: Maybe<NotificationsData>;
  /** Off-chain conditions or data */
  offChain?: Maybe<OffChainDataOutput>;
  /** Number of times the condition needs to be met */
  requiredCount: Scalars['Int'];
  /** User actions that need to be performed */
  userActions?: Maybe<UserActionsDataOutput>;
  /** User interactions that need to occur */
  userInteractions?: Maybe<UserInteractionsDataOutput>;
};

/** Response object for a reward offer */
export type OfferResponse = {
  __typename?: 'OfferResponse';
  /** Current activation state of the offer */
  activationState?: Maybe<PointsOfferActivationState>;
  calculateSize: Scalars['Int'];
  clone?: Maybe<OfferResponse>;
  /** Conditions that need to be met for this offer */
  condition: OfferCondition;
  /** Timestamp for when the offer was first created */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Identifier of the dapp associated with this offer */
  dappId: Scalars['String'];
  /** Detailed description of the reward offer */
  description?: Maybe<Scalars['String']>;
  /** End date and time of the offer */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Unique identifier for the reward offer */
  id: Scalars['String'];
  /** ID of the user who last updated this offer */
  lastUpdatedByUserId: Scalars['String'];
  /** Name of the reward offer */
  name: Scalars['String'];
  /** Rewards associated with this offer */
  reward?: Maybe<RewardData>;
  /** Start date and time of the offer */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Timestamp for when the offer was last updated */
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type OidcConfiguration = OidcCustomProviderConfiguration | OidcPublicProviderConfiguration;

export type OidcCustomProviderConfiguration = {
  __typename?: 'OidcCustomProviderConfiguration';
  claimToUseForUserId: Scalars['String'];
  clientId: Scalars['String'];
  issuer?: Maybe<Scalars['String']>;
  jwksUrl?: Maybe<Scalars['String']>;
  oidcConfigurationUrl?: Maybe<Scalars['String']>;
  oidcProvider: OidcProvider;
  requireVerifiedEmailAddress: Scalars['Boolean'];
  userIdPrefix?: Maybe<Scalars['String']>;
};

/** Indicates the issuer type of the id_token */
export enum OidcProvider {
  APPLE = 'APPLE',
  CUSTOM = 'CUSTOM',
  FACEBOOK = 'FACEBOOK',
  GITHUB = 'GITHUB',
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
  TWITTER = 'TWITTER',
  UNSTOPPABLE_DOMAINS = 'UNSTOPPABLE_DOMAINS'
}

/** OIDC provider enabled for this tenant */
export type OidcProviderConfiguration = {
  __typename?: 'OidcProviderConfiguration';
  /** ClientId set on the issuer that is producing the id_token. This value must be present in the aud claim */
  clientId?: Maybe<Scalars['String']>;
  /** Id of the OIDC provider configuration */
  id: Scalars['String'];
  /** Issuer of the id_token used for OIDC */
  issuer?: Maybe<OidcProvider>;
  /** The OIDC provider's configuration enabled for this tenant */
  providerConfig?: Maybe<OidcConfiguration>;
  /** Prefix added to unique id of user (typically pulled from sub claim) on the Custom Issuer */
  userIdPrefix?: Maybe<Scalars['String']>;
};

export type OidcPublicProviderConfiguration = {
  __typename?: 'OidcPublicProviderConfiguration';
  clientId: Scalars['String'];
  oidcProvider: OidcProvider;
  requireVerifiedEmailAddress: Scalars['Boolean'];
  userIdPrefix?: Maybe<Scalars['String']>;
};

/** Open user registration allowed for this tenant */
export type OpenUserRegistration = {
  __typename?: 'OpenUserRegistration';
  /** This is the time when this open user registration will be valid and allow users to sign up */
  beginTime?: Maybe<Timestamp>;
  /** Timestamp of when this open user registration was created */
  createdTime?: Maybe<Scalars['Long']>;
  /** Optional time when this open user registration ends */
  endTime?: Maybe<Timestamp>;
  /** FusionEvents/topics that a user registering through this open user registration will be automatically signed up for */
  fusionEventIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Id of the open user registration */
  id?: Maybe<Scalars['String']>;
  /** Name of the open user registration */
  name?: Maybe<Scalars['String']>;
  /** The user registration execution setup for this open user registration. This will provide the post url for emails, telegram action url, etc. */
  userRegistrationExecutionEndpoints?: Maybe<Array<Maybe<UserRegistrationExecutionEndpoint>>>;
};

/** A connection to a list of items. */
export type OpenUserRegistrationsConnection = {
  __typename?: 'OpenUserRegistrationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OpenUserRegistrationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OpenUserRegistration>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OpenUserRegistrationsEdge = {
  __typename?: 'OpenUserRegistrationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OpenUserRegistration;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type PersistentStorageConnection = {
  __typename?: 'PersistentStorageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PersistentStorageEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PersistentStorageEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PersistentStorageEdge = {
  __typename?: 'PersistentStorageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PersistentStorageEntry;
};

/** Persistent storage entry used by the Fusion system */
export type PersistentStorageEntry = {
  __typename?: 'PersistentStorageEntry';
  /** Date the entry was created */
  createdDate?: Maybe<Timestamp>;
  /** Key of the entry */
  key?: Maybe<Scalars['String']>;
  /** Date the entry was last updated */
  updatedDate?: Maybe<Timestamp>;
  /** Value of the entry */
  value?: Maybe<Scalars['String']>;
  /** Version of the entry */
  version?: Maybe<Scalars['Long']>;
};

export type PersistentStorageEntryInput = {
  /** Key of the entry to update */
  key: Scalars['String'];
  /** Value to update the entry to. Null values will cause the entry to be deleted */
  value?: InputMaybe<Scalars['String']>;
  /** Optional version of the entry to set. If provided, a write will only succeed if the version is greater than the current stored version. If omitted, the current version is overwritten */
  version?: InputMaybe<Scalars['Long']>;
};

/** Object used to query persistent storage used by the Fusion system */
export type PersistentStorageQueryInput = {
  /** Keys of the entries to be returned. Max of 50 per query */
  keys?: InputMaybe<Array<Scalars['String']>>;
};

/** Data about points rewards */
export type PointsDataGrpc = {
  __typename?: 'PointsDataGrpc';
  /** Amount of points to be rewarded */
  amount: Scalars['Long'];
  calculateSize: Scalars['Int'];
  clone?: Maybe<PointsDataGrpc>;
  /** Total supply of points (optional) */
  totalSupply?: Maybe<Scalars['Long']>;
};

/** Entities that can perform actions in a points offer */
export enum PointsOfferActionEntity {
  /** Alert action entity */
  ALERT = 'ALERT',
  /** Source action entity */
  SOURCE = 'SOURCE',
  /** Target action entity */
  TARGET = 'TARGET',
  /** Unspecified action entity */
  UNSPECIFIED = 'UNSPECIFIED',
  /** User action entity */
  USER = 'USER'
}

/** Verbs describing actions in a points offer */
export enum PointsOfferActionVerb {
  /** Created action */
  CREATED = 'CREATED',
  /** Deleted action */
  DELETED = 'DELETED',
  /** Link opened action */
  LINK_OPENED = 'LINK_OPENED',
  /** Message opened action */
  MESSAGE_OPENED = 'MESSAGE_OPENED',
  /** Unspecified action verb */
  UNSPECIFIED = 'UNSPECIFIED',
  /** Updated action */
  UPDATED = 'UPDATED'
}

export enum PointsOfferActivationState {
  ACTIVATION_STATE_ACTIVE = 'ACTIVATION_STATE_ACTIVE',
  ACTIVATION_STATE_INACTIVE = 'ACTIVATION_STATE_INACTIVE',
  ACTIVATION_STATE_NEEDS_REVIEW = 'ACTIVATION_STATE_NEEDS_REVIEW',
  ACTIVATION_STATE_UNSPECIFIED = 'ACTIVATION_STATE_UNSPECIFIED'
}

export enum PointsTargetType {
  TARGET_TYPE_DISCORD = 'TARGET_TYPE_DISCORD',
  TARGET_TYPE_EMAIL = 'TARGET_TYPE_EMAIL',
  TARGET_TYPE_FCM = 'TARGET_TYPE_FCM',
  TARGET_TYPE_PLATFORM = 'TARGET_TYPE_PLATFORM',
  TARGET_TYPE_SLACK_CHANNEL = 'TARGET_TYPE_SLACK_CHANNEL',
  TARGET_TYPE_SMS = 'TARGET_TYPE_SMS',
  TARGET_TYPE_TELEGRAM = 'TARGET_TYPE_TELEGRAM',
  TARGET_TYPE_UNSPECIFIED = 'TARGET_TYPE_UNSPECIFIED',
  TARGET_TYPE_WEB3 = 'TARGET_TYPE_WEB3',
  TARGET_TYPE_WEBHOOK = 'TARGET_TYPE_WEBHOOK'
}

/** Public information about a Dapp */
export type PublicDappInfo = {
  __typename?: 'PublicDappInfo';
  /** A brief description of the Dapp */
  description?: Maybe<Scalars['String']>;
  /** The URL of the Dapp's icon */
  iconUrl?: Maybe<Scalars['String']>;
  /** The URL of the Dapp's logo */
  logoUrl?: Maybe<Scalars['String']>;
  /** The name of the Dapp */
  name?: Maybe<Scalars['String']>;
  /** The URL of the Dapp's website */
  websiteUrl?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type PublicTopicsConnection = {
  __typename?: 'PublicTopicsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PublicTopicsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SubscribeableTopic>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PublicTopicsEdge = {
  __typename?: 'PublicTopicsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SubscribeableTopic;
};

/** Notifi Public UserTopic Post */
export type PublicUserTopicPost = {
  __typename?: 'PublicUserTopicPost';
  createdDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  topicName: Scalars['String'];
  variables: Array<KeyValuePairOfStringAndString>;
};

/** A connection to a list of items. */
export type PublicUserTopicPostConnection = {
  __typename?: 'PublicUserTopicPostConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PublicUserTopicPostEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<PublicUserTopicPost>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PublicUserTopicPostEdge = {
  __typename?: 'PublicUserTopicPostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PublicUserTopicPost>;
};

export type PublishFusionMessageInput = {
  /** The event type ID */
  eventTypeId: Scalars['String'];
  /** The specific wallets to target, if any */
  specificWallets?: InputMaybe<Array<KeyValuePairOfStringAndWalletBlockchainInput>>;
  /** The variables to send, in json. Must be an object */
  variablesJson: Scalars['String'];
};

export type PublishFusionMessageResult = {
  __typename?: 'PublishFusionMessageResult';
  /** The uuid of the enqueued event */
  eventUuid: Scalars['String'];
};

export type Regenerate2FaBackupCodesError = RequiresElevatedTokenError;

export type Regenerate2FaBackupCodesPayload = {
  __typename?: 'Regenerate2FABackupCodesPayload';
  backupCodes?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<Regenerate2FaBackupCodesError>>;
};

/** Object used to remove an existing bot */
export type RemoveBotInput = {
  /** Id of the bot to remove */
  id: Scalars['String'];
};

/** Object used to remove an email sender domain for a tenant */
export type RemoveEmailSenderDomainInput = {
  /** Id of the domain that is being removed. If it was the default, no default will be set after removal */
  domainNameId: Scalars['String'];
};

export type RemoveFusionEventFromFusionSourceInput = {
  /** The fusion event id to remove from the fusion source id */
  fusionEventId: Scalars['String'];
};

export type RemoveFusionEventFromFusionSourceResponse = {
  __typename?: 'RemoveFusionEventFromFusionSourceResponse';
  /** The updated fusion source after the fusion event has been removed. */
  fusionSource: FusionSourceWithCursor;
};

export type RemoveServiceIdentifierInput = {
  sid: Scalars['String'];
};

export type RemoveServiceIdentifierPayload = {
  __typename?: 'RemoveServiceIdentifierPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Remove a Source from a SourceGroup. Id and AccountAddress/WalletBlockchain are mutually exclusive. If SourceId is set, other parameters will be ignored. Setting WalletAddress requires SourceType to be set */
export type RemoveSourceFromSourceGroupInput = {
  /** Id of the SourceGroup object returned from CreateSourceGroup mutation or SourceGroup query */
  sourceGroupId: Scalars['String'];
  /** Id of the Source object returned from CreateSource or Sources */
  sourceId?: InputMaybe<Scalars['String']>;
  /** SourceType associated to WalletAddress */
  sourceType?: InputMaybe<SourceType>;
  /** For chains where public keys are rotatable and not tied to the lifetime of the account, use the accountId. Use public key for all other chains */
  walletAddress?: InputMaybe<Scalars['String']>;
};

export type RemoveTotp2FaError = RequiresElevatedTokenError;

export type RemoveTotp2FaPayload = {
  __typename?: 'RemoveTOTP2FAPayload';
  errors?: Maybe<Array<RemoveTotp2FaError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Object used to delete a user from the admin panel. */
export type RemoveTenantUserRolesInput = {
  /** Primary email address for account. */
  email: Scalars['String'];
  /** List of roles to remove from the user */
  roles: Array<Scalars['String']>;
};

export enum RequiredParserVariableType {
  /** Base58 wallets (Solana, Bitcoin) */
  BASE58_WALLET = 'BASE58_WALLET',
  /** Bech32 wallet (any prefix) */
  BECH32_WALLET = 'BECH32_WALLET',
  /** EVM wallet */
  EVM_WALLET = 'EVM_WALLET',
  /** Decimal value that can fit in a double precision float */
  FLOAT = 'FLOAT',
  /** Hexidecimal starting with 0x */
  HEX = 'HEX',
  /** Integer value that can fit in a signed 64bit integer */
  INTEGER = 'INTEGER',
  /** Any string */
  STRING = 'STRING',
  UNSPECIFIED = 'UNSPECIFIED'
}

export type RequiredVariable = {
  __typename?: 'RequiredVariable';
  variableDescription: Scalars['String'];
  variableName: Scalars['String'];
  variableType: RequiredParserVariableType;
};

/** Thrown if this API is called without an elevated session token */
export type RequiresElevatedTokenError = Error & {
  __typename?: 'RequiresElevatedTokenError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** Data about the rewards for an offer */
export type RewardData = {
  __typename?: 'RewardData';
  calculateSize: Scalars['Int'];
  clone?: Maybe<RewardData>;
  /** Points reward data */
  points?: Maybe<PointsDataGrpc>;
  rewardDetailsCase: RewardDetailsOneofCase;
  /** Tokens reward data */
  tokens?: Maybe<TokensDataGrpc>;
};

export enum RewardDetailsOneofCase {
  NONE = 'NONE',
  POINTS = 'POINTS',
  TOKENS = 'TOKENS'
}

/** Input for specifying conditions when querying reward offers */
export type RewardOffersConditionsInput = {
  /** Blockchain type for filtering offers */
  chainName?: InputMaybe<WalletBlockchain>;
};

/** A connection to a list of items. */
export type RewardOffersConnection = {
  __typename?: 'RewardOffersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RewardOffersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OfferResponse>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RewardOffersEdge = {
  __typename?: 'RewardOffersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OfferResponse;
};

/** Input for querying reward offers */
export type RewardOffersInput = {
  /** Optional conditions for filtering offers */
  conditions?: InputMaybe<RewardOffersConditionsInput>;
  /** Optional end date for filtering offers */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** Optional ID of a specific reward offer */
  id?: InputMaybe<Scalars['String']>;
  /** Optional name for filtering offers */
  name?: InputMaybe<Scalars['String']>;
  /** Optional start date for filtering offers */
  startDate?: InputMaybe<Scalars['DateTime']>;
};

/** Object used to query the public topics */
export type SendConversationMessageInput = {
  /** Conversation identifier */
  conversationId: Scalars['String'];
  /** Individual message of a conversation */
  message: Scalars['String'];
};

/** Describes a message to be sent directly to a user. */
export type SendMessageInput = {
  /** Message data to be sent. All but RawMessage require a JSON formatted object. See individual MessageTypes for requirements. Inlcude JSON members that your template requires. */
  message?: InputMaybe<Scalars['String']>;
  /** The key of the message. Multiple calls with the same key will be deduplicated. */
  messageKey?: InputMaybe<Scalars['String']>;
  /** Describes the message type of Message. RawMessage is a passthrough. SimpleHealthThreshold requires a single member, 'value', in range of [0, 100]. Include any other members required for your template. */
  messageType: MessageType;
  /** Blockchain the wallet belongs to. */
  walletBlockchain: WalletBlockchain;
  /** Target wallet address. */
  walletPublicKey?: InputMaybe<Scalars['String']>;
};

/** Describes the Target to send a confirmation request for */
export type SendTargetConfirmationRequestInput = {
  /** ID of the Target. Currently only EmailTargets are supported */
  targetId?: InputMaybe<Scalars['String']>;
};

/** An object describing credentials that an autonomous service can use to authenticate with Notifi APIs */
export type ServiceIdentifierOutput = {
  __typename?: 'ServiceIdentifierOutput';
  /** The SID portion of the service identifier */
  id: Scalars['String'];
  /** Whether or not the service identifier can be used to authenticate with Notifi */
  isActive: Scalars['Boolean'];
  /** @deprecated Secrets will not be returned in future versions */
  secret?: Maybe<Scalars['String']>;
  /** The internal ID of the user the service identifier authenticates */
  userId: Scalars['String'];
};

/** Input for service authentication using a SID/Secret pair */
export type ServiceLogInInput = {
  /** Secret for service auth params */
  secret: Scalars['String'];
  /** SID for service auth params */
  sid: Scalars['String'];
};

export type SidSecretTwoFactorChallengeAnswerInput = {
  secret: Scalars['String'];
  sid: Scalars['String'];
};

export type SlackChannel = {
  __typename?: 'SlackChannel';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Slack Channel Target */
export type SlackChannelTarget = {
  __typename?: 'SlackChannelTarget';
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  slackChannelName?: Maybe<Scalars['String']>;
  slackWorkspaceName?: Maybe<Scalars['String']>;
  verificationLink?: Maybe<Scalars['String']>;
  verificationStatus: SlackTargetStatus;
  webhookVerificationLink?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type SlackChannelTargetsConnection = {
  __typename?: 'SlackChannelTargetsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SlackChannelTargetsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SlackChannelTarget>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SlackChannelTargetsEdge = {
  __typename?: 'SlackChannelTargetsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SlackChannelTarget;
};

export enum SlackTargetStatus {
  MISSING_CHANNEL = 'MISSING_CHANNEL',
  MISSING_PERMISSIONS = 'MISSING_PERMISSIONS',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED'
}

export type SmsTarget = {
  __typename?: 'SmsTarget';
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  isConfirmed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
};

/** Notifi Source */
export type Source = {
  __typename?: 'Source';
  activeAlertCount: Scalars['Long'];
  applicableFilters?: Maybe<Array<Maybe<Filter>>>;
  blockchainAddress: Scalars['String'];
  createdDate: Scalars['DateTime'];
  fusionEventTypeId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type: SourceType;
  updatedDate: Scalars['DateTime'];
};

export type SourceGroup = {
  __typename?: 'SourceGroup';
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  sources?: Maybe<Array<Maybe<Source>>>;
  updatedDate: Scalars['DateTime'];
};

/** Object used to create or bulk update a SourceGroup. Use AddSourceToSourceGroup or RemoveSourceFromSourceGroup for individual source updates */
export type SourceGroupInput = {
  /** Id of the SourceGroup */
  id?: InputMaybe<Scalars['String']>;
  /** Friendly name of SourceGroup */
  name?: InputMaybe<Scalars['String']>;
  /** Ids from previously created Sources */
  sourceIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum SourceType {
  ACALA_WALLET = 'ACALA_WALLET',
  AGORIC_WALLET = 'AGORIC_WALLET',
  APTOS_WALLET = 'APTOS_WALLET',
  ARBITRUM_WALLET = 'ARBITRUM_WALLET',
  ARCHWAY_WALLET = 'ARCHWAY_WALLET',
  ARCH_WALLET = 'ARCH_WALLET',
  AVALANCHE_WALLET = 'AVALANCHE_WALLET',
  AXELAR_WALLET = 'AXELAR_WALLET',
  BASE_WALLET = 'BASE_WALLET',
  BENQI = 'BENQI',
  BERACHAIN_WALLET = 'BERACHAIN_WALLET',
  BINANCE_WALLET = 'BINANCE_WALLET',
  BITCOIN_WALLET = 'BITCOIN_WALLET',
  BLAST_WALLET = 'BLAST_WALLET',
  BONFIDA_NAME_AUCTIONING = 'BONFIDA_NAME_AUCTIONING',
  BONFIDA_NAME_OFFERS = 'BONFIDA_NAME_OFFERS',
  BROADCAST = 'BROADCAST',
  CELESTIA_WALLET = 'CELESTIA_WALLET',
  CELO_WALLET = 'CELO_WALLET',
  COIN_PRICE_CHANGES = 'COIN_PRICE_CHANGES',
  COSMOS_WALLET = 'COSMOS_WALLET',
  DELTA_PRIME = 'DELTA_PRIME',
  DELTA_PRIME_LENDING_RATES = 'DELTA_PRIME_LENDING_RATES',
  DIRECT_PUSH = 'DIRECT_PUSH',
  DYDX_WALLET = 'DYDX_WALLET',
  DYMENSION_WALLET = 'DYMENSION_WALLET',
  ELYS_WALLET = 'ELYS_WALLET',
  ETHEREUM_WALLET = 'ETHEREUM_WALLET',
  FUSION_SOURCE = 'FUSION_SOURCE',
  HAWK_SIGHT = 'HAWK_SIGHT',
  HEDGE_PROTOCOL = 'HEDGE_PROTOCOL',
  KAVA_WALLET = 'KAVA_WALLET',
  LINEA_WALLET = 'LINEA_WALLET',
  MANTA_WALLET = 'MANTA_WALLET',
  MANTLE_WALLET = 'MANTLE_WALLET',
  METAPLEX_AUCTION_HOUSE = 'METAPLEX_AUCTION_HOUSE',
  MONAD_WALLET = 'MONAD_WALLET',
  MOVEMENT_WALLET = 'MOVEMENT_WALLET',
  NEUTRON_WALLET = 'NEUTRON_WALLET',
  NOTIFI_CHAT = 'NOTIFI_CHAT',
  OPTIMISM_WALLET = 'OPTIMISM_WALLET',
  ORAI_WALLET = 'ORAI_WALLET',
  ORCA = 'ORCA',
  PERSISTENCE_WALLET = 'PERSISTENCE_WALLET',
  POLYGON_WALLET = 'POLYGON_WALLET',
  PORT_FINANCE = 'PORT_FINANCE',
  REALM_PROPOSALS = 'REALM_PROPOSALS',
  SCROLL_WALLET = 'SCROLL_WALLET',
  SHARKY_PROTOCOL = 'SHARKY_PROTOCOL',
  SNAPSHOT = 'SNAPSHOT',
  SOLANA_BONFIDA_AUCTION = 'SOLANA_BONFIDA_AUCTION',
  SOLANA_JABBER_V1 = 'SOLANA_JABBER_V1',
  SOLANA_METAPLEX_AUCTION = 'SOLANA_METAPLEX_AUCTION',
  SOLANA_SNOWFLAKE = 'SOLANA_SNOWFLAKE',
  SOLANA_WALLET = 'SOLANA_WALLET',
  SUI_WALLET = 'SUI_WALLET',
  TERRA_WALLET = 'TERRA_WALLET',
  THE_ROOT_NETWORK = 'THE_ROOT_NETWORK',
  TOPAZ = 'TOPAZ',
  TRIBECA_PROPOSALS = 'TRIBECA_PROPOSALS',
  XION_WALLET = 'XION_WALLET',
  XMTP = 'XMTP',
  ZKSYNC = 'ZKSYNC',
  ZKSYNC_WALLET = 'ZKSYNC_WALLET'
}

/** A connection to a list of items. */
export type SourcesConnection = {
  __typename?: 'SourcesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SourcesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Source>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SourcesEdge = {
  __typename?: 'SourcesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Source;
};

export type SpecificWalletTarget = {
  __typename?: 'SpecificWalletTarget';
  address?: Maybe<Scalars['String']>;
  blockchainType: BlockchainType;
  calculateSize: Scalars['Int'];
  clone?: Maybe<SpecificWalletTarget>;
};

export type StateChangedEvent = NotificationHistoryStateChangedEvent | TargetStateChangedEvent;

/** Statistics for a specific target type in the campaign. */
export type StatisticsByTarget = {
  __typename?: 'StatisticsByTarget';
  /** The number of messages closed by recipients for this target type. */
  closed: Scalars['Int'];
  /** The number of messages successfully delivered for this target type. */
  delivered: Scalars['Int'];
  /** The number of messages opened by recipients for this target type. */
  opened: Scalars['Int'];
  /** The number of messages sent for this target type. */
  sent: Scalars['Int'];
  /** The identifier of the target type. */
  targetType: Scalars['String'];
};

export enum StorageType {
  INTERNAL_BLOB = 'INTERNAL_BLOB',
  INTERNAL_CACHE = 'INTERNAL_CACHE',
  INTERNAL_DB = 'INTERNAL_DB',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** A public topic that can be subscribed. */
export type SubscribeableTopic = {
  __typename?: 'SubscribeableTopic';
  /** The user-friendly name of this topic */
  displayName?: Maybe<Scalars['String']>;
  /** The topic identifier */
  topicName?: Maybe<Scalars['String']>;
};

export type Totp2FaRequiredForEmailLoginsPayload = {
  __typename?: 'TOTP2FARequiredForEmailLoginsPayload';
  totp2faRequired: Scalars['Boolean'];
};

export type Totp2FaStatusPayload = {
  __typename?: 'TOTP2FAStatusPayload';
  /** Whether or not your account has TOTP 2FA set up */
  isAdded: Scalars['Boolean'];
  /** Whether Notifi will ask you for your 2FA code, if you have one, in order to log in */
  isEnforced: Scalars['Boolean'];
  /** Whether your Dapp requires you to have 2FA set up in order to log in */
  isRequired: Scalars['Boolean'];
};

export type TargetAssignedToExistingTargetGroupError = Error & {
  __typename?: 'TargetAssignedToExistingTargetGroupError';
  message: Scalars['String'];
};

export enum TargetDeliveryStatus {
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  OPENED = 'OPENED',
  SENT = 'SENT',
  UNSPECIFIED = 'UNSPECIFIED'
}

export type TargetDoesNotExistError = Error & {
  __typename?: 'TargetDoesNotExistError';
  message: Scalars['String'];
};

/** Notifi TargetGroup */
export type TargetGroup = {
  __typename?: 'TargetGroup';
  alerts?: Maybe<Array<Maybe<Alert>>>;
  createdDate: Scalars['DateTime'];
  discordTargets?: Maybe<Array<Maybe<DiscordTarget>>>;
  emailTargets?: Maybe<Array<Maybe<EmailTarget>>>;
  fcmTargets?: Maybe<Array<Maybe<FcmTarget>>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  slackChannelTargets?: Maybe<Array<Maybe<SlackChannelTarget>>>;
  smsTargets?: Maybe<Array<Maybe<SmsTarget>>>;
  telegramTargets?: Maybe<Array<Maybe<TelegramTarget>>>;
  updatedDate: Scalars['DateTime'];
  web3Targets?: Maybe<Array<Maybe<Web3Target>>>;
  webPushTargets?: Maybe<Array<Maybe<WebPushTarget>>>;
  webhookTargets?: Maybe<Array<Maybe<WebhookTarget>>>;
};

/** Object used to create or update a TargetGroup. */
export type TargetGroupInput = {
  /** Ids from previously created DiscordTargets. */
  discordTargetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Ids from previously created EmailTargets. */
  emailTargetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Ids from previously created FcmTargets. */
  fcmTargetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Id of the TargetGroup. */
  id?: InputMaybe<Scalars['String']>;
  /** Friendly name of TargetGroup. */
  name?: InputMaybe<Scalars['String']>;
  /** Ids from previously created SlackChannelTargets. */
  slackChannelTargetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Ids from previously created SmsTargets. */
  smsTargetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Ids from previously created TelegramTargets. */
  telegramTargetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Ids from previously created SlackChannelTargets. */
  web3TargetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Ids from previously created WebPushTargets. */
  webPushTargetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Ids from previously created WebhookTargets. */
  webhookTargetIds?: InputMaybe<Array<Scalars['String']>>;
};

export type TargetLimitExceededError = Error & {
  __typename?: 'TargetLimitExceededError';
  message: Scalars['String'];
};

export type TargetStateChangedEvent = {
  __typename?: 'TargetStateChangedEvent';
  targetId: Scalars['String'];
  targetType: TargetType;
  timestamp: Scalars['DateTime'];
};

export enum TargetType {
  BROWSER_PUSH = 'BROWSER_PUSH',
  DISCORD = 'DISCORD',
  EMAIL = 'EMAIL',
  FCM = 'FCM',
  PLATFORM = 'PLATFORM',
  SLACK_CHANNEL = 'SLACK_CHANNEL',
  SMS = 'SMS',
  TELEGRAM = 'TELEGRAM',
  WEB3 = 'WEB3',
  WEBHOOK = 'WEBHOOK'
}

export type TelegramTarget = {
  __typename?: 'TelegramTarget';
  /** Used to confirm the Telegram chat. User must click url and start bot. */
  confirmationUrl?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  isAccountOwner: Scalars['Boolean'];
  isConfirmed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  telegramId: Scalars['String'];
  updatedDate: Scalars['DateTime'];
};

export type TelegramUserRegistrationExecutionEndpoint = {
  __typename?: 'TelegramUserRegistrationExecutionEndpoint';
  groupUserActionUrl: Scalars['String'];
  targetType: TargetType;
  userActionUrl: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  message: Scalars['String'];
  templateName: Scalars['String'];
  templateType: TemplateType;
  tenantId: Scalars['String'];
  updatedDate: Scalars['DateTime'];
};

export enum TemplateType {
  BROWSER_PUSH = 'BROWSER_PUSH',
  BROWSER_PUSH_SUBJECT = 'BROWSER_PUSH_SUBJECT',
  DISCORD = 'DISCORD',
  EMAIL = 'EMAIL',
  EMAIL_SUBJECT = 'EMAIL_SUBJECT',
  EVENT_DETAIL_BODY = 'EVENT_DETAIL_BODY',
  EVENT_DETAIL_TITLE = 'EVENT_DETAIL_TITLE',
  FCM = 'FCM',
  FCM_SUBJECT = 'FCM_SUBJECT',
  SLACK_CHANNEL = 'SLACK_CHANNEL',
  SMS = 'SMS',
  TELEGRAM = 'TELEGRAM',
  WEB3 = 'WEB3',
  WEBHOOK = 'WEBHOOK'
}

/** Notifi tenant information */
export type Tenant = {
  __typename?: 'Tenant';
  dappId: Scalars['String'];
  name: Scalars['String'];
};

export type TenantActiveAlertChangeEvent = ActiveAlertCreatedEvent | ActiveAlertDeletedEvent;

/** The response for a tenant config query */
export type TenantConfig = {
  __typename?: 'TenantConfig';
  createdDate: Scalars['DateTime'];
  dataJson: Scalars['String'];
  fusionEvents?: Maybe<Array<Maybe<FusionEventDescriptor>>>;
  id: Scalars['String'];
  type: TenantConfigType;
  updatedDate: Scalars['DateTime'];
};

export enum TenantConfigType {
  INTERCOM_CARD = 'INTERCOM_CARD',
  SUBSCRIPTION_CARD = 'SUBSCRIPTION_CARD'
}

/** A connection to a list of items. */
export type TenantConfigsConnection = {
  __typename?: 'TenantConfigsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TenantConfigsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TenantConfig>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TenantConfigsEdge = {
  __typename?: 'TenantConfigsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TenantConfig;
};

/** Notifi Tenant Connected Wallet */
export type TenantConnectedWallet = {
  __typename?: 'TenantConnectedWallet';
  address?: Maybe<Scalars['String']>;
  user?: Maybe<TenantUser>;
  walletBlockchain: WalletBlockchain;
};

/** A connection to a list of items. */
export type TenantConnectedWalletConnection = {
  __typename?: 'TenantConnectedWalletConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TenantConnectedWalletEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<TenantConnectedWallet>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TenantConnectedWalletEdge = {
  __typename?: 'TenantConnectedWalletEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TenantConnectedWallet>;
};

export type TenantEntityChangeEvent = AlertCreatedEvent | AlertDeletedEvent | UserCreatedEvent;

export type TenantFeatureFlag = {
  __typename?: 'TenantFeatureFlag';
  /** Tenant feature flag name. */
  name: Scalars['String'];
  /** Tenant feature flag value. Value can be a string or a json string. */
  value: Scalars['String'];
};

export type TenantFeatureFlagInput = {
  /** Query will fetch the feature flag value for the given name. */
  tenantFeatureFlagName: Scalars['String'];
};

export type TenantStatisticalReportOutput = {
  __typename?: 'TenantStatisticalReportOutput';
  endTime: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  reportType: TenantStatisticalReportType;
  startTime: Scalars['DateTime'];
};

export enum TenantStatisticalReportType {
  ACTIVITY_REPORT = 'ACTIVITY_REPORT',
  MESSAGE_DELIVERY_REPORT = 'MESSAGE_DELIVERY_REPORT',
  NOTIFICATIONS_REPORT = 'NOTIFICATIONS_REPORT',
  SUBSCRIBER_LIST = 'SUBSCRIBER_LIST'
}

/** Notifi Tenant Target Group */
export type TenantTargetGroup = {
  __typename?: 'TenantTargetGroup';
  hasVerifiedDiscord: Scalars['Boolean'];
  hasVerifiedEmails: Scalars['Boolean'];
  hasVerifiedFcm: Scalars['Boolean'];
  hasVerifiedSms: Scalars['Boolean'];
  hasVerifiedTelegram: Scalars['Boolean'];
  hasVerifiedWeb3: Scalars['Boolean'];
  hasVerifiedWebhook: Scalars['Boolean'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

/** Notifi Tenant User */
export type TenantUser = {
  __typename?: 'TenantUser';
  alerts?: Maybe<Array<Maybe<TenantUserAlert>>>;
  connectedWallets?: Maybe<Array<Maybe<TenantConnectedWallet>>>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  userName?: Maybe<Scalars['String']>;
};

/** Notifi Tenant User Alert */
export type TenantUserAlert = {
  __typename?: 'TenantUserAlert';
  filter: Filter;
  filterOptions: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  sourceGroup: SourceGroup;
  targetGroup?: Maybe<TenantTargetGroup>;
  user?: Maybe<TenantUser>;
};

/** A connection to a list of items. */
export type TenantUserConnection = {
  __typename?: 'TenantUserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TenantUserEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<TenantUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TenantUserEdge = {
  __typename?: 'TenantUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TenantUser>;
};

/** A connection to a list of items. */
export type TenantUsersConnection = {
  __typename?: 'TenantUsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TenantUsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<TenantUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TenantUsersEdge = {
  __typename?: 'TenantUsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TenantUser>;
};

/** Object used to query TenantUsers. */
export type TenantUsersInput = {
  /** Filter roles for admin panel */
  filterRoles?: InputMaybe<Array<Scalars['String']>>;
};

export type Timestamp = {
  __typename?: 'Timestamp';
  calculateSize: Scalars['Int'];
  clone?: Maybe<Timestamp>;
  nanos: Scalars['Int'];
  seconds: Scalars['Long'];
  toDateTime: Scalars['DateTime'];
  toDateTimeOffset: Scalars['DateTime'];
  toDiagnosticString?: Maybe<Scalars['String']>;
};

/** Data about token rewards */
export type TokensDataGrpc = {
  __typename?: 'TokensDataGrpc';
  /** Amount of tokens to be rewarded */
  amount: Scalars['Long'];
  calculateSize: Scalars['Int'];
  clone?: Maybe<TokensDataGrpc>;
  /** Symbol or identifier of the token */
  symbol: Scalars['String'];
  /** Total supply of tokens (optional) */
  totalSupply?: Maybe<Scalars['Long']>;
};

/** A connection to a list of items. */
export type TopicsConnection = {
  __typename?: 'TopicsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TopicsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<UserTopic>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TopicsEdge = {
  __typename?: 'TopicsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: UserTopic;
};

/** A connection to a list of items. */
export type TotalMessagesPerTopicByTenantConnection = {
  __typename?: 'TotalMessagesPerTopicByTenantConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TotalMessagesPerTopicByTenantEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<GetTotalMessagesPerTopicByTenantPayload>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TotalMessagesPerTopicByTenantEdge = {
  __typename?: 'TotalMessagesPerTopicByTenantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GetTotalMessagesPerTopicByTenantPayload;
};

export type TotpTwoFactorChallengeAnswerInput = {
  totpCode: Scalars['String'];
};

export type TwoFactorChallengeAnswerInput = {
  backupCodeAnswer?: InputMaybe<BackupCodeTwoFactorChallengeAnswerInput>;
  sidSecretAnswer?: InputMaybe<SidSecretTwoFactorChallengeAnswerInput>;
  totpAnswer?: InputMaybe<TotpTwoFactorChallengeAnswerInput>;
};

export type UnauthorizedAccessError = Error & {
  __typename?: 'UnauthorizedAccessError';
  message: Scalars['String'];
};

export type UnauthorizedXmtpAccountError = Error & {
  __typename?: 'UnauthorizedXmtpAccountError';
  message: Scalars['String'];
};

export type UnexpectedError = Error & {
  __typename?: 'UnexpectedError';
  message: Scalars['String'];
};

export type UnsubscribeInputTypeInput = {
  token: Scalars['String'];
};

/** A response from unsubscribing a target. */
export type UnsubscribeResult = {
  __typename?: 'UnsubscribeResult';
  targetValue: Scalars['String'];
  tenantName: Scalars['String'];
};

export type UnsupportedWeb3ProtocolError = Error & {
  __typename?: 'UnsupportedWeb3ProtocolError';
  message: Scalars['String'];
};

export type UnverifiedXmtpTargetError = Error & {
  __typename?: 'UnverifiedXmtpTargetError';
  message: Scalars['String'];
};

/** Object used to update an existing bot */
export type UpdateBotInput = {
  /** Bot credentials to be used when sending messages */
  botCredentials?: InputMaybe<Scalars['String']>;
  /** Existing bot id */
  id: Scalars['String'];
  /** Unique name of the bot. This may also be used when users are shown the bot name such as in Telegram or Discord */
  name?: InputMaybe<Scalars['String']>;
};

/** Object used to update the CORS policy for a tenant */
export type UpdateCorsPolicyInput = {
  /** Non-wildcard domains that are allowed to be used for CORS validation */
  allowedDomains: Array<Scalars['String']>;
};

/** Object used to update the default email sender name for a tenant */
export type UpdateDefaultEmailSenderDisplayNameInput = {
  /** Default sender display name to use. Omit to remove */
  defaultDisplayName?: InputMaybe<Scalars['String']>;
};

/** Object used to update the default email sender domain for a tenant */
export type UpdateDefaultEmailSenderDomainInput = {
  /** Id of the default sender domain to use. Omit to remove */
  defaultDomainId?: InputMaybe<Scalars['String']>;
};

/** Object used to update the default email sender name for a tenant */
export type UpdateDefaultEmailSenderNameInput = {
  /** Default sender name to use. Omit to remove */
  defaultName?: InputMaybe<Scalars['String']>;
};

/** Object used to update the tenant's discord bot configuration. */
export type UpdateDiscordBotConfigurationInput = {
  /** The Discord server id in which the Discord bot resides */
  discordGuildId: Scalars['String'];
  /** The invite link to join the tenant's Discord server. */
  discordServerInviteLink: Scalars['String'];
  /** The redirect site for OAuth2. This url is configured in Discord's developer portal */
  redirectUrl: Scalars['String'];
};

/** Object used to upsert values in ephemeral storage */
export type UpdateEphemeralStorageInput = {
  /** Entries to update the persistent storage with */
  entries: Array<EphemeralStorageEntryInput>;
};

/** Result from UpdateEphemeralStorageResult mutation */
export type UpdateEphemeralStorageResult = {
  __typename?: 'UpdateEphemeralStorageResult';
  /** Entries that were updated */
  entries?: Maybe<Array<Maybe<EphemeralStorageEntry>>>;
};

export type UpdateFusionEventInput = {
  fusionEventId: Scalars['String'];
  metadataJson?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpdateFusionSourceInput = {
  /** Update the fusion source filter. NOTE: The update will not actually happen unless the source is activated after setting this. If the source is currently running, Deactivate, then Activate again after setting this */
  filter?: InputMaybe<Scalars['String']>;
  /** Update the fusion source with this id. */
  fusionSourceId: Scalars['String'];
  /** Update the fusion source name */
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateFusionSourceResponse = {
  __typename?: 'UpdateFusionSourceResponse';
  /** Operation ID that will be set on success of queuing the operation. You can use this ID in the future to either poll for status or listen to updates from subscriptions */
  asyncOperationId?: Maybe<Scalars['String']>;
  /**
   * The updated fusion source
   * @deprecated Do not use this anymore, as the operation is async and eventually consistent. Check for queued success by looking at AsyncOperationId, and then periodically poll or listen for async updates via subscriptions
   */
  fusionSource: FusionSourceWithCursor;
};

export type UpdateOpenRegistrationResponse = {
  __typename?: 'UpdateOpenRegistrationResponse';
  calculateSize: Scalars['Int'];
  clone?: Maybe<UpdateOpenRegistrationResponse>;
};

export type UpdateOpenUserRegistrationError = ArgumentError;

/** Object used to update an existing open user registration campaign allowing users to sign up for any notifications */
export type UpdateOpenUserRegistrationInput = {
  endTime: Scalars['DateTime'];
  /** ID of the campaign/link. This is used to help identify the respective campaign */
  openUserRegistrationCampaignId: Scalars['String'];
};

export type UpdateOpenUserRegistrationPayload = {
  __typename?: 'UpdateOpenUserRegistrationPayload';
  errors?: Maybe<Array<UpdateOpenUserRegistrationError>>;
  updateOpenRegistrationResponse?: Maybe<UpdateOpenRegistrationResponse>;
};

/** Object used to upsert values in persistent storage */
export type UpdatePersistentStorageInput = {
  /** Entries to update the persistent storage with */
  entries: Array<PersistentStorageEntryInput>;
};

/** Result from UpdatePersistentStorageResult mutation */
export type UpdatePersistentStorageResult = {
  __typename?: 'UpdatePersistentStorageResult';
  /** Entries that were updated */
  entries?: Maybe<Array<Maybe<PersistentStorageEntry>>>;
};

export type UpdateWebPushTargetError = TargetDoesNotExistError | UnexpectedError;

export type UpdateWebPushTargetInput = {
  auth: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['String'];
  p256dh: Scalars['String'];
};

export type UpdateWebPushTargetResponse = {
  __typename?: 'UpdateWebPushTargetResponse';
  errors?: Maybe<Array<UpdateWebPushTargetError>>;
  webPushTarget?: Maybe<WebPushTarget>;
};

/** Notifi User */
export type User = {
  __typename?: 'User';
  alerts?: Maybe<Array<Maybe<Alert>>>;
  authorization?: Maybe<Authorization>;
  email?: Maybe<Scalars['String']>;
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<CreateOrUpdateUserError>>;
  id?: Maybe<Scalars['String']>;
  profile?: Maybe<UserProfile>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  settings?: Maybe<UserSettings>;
};

/** Output for user actions that need to be performed */
export type UserActionsDataOutput = {
  __typename?: 'UserActionsDataOutput';
  /** Entity associated with the action */
  actionEntity: PointsOfferActionEntity;
  /** Verb describing the action */
  actionVerb: PointsOfferActionVerb;
  /** Identifier of the entity associated with the action */
  entityId: Scalars['String'];
};

/** An event emitted when a user is created in the tenant */
export type UserCreatedEvent = {
  __typename?: 'UserCreatedEvent';
  user?: Maybe<TenantUser>;
};

export type UserFeatureFlags = {
  __typename?: 'UserFeatureFlags';
  chatEnabled?: Maybe<Scalars['Boolean']>;
};

/** Object used to login/create an account. */
export type UserInput = {
  /** Primary email address for account. This will be used for verification and password resets. */
  email?: InputMaybe<Scalars['String']>;
  /** Password used for account. Must be at least 10 characters with at least 1 capital, 1 lower, 1 numeric and one symbol. */
  password?: InputMaybe<Scalars['String']>;
};

/** Output for user interactions that need to occur */
export type UserInteractionsDataOutput = {
  __typename?: 'UserInteractionsDataOutput';
  /** Verb describing the interaction */
  actionVerb: PointsOfferActionVerb;
  /** Delivery status of the target */
  targetDeliveryStatus: TargetDeliveryStatus;
};

/** Notifi user profile */
export type UserProfile = {
  __typename?: 'UserProfile';
  avatarData?: Maybe<Scalars['String']>;
  avatarDataType: AvatarDataType;
  id: Scalars['String'];
  preferredAddress?: Maybe<Scalars['String']>;
  preferredBlockchain: WalletBlockchain;
  preferredName?: Maybe<Scalars['String']>;
  tenantInfo?: Maybe<Tenant>;
};

/** Object used to update a user's profile. Only specified fields will be updated */
export type UserProfileInput = {
  /** Avatar data specifying the url or notifi stock avatar to use. Requires AvatarDataType */
  avatarData?: InputMaybe<Scalars['String']>;
  /** Avatar data type used to interpret the AvatarData. Requires AvatarData */
  avatarDataType?: InputMaybe<AvatarDataType>;
  /** The Preferred address to use. Requires PreferredBlockchain */
  preferredAddress?: InputMaybe<Scalars['String']>;
  /** The Preferred blockchain to use. Requires PreferredAddress */
  preferredBlockchain?: InputMaybe<WalletBlockchain>;
};

export type UserRegistrationExecutionEndpoint = EmailUserRegistrationExecutionEndpoint | TelegramUserRegistrationExecutionEndpoint;

/** User Settings */
export type UserSettings = {
  __typename?: 'UserSettings';
  detailedAlertHistoryEnabled: Scalars['Boolean'];
  ftuStage?: Maybe<Scalars['Int']>;
  userHasChatEnabled: Scalars['Boolean'];
};

/** Object used to update user settings. Only specified fields will be updated */
export type UserSettingsInput = {
  /** Allow Notifi to store details as part of alert history */
  detailedAlertHistoryEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The FTU stage to set */
  ftuStage?: InputMaybe<Scalars['Int']>;
  /** Enable the chat on the UI */
  userHasChatEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type UserTopic = {
  __typename?: 'UserTopic';
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  targetCollections?: Maybe<Array<Scalars['String']>>;
  targetTemplate?: Maybe<Scalars['String']>;
  topicName: Scalars['String'];
  updatedDate: Scalars['DateTime'];
};

export type VapidPublicKey = {
  __typename?: 'VapidPublicKey';
  publicKey: Scalars['String'];
};

/** A connection to a list of items. */
export type VapidPublicKeysConnection = {
  __typename?: 'VapidPublicKeysConnection';
  /** A list of edges. */
  edges?: Maybe<Array<VapidPublicKeysEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<VapidPublicKey>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VapidPublicKeysEdge = {
  __typename?: 'VapidPublicKeysEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: VapidPublicKey;
};

export type VerifyCbwTargetError = UnsupportedWeb3ProtocolError | UnverifiedXmtpTargetError | Web3TargetNotFoundError;

export type VerifyCbwTargetInput = {
  targetId: Scalars['String'];
};

export type VerifyCbwTargetResponse = {
  __typename?: 'VerifyCbwTargetResponse';
  errors?: Maybe<Array<VerifyCbwTargetError>>;
  web3Target?: Maybe<Web3Target>;
};

/** Object used to verify a new Discord target. */
export type VerifyDiscordTargetInput = {
  /** Code from OAuth2 */
  code: Scalars['String'];
  /** Discord target id */
  discordTargetId: Scalars['String'];
  /** Redirected Uri */
  redirectUri: Scalars['String'];
  /** Token value sent in verification message. */
  token?: InputMaybe<Scalars['String']>;
};

export type VerifySlackChannelTargetForWebhookInput = {
  /** Code from OAuth2 */
  code: Scalars['String'];
  slackChannelTargetId: Scalars['String'];
};

export type VerifySlackChannelTargetForWebhookResponse = {
  __typename?: 'VerifySlackChannelTargetForWebhookResponse';
  slackChannelTarget: SlackChannelTarget;
};

export type VerifySlackChannelTargetInput = {
  /** Code from OAuth2 */
  code: Scalars['String'];
  slackChannelTargetId: Scalars['String'];
};

export type VerifySlackChannelTargetResponse = {
  __typename?: 'VerifySlackChannelTargetResponse';
  slackChannelTarget: SlackChannelTarget;
};

/** Object used to verify a new Target. */
export type VerifyTargetInput = {
  /** Token value sent in verification message. */
  token?: InputMaybe<Scalars['String']>;
};

export type VerifyXmtpTargetError = InvalidXmtpConsentProofError | UnauthorizedXmtpAccountError | UnsupportedWeb3ProtocolError | Web3TargetNotFoundError;

export type VerifyXmtpTargetInput = {
  /** Xmtp wallet address */
  accountId: Scalars['String'];
  /** Consent proof to prove consent for a receiving address as outlined in XIP-43 */
  consentProofSignature: Scalars['String'];
  /** Special flag for Coinbase Wallet. Will be depreciated later. Set this to false if unsure. */
  isCBW?: InputMaybe<Scalars['Boolean']>;
  /** Timestamp in milliseconds since Unix epoch. January 1, 1970 (midnight UTC/GMT) */
  timestamp: Scalars['Long'];
  /** Specify the web3 target id that you will be verifying. */
  web3TargetId: Scalars['String'];
};

export type VerifyXmtpTargetResponse = {
  __typename?: 'VerifyXmtpTargetResponse';
  errors?: Maybe<Array<VerifyXmtpTargetError>>;
  web3Target?: Maybe<Web3Target>;
};

export type VerifyXmtpTargetViaXip42Error = UnauthorizedXmtpAccountError | UnsupportedWeb3ProtocolError | Web3TargetNotFoundError | XmtpAccountIsntConnectedWalletError;

export type VerifyXmtpTargetViaXip42Input = {
  /** Xmtp wallet address */
  accountId: Scalars['String'];
  /** The conversation topic created in the XMTP network. */
  conversationTopic: Scalars['String'];
  /** Specify the web3 target id that you will be verifying. */
  web3TargetId: Scalars['String'];
};

export type VerifyXmtpTargetViaXip42Response = {
  __typename?: 'VerifyXmtpTargetViaXip42Response';
  errors?: Maybe<Array<VerifyXmtpTargetViaXip42Error>>;
  web3Target?: Maybe<Web3Target>;
};

export enum WalletBlockchain {
  ACALA = 'ACALA',
  AGORIC = 'AGORIC',
  APTOS = 'APTOS',
  ARBITRUM = 'ARBITRUM',
  ARCH = 'ARCH',
  ARCHWAY = 'ARCHWAY',
  AVALANCHE = 'AVALANCHE',
  AXELAR = 'AXELAR',
  BASE = 'BASE',
  BERACHAIN = 'BERACHAIN',
  BINANCE = 'BINANCE',
  BITCOIN = 'BITCOIN',
  BLAST = 'BLAST',
  CELESTIA = 'CELESTIA',
  CELO = 'CELO',
  COSMOS = 'COSMOS',
  DYDX = 'DYDX',
  DYMENSION = 'DYMENSION',
  ELYS = 'ELYS',
  ETHEREUM = 'ETHEREUM',
  EVMOS = 'EVMOS',
  INJECTIVE = 'INJECTIVE',
  KAVA = 'KAVA',
  LINEA = 'LINEA',
  MANTA = 'MANTA',
  MANTLE = 'MANTLE',
  MONAD = 'MONAD',
  MOVEMENT = 'MOVEMENT',
  NEAR = 'NEAR',
  NEUTRON = 'NEUTRON',
  NIBIRU = 'NIBIRU',
  OFF_CHAIN = 'OFF_CHAIN',
  OPTIMISM = 'OPTIMISM',
  ORAI = 'ORAI',
  OSMOSIS = 'OSMOSIS',
  PERSISTENCE = 'PERSISTENCE',
  POLYGON = 'POLYGON',
  SCROLL = 'SCROLL',
  SOLANA = 'SOLANA',
  SUI = 'SUI',
  THE_ROOT_NETWORK = 'THE_ROOT_NETWORK',
  XION = 'XION',
  ZKSYNC = 'ZKSYNC'
}

export type WalletsActivityReportEventDetails = {
  __typename?: 'WalletsActivityReportEventDetails';
  providerName: Scalars['String'];
  sourceLink: Scalars['String'];
  type: WalletsActivityReportType;
  wallets: Array<WalletsActivityReportWallet>;
};

export enum WalletsActivityReportType {
  MOST_ACTIVE = 'MOST_ACTIVE'
}

export type WalletsActivityReportWallet = {
  __typename?: 'WalletsActivityReportWallet';
  address: Scalars['String'];
  maxPurchase1Day?: Maybe<Scalars['String']>;
  maxPurchaseImgUrl?: Maybe<Scalars['String']>;
  maxPurchaseName?: Maybe<Scalars['String']>;
  maxPurchaseTokenAddress?: Maybe<Scalars['String']>;
  volume1Day: Scalars['String'];
};

export enum Web3AuthType {
  COSMOS_ADR36 = 'COSMOS_ADR36',
  COSMOS_AUTHZ_GRANT = 'COSMOS_AUTHZ_GRANT',
  EIP1271 = 'EIP1271',
  ETHEREUM_PERSONAL_SIGN = 'ETHEREUM_PERSONAL_SIGN',
  NEAR_SIGN_MESSAGE = 'NEAR_SIGN_MESSAGE',
  SOLANA_SIGN_MESSAGE = 'SOLANA_SIGN_MESSAGE',
  SUI_SIGNED_MESSAGE = 'SUI_SIGNED_MESSAGE'
}

/** Web3 Target */
export type Web3Target = {
  __typename?: 'Web3Target';
  accountId?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  isConfirmed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  senderAddress?: Maybe<Scalars['String']>;
  targetProtocol: Web3TargetProtocol;
  updatedDate: Scalars['DateTime'];
  walletBlockchain: WalletBlockchain;
};

export type Web3TargetNotFoundError = Error & {
  __typename?: 'Web3TargetNotFoundError';
  message: Scalars['String'];
};

export enum Web3TargetProtocol {
  XMTP = 'XMTP'
}

/** A connection to a list of items. */
export type Web3TargetsConnection = {
  __typename?: 'Web3TargetsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Web3TargetsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Web3Target>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type Web3TargetsEdge = {
  __typename?: 'Web3TargetsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Web3Target;
};

/** Web Push Target */
export type WebPushTarget = {
  __typename?: 'WebPushTarget';
  createdDate: Scalars['DateTime'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type WebPushTargetsConnection = {
  __typename?: 'WebPushTargetsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<WebPushTargetsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<WebPushTarget>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type WebPushTargetsEdge = {
  __typename?: 'WebPushTargetsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: WebPushTarget;
};

export enum WebhookPayloadFormat {
  BROWSER_PUSH_CHROME = 'BROWSER_PUSH_CHROME',
  BROWSER_PUSH_MOZILLA = 'BROWSER_PUSH_MOZILLA',
  BROWSER_PUSH_NOTIFI = 'BROWSER_PUSH_NOTIFI',
  PAGER_DUTY = 'PAGER_DUTY',
  RAW = 'RAW'
}

export enum WebhookStatus {
  ERROR = 'ERROR',
  OK = 'OK',
  RATE_LIMITED = 'RATE_LIMITED',
  UNCONFIRMED = 'UNCONFIRMED'
}

export type WebhookTarget = {
  __typename?: 'WebhookTarget';
  createdDate: Scalars['DateTime'];
  format: WebhookPayloadFormat;
  headers?: Maybe<Array<KeyValuePairOfStringAndString>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  status: WebhookStatus;
  updatedDate: Scalars['DateTime'];
  url: Scalars['String'];
};

export type XmtpAccountIsntConnectedWalletError = Error & {
  __typename?: 'XmtpAccountIsntConnectedWalletError';
  message: Scalars['String'];
};

export type AuthorizationFragmentFragment = { __typename?: 'Authorization', token: string, expiry: string };

export type FusionEventDataFragment = { __typename?: 'FusionEventData', id: string, name: string, metadata: string, createdDate: string, updatedDate: string, archivedDate?: string | undefined, fusionEventCategory: FusionEventCategory, fusionModules: Array<{ __typename?: 'FusionModuleData', id: string, tenantId: string, moduleType: FusionModuleType, storageType: StorageType, expectedMetadataOutputSchema: string, validateMetadataOutput: boolean, fusionEventId: string, createdDate: string, archivedDate?: string | undefined, active: boolean }>, fusionSource?: { __typename?: 'FusionSourceWithCursor', id: string, name: string, blockchainSource: WalletBlockchain, filter: string, createdDate: string, updatedDate: string, fusionEventTypeIds?: Array<string> | undefined, archivedDate?: string | undefined, version: any, cursor?: { __typename?: 'CursorEntry', lastExecutedTimestamp: string, sourceState: FusionSourceState, cursorValue: string } | undefined } | undefined };

export type CreateFusionSourceResponseFragment = { __typename?: 'CreateFusionSourceResponse', fusionSource: { __typename?: 'FusionSourceWithCursor', id: string, name: string, version: any, archivedDate?: string | undefined, blockchainSource: WalletBlockchain, filter: string, fusionEventTypeIds?: Array<string> | undefined, createdDate: string, updatedDate: string } };

export type FusionSourceWithCursorFragment = { __typename?: 'FusionSourceWithCursor', id: string, name: string, version: any, archivedDate?: string | undefined, blockchainSource: WalletBlockchain, filter: string, fusionEventTypeIds?: Array<string> | undefined, createdDate: string, updatedDate: string, cursor?: { __typename?: 'CursorEntry', lastExecutedTimestamp: string, sourceState: FusionSourceState, cursorValue: string } | undefined };

export type UpdateFusionSourceResponseFragment = { __typename?: 'UpdateFusionSourceResponse', fusionSource: { __typename?: 'FusionSourceWithCursor', id: string, name: string, version: any, archivedDate?: string | undefined, blockchainSource: WalletBlockchain, filter: string, fusionEventTypeIds?: Array<string> | undefined, createdDate: string, updatedDate: string } };

export type AddFusionEventToFusionSourceResponseFragment = { __typename?: 'AddFusionEventToFusionSourceResponse', fusionSource: { __typename?: 'FusionSourceWithCursor', id: string, name: string, version: any, archivedDate?: string | undefined, blockchainSource: WalletBlockchain, filter: string, fusionEventTypeIds?: Array<string> | undefined, createdDate: string, updatedDate: string, cursor?: { __typename?: 'CursorEntry', lastExecutedTimestamp: string, sourceState: FusionSourceState, cursorValue: string } | undefined } };

export type RemoveFusionEventFromFusionSourceResponseFragment = { __typename?: 'RemoveFusionEventFromFusionSourceResponse', fusionSource: { __typename?: 'FusionSourceWithCursor', id: string, name: string, version: any, archivedDate?: string | undefined, blockchainSource: WalletBlockchain, filter: string, fusionEventTypeIds?: Array<string> | undefined, createdDate: string, updatedDate: string, cursor?: { __typename?: 'CursorEntry', lastExecutedTimestamp: string, sourceState: FusionSourceState, cursorValue: string } | undefined } };

export type FusionExecutionDetailsFragment = { __typename?: 'GetFusionSourceExecutionDetailsResult', cursorId: string, executionTimestamp: string, fusionSourceId: string, logData: string, logDataType: FusionExecutionLogDataType, status: FusionExecutionStatus };

export type CreateFusionSourceUploadUrlErrorFragment = { __typename?: 'ArgumentError', message: string, paramName?: string | undefined };

export type AvailableTenantStatisticalReportsConnectionFragment = { __typename?: 'AvailableTenantStatisticalReportsConnection', edges?: Array<{ __typename?: 'AvailableTenantStatisticalReportsEdge', cursor: string }> | undefined, nodes?: Array<{ __typename?: 'TenantStatisticalReportOutput', id: string, name: string, endTime: string, startTime: string, reportType: TenantStatisticalReportType }> | undefined, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined } };

export type GetUrlsForTenantStatisticalReportsOutputFragment = { __typename?: 'GetUrlsForTenantStatisticalReportsOutput', preSignedUrlsForReportIds: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }> };

export type ActivateFusionSourceMutationVariables = Exact<{
  filter?: InputMaybe<Scalars['String']>;
  cursorValue?: InputMaybe<Scalars['String']>;
  fusionSourceId: Scalars['String'];
  isActivatingForTest?: InputMaybe<Scalars['Boolean']>;
}>;


export type ActivateFusionSourceMutation = { __typename?: 'NotifiMutation', activateFusionSource: { __typename?: 'ActivateFusionSourcePayload', activateFusionSourceResponse?: { __typename?: 'ActivateFusionSourceResponse', asyncOperationId?: string | undefined } | undefined } };

export type AddBotMutationVariables = Exact<{
  botCredentials: Scalars['String'];
  botType: BotType;
  name: Scalars['String'];
}>;


export type AddBotMutation = { __typename?: 'NotifiMutation', addBot: { __typename?: 'Bot', botType: BotType, name: string } };

export type AddCustomIconUrlMutationVariables = Exact<{
  input: AddCustomIconUrlInput;
}>;


export type AddCustomIconUrlMutation = { __typename?: 'NotifiMutation', addCustomIconUrl: { __typename?: 'PublicDappInfo', iconUrl?: string | undefined } };

export type AddEmailSenderDomainMutationVariables = Exact<{
  domainName: Scalars['String'];
}>;


export type AddEmailSenderDomainMutation = { __typename?: 'NotifiMutation', addEmailSenderDomain?: { __typename?: 'EmailSenderDomain', dkimTokens?: Array<string | undefined> | undefined, dnsVerificationToken?: string | undefined, domain?: string | undefined, id?: string | undefined, verificationStatus: EmailSenderDomainStatus } | undefined };

export type AddFusionEventToFusionSourceMutationVariables = Exact<{
  fusionSourceId: Scalars['String'];
  fusionEventId: Scalars['String'];
}>;


export type AddFusionEventToFusionSourceMutation = { __typename?: 'NotifiMutation', addFusionEventToFusionSource: { __typename?: 'AddFusionEventToFusionSourceResponse', fusionSource: { __typename?: 'FusionSourceWithCursor', id: string, name: string, version: any, archivedDate?: string | undefined, blockchainSource: WalletBlockchain, filter: string, fusionEventTypeIds?: Array<string> | undefined, createdDate: string, updatedDate: string, cursor?: { __typename?: 'CursorEntry', lastExecutedTimestamp: string, sourceState: FusionSourceState, cursorValue: string } | undefined } } };

export type AddUserMutationVariables = Exact<{
  email: Scalars['String'];
  roles: Array<Scalars['String']> | Scalars['String'];
}>;


export type AddUserMutation = { __typename?: 'NotifiMutation', createOrUpdateUser: { __typename?: 'User', id?: string | undefined, email?: string | undefined, roles?: Array<string | undefined> | undefined, errors?: Array<{ __typename?: 'InvalidEmailError', message: string } | { __typename?: 'LimitedResourceTypeError' } | { __typename?: 'RequiresElevatedTokenError', code: string, message: string }> | undefined } };

export type BatchBroadcastMessageMutationVariables = Exact<{
  input: BatchBroadcastMessageInput;
}>;


export type BatchBroadcastMessageMutation = { __typename?: 'NotifiMutation', batchBroadcastMessage?: { __typename?: 'BatchBroadcastMessageResult', id: string } | undefined };

export type BeginAddTotp2FaMutationVariables = Exact<{ [key: string]: never; }>;


export type BeginAddTotp2FaMutation = { __typename?: 'NotifiMutation', beginAddTOTP2FA: { __typename?: 'BeginAddTOTP2FAPayload', secret: string } };

export type BeginLogInByQrCodeMutationVariables = Exact<{ [key: string]: never; }>;


export type BeginLogInByQrCodeMutation = { __typename?: 'NotifiMutation', beginLogInByQRCode: { __typename?: 'BeginLogInByQRCodeResult', nonce: string } };

export type CancelCommunityManagerPublishTaskMutationVariables = Exact<{
  input: CancelCommunityManagerPublishTaskInput;
}>;


export type CancelCommunityManagerPublishTaskMutation = { __typename?: 'NotifiMutation', cancelCommunityManagerPublishTask: { __typename?: 'CancelCommunityManagerPublishTaskPayload', boolean?: boolean | undefined } };

export type ChangeExecutionTimeCmPublishTaskMutationVariables = Exact<{
  id: Scalars['String'];
  newExecutionTime: Scalars['DateTime'];
}>;


export type ChangeExecutionTimeCmPublishTaskMutation = { __typename?: 'NotifiMutation', changeExecutionTimeCMPublishTask: { __typename?: 'ChangeExecutionTimeCMPublishTaskPayload', boolean?: boolean | undefined } };

export type CompleteAddTotp2FaMutationVariables = Exact<{
  input: CompleteAddTotp2FaInput;
}>;


export type CompleteAddTotp2FaMutation = { __typename?: 'NotifiMutation', completeAddTOTP2FA: { __typename?: 'CompleteAddTOTP2FAPayload', backupCodes: Array<string>, elevatedPermissionsAuthorization: { __typename?: 'Authorization', expiry: string, serialNumber: string, token: string, tenantInfo?: { __typename?: 'CorsPolicy', allowedDomains?: Array<{ __typename?: 'CorsPolicyEntry', originAddress: string } | undefined> | undefined } | undefined }, normalPermissionsAuthorization: { __typename?: 'Authorization', expiry: string, serialNumber: string, token: string, tenantInfo?: { __typename?: 'CorsPolicy', allowedDomains?: Array<{ __typename?: 'CorsPolicyEntry', originAddress: string } | undefined> | undefined } | undefined }, refreshAuthorization: { __typename?: 'Authorization', expiry: string, serialNumber: string, token: string, tenantInfo?: { __typename?: 'CorsPolicy', allowedDomains?: Array<{ __typename?: 'CorsPolicyEntry', originAddress: string } | undefined> | undefined } | undefined } } };

export type CreateCardConfigMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TenantConfigType>;
  json: Scalars['String'];
}>;


export type CreateCardConfigMutation = { __typename?: 'NotifiMutation', createTenantConfig: { __typename?: 'TenantConfig', id: string, type: TenantConfigType, dataJson: string } };

export type CreateDiscordBotConfigurationMutationVariables = Exact<{
  discordClientId: Scalars['String'];
  discordClientSecret: Scalars['String'];
  discordGuildId: Scalars['String'];
  discordServerInviteLink: Scalars['String'];
  redirectUrl: Scalars['String'];
}>;


export type CreateDiscordBotConfigurationMutation = { __typename?: 'NotifiMutation', createDiscordBotConfiguration: { __typename?: 'DiscordBotConfiguration', discordClientId?: string | undefined, discordGuildId?: string | undefined, discordServerInviteLink?: string | undefined, redirectUrl?: string | undefined } };

export type CreateFusionEventMutationVariables = Exact<{
  name: Scalars['String'];
  isCommunityManagerEvent?: InputMaybe<Scalars['Boolean']>;
  metadataJson?: InputMaybe<Scalars['String']>;
}>;


export type CreateFusionEventMutation = { __typename?: 'NotifiMutation', createFusionEvent: { __typename?: 'FusionEventData', id: string, name: string, metadata: string, createdDate: string, updatedDate: string, archivedDate?: string | undefined, fusionEventCategory: FusionEventCategory, fusionModules: Array<{ __typename?: 'FusionModuleData', id: string, tenantId: string, moduleType: FusionModuleType, storageType: StorageType, expectedMetadataOutputSchema: string, validateMetadataOutput: boolean, fusionEventId: string, createdDate: string, archivedDate?: string | undefined, active: boolean }>, fusionSource?: { __typename?: 'FusionSourceWithCursor', id: string, name: string, blockchainSource: WalletBlockchain, filter: string, createdDate: string, updatedDate: string, fusionEventTypeIds?: Array<string> | undefined, archivedDate?: string | undefined, version: any, cursor?: { __typename?: 'CursorEntry', lastExecutedTimestamp: string, sourceState: FusionSourceState, cursorValue: string } | undefined } | undefined } };

export type CreateFusionSourceMutationVariables = Exact<{
  name: Scalars['String'];
  blockchainType: WalletBlockchain;
  filter: Scalars['String'];
  fusionEventTypeIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateFusionSourceMutation = { __typename?: 'NotifiMutation', createFusionSource: { __typename?: 'CreateFusionSourcePayload', createFusionSourceResponse?: { __typename?: 'CreateFusionSourceResponse', fusionSource: { __typename?: 'FusionSourceWithCursor', id: string, name: string, version: any, archivedDate?: string | undefined, blockchainSource: WalletBlockchain, filter: string, fusionEventTypeIds?: Array<string> | undefined, createdDate: string, updatedDate: string } } | undefined, errors?: Array<{ __typename: 'ArgumentError', message: string, paramName?: string | undefined }> | undefined } };

export type CreateFusionSourceUploadUrlMutationVariables = Exact<{
  byteCount: Scalars['Long'];
  fusionSourceId: Scalars['String'];
}>;


export type CreateFusionSourceUploadUrlMutation = { __typename?: 'NotifiMutation', createFusionSourceUploadUrl: { __typename?: 'CreateFusionSourceUploadUrlPayload', createFusionSourceUploadUrlResponse?: { __typename?: 'CreateFusionSourceUploadUrlResponse', fusionSourcePutUrl: string, version: any } | undefined, errors?: Array<{ __typename?: 'ArgumentError', message: string, paramName?: string | undefined }> | undefined } };

export type CreateOpenRegMutationVariables = Exact<{
  input: CreateOpenUserRegistrationInput;
}>;


export type CreateOpenRegMutation = { __typename?: 'NotifiMutation', createOpenUserRegistration: { __typename?: 'CreateOpenUserRegistrationPayload', openUserRegistration?: { __typename?: 'OpenUserRegistration', id?: string | undefined, name?: string | undefined, fusionEventIds?: Array<string | undefined> | undefined, beginTime?: { __typename?: 'Timestamp', toDateTime: string } | undefined, endTime?: { __typename?: 'Timestamp', toDateTime: string } | undefined, userRegistrationExecutionEndpoints?: Array<{ __typename: 'EmailUserRegistrationExecutionEndpoint', targetType: TargetType, postUrl: string } | { __typename: 'TelegramUserRegistrationExecutionEndpoint', targetType: TargetType, userActionUrl: string, groupUserActionUrl: string } | undefined> | undefined } | undefined } };

export type DeactivateFusionSourceMutationVariables = Exact<{
  fusionSourceId: Scalars['String'];
}>;


export type DeactivateFusionSourceMutation = { __typename?: 'NotifiMutation', deactivateFusionSource: { __typename?: 'DeactivateFusionSourcePayload', deactivateFusionSourceResponse?: { __typename?: 'DeactivateFusionSourceResponse', asyncOperationId?: string | undefined } | undefined } };

export type DeleteCardConfigMutationVariables = Exact<{
  id: Scalars['String'];
  type?: InputMaybe<TenantConfigType>;
}>;


export type DeleteCardConfigMutation = { __typename?: 'NotifiMutation', deleteTenantConfig: { __typename?: 'TenantConfig', id: string, type: TenantConfigType } };

export type DeleteFusionEventMutationVariables = Exact<{
  fusionEventId: Scalars['String'];
}>;


export type DeleteFusionEventMutation = { __typename?: 'NotifiMutation', deleteFusionEvent: boolean };

export type DeleteFusionSourceMutationVariables = Exact<{
  fusionSourceId: Scalars['String'];
}>;


export type DeleteFusionSourceMutation = { __typename?: 'NotifiMutation', deleteFusionSource: { __typename?: 'DeleteFusionSourceResponse', success: boolean } };

export type DisableTotp2FaRequiredForEmailLoginsMutationVariables = Exact<{ [key: string]: never; }>;


export type DisableTotp2FaRequiredForEmailLoginsMutation = { __typename?: 'NotifiMutation', disableTOTP2FARequiredForEmailLogins: { __typename?: 'DisableTOTP2FARequiredForEmailLoginsPayload', totp2faRequired?: boolean | undefined, errors?: Array<{ __typename?: 'RequiresElevatedTokenError', code: string, message: string }> | undefined } };

export type GenerateEmailConfirmationTokenMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
}>;


export type GenerateEmailConfirmationTokenMutation = { __typename?: 'NotifiMutation', generateEmailConfirmationToken: boolean };

export type ConfirmEmailMutationVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
}>;


export type ConfirmEmailMutation = { __typename?: 'NotifiMutation', confirmEmail: boolean };

export type EnableTotp2FaRequiredForEmailLoginsMutationVariables = Exact<{ [key: string]: never; }>;


export type EnableTotp2FaRequiredForEmailLoginsMutation = { __typename?: 'NotifiMutation', enableTOTP2FARequiredForEmailLogins: { __typename?: 'EnableTOTP2FARequiredForEmailLoginsPayload', totp2faRequired?: boolean | undefined, errors?: Array<{ __typename?: 'RequiresElevatedTokenError', code: string, message: string }> | undefined } };

export type FusionBroadcastMessageMutationVariables = Exact<{
  input: FusionBroadcastMessageInput;
}>;


export type FusionBroadcastMessageMutation = { __typename?: 'NotifiMutation', fusionBroadcastMessage: { __typename?: 'FusionBroadcastMessageResult', id: string } };

export type GenerateElevatedPermissionsAuthorizationMutationVariables = Exact<{
  input: GenerateElevatedPermissionsAuthorizationInput;
}>;


export type GenerateElevatedPermissionsAuthorizationMutation = { __typename?: 'NotifiMutation', generateElevatedPermissionsAuthorization: { __typename?: 'GenerateElevatedPermissionsAuthorizationPayload', authorization?: { __typename?: 'Authorization', expiry: string, serialNumber: string, token: string, tenantInfo?: { __typename?: 'CorsPolicy', allowedDomains?: Array<{ __typename?: 'CorsPolicyEntry', originAddress: string } | undefined> | undefined } | undefined } | undefined } };

export type GenerateNormalPermissionsAuthorizationMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateNormalPermissionsAuthorizationMutation = { __typename?: 'NotifiMutation', generateNormalPermissionsAuthorization: { __typename?: 'GenerateNormalPermissionsAuthorizationPayload', newRefreshAuthorization?: { __typename?: 'Authorization', expiry: string, serialNumber: string, token: string, tenantInfo?: { __typename?: 'CorsPolicy', allowedDomains?: Array<{ __typename?: 'CorsPolicyEntry', originAddress: string } | undefined> | undefined } | undefined } | undefined, normalPermissionsAuthorization?: { __typename?: 'Authorization', expiry: string, serialNumber: string, token: string, tenantInfo?: { __typename?: 'CorsPolicy', allowedDomains?: Array<{ __typename?: 'CorsPolicyEntry', originAddress: string } | undefined> | undefined } | undefined } | undefined } };

export type GenerateRefreshAuthorizationMutationVariables = Exact<{
  input: GenerateRefreshAuthorizationInput;
}>;


export type GenerateRefreshAuthorizationMutation = { __typename?: 'NotifiMutation', generateRefreshAuthorization: { __typename?: 'GenerateRefreshAuthorizationPayload', authorization?: { __typename?: 'Authorization', expiry: string, serialNumber: string, token: string, tenantInfo?: { __typename?: 'CorsPolicy', allowedDomains?: Array<{ __typename?: 'CorsPolicyEntry', originAddress: string } | undefined> | undefined } | undefined } | undefined } };

export type SubmitLoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SubmitLoginMutation = { __typename?: 'NotifiMutation', logInByEmailAddress?: { __typename?: 'User', email?: string | undefined, emailConfirmed?: boolean | undefined, roles?: Array<string | undefined> | undefined, authorization?: { __typename?: 'Authorization', token: string, expiry: string } | undefined } | undefined };

export type RefreshAuthorizationMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshAuthorizationMutation = { __typename?: 'NotifiMutation', refreshAuthorization?: { __typename?: 'Authorization', token: string, expiry: string } | undefined };

export type Regenerate2FaBackupCodesMutationVariables = Exact<{ [key: string]: never; }>;


export type Regenerate2FaBackupCodesMutation = { __typename?: 'NotifiMutation', regenerate2FABackupCodes: { __typename?: 'Regenerate2FABackupCodesPayload', backupCodes?: Array<string> | undefined, errors?: Array<{ __typename?: 'RequiresElevatedTokenError', code: string, message: string }> | undefined } };

export type RemoveBotMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveBotMutation = { __typename?: 'NotifiMutation', removeBot: { __typename?: 'Bot', botType: BotType, id: string, name: string } };

export type RemoveEmailSenderDomainMutationVariables = Exact<{
  domainNameId: Scalars['String'];
}>;


export type RemoveEmailSenderDomainMutation = { __typename?: 'NotifiMutation', removeEmailSenderDomain?: { __typename?: 'EmailSenderDomain', dnsVerificationToken?: string | undefined, domain?: string | undefined, id?: string | undefined, verificationStatus: EmailSenderDomainStatus } | undefined };

export type RemoveFusionEventFromFusionSourceMutationVariables = Exact<{
  fusionEventId: Scalars['String'];
}>;


export type RemoveFusionEventFromFusionSourceMutation = { __typename?: 'NotifiMutation', removeFusionEventFromFusionSource: { __typename?: 'RemoveFusionEventFromFusionSourceResponse', fusionSource: { __typename?: 'FusionSourceWithCursor', id: string, name: string, version: any, archivedDate?: string | undefined, blockchainSource: WalletBlockchain, filter: string, fusionEventTypeIds?: Array<string> | undefined, createdDate: string, updatedDate: string, cursor?: { __typename?: 'CursorEntry', lastExecutedTimestamp: string, sourceState: FusionSourceState, cursorValue: string } | undefined } } };

export type RemoveTotp2FaMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveTotp2FaMutation = { __typename?: 'NotifiMutation', removeTOTP2FA: { __typename?: 'RemoveTOTP2FAPayload', success?: boolean | undefined, errors?: Array<{ __typename?: 'RequiresElevatedTokenError', code: string, message: string }> | undefined } };

export type RemoveUserRolesMutationVariables = Exact<{
  email: Scalars['String'];
  roles: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveUserRolesMutation = { __typename?: 'NotifiMutation', removeTenantUserRoles?: { __typename?: 'User', id?: string | undefined, email?: string | undefined, roles?: Array<string | undefined> | undefined } | undefined };

export type SendBroadcastMutationVariables = Exact<{
  input: BroadcastMessageInput;
  signature?: InputMaybe<Scalars['String']>;
}>;


export type SendBroadcastMutation = { __typename?: 'NotifiMutation', broadcastMessage?: { __typename?: 'BroadcastMessageResult', id?: string | undefined } | undefined };

export type SendDirectPushMutationVariables = Exact<{
  input: SendMessageInput;
}>;


export type SendDirectPushMutation = { __typename?: 'NotifiMutation', sendMessage: boolean };

export type SendEmailTargetVerificationRequestMutationVariables = Exact<{
  sendTargetConfirmationRequestInput?: InputMaybe<SendTargetConfirmationRequestInput>;
}>;


export type SendEmailTargetVerificationRequestMutation = { __typename?: 'NotifiMutation', sendEmailTargetVerificationRequest?: { __typename?: 'EmailTarget', emailAddress?: string | undefined, isConfirmed: boolean, id: string, name?: string | undefined, createdDate: string, updatedDate: string } | undefined };

export type CreateTenantUserMutationVariables = Exact<{
  email: Scalars['String'];
  dappId?: InputMaybe<Scalars['String']>;
  tenantName: Scalars['String'];
  password: Scalars['String'];
  captchaResponse: Scalars['String'];
}>;


export type CreateTenantUserMutation = { __typename?: 'NotifiMutation', createTenant?: { __typename?: 'User', email?: string | undefined, emailConfirmed?: boolean | undefined, id?: string | undefined } | undefined };

export type UpdateDefaultEmailSenderDisplayNameMutationVariables = Exact<{
  defaultDisplayName?: InputMaybe<Scalars['String']>;
}>;


export type UpdateDefaultEmailSenderDisplayNameMutation = { __typename?: 'NotifiMutation', updateDefaultEmailSenderDisplayName?: { __typename?: 'EmailSenderDefaults', defaultEmailSenderDisplayName?: string | undefined, defaultEmailSenderName?: string | undefined, allowedDomains?: Array<{ __typename?: 'CorsPolicyEntry', originAddress: string } | undefined> | undefined, defaultEmailSenderDomain?: { __typename?: 'EmailSenderDomain', dkimTokens?: Array<string | undefined> | undefined, dnsVerificationToken?: string | undefined, domain?: string | undefined, id?: string | undefined, verificationStatus: EmailSenderDomainStatus } | undefined } | undefined };

export type UpdateDefaultEmailSenderDomainMutationVariables = Exact<{
  defaultDomainId?: InputMaybe<Scalars['String']>;
}>;


export type UpdateDefaultEmailSenderDomainMutation = { __typename?: 'NotifiMutation', updateDefaultEmailSenderDomain?: { __typename?: 'EmailSenderDefaults', defaultEmailSenderDisplayName?: string | undefined, defaultEmailSenderName?: string | undefined, allowedDomains?: Array<{ __typename?: 'CorsPolicyEntry', originAddress: string } | undefined> | undefined, defaultEmailSenderDomain?: { __typename?: 'EmailSenderDomain', dkimTokens?: Array<string | undefined> | undefined, dnsVerificationToken?: string | undefined, domain?: string | undefined, id?: string | undefined, verificationStatus: EmailSenderDomainStatus } | undefined } | undefined };

export type UpdateDefaultEmailSenderNameMutationVariables = Exact<{
  defaultName?: InputMaybe<Scalars['String']>;
}>;


export type UpdateDefaultEmailSenderNameMutation = { __typename?: 'NotifiMutation', updateDefaultEmailSenderName?: { __typename?: 'EmailSenderDefaults', defaultEmailSenderDisplayName?: string | undefined, defaultEmailSenderName?: string | undefined, allowedDomains?: Array<{ __typename?: 'CorsPolicyEntry', originAddress: string } | undefined> | undefined, defaultEmailSenderDomain?: { __typename?: 'EmailSenderDomain', dkimTokens?: Array<string | undefined> | undefined, dnsVerificationToken?: string | undefined, domain?: string | undefined, id?: string | undefined, verificationStatus: EmailSenderDomainStatus } | undefined } | undefined };

export type UpdateEphemeralStorageMutationVariables = Exact<{
  updateEphemeralStorageInput: UpdateEphemeralStorageInput;
}>;


export type UpdateEphemeralStorageMutation = { __typename?: 'NotifiMutation', updateEphemeralStorage: { __typename?: 'UpdateEphemeralStorageResult', entries?: Array<{ __typename?: 'EphemeralStorageEntry', key?: string | undefined, value?: string | undefined } | undefined> | undefined } };

export type UpdateFusionEventMutationVariables = Exact<{
  fusionEventId: Scalars['String'];
  name: Scalars['String'];
  metadataJson?: InputMaybe<Scalars['String']>;
}>;


export type UpdateFusionEventMutation = { __typename?: 'NotifiMutation', updateFusionEvent: { __typename?: 'FusionEventData', id: string, name: string, metadata: string, createdDate: string, updatedDate: string, archivedDate?: string | undefined, fusionEventCategory: FusionEventCategory, fusionModules: Array<{ __typename?: 'FusionModuleData', id: string, tenantId: string, moduleType: FusionModuleType, storageType: StorageType, expectedMetadataOutputSchema: string, validateMetadataOutput: boolean, fusionEventId: string, createdDate: string, archivedDate?: string | undefined, active: boolean }>, fusionSource?: { __typename?: 'FusionSourceWithCursor', id: string, name: string, blockchainSource: WalletBlockchain, filter: string, createdDate: string, updatedDate: string, fusionEventTypeIds?: Array<string> | undefined, archivedDate?: string | undefined, version: any, cursor?: { __typename?: 'CursorEntry', lastExecutedTimestamp: string, sourceState: FusionSourceState, cursorValue: string } | undefined } | undefined } };

export type UpdateFusionSourceMutationVariables = Exact<{
  fusionSourceId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
}>;


export type UpdateFusionSourceMutation = { __typename?: 'NotifiMutation', updateFusionSource: { __typename?: 'UpdateFusionSourceResponse', fusionSource: { __typename?: 'FusionSourceWithCursor', id: string, name: string, version: any, archivedDate?: string | undefined, blockchainSource: WalletBlockchain, filter: string, fusionEventTypeIds?: Array<string> | undefined, createdDate: string, updatedDate: string } } };

export type UpdateOpenUserRegistrationMutationVariables = Exact<{
  input: UpdateOpenUserRegistrationInput;
}>;


export type UpdateOpenUserRegistrationMutation = { __typename?: 'NotifiMutation', updateOpenUserRegistration: { __typename?: 'UpdateOpenUserRegistrationPayload', updateOpenRegistrationResponse?: { __typename?: 'UpdateOpenRegistrationResponse', clone?: { __typename?: 'UpdateOpenRegistrationResponse', calculateSize: number } | undefined } | undefined } };

export type UpdatePersistentStorageMutationVariables = Exact<{
  updatePersistentStorageInput: UpdatePersistentStorageInput;
}>;


export type UpdatePersistentStorageMutation = { __typename?: 'NotifiMutation', updatePersistentStorage: { __typename?: 'UpdatePersistentStorageResult', entries?: Array<{ __typename?: 'PersistentStorageEntry', key?: string | undefined, value?: string | undefined } | undefined> | undefined } };

export type UploadTemplateMutationVariables = Exact<{
  templateName: Scalars['String'];
  template: Scalars['String'];
  type: TemplateType;
}>;


export type UploadTemplateMutation = { __typename?: 'NotifiMutation', uploadTemplate: boolean };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  tfaCode: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'NotifiMutation', resetPassword: boolean };

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetPasswordEmailMutation = { __typename?: 'NotifiMutation', sendResetPasswordEmail: boolean };

export type AllEmailSendersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllEmailSendersQuery = { __typename?: 'NotifiQuery', allEmailSenders: Array<{ __typename?: 'EmailSenderDomain', dkimTokens?: Array<string | undefined> | undefined, dnsVerificationToken?: string | undefined, domain?: string | undefined, id?: string | undefined, verificationStatus: EmailSenderDomainStatus } | undefined> };

export type BotsByTypeQueryVariables = Exact<{
  GetBotsByTypeInput: GetBotsByTypeInput;
}>;


export type BotsByTypeQuery = { __typename?: 'NotifiQuery', botsByType: Array<{ __typename?: 'Bot', botType: BotType, name: string, id: string, status: BotStatus }> };

export type CampaignPerformanceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CampaignPerformanceQuery = { __typename?: 'NotifiQuery', campaignPerformance?: { __typename: 'CampaignDetails', campaignId: string, created: string, executionTime?: string | undefined, fusionEventTypeId: string, id: string, lastUpdated: string, status: string, statisticsByTarget: Array<{ __typename: 'StatisticsByTarget', closed: number, delivered: number, opened: number, sent: number, targetType: string } | undefined> } | undefined };

export type CampaignsByTenantIdQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
}>;


export type CampaignsByTenantIdQuery = { __typename?: 'NotifiQuery', campaignsByTenantId: { __typename?: 'CampaignStatusConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | undefined, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | undefined }, scheduled: Array<{ __typename?: 'CMDashboardCampaignDetails', campaignId: string, delivered: number, executionTime: string, fusionEventTypeId: string, id: string, opened: number, status: string, sent: number, createdDate: string }>, executing: Array<{ __typename?: 'CMDashboardCampaignDetails', campaignId: string, delivered: number, executionTime: string, fusionEventTypeId: string, id: string, opened: number, status: string, sent: number }>, completed: Array<{ __typename?: 'CMDashboardCampaignDetails', campaignId: string, delivered: number, executionTime: string, fusionEventTypeId: string, id: string, opened: number, status: string, sent: number }> } };

export type CardConfigQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TenantConfigType>;
}>;


export type CardConfigQuery = { __typename?: 'NotifiQuery', tenantConfigs?: { __typename?: 'TenantConfigsConnection', nodes?: Array<{ __typename?: 'TenantConfig', id: string, type: TenantConfigType, dataJson: string }> | undefined, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined } } | undefined };

export type CommunityManagerPublishTaskQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CommunityManagerPublishTaskQuery = { __typename?: 'NotifiQuery', communityManagerPublishTask: { __typename: 'CommunityManagerPublish', campaignId: string, executionTime: string, fusionEventTypeId: string, id: string, createdDate: string, status: CommunityManagerMessageStatus, variablesJson: string, specificWallets: Array<{ __typename?: 'SpecificWalletTarget', address?: string | undefined, blockchainType: BlockchainType, calculateSize: number }> } };

export type EphemeralStorageQueryVariables = Exact<{
  ephemeralStorageQueryInput?: InputMaybe<EphemeralStorageQueryInput>;
}>;


export type EphemeralStorageQuery = { __typename?: 'NotifiQuery', ephemeralStorage?: { __typename?: 'EphemeralStorageConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined }, nodes?: Array<{ __typename?: 'EphemeralStorageEntry', key?: string | undefined, value?: string | undefined }> | undefined } | undefined };

export type FusionEventsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  fusionEventCategory?: InputMaybe<FusionEventCategory>;
}>;


export type FusionEventsQuery = { __typename?: 'NotifiQuery', fusionEvents?: { __typename?: 'FusionEventsConnection', nodes?: Array<{ __typename?: 'FusionEventData', id: string, name: string, metadata: string, createdDate: string, updatedDate: string, archivedDate?: string | undefined, fusionEventCategory: FusionEventCategory, fusionModules: Array<{ __typename?: 'FusionModuleData', id: string, tenantId: string, moduleType: FusionModuleType, storageType: StorageType, expectedMetadataOutputSchema: string, validateMetadataOutput: boolean, fusionEventId: string, createdDate: string, archivedDate?: string | undefined, active: boolean }>, fusionSource?: { __typename?: 'FusionSourceWithCursor', id: string, name: string, blockchainSource: WalletBlockchain, filter: string, createdDate: string, updatedDate: string, fusionEventTypeIds?: Array<string> | undefined, archivedDate?: string | undefined, version: any, cursor?: { __typename?: 'CursorEntry', lastExecutedTimestamp: string, sourceState: FusionSourceState, cursorValue: string } | undefined } | undefined }> | undefined, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined } } | undefined };

export type FusionExecutionLogsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  getFusionExecutionLogsInput?: InputMaybe<GetFusionExecutionLogsInput>;
}>;


export type FusionExecutionLogsQuery = { __typename?: 'NotifiQuery', fusionExecutionLogs?: { __typename?: 'FusionExecutionLogsConnection', nodes?: Array<{ __typename?: 'GetFusionModuleExecutionDetailsResult' } | { __typename?: 'GetFusionSourceExecutionDetailsResult', cursorId: string, executionTimestamp: string, fusionSourceId: string, logData: string, logDataType: FusionExecutionLogDataType, status: FusionExecutionStatus }> | undefined, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined } } | undefined };

export type FusionPreviewCommunityManagerContentQueryVariables = Exact<{
  fusionCommunityManagerPreviewInput: FusionCommunityManagerPreviewInput;
}>;


export type FusionPreviewCommunityManagerContentQuery = { __typename?: 'NotifiQuery', fusionPreviewCommunityManagerContent: { __typename?: 'FusionCommunityManagerPreviewResult', content: string } };

export type FusionSourcesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type FusionSourcesQuery = { __typename?: 'NotifiQuery', fusionSources?: { __typename?: 'FusionSourcesConnection', nodes?: Array<{ __typename?: 'FusionSourceWithCursor', id: string, name: string, version: any, archivedDate?: string | undefined, blockchainSource: WalletBlockchain, filter: string, fusionEventTypeIds?: Array<string> | undefined, createdDate: string, updatedDate: string, cursor?: { __typename?: 'CursorEntry', lastExecutedTimestamp: string, sourceState: FusionSourceState, cursorValue: string } | undefined }> | undefined, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined } } | undefined };

export type AvailableTenantStatisticalReportsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  input: GetAvailableTenantStatisticalReportsInput;
}>;


export type AvailableTenantStatisticalReportsQuery = { __typename?: 'NotifiQuery', availableTenantStatisticalReports?: { __typename?: 'AvailableTenantStatisticalReportsConnection', edges?: Array<{ __typename?: 'AvailableTenantStatisticalReportsEdge', cursor: string }> | undefined, nodes?: Array<{ __typename?: 'TenantStatisticalReportOutput', id: string, name: string, endTime: string, startTime: string, reportType: TenantStatisticalReportType }> | undefined, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined } } | undefined };

export type GetEmailSenderDefaultsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailSenderDefaultsQuery = { __typename?: 'NotifiQuery', emailSenderDefaults?: { __typename?: 'EmailSenderDefaults', defaultEmailSenderName?: string | undefined, defaultEmailSenderDisplayName?: string | undefined, defaultEmailSenderDomainId?: string | undefined } | undefined };

export type FusionSourceStateQueryVariables = Exact<{
  input: GetFusionSourceStateInput;
}>;


export type FusionSourceStateQuery = { __typename?: 'NotifiQuery', fusionSourceState: { __typename?: 'FusionSourceWithCursor', id: string, name: string, version: any, archivedDate?: string | undefined, blockchainSource: WalletBlockchain, filter: string, fusionEventTypeIds?: Array<string> | undefined, createdDate: string, updatedDate: string, cursor?: { __typename?: 'CursorEntry', lastExecutedTimestamp: string, sourceState: FusionSourceState, cursorValue: string } | undefined } };

export type ListTemplatesQueryVariables = Exact<{
  ListTemplatesInput: ListTemplatesInput;
}>;


export type ListTemplatesQuery = { __typename?: 'NotifiQuery', listTemplates: { __typename?: 'ListTemplatesResponse', templates: Array<{ __typename?: 'Template', createdDate: string, id: string, message: string, templateName: string, templateType: TemplateType, tenantId: string, updatedDate: string }> } };

export type GetMessageDeliveryStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMessageDeliveryStatisticsQuery = { __typename?: 'NotifiQuery', messageDeliveryStatistics: { __typename?: 'GetMessageDeliveryStatisticsResult', messagesByTarget: { __typename?: 'MessageStats', stats?: Array<{ __typename?: 'KeyValuePairOfStringAndMessageDetails', key: string, value: { __typename?: 'MessageDetails', messages: number, users: number } }> | undefined }, messagesByAttributes: { __typename?: 'MessageStats', stats?: Array<{ __typename?: 'KeyValuePairOfStringAndMessageDetails', key: string, value: { __typename?: 'MessageDetails', messages: number, users: number } }> | undefined } } };

export type GetOpenUserRegistrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOpenUserRegistrationsQuery = { __typename?: 'NotifiQuery', openUserRegistrations?: { __typename?: 'OpenUserRegistrationsConnection', nodes?: Array<{ __typename?: 'OpenUserRegistration', id?: string | undefined, name?: string | undefined, fusionEventIds?: Array<string | undefined> | undefined, beginTime?: { __typename?: 'Timestamp', toDateTime: string } | undefined, endTime?: { __typename?: 'Timestamp', toDateTime: string } | undefined, userRegistrationExecutionEndpoints?: Array<{ __typename?: 'EmailUserRegistrationExecutionEndpoint', targetType: TargetType, postUrl: string } | { __typename?: 'TelegramUserRegistrationExecutionEndpoint', targetType: TargetType, userActionUrl: string, groupUserActionUrl: string } | undefined> | undefined }> | undefined } | undefined };

export type PublicDappMetadataQueryVariables = Exact<{
  input: GetPublicDappMetadataInput;
}>;


export type PublicDappMetadataQuery = { __typename?: 'NotifiQuery', publicDappMetadata?: { __typename?: 'PublicDappInfo', iconUrl?: string | undefined } | undefined };

export type TemplateDataQueryVariables = Exact<{
  GetTemplateDataInput: GetTemplateDataInput;
}>;


export type TemplateDataQuery = { __typename?: 'NotifiQuery', templateData: { __typename?: 'GetTemplateResponse', template?: { __typename?: 'Template', createdDate: string, id: string, message: string, templateName: string, templateType: TemplateType, tenantId: string, updatedDate: string } | undefined } };

export type GetTenantAvailableReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantAvailableReportsQuery = { __typename?: 'NotifiQuery', tenantAvailableReports: { __typename?: 'GetTenantAvailableReportsResult', reports: Array<string> } };

export type GetTenantBillingPlanQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantBillingPlanQuery = { __typename?: 'NotifiQuery', billingPlan: { __typename?: 'BillingPlan', billingPlanType: BillingPlanType } };

export type TenantDeliveryReportQueryVariables = Exact<{
  GetTenantDeliveryReportRequestInput: GetTenantDeliveryReportRequestInput;
}>;


export type TenantDeliveryReportQuery = { __typename?: 'NotifiQuery', tenantDeliveryReport: { __typename?: 'GetTenantDeliveryReportResult', preSignedUrls: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }> } };

export type TenantDiscordBotConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantDiscordBotConfigurationQuery = { __typename?: 'NotifiQuery', tenantDiscordBotConfiguration?: { __typename?: 'DiscordBotConfiguration', discordClientId?: string | undefined, discordGuildId?: string | undefined, discordServerInviteLink?: string | undefined, redirectUrl?: string | undefined } | undefined };

export type TenantFeatureFlagQueryVariables = Exact<{
  TenantFeatureFlagInput: TenantFeatureFlagInput;
}>;


export type TenantFeatureFlagQuery = { __typename?: 'NotifiQuery', tenantFeatureFlag: { __typename?: 'TenantFeatureFlag', name: string, value: string } };

export type TenantMessagesByMonthQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantMessagesByMonthQuery = { __typename?: 'NotifiQuery', totalMessagesPerMonthByTenant: { __typename?: 'GetTotalMessagesPerMonthByTenantResult', messagesPerMonth: Array<{ __typename?: 'KeyValuePairOfStringAndInt32', key: string, value: number }> } };

export type TenantMessagesByTopicQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
}>;


export type TenantMessagesByTopicQuery = { __typename?: 'NotifiQuery', totalMessagesPerTopicByTenant?: { __typename?: 'TotalMessagesPerTopicByTenantConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined }, nodes?: Array<{ __typename?: 'GetTotalMessagesPerTopicByTenantPayload', topicName: string, eventTypeId: string, subscriberCount: any, messageCount: any }> | undefined } | undefined };

export type UrlsForTenantStatisticalReportsQueryVariables = Exact<{
  input: GetUrlsForTenantStatisticalReportsInput;
}>;


export type UrlsForTenantStatisticalReportsQuery = { __typename?: 'NotifiQuery', urlsForTenantStatisticalReports: { __typename?: 'GetUrlsForTenantStatisticalReportsOutput', preSignedUrlsForReportIds: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }> } };

export type GetUserTopicsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserTopicsQuery = { __typename?: 'NotifiQuery', usersByTopic: { __typename?: 'GetUsersByTopicResult', usersByTopic: Array<{ __typename?: 'KeyValuePairOfStringAndInt32', key: string, value: number }> } };

export type Is2faQueryVariables = Exact<{ [key: string]: never; }>;


export type Is2faQuery = { __typename?: 'NotifiQuery', totp2FAStatus: { __typename?: 'TOTP2FAStatusPayload', isAdded: boolean, isRequired: boolean, isEnforced: boolean } };

export type PersistentStorageQueryVariables = Exact<{
  PersistentStorageQueryInput?: InputMaybe<PersistentStorageQueryInput>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type PersistentStorageQuery = { __typename?: 'NotifiQuery', persistentStorage?: { __typename?: 'PersistentStorageConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined }, nodes?: Array<{ __typename?: 'PersistentStorageEntry', key?: string | undefined, value?: string | undefined, version?: any | undefined, createdDate?: { __typename?: 'Timestamp', calculateSize: number, nanos: number, seconds: any, toDateTime: string, toDateTimeOffset: string, toDiagnosticString?: string | undefined } | undefined, updatedDate?: { __typename?: 'Timestamp', calculateSize: number, nanos: number, seconds: any, toDateTime: string, toDateTimeOffset: string, toDiagnosticString?: string | undefined } | undefined }> | undefined } | undefined };

export type PreviewCommunityManagerContentQueryVariables = Exact<{
  previewInput: CommunityManagerPreviewInput;
}>;


export type PreviewCommunityManagerContentQuery = { __typename?: 'NotifiQuery', previewCommunityManagerContent: { __typename?: 'CommunityManagerPreviewResult', content: string } };

export type ServiceIdentifierQueryVariables = Exact<{ [key: string]: never; }>;


export type ServiceIdentifierQuery = { __typename?: 'NotifiQuery', serviceIdentifier?: { __typename?: 'ServiceIdentifierOutput', id: string, secret?: string | undefined } | undefined };

export type TenantApprovalStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantApprovalStatusQuery = { __typename?: 'NotifiQuery', tenantApprovalStatus: ApprovalStatus };

export type TenantUsersQueryVariables = Exact<{
  filterRoles?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type TenantUsersQuery = { __typename?: 'NotifiQuery', tenantUsers?: { __typename?: 'TenantUsersConnection', nodes?: Array<{ __typename?: 'TenantUser', id: string, email?: string | undefined, userName?: string | undefined, roles?: Array<string | undefined> | undefined } | undefined> | undefined } | undefined };

export type TopicsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type TopicsQuery = { __typename?: 'NotifiQuery', topics?: { __typename?: 'TopicsConnection', nodes?: Array<{ __typename?: 'UserTopic', targetCollections?: Array<string> | undefined, targetTemplate?: string | undefined, topicName: string, name?: string | undefined }> | undefined, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined } } | undefined };

export type UserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileQuery = { __typename?: 'NotifiQuery', userProfile?: { __typename?: 'UserProfile', id: string, tenantInfo?: { __typename?: 'Tenant', dappId: string, name: string } | undefined } | undefined };

export type Totp2FaRequiredForEmailLoginsQueryVariables = Exact<{ [key: string]: never; }>;


export type Totp2FaRequiredForEmailLoginsQuery = { __typename?: 'NotifiQuery', totp2FARequiredForEmailLogins: { __typename?: 'TOTP2FARequiredForEmailLoginsPayload', totp2faRequired: boolean } };

export const AuthorizationFragmentFragmentDoc = gql`
    fragment AuthorizationFragment on Authorization {
  token
  expiry
}
    `;
export const FusionEventDataFragmentDoc = gql`
    fragment FusionEventData on FusionEventData {
  id
  name
  metadata
  fusionModules {
    id
    tenantId
    moduleType
    storageType
    expectedMetadataOutputSchema
    validateMetadataOutput
    fusionEventId
    createdDate
    archivedDate
    active
  }
  createdDate
  updatedDate
  archivedDate
  fusionEventCategory
  fusionSource {
    id
    name
    blockchainSource
    filter
    createdDate
    updatedDate
    fusionEventTypeIds
    archivedDate
    version
    cursor {
      lastExecutedTimestamp
      sourceState
      cursorValue
    }
  }
}
    `;
export const CreateFusionSourceResponseFragmentDoc = gql`
    fragment CreateFusionSourceResponse on CreateFusionSourceResponse {
  fusionSource {
    id
    name
    version
    archivedDate
    blockchainSource
    filter
    fusionEventTypeIds
    createdDate
    updatedDate
  }
}
    `;
export const FusionSourceWithCursorFragmentDoc = gql`
    fragment FusionSourceWithCursor on FusionSourceWithCursor {
  id
  name
  version
  archivedDate
  blockchainSource
  filter
  fusionEventTypeIds
  createdDate
  updatedDate
  cursor {
    lastExecutedTimestamp
    sourceState
    cursorValue
  }
}
    `;
export const UpdateFusionSourceResponseFragmentDoc = gql`
    fragment UpdateFusionSourceResponse on UpdateFusionSourceResponse {
  fusionSource {
    id
    name
    version
    archivedDate
    blockchainSource
    filter
    fusionEventTypeIds
    createdDate
    updatedDate
  }
}
    `;
export const AddFusionEventToFusionSourceResponseFragmentDoc = gql`
    fragment AddFusionEventToFusionSourceResponse on AddFusionEventToFusionSourceResponse {
  fusionSource {
    id
    name
    version
    archivedDate
    blockchainSource
    filter
    fusionEventTypeIds
    createdDate
    updatedDate
    cursor {
      lastExecutedTimestamp
      sourceState
      cursorValue
    }
  }
}
    `;
export const RemoveFusionEventFromFusionSourceResponseFragmentDoc = gql`
    fragment RemoveFusionEventFromFusionSourceResponse on RemoveFusionEventFromFusionSourceResponse {
  fusionSource {
    id
    name
    version
    archivedDate
    blockchainSource
    filter
    fusionEventTypeIds
    createdDate
    updatedDate
    cursor {
      lastExecutedTimestamp
      sourceState
      cursorValue
    }
  }
}
    `;
export const FusionExecutionDetailsFragmentDoc = gql`
    fragment FusionExecutionDetails on GetFusionSourceExecutionDetailsResult {
  cursorId
  executionTimestamp
  fusionSourceId
  logData
  logDataType
  status
}
    `;
export const CreateFusionSourceUploadUrlErrorFragmentDoc = gql`
    fragment CreateFusionSourceUploadUrlError on ArgumentError {
  message
  paramName
}
    `;
export const AvailableTenantStatisticalReportsConnectionFragmentDoc = gql`
    fragment AvailableTenantStatisticalReportsConnection on AvailableTenantStatisticalReportsConnection {
  edges {
    cursor
  }
  nodes {
    id
    name
    endTime
    startTime
    reportType
  }
  pageInfo {
    hasNextPage
    endCursor
  }
}
    `;
export const GetUrlsForTenantStatisticalReportsOutputFragmentDoc = gql`
    fragment GetUrlsForTenantStatisticalReportsOutput on GetUrlsForTenantStatisticalReportsOutput {
  preSignedUrlsForReportIds {
    key
    value
  }
}
    `;
export const ActivateFusionSourceDocument = gql`
    mutation ActivateFusionSource($filter: String, $cursorValue: String, $fusionSourceId: String!, $isActivatingForTest: Boolean) {
  activateFusionSource(
    input: {filter: $filter, cursorValue: $cursorValue, fusionSourceId: $fusionSourceId, isActivatingForTest: $isActivatingForTest}
  ) {
    activateFusionSourceResponse {
      asyncOperationId
    }
  }
}
    `;
export type ActivateFusionSourceMutationFn = Apollo.MutationFunction<ActivateFusionSourceMutation, ActivateFusionSourceMutationVariables>;

/**
 * __useActivateFusionSourceMutation__
 *
 * To run a mutation, you first call `useActivateFusionSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateFusionSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateFusionSourceMutation, { data, loading, error }] = useActivateFusionSourceMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursorValue: // value for 'cursorValue'
 *      fusionSourceId: // value for 'fusionSourceId'
 *      isActivatingForTest: // value for 'isActivatingForTest'
 *   },
 * });
 */
export function useActivateFusionSourceMutation(baseOptions?: Apollo.MutationHookOptions<ActivateFusionSourceMutation, ActivateFusionSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateFusionSourceMutation, ActivateFusionSourceMutationVariables>(ActivateFusionSourceDocument, options);
      }
export type ActivateFusionSourceMutationHookResult = ReturnType<typeof useActivateFusionSourceMutation>;
export type ActivateFusionSourceMutationResult = Apollo.MutationResult<ActivateFusionSourceMutation>;
export type ActivateFusionSourceMutationOptions = Apollo.BaseMutationOptions<ActivateFusionSourceMutation, ActivateFusionSourceMutationVariables>;
export const AddBotDocument = gql`
    mutation AddBot($botCredentials: String!, $botType: BotType!, $name: String!) {
  addBot(
    addBotInput: {botCredentials: $botCredentials, botType: $botType, name: $name}
  ) {
    botType
    name
  }
}
    `;
export type AddBotMutationFn = Apollo.MutationFunction<AddBotMutation, AddBotMutationVariables>;

/**
 * __useAddBotMutation__
 *
 * To run a mutation, you first call `useAddBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBotMutation, { data, loading, error }] = useAddBotMutation({
 *   variables: {
 *      botCredentials: // value for 'botCredentials'
 *      botType: // value for 'botType'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddBotMutation(baseOptions?: Apollo.MutationHookOptions<AddBotMutation, AddBotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBotMutation, AddBotMutationVariables>(AddBotDocument, options);
      }
export type AddBotMutationHookResult = ReturnType<typeof useAddBotMutation>;
export type AddBotMutationResult = Apollo.MutationResult<AddBotMutation>;
export type AddBotMutationOptions = Apollo.BaseMutationOptions<AddBotMutation, AddBotMutationVariables>;
export const AddCustomIconUrlDocument = gql`
    mutation AddCustomIconUrl($input: AddCustomIconUrlInput!) {
  addCustomIconUrl(input: $input) {
    iconUrl
  }
}
    `;
export type AddCustomIconUrlMutationFn = Apollo.MutationFunction<AddCustomIconUrlMutation, AddCustomIconUrlMutationVariables>;

/**
 * __useAddCustomIconUrlMutation__
 *
 * To run a mutation, you first call `useAddCustomIconUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomIconUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomIconUrlMutation, { data, loading, error }] = useAddCustomIconUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCustomIconUrlMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomIconUrlMutation, AddCustomIconUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomIconUrlMutation, AddCustomIconUrlMutationVariables>(AddCustomIconUrlDocument, options);
      }
export type AddCustomIconUrlMutationHookResult = ReturnType<typeof useAddCustomIconUrlMutation>;
export type AddCustomIconUrlMutationResult = Apollo.MutationResult<AddCustomIconUrlMutation>;
export type AddCustomIconUrlMutationOptions = Apollo.BaseMutationOptions<AddCustomIconUrlMutation, AddCustomIconUrlMutationVariables>;
export const AddEmailSenderDomainDocument = gql`
    mutation AddEmailSenderDomain($domainName: String!) {
  addEmailSenderDomain(addEmailSenderDomainInput: {domainName: $domainName}) {
    dkimTokens
    dnsVerificationToken
    domain
    id
    verificationStatus
  }
}
    `;
export type AddEmailSenderDomainMutationFn = Apollo.MutationFunction<AddEmailSenderDomainMutation, AddEmailSenderDomainMutationVariables>;

/**
 * __useAddEmailSenderDomainMutation__
 *
 * To run a mutation, you first call `useAddEmailSenderDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailSenderDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailSenderDomainMutation, { data, loading, error }] = useAddEmailSenderDomainMutation({
 *   variables: {
 *      domainName: // value for 'domainName'
 *   },
 * });
 */
export function useAddEmailSenderDomainMutation(baseOptions?: Apollo.MutationHookOptions<AddEmailSenderDomainMutation, AddEmailSenderDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmailSenderDomainMutation, AddEmailSenderDomainMutationVariables>(AddEmailSenderDomainDocument, options);
      }
export type AddEmailSenderDomainMutationHookResult = ReturnType<typeof useAddEmailSenderDomainMutation>;
export type AddEmailSenderDomainMutationResult = Apollo.MutationResult<AddEmailSenderDomainMutation>;
export type AddEmailSenderDomainMutationOptions = Apollo.BaseMutationOptions<AddEmailSenderDomainMutation, AddEmailSenderDomainMutationVariables>;
export const AddFusionEventToFusionSourceDocument = gql`
    mutation addFusionEventToFusionSource($fusionSourceId: String!, $fusionEventId: String!) {
  addFusionEventToFusionSource(
    input: {fusionSourceId: $fusionSourceId, fusionEventId: $fusionEventId}
  ) {
    ...AddFusionEventToFusionSourceResponse
  }
}
    ${AddFusionEventToFusionSourceResponseFragmentDoc}`;
export type AddFusionEventToFusionSourceMutationFn = Apollo.MutationFunction<AddFusionEventToFusionSourceMutation, AddFusionEventToFusionSourceMutationVariables>;

/**
 * __useAddFusionEventToFusionSourceMutation__
 *
 * To run a mutation, you first call `useAddFusionEventToFusionSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFusionEventToFusionSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFusionEventToFusionSourceMutation, { data, loading, error }] = useAddFusionEventToFusionSourceMutation({
 *   variables: {
 *      fusionSourceId: // value for 'fusionSourceId'
 *      fusionEventId: // value for 'fusionEventId'
 *   },
 * });
 */
export function useAddFusionEventToFusionSourceMutation(baseOptions?: Apollo.MutationHookOptions<AddFusionEventToFusionSourceMutation, AddFusionEventToFusionSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFusionEventToFusionSourceMutation, AddFusionEventToFusionSourceMutationVariables>(AddFusionEventToFusionSourceDocument, options);
      }
export type AddFusionEventToFusionSourceMutationHookResult = ReturnType<typeof useAddFusionEventToFusionSourceMutation>;
export type AddFusionEventToFusionSourceMutationResult = Apollo.MutationResult<AddFusionEventToFusionSourceMutation>;
export type AddFusionEventToFusionSourceMutationOptions = Apollo.BaseMutationOptions<AddFusionEventToFusionSourceMutation, AddFusionEventToFusionSourceMutationVariables>;
export const AddUserDocument = gql`
    mutation AddUser($email: String!, $roles: [String!]!) {
  createOrUpdateUser(createOrUpdateUserInput: {email: $email, roles: $roles}) {
    id
    email
    roles
    errors {
      ... on InvalidEmailError {
        message
      }
      ... on RequiresElevatedTokenError {
        code
        message
      }
    }
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const BatchBroadcastMessageDocument = gql`
    mutation BatchBroadcastMessage($input: BatchBroadcastMessageInput!) {
  batchBroadcastMessage(broadcastMessageInput: $input) {
    id
  }
}
    `;
export type BatchBroadcastMessageMutationFn = Apollo.MutationFunction<BatchBroadcastMessageMutation, BatchBroadcastMessageMutationVariables>;

/**
 * __useBatchBroadcastMessageMutation__
 *
 * To run a mutation, you first call `useBatchBroadcastMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchBroadcastMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchBroadcastMessageMutation, { data, loading, error }] = useBatchBroadcastMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchBroadcastMessageMutation(baseOptions?: Apollo.MutationHookOptions<BatchBroadcastMessageMutation, BatchBroadcastMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BatchBroadcastMessageMutation, BatchBroadcastMessageMutationVariables>(BatchBroadcastMessageDocument, options);
      }
export type BatchBroadcastMessageMutationHookResult = ReturnType<typeof useBatchBroadcastMessageMutation>;
export type BatchBroadcastMessageMutationResult = Apollo.MutationResult<BatchBroadcastMessageMutation>;
export type BatchBroadcastMessageMutationOptions = Apollo.BaseMutationOptions<BatchBroadcastMessageMutation, BatchBroadcastMessageMutationVariables>;
export const BeginAddTotp2FaDocument = gql`
    mutation BeginAddTOTP2FA {
  beginAddTOTP2FA {
    secret
  }
}
    `;
export type BeginAddTotp2FaMutationFn = Apollo.MutationFunction<BeginAddTotp2FaMutation, BeginAddTotp2FaMutationVariables>;

/**
 * __useBeginAddTotp2FaMutation__
 *
 * To run a mutation, you first call `useBeginAddTotp2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginAddTotp2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginAddTotp2FaMutation, { data, loading, error }] = useBeginAddTotp2FaMutation({
 *   variables: {
 *   },
 * });
 */
export function useBeginAddTotp2FaMutation(baseOptions?: Apollo.MutationHookOptions<BeginAddTotp2FaMutation, BeginAddTotp2FaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BeginAddTotp2FaMutation, BeginAddTotp2FaMutationVariables>(BeginAddTotp2FaDocument, options);
      }
export type BeginAddTotp2FaMutationHookResult = ReturnType<typeof useBeginAddTotp2FaMutation>;
export type BeginAddTotp2FaMutationResult = Apollo.MutationResult<BeginAddTotp2FaMutation>;
export type BeginAddTotp2FaMutationOptions = Apollo.BaseMutationOptions<BeginAddTotp2FaMutation, BeginAddTotp2FaMutationVariables>;
export const BeginLogInByQrCodeDocument = gql`
    mutation beginLogInByQRCode {
  beginLogInByQRCode {
    nonce
  }
}
    `;
export type BeginLogInByQrCodeMutationFn = Apollo.MutationFunction<BeginLogInByQrCodeMutation, BeginLogInByQrCodeMutationVariables>;

/**
 * __useBeginLogInByQrCodeMutation__
 *
 * To run a mutation, you first call `useBeginLogInByQrCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginLogInByQrCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginLogInByQrCodeMutation, { data, loading, error }] = useBeginLogInByQrCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useBeginLogInByQrCodeMutation(baseOptions?: Apollo.MutationHookOptions<BeginLogInByQrCodeMutation, BeginLogInByQrCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BeginLogInByQrCodeMutation, BeginLogInByQrCodeMutationVariables>(BeginLogInByQrCodeDocument, options);
      }
export type BeginLogInByQrCodeMutationHookResult = ReturnType<typeof useBeginLogInByQrCodeMutation>;
export type BeginLogInByQrCodeMutationResult = Apollo.MutationResult<BeginLogInByQrCodeMutation>;
export type BeginLogInByQrCodeMutationOptions = Apollo.BaseMutationOptions<BeginLogInByQrCodeMutation, BeginLogInByQrCodeMutationVariables>;
export const CancelCommunityManagerPublishTaskDocument = gql`
    mutation CancelCommunityManagerPublishTask($input: CancelCommunityManagerPublishTaskInput!) {
  cancelCommunityManagerPublishTask(input: $input) {
    boolean
  }
}
    `;
export type CancelCommunityManagerPublishTaskMutationFn = Apollo.MutationFunction<CancelCommunityManagerPublishTaskMutation, CancelCommunityManagerPublishTaskMutationVariables>;

/**
 * __useCancelCommunityManagerPublishTaskMutation__
 *
 * To run a mutation, you first call `useCancelCommunityManagerPublishTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCommunityManagerPublishTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCommunityManagerPublishTaskMutation, { data, loading, error }] = useCancelCommunityManagerPublishTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCommunityManagerPublishTaskMutation(baseOptions?: Apollo.MutationHookOptions<CancelCommunityManagerPublishTaskMutation, CancelCommunityManagerPublishTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelCommunityManagerPublishTaskMutation, CancelCommunityManagerPublishTaskMutationVariables>(CancelCommunityManagerPublishTaskDocument, options);
      }
export type CancelCommunityManagerPublishTaskMutationHookResult = ReturnType<typeof useCancelCommunityManagerPublishTaskMutation>;
export type CancelCommunityManagerPublishTaskMutationResult = Apollo.MutationResult<CancelCommunityManagerPublishTaskMutation>;
export type CancelCommunityManagerPublishTaskMutationOptions = Apollo.BaseMutationOptions<CancelCommunityManagerPublishTaskMutation, CancelCommunityManagerPublishTaskMutationVariables>;
export const ChangeExecutionTimeCmPublishTaskDocument = gql`
    mutation changeExecutionTimeCMPublishTask($id: String!, $newExecutionTime: DateTime!) {
  changeExecutionTimeCMPublishTask(
    input: {id: $id, newExecutionTime: $newExecutionTime}
  ) {
    boolean
  }
}
    `;
export type ChangeExecutionTimeCmPublishTaskMutationFn = Apollo.MutationFunction<ChangeExecutionTimeCmPublishTaskMutation, ChangeExecutionTimeCmPublishTaskMutationVariables>;

/**
 * __useChangeExecutionTimeCmPublishTaskMutation__
 *
 * To run a mutation, you first call `useChangeExecutionTimeCmPublishTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeExecutionTimeCmPublishTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeExecutionTimeCmPublishTaskMutation, { data, loading, error }] = useChangeExecutionTimeCmPublishTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newExecutionTime: // value for 'newExecutionTime'
 *   },
 * });
 */
export function useChangeExecutionTimeCmPublishTaskMutation(baseOptions?: Apollo.MutationHookOptions<ChangeExecutionTimeCmPublishTaskMutation, ChangeExecutionTimeCmPublishTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeExecutionTimeCmPublishTaskMutation, ChangeExecutionTimeCmPublishTaskMutationVariables>(ChangeExecutionTimeCmPublishTaskDocument, options);
      }
export type ChangeExecutionTimeCmPublishTaskMutationHookResult = ReturnType<typeof useChangeExecutionTimeCmPublishTaskMutation>;
export type ChangeExecutionTimeCmPublishTaskMutationResult = Apollo.MutationResult<ChangeExecutionTimeCmPublishTaskMutation>;
export type ChangeExecutionTimeCmPublishTaskMutationOptions = Apollo.BaseMutationOptions<ChangeExecutionTimeCmPublishTaskMutation, ChangeExecutionTimeCmPublishTaskMutationVariables>;
export const CompleteAddTotp2FaDocument = gql`
    mutation completeAddTOTP2FA($input: CompleteAddTOTP2FAInput!) {
  completeAddTOTP2FA(input: $input) {
    backupCodes
    elevatedPermissionsAuthorization {
      expiry
      serialNumber
      tenantInfo {
        allowedDomains {
          originAddress
        }
      }
      token
    }
    normalPermissionsAuthorization {
      expiry
      serialNumber
      tenantInfo {
        allowedDomains {
          originAddress
        }
      }
      token
    }
    refreshAuthorization {
      expiry
      serialNumber
      tenantInfo {
        allowedDomains {
          originAddress
        }
      }
      token
    }
  }
}
    `;
export type CompleteAddTotp2FaMutationFn = Apollo.MutationFunction<CompleteAddTotp2FaMutation, CompleteAddTotp2FaMutationVariables>;

/**
 * __useCompleteAddTotp2FaMutation__
 *
 * To run a mutation, you first call `useCompleteAddTotp2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAddTotp2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAddTotp2FaMutation, { data, loading, error }] = useCompleteAddTotp2FaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteAddTotp2FaMutation(baseOptions?: Apollo.MutationHookOptions<CompleteAddTotp2FaMutation, CompleteAddTotp2FaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteAddTotp2FaMutation, CompleteAddTotp2FaMutationVariables>(CompleteAddTotp2FaDocument, options);
      }
export type CompleteAddTotp2FaMutationHookResult = ReturnType<typeof useCompleteAddTotp2FaMutation>;
export type CompleteAddTotp2FaMutationResult = Apollo.MutationResult<CompleteAddTotp2FaMutation>;
export type CompleteAddTotp2FaMutationOptions = Apollo.BaseMutationOptions<CompleteAddTotp2FaMutation, CompleteAddTotp2FaMutationVariables>;
export const CreateCardConfigDocument = gql`
    mutation CreateCardConfig($id: String, $type: TenantConfigType = SUBSCRIPTION_CARD, $json: String!) {
  createTenantConfig(createTenantConfigInput: {id: $id, type: $type, data: $json}) {
    id
    type
    dataJson
  }
}
    `;
export type CreateCardConfigMutationFn = Apollo.MutationFunction<CreateCardConfigMutation, CreateCardConfigMutationVariables>;

/**
 * __useCreateCardConfigMutation__
 *
 * To run a mutation, you first call `useCreateCardConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardConfigMutation, { data, loading, error }] = useCreateCardConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      json: // value for 'json'
 *   },
 * });
 */
export function useCreateCardConfigMutation(baseOptions?: Apollo.MutationHookOptions<CreateCardConfigMutation, CreateCardConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCardConfigMutation, CreateCardConfigMutationVariables>(CreateCardConfigDocument, options);
      }
export type CreateCardConfigMutationHookResult = ReturnType<typeof useCreateCardConfigMutation>;
export type CreateCardConfigMutationResult = Apollo.MutationResult<CreateCardConfigMutation>;
export type CreateCardConfigMutationOptions = Apollo.BaseMutationOptions<CreateCardConfigMutation, CreateCardConfigMutationVariables>;
export const CreateDiscordBotConfigurationDocument = gql`
    mutation CreateDiscordBotConfiguration($discordClientId: String!, $discordClientSecret: String!, $discordGuildId: String!, $discordServerInviteLink: String!, $redirectUrl: String!) {
  createDiscordBotConfiguration(
    createDiscordBotConfigurationInput: {discordClientId: $discordClientId, discordClientSecret: $discordClientSecret, discordGuildId: $discordGuildId, discordServerInviteLink: $discordServerInviteLink, redirectUrl: $redirectUrl}
  ) {
    discordClientId
    discordGuildId
    discordServerInviteLink
    redirectUrl
  }
}
    `;
export type CreateDiscordBotConfigurationMutationFn = Apollo.MutationFunction<CreateDiscordBotConfigurationMutation, CreateDiscordBotConfigurationMutationVariables>;

/**
 * __useCreateDiscordBotConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateDiscordBotConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiscordBotConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiscordBotConfigurationMutation, { data, loading, error }] = useCreateDiscordBotConfigurationMutation({
 *   variables: {
 *      discordClientId: // value for 'discordClientId'
 *      discordClientSecret: // value for 'discordClientSecret'
 *      discordGuildId: // value for 'discordGuildId'
 *      discordServerInviteLink: // value for 'discordServerInviteLink'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCreateDiscordBotConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDiscordBotConfigurationMutation, CreateDiscordBotConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDiscordBotConfigurationMutation, CreateDiscordBotConfigurationMutationVariables>(CreateDiscordBotConfigurationDocument, options);
      }
export type CreateDiscordBotConfigurationMutationHookResult = ReturnType<typeof useCreateDiscordBotConfigurationMutation>;
export type CreateDiscordBotConfigurationMutationResult = Apollo.MutationResult<CreateDiscordBotConfigurationMutation>;
export type CreateDiscordBotConfigurationMutationOptions = Apollo.BaseMutationOptions<CreateDiscordBotConfigurationMutation, CreateDiscordBotConfigurationMutationVariables>;
export const CreateFusionEventDocument = gql`
    mutation createFusionEvent($name: String!, $isCommunityManagerEvent: Boolean, $metadataJson: String) {
  createFusionEvent(
    input: {name: $name, isCommunityManagerEvent: $isCommunityManagerEvent, metadataJson: $metadataJson}
  ) {
    ...FusionEventData
  }
}
    ${FusionEventDataFragmentDoc}`;
export type CreateFusionEventMutationFn = Apollo.MutationFunction<CreateFusionEventMutation, CreateFusionEventMutationVariables>;

/**
 * __useCreateFusionEventMutation__
 *
 * To run a mutation, you first call `useCreateFusionEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFusionEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFusionEventMutation, { data, loading, error }] = useCreateFusionEventMutation({
 *   variables: {
 *      name: // value for 'name'
 *      isCommunityManagerEvent: // value for 'isCommunityManagerEvent'
 *      metadataJson: // value for 'metadataJson'
 *   },
 * });
 */
export function useCreateFusionEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateFusionEventMutation, CreateFusionEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFusionEventMutation, CreateFusionEventMutationVariables>(CreateFusionEventDocument, options);
      }
export type CreateFusionEventMutationHookResult = ReturnType<typeof useCreateFusionEventMutation>;
export type CreateFusionEventMutationResult = Apollo.MutationResult<CreateFusionEventMutation>;
export type CreateFusionEventMutationOptions = Apollo.BaseMutationOptions<CreateFusionEventMutation, CreateFusionEventMutationVariables>;
export const CreateFusionSourceDocument = gql`
    mutation createFusionSource($name: String!, $blockchainType: WalletBlockchain!, $filter: String!, $fusionEventTypeIds: [String!]!) {
  createFusionSource(
    input: {name: $name, blockchainType: $blockchainType, filter: $filter, fusionEventTypeIds: $fusionEventTypeIds}
  ) {
    createFusionSourceResponse {
      fusionSource {
        id
        name
        version
        archivedDate
        blockchainSource
        filter
        fusionEventTypeIds
        createdDate
        updatedDate
      }
    }
    errors {
      __typename
      ... on Error {
        message
      }
      ... on ArgumentError {
        message
        paramName
      }
    }
  }
}
    `;
export type CreateFusionSourceMutationFn = Apollo.MutationFunction<CreateFusionSourceMutation, CreateFusionSourceMutationVariables>;

/**
 * __useCreateFusionSourceMutation__
 *
 * To run a mutation, you first call `useCreateFusionSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFusionSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFusionSourceMutation, { data, loading, error }] = useCreateFusionSourceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      blockchainType: // value for 'blockchainType'
 *      filter: // value for 'filter'
 *      fusionEventTypeIds: // value for 'fusionEventTypeIds'
 *   },
 * });
 */
export function useCreateFusionSourceMutation(baseOptions?: Apollo.MutationHookOptions<CreateFusionSourceMutation, CreateFusionSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFusionSourceMutation, CreateFusionSourceMutationVariables>(CreateFusionSourceDocument, options);
      }
export type CreateFusionSourceMutationHookResult = ReturnType<typeof useCreateFusionSourceMutation>;
export type CreateFusionSourceMutationResult = Apollo.MutationResult<CreateFusionSourceMutation>;
export type CreateFusionSourceMutationOptions = Apollo.BaseMutationOptions<CreateFusionSourceMutation, CreateFusionSourceMutationVariables>;
export const CreateFusionSourceUploadUrlDocument = gql`
    mutation CreateFusionSourceUploadUrl($byteCount: Long!, $fusionSourceId: String!) {
  createFusionSourceUploadUrl(
    input: {byteCount: $byteCount, fusionSourceId: $fusionSourceId}
  ) {
    createFusionSourceUploadUrlResponse {
      fusionSourcePutUrl
      version
    }
    errors {
      ...CreateFusionSourceUploadUrlError
    }
  }
}
    ${CreateFusionSourceUploadUrlErrorFragmentDoc}`;
export type CreateFusionSourceUploadUrlMutationFn = Apollo.MutationFunction<CreateFusionSourceUploadUrlMutation, CreateFusionSourceUploadUrlMutationVariables>;

/**
 * __useCreateFusionSourceUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateFusionSourceUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFusionSourceUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFusionSourceUploadUrlMutation, { data, loading, error }] = useCreateFusionSourceUploadUrlMutation({
 *   variables: {
 *      byteCount: // value for 'byteCount'
 *      fusionSourceId: // value for 'fusionSourceId'
 *   },
 * });
 */
export function useCreateFusionSourceUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateFusionSourceUploadUrlMutation, CreateFusionSourceUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFusionSourceUploadUrlMutation, CreateFusionSourceUploadUrlMutationVariables>(CreateFusionSourceUploadUrlDocument, options);
      }
export type CreateFusionSourceUploadUrlMutationHookResult = ReturnType<typeof useCreateFusionSourceUploadUrlMutation>;
export type CreateFusionSourceUploadUrlMutationResult = Apollo.MutationResult<CreateFusionSourceUploadUrlMutation>;
export type CreateFusionSourceUploadUrlMutationOptions = Apollo.BaseMutationOptions<CreateFusionSourceUploadUrlMutation, CreateFusionSourceUploadUrlMutationVariables>;
export const CreateOpenRegDocument = gql`
    mutation createOpenReg($input: CreateOpenUserRegistrationInput!) {
  createOpenUserRegistration(input: $input) {
    openUserRegistration {
      id
      name
      beginTime {
        toDateTime
      }
      endTime {
        toDateTime
      }
      fusionEventIds
      userRegistrationExecutionEndpoints {
        __typename
        ... on EmailUserRegistrationExecutionEndpoint {
          targetType
          postUrl
        }
        ... on TelegramUserRegistrationExecutionEndpoint {
          targetType
          userActionUrl
          groupUserActionUrl
        }
      }
    }
  }
}
    `;
export type CreateOpenRegMutationFn = Apollo.MutationFunction<CreateOpenRegMutation, CreateOpenRegMutationVariables>;

/**
 * __useCreateOpenRegMutation__
 *
 * To run a mutation, you first call `useCreateOpenRegMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpenRegMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpenRegMutation, { data, loading, error }] = useCreateOpenRegMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOpenRegMutation(baseOptions?: Apollo.MutationHookOptions<CreateOpenRegMutation, CreateOpenRegMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOpenRegMutation, CreateOpenRegMutationVariables>(CreateOpenRegDocument, options);
      }
export type CreateOpenRegMutationHookResult = ReturnType<typeof useCreateOpenRegMutation>;
export type CreateOpenRegMutationResult = Apollo.MutationResult<CreateOpenRegMutation>;
export type CreateOpenRegMutationOptions = Apollo.BaseMutationOptions<CreateOpenRegMutation, CreateOpenRegMutationVariables>;
export const DeactivateFusionSourceDocument = gql`
    mutation DeactivateFusionSource($fusionSourceId: String!) {
  deactivateFusionSource(input: {fusionSourceId: $fusionSourceId}) {
    deactivateFusionSourceResponse {
      asyncOperationId
    }
  }
}
    `;
export type DeactivateFusionSourceMutationFn = Apollo.MutationFunction<DeactivateFusionSourceMutation, DeactivateFusionSourceMutationVariables>;

/**
 * __useDeactivateFusionSourceMutation__
 *
 * To run a mutation, you first call `useDeactivateFusionSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateFusionSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateFusionSourceMutation, { data, loading, error }] = useDeactivateFusionSourceMutation({
 *   variables: {
 *      fusionSourceId: // value for 'fusionSourceId'
 *   },
 * });
 */
export function useDeactivateFusionSourceMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateFusionSourceMutation, DeactivateFusionSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateFusionSourceMutation, DeactivateFusionSourceMutationVariables>(DeactivateFusionSourceDocument, options);
      }
export type DeactivateFusionSourceMutationHookResult = ReturnType<typeof useDeactivateFusionSourceMutation>;
export type DeactivateFusionSourceMutationResult = Apollo.MutationResult<DeactivateFusionSourceMutation>;
export type DeactivateFusionSourceMutationOptions = Apollo.BaseMutationOptions<DeactivateFusionSourceMutation, DeactivateFusionSourceMutationVariables>;
export const DeleteCardConfigDocument = gql`
    mutation DeleteCardConfig($id: String!, $type: TenantConfigType = SUBSCRIPTION_CARD) {
  deleteTenantConfig(deleteTenantConfigInput: {id: $id, type: $type}) {
    id
    type
  }
}
    `;
export type DeleteCardConfigMutationFn = Apollo.MutationFunction<DeleteCardConfigMutation, DeleteCardConfigMutationVariables>;

/**
 * __useDeleteCardConfigMutation__
 *
 * To run a mutation, you first call `useDeleteCardConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardConfigMutation, { data, loading, error }] = useDeleteCardConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDeleteCardConfigMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardConfigMutation, DeleteCardConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardConfigMutation, DeleteCardConfigMutationVariables>(DeleteCardConfigDocument, options);
      }
export type DeleteCardConfigMutationHookResult = ReturnType<typeof useDeleteCardConfigMutation>;
export type DeleteCardConfigMutationResult = Apollo.MutationResult<DeleteCardConfigMutation>;
export type DeleteCardConfigMutationOptions = Apollo.BaseMutationOptions<DeleteCardConfigMutation, DeleteCardConfigMutationVariables>;
export const DeleteFusionEventDocument = gql`
    mutation DeleteFusionEvent($fusionEventId: String!) {
  deleteFusionEvent(fusionEventId: $fusionEventId)
}
    `;
export type DeleteFusionEventMutationFn = Apollo.MutationFunction<DeleteFusionEventMutation, DeleteFusionEventMutationVariables>;

/**
 * __useDeleteFusionEventMutation__
 *
 * To run a mutation, you first call `useDeleteFusionEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFusionEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFusionEventMutation, { data, loading, error }] = useDeleteFusionEventMutation({
 *   variables: {
 *      fusionEventId: // value for 'fusionEventId'
 *   },
 * });
 */
export function useDeleteFusionEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFusionEventMutation, DeleteFusionEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFusionEventMutation, DeleteFusionEventMutationVariables>(DeleteFusionEventDocument, options);
      }
export type DeleteFusionEventMutationHookResult = ReturnType<typeof useDeleteFusionEventMutation>;
export type DeleteFusionEventMutationResult = Apollo.MutationResult<DeleteFusionEventMutation>;
export type DeleteFusionEventMutationOptions = Apollo.BaseMutationOptions<DeleteFusionEventMutation, DeleteFusionEventMutationVariables>;
export const DeleteFusionSourceDocument = gql`
    mutation DeleteFusionSource($fusionSourceId: String!) {
  deleteFusionSource(input: {fusionSourceId: $fusionSourceId}) {
    success
  }
}
    `;
export type DeleteFusionSourceMutationFn = Apollo.MutationFunction<DeleteFusionSourceMutation, DeleteFusionSourceMutationVariables>;

/**
 * __useDeleteFusionSourceMutation__
 *
 * To run a mutation, you first call `useDeleteFusionSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFusionSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFusionSourceMutation, { data, loading, error }] = useDeleteFusionSourceMutation({
 *   variables: {
 *      fusionSourceId: // value for 'fusionSourceId'
 *   },
 * });
 */
export function useDeleteFusionSourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFusionSourceMutation, DeleteFusionSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFusionSourceMutation, DeleteFusionSourceMutationVariables>(DeleteFusionSourceDocument, options);
      }
export type DeleteFusionSourceMutationHookResult = ReturnType<typeof useDeleteFusionSourceMutation>;
export type DeleteFusionSourceMutationResult = Apollo.MutationResult<DeleteFusionSourceMutation>;
export type DeleteFusionSourceMutationOptions = Apollo.BaseMutationOptions<DeleteFusionSourceMutation, DeleteFusionSourceMutationVariables>;
export const DisableTotp2FaRequiredForEmailLoginsDocument = gql`
    mutation disableTOTP2FARequiredForEmailLogins {
  disableTOTP2FARequiredForEmailLogins {
    totp2faRequired
    errors {
      ... on RequiresElevatedTokenError {
        code
        message
      }
    }
  }
}
    `;
export type DisableTotp2FaRequiredForEmailLoginsMutationFn = Apollo.MutationFunction<DisableTotp2FaRequiredForEmailLoginsMutation, DisableTotp2FaRequiredForEmailLoginsMutationVariables>;

/**
 * __useDisableTotp2FaRequiredForEmailLoginsMutation__
 *
 * To run a mutation, you first call `useDisableTotp2FaRequiredForEmailLoginsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableTotp2FaRequiredForEmailLoginsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableTotp2FaRequiredForEmailLoginsMutation, { data, loading, error }] = useDisableTotp2FaRequiredForEmailLoginsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisableTotp2FaRequiredForEmailLoginsMutation(baseOptions?: Apollo.MutationHookOptions<DisableTotp2FaRequiredForEmailLoginsMutation, DisableTotp2FaRequiredForEmailLoginsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableTotp2FaRequiredForEmailLoginsMutation, DisableTotp2FaRequiredForEmailLoginsMutationVariables>(DisableTotp2FaRequiredForEmailLoginsDocument, options);
      }
export type DisableTotp2FaRequiredForEmailLoginsMutationHookResult = ReturnType<typeof useDisableTotp2FaRequiredForEmailLoginsMutation>;
export type DisableTotp2FaRequiredForEmailLoginsMutationResult = Apollo.MutationResult<DisableTotp2FaRequiredForEmailLoginsMutation>;
export type DisableTotp2FaRequiredForEmailLoginsMutationOptions = Apollo.BaseMutationOptions<DisableTotp2FaRequiredForEmailLoginsMutation, DisableTotp2FaRequiredForEmailLoginsMutationVariables>;
export const GenerateEmailConfirmationTokenDocument = gql`
    mutation generateEmailConfirmationToken($email: String) {
  generateEmailConfirmationToken(email: $email)
}
    `;
export type GenerateEmailConfirmationTokenMutationFn = Apollo.MutationFunction<GenerateEmailConfirmationTokenMutation, GenerateEmailConfirmationTokenMutationVariables>;

/**
 * __useGenerateEmailConfirmationTokenMutation__
 *
 * To run a mutation, you first call `useGenerateEmailConfirmationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateEmailConfirmationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateEmailConfirmationTokenMutation, { data, loading, error }] = useGenerateEmailConfirmationTokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGenerateEmailConfirmationTokenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateEmailConfirmationTokenMutation, GenerateEmailConfirmationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateEmailConfirmationTokenMutation, GenerateEmailConfirmationTokenMutationVariables>(GenerateEmailConfirmationTokenDocument, options);
      }
export type GenerateEmailConfirmationTokenMutationHookResult = ReturnType<typeof useGenerateEmailConfirmationTokenMutation>;
export type GenerateEmailConfirmationTokenMutationResult = Apollo.MutationResult<GenerateEmailConfirmationTokenMutation>;
export type GenerateEmailConfirmationTokenMutationOptions = Apollo.BaseMutationOptions<GenerateEmailConfirmationTokenMutation, GenerateEmailConfirmationTokenMutationVariables>;
export const ConfirmEmailDocument = gql`
    mutation confirmEmail($email: String!, $token: String!) {
  confirmEmail(email: $email, token: $token)
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const EnableTotp2FaRequiredForEmailLoginsDocument = gql`
    mutation enableTOTP2FARequiredForEmailLogins {
  enableTOTP2FARequiredForEmailLogins {
    totp2faRequired
    errors {
      ... on RequiresElevatedTokenError {
        code
        message
      }
    }
  }
}
    `;
export type EnableTotp2FaRequiredForEmailLoginsMutationFn = Apollo.MutationFunction<EnableTotp2FaRequiredForEmailLoginsMutation, EnableTotp2FaRequiredForEmailLoginsMutationVariables>;

/**
 * __useEnableTotp2FaRequiredForEmailLoginsMutation__
 *
 * To run a mutation, you first call `useEnableTotp2FaRequiredForEmailLoginsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableTotp2FaRequiredForEmailLoginsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableTotp2FaRequiredForEmailLoginsMutation, { data, loading, error }] = useEnableTotp2FaRequiredForEmailLoginsMutation({
 *   variables: {
 *   },
 * });
 */
export function useEnableTotp2FaRequiredForEmailLoginsMutation(baseOptions?: Apollo.MutationHookOptions<EnableTotp2FaRequiredForEmailLoginsMutation, EnableTotp2FaRequiredForEmailLoginsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableTotp2FaRequiredForEmailLoginsMutation, EnableTotp2FaRequiredForEmailLoginsMutationVariables>(EnableTotp2FaRequiredForEmailLoginsDocument, options);
      }
export type EnableTotp2FaRequiredForEmailLoginsMutationHookResult = ReturnType<typeof useEnableTotp2FaRequiredForEmailLoginsMutation>;
export type EnableTotp2FaRequiredForEmailLoginsMutationResult = Apollo.MutationResult<EnableTotp2FaRequiredForEmailLoginsMutation>;
export type EnableTotp2FaRequiredForEmailLoginsMutationOptions = Apollo.BaseMutationOptions<EnableTotp2FaRequiredForEmailLoginsMutation, EnableTotp2FaRequiredForEmailLoginsMutationVariables>;
export const FusionBroadcastMessageDocument = gql`
    mutation FusionBroadcastMessage($input: FusionBroadcastMessageInput!) {
  fusionBroadcastMessage(fusionBroadcastMessageInput: $input) {
    id
  }
}
    `;
export type FusionBroadcastMessageMutationFn = Apollo.MutationFunction<FusionBroadcastMessageMutation, FusionBroadcastMessageMutationVariables>;

/**
 * __useFusionBroadcastMessageMutation__
 *
 * To run a mutation, you first call `useFusionBroadcastMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFusionBroadcastMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fusionBroadcastMessageMutation, { data, loading, error }] = useFusionBroadcastMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFusionBroadcastMessageMutation(baseOptions?: Apollo.MutationHookOptions<FusionBroadcastMessageMutation, FusionBroadcastMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FusionBroadcastMessageMutation, FusionBroadcastMessageMutationVariables>(FusionBroadcastMessageDocument, options);
      }
export type FusionBroadcastMessageMutationHookResult = ReturnType<typeof useFusionBroadcastMessageMutation>;
export type FusionBroadcastMessageMutationResult = Apollo.MutationResult<FusionBroadcastMessageMutation>;
export type FusionBroadcastMessageMutationOptions = Apollo.BaseMutationOptions<FusionBroadcastMessageMutation, FusionBroadcastMessageMutationVariables>;
export const GenerateElevatedPermissionsAuthorizationDocument = gql`
    mutation GenerateElevatedPermissionsAuthorization($input: GenerateElevatedPermissionsAuthorizationInput!) {
  generateElevatedPermissionsAuthorization(input: $input) {
    authorization {
      expiry
      serialNumber
      tenantInfo {
        allowedDomains {
          originAddress
        }
      }
      token
    }
  }
}
    `;
export type GenerateElevatedPermissionsAuthorizationMutationFn = Apollo.MutationFunction<GenerateElevatedPermissionsAuthorizationMutation, GenerateElevatedPermissionsAuthorizationMutationVariables>;

/**
 * __useGenerateElevatedPermissionsAuthorizationMutation__
 *
 * To run a mutation, you first call `useGenerateElevatedPermissionsAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateElevatedPermissionsAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateElevatedPermissionsAuthorizationMutation, { data, loading, error }] = useGenerateElevatedPermissionsAuthorizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateElevatedPermissionsAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<GenerateElevatedPermissionsAuthorizationMutation, GenerateElevatedPermissionsAuthorizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateElevatedPermissionsAuthorizationMutation, GenerateElevatedPermissionsAuthorizationMutationVariables>(GenerateElevatedPermissionsAuthorizationDocument, options);
      }
export type GenerateElevatedPermissionsAuthorizationMutationHookResult = ReturnType<typeof useGenerateElevatedPermissionsAuthorizationMutation>;
export type GenerateElevatedPermissionsAuthorizationMutationResult = Apollo.MutationResult<GenerateElevatedPermissionsAuthorizationMutation>;
export type GenerateElevatedPermissionsAuthorizationMutationOptions = Apollo.BaseMutationOptions<GenerateElevatedPermissionsAuthorizationMutation, GenerateElevatedPermissionsAuthorizationMutationVariables>;
export const GenerateNormalPermissionsAuthorizationDocument = gql`
    mutation GenerateNormalPermissionsAuthorization {
  generateNormalPermissionsAuthorization {
    newRefreshAuthorization {
      expiry
      serialNumber
      tenantInfo {
        allowedDomains {
          originAddress
        }
      }
      token
    }
    normalPermissionsAuthorization {
      expiry
      serialNumber
      tenantInfo {
        allowedDomains {
          originAddress
        }
      }
      token
    }
  }
}
    `;
export type GenerateNormalPermissionsAuthorizationMutationFn = Apollo.MutationFunction<GenerateNormalPermissionsAuthorizationMutation, GenerateNormalPermissionsAuthorizationMutationVariables>;

/**
 * __useGenerateNormalPermissionsAuthorizationMutation__
 *
 * To run a mutation, you first call `useGenerateNormalPermissionsAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateNormalPermissionsAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateNormalPermissionsAuthorizationMutation, { data, loading, error }] = useGenerateNormalPermissionsAuthorizationMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateNormalPermissionsAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<GenerateNormalPermissionsAuthorizationMutation, GenerateNormalPermissionsAuthorizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateNormalPermissionsAuthorizationMutation, GenerateNormalPermissionsAuthorizationMutationVariables>(GenerateNormalPermissionsAuthorizationDocument, options);
      }
export type GenerateNormalPermissionsAuthorizationMutationHookResult = ReturnType<typeof useGenerateNormalPermissionsAuthorizationMutation>;
export type GenerateNormalPermissionsAuthorizationMutationResult = Apollo.MutationResult<GenerateNormalPermissionsAuthorizationMutation>;
export type GenerateNormalPermissionsAuthorizationMutationOptions = Apollo.BaseMutationOptions<GenerateNormalPermissionsAuthorizationMutation, GenerateNormalPermissionsAuthorizationMutationVariables>;
export const GenerateRefreshAuthorizationDocument = gql`
    mutation GenerateRefreshAuthorization($input: GenerateRefreshAuthorizationInput!) {
  generateRefreshAuthorization(input: $input) {
    authorization {
      expiry
      serialNumber
      tenantInfo {
        allowedDomains {
          originAddress
        }
      }
      token
    }
  }
}
    `;
export type GenerateRefreshAuthorizationMutationFn = Apollo.MutationFunction<GenerateRefreshAuthorizationMutation, GenerateRefreshAuthorizationMutationVariables>;

/**
 * __useGenerateRefreshAuthorizationMutation__
 *
 * To run a mutation, you first call `useGenerateRefreshAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateRefreshAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateRefreshAuthorizationMutation, { data, loading, error }] = useGenerateRefreshAuthorizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateRefreshAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<GenerateRefreshAuthorizationMutation, GenerateRefreshAuthorizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateRefreshAuthorizationMutation, GenerateRefreshAuthorizationMutationVariables>(GenerateRefreshAuthorizationDocument, options);
      }
export type GenerateRefreshAuthorizationMutationHookResult = ReturnType<typeof useGenerateRefreshAuthorizationMutation>;
export type GenerateRefreshAuthorizationMutationResult = Apollo.MutationResult<GenerateRefreshAuthorizationMutation>;
export type GenerateRefreshAuthorizationMutationOptions = Apollo.BaseMutationOptions<GenerateRefreshAuthorizationMutation, GenerateRefreshAuthorizationMutationVariables>;
export const SubmitLoginDocument = gql`
    mutation submitLogin($email: String!, $password: String!) {
  logInByEmailAddress(
    logInByEmailAddressInput: {emailAddress: $email, password: $password}
  ) {
    email
    emailConfirmed
    roles
    authorization {
      token
      expiry
    }
  }
}
    `;
export type SubmitLoginMutationFn = Apollo.MutationFunction<SubmitLoginMutation, SubmitLoginMutationVariables>;

/**
 * __useSubmitLoginMutation__
 *
 * To run a mutation, you first call `useSubmitLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitLoginMutation, { data, loading, error }] = useSubmitLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSubmitLoginMutation(baseOptions?: Apollo.MutationHookOptions<SubmitLoginMutation, SubmitLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitLoginMutation, SubmitLoginMutationVariables>(SubmitLoginDocument, options);
      }
export type SubmitLoginMutationHookResult = ReturnType<typeof useSubmitLoginMutation>;
export type SubmitLoginMutationResult = Apollo.MutationResult<SubmitLoginMutation>;
export type SubmitLoginMutationOptions = Apollo.BaseMutationOptions<SubmitLoginMutation, SubmitLoginMutationVariables>;
export const RefreshAuthorizationDocument = gql`
    mutation refreshAuthorization {
  refreshAuthorization {
    token
    expiry
  }
}
    `;
export type RefreshAuthorizationMutationFn = Apollo.MutationFunction<RefreshAuthorizationMutation, RefreshAuthorizationMutationVariables>;

/**
 * __useRefreshAuthorizationMutation__
 *
 * To run a mutation, you first call `useRefreshAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAuthorizationMutation, { data, loading, error }] = useRefreshAuthorizationMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<RefreshAuthorizationMutation, RefreshAuthorizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshAuthorizationMutation, RefreshAuthorizationMutationVariables>(RefreshAuthorizationDocument, options);
      }
export type RefreshAuthorizationMutationHookResult = ReturnType<typeof useRefreshAuthorizationMutation>;
export type RefreshAuthorizationMutationResult = Apollo.MutationResult<RefreshAuthorizationMutation>;
export type RefreshAuthorizationMutationOptions = Apollo.BaseMutationOptions<RefreshAuthorizationMutation, RefreshAuthorizationMutationVariables>;
export const Regenerate2FaBackupCodesDocument = gql`
    mutation regenerate2FABackupCodes {
  regenerate2FABackupCodes {
    backupCodes
    errors {
      ... on RequiresElevatedTokenError {
        code
        message
      }
    }
  }
}
    `;
export type Regenerate2FaBackupCodesMutationFn = Apollo.MutationFunction<Regenerate2FaBackupCodesMutation, Regenerate2FaBackupCodesMutationVariables>;

/**
 * __useRegenerate2FaBackupCodesMutation__
 *
 * To run a mutation, you first call `useRegenerate2FaBackupCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerate2FaBackupCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerate2FaBackupCodesMutation, { data, loading, error }] = useRegenerate2FaBackupCodesMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegenerate2FaBackupCodesMutation(baseOptions?: Apollo.MutationHookOptions<Regenerate2FaBackupCodesMutation, Regenerate2FaBackupCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Regenerate2FaBackupCodesMutation, Regenerate2FaBackupCodesMutationVariables>(Regenerate2FaBackupCodesDocument, options);
      }
export type Regenerate2FaBackupCodesMutationHookResult = ReturnType<typeof useRegenerate2FaBackupCodesMutation>;
export type Regenerate2FaBackupCodesMutationResult = Apollo.MutationResult<Regenerate2FaBackupCodesMutation>;
export type Regenerate2FaBackupCodesMutationOptions = Apollo.BaseMutationOptions<Regenerate2FaBackupCodesMutation, Regenerate2FaBackupCodesMutationVariables>;
export const RemoveBotDocument = gql`
    mutation RemoveBot($id: String!) {
  removeBot(removeBotInput: {id: $id}) {
    botType
    id
    name
  }
}
    `;
export type RemoveBotMutationFn = Apollo.MutationFunction<RemoveBotMutation, RemoveBotMutationVariables>;

/**
 * __useRemoveBotMutation__
 *
 * To run a mutation, you first call `useRemoveBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBotMutation, { data, loading, error }] = useRemoveBotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBotMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBotMutation, RemoveBotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBotMutation, RemoveBotMutationVariables>(RemoveBotDocument, options);
      }
export type RemoveBotMutationHookResult = ReturnType<typeof useRemoveBotMutation>;
export type RemoveBotMutationResult = Apollo.MutationResult<RemoveBotMutation>;
export type RemoveBotMutationOptions = Apollo.BaseMutationOptions<RemoveBotMutation, RemoveBotMutationVariables>;
export const RemoveEmailSenderDomainDocument = gql`
    mutation RemoveEmailSenderDomain($domainNameId: String!) {
  removeEmailSenderDomain(
    removeEmailSenderDomainInput: {domainNameId: $domainNameId}
  ) {
    dnsVerificationToken
    domain
    id
    verificationStatus
  }
}
    `;
export type RemoveEmailSenderDomainMutationFn = Apollo.MutationFunction<RemoveEmailSenderDomainMutation, RemoveEmailSenderDomainMutationVariables>;

/**
 * __useRemoveEmailSenderDomainMutation__
 *
 * To run a mutation, you first call `useRemoveEmailSenderDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEmailSenderDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEmailSenderDomainMutation, { data, loading, error }] = useRemoveEmailSenderDomainMutation({
 *   variables: {
 *      domainNameId: // value for 'domainNameId'
 *   },
 * });
 */
export function useRemoveEmailSenderDomainMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEmailSenderDomainMutation, RemoveEmailSenderDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEmailSenderDomainMutation, RemoveEmailSenderDomainMutationVariables>(RemoveEmailSenderDomainDocument, options);
      }
export type RemoveEmailSenderDomainMutationHookResult = ReturnType<typeof useRemoveEmailSenderDomainMutation>;
export type RemoveEmailSenderDomainMutationResult = Apollo.MutationResult<RemoveEmailSenderDomainMutation>;
export type RemoveEmailSenderDomainMutationOptions = Apollo.BaseMutationOptions<RemoveEmailSenderDomainMutation, RemoveEmailSenderDomainMutationVariables>;
export const RemoveFusionEventFromFusionSourceDocument = gql`
    mutation removeFusionEventFromFusionSource($fusionEventId: String!) {
  removeFusionEventFromFusionSource(input: {fusionEventId: $fusionEventId}) {
    ...RemoveFusionEventFromFusionSourceResponse
  }
}
    ${RemoveFusionEventFromFusionSourceResponseFragmentDoc}`;
export type RemoveFusionEventFromFusionSourceMutationFn = Apollo.MutationFunction<RemoveFusionEventFromFusionSourceMutation, RemoveFusionEventFromFusionSourceMutationVariables>;

/**
 * __useRemoveFusionEventFromFusionSourceMutation__
 *
 * To run a mutation, you first call `useRemoveFusionEventFromFusionSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFusionEventFromFusionSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFusionEventFromFusionSourceMutation, { data, loading, error }] = useRemoveFusionEventFromFusionSourceMutation({
 *   variables: {
 *      fusionEventId: // value for 'fusionEventId'
 *   },
 * });
 */
export function useRemoveFusionEventFromFusionSourceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFusionEventFromFusionSourceMutation, RemoveFusionEventFromFusionSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFusionEventFromFusionSourceMutation, RemoveFusionEventFromFusionSourceMutationVariables>(RemoveFusionEventFromFusionSourceDocument, options);
      }
export type RemoveFusionEventFromFusionSourceMutationHookResult = ReturnType<typeof useRemoveFusionEventFromFusionSourceMutation>;
export type RemoveFusionEventFromFusionSourceMutationResult = Apollo.MutationResult<RemoveFusionEventFromFusionSourceMutation>;
export type RemoveFusionEventFromFusionSourceMutationOptions = Apollo.BaseMutationOptions<RemoveFusionEventFromFusionSourceMutation, RemoveFusionEventFromFusionSourceMutationVariables>;
export const RemoveTotp2FaDocument = gql`
    mutation RemoveTOTP2FA {
  removeTOTP2FA {
    success
    errors {
      ... on RequiresElevatedTokenError {
        code
        message
      }
    }
  }
}
    `;
export type RemoveTotp2FaMutationFn = Apollo.MutationFunction<RemoveTotp2FaMutation, RemoveTotp2FaMutationVariables>;

/**
 * __useRemoveTotp2FaMutation__
 *
 * To run a mutation, you first call `useRemoveTotp2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTotp2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTotp2FaMutation, { data, loading, error }] = useRemoveTotp2FaMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveTotp2FaMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTotp2FaMutation, RemoveTotp2FaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTotp2FaMutation, RemoveTotp2FaMutationVariables>(RemoveTotp2FaDocument, options);
      }
export type RemoveTotp2FaMutationHookResult = ReturnType<typeof useRemoveTotp2FaMutation>;
export type RemoveTotp2FaMutationResult = Apollo.MutationResult<RemoveTotp2FaMutation>;
export type RemoveTotp2FaMutationOptions = Apollo.BaseMutationOptions<RemoveTotp2FaMutation, RemoveTotp2FaMutationVariables>;
export const RemoveUserRolesDocument = gql`
    mutation RemoveUserRoles($email: String!, $roles: [String!]!) {
  removeTenantUserRoles(
    removeTenantUserRolesInput: {email: $email, roles: $roles}
  ) {
    id
    email
    roles
  }
}
    `;
export type RemoveUserRolesMutationFn = Apollo.MutationFunction<RemoveUserRolesMutation, RemoveUserRolesMutationVariables>;

/**
 * __useRemoveUserRolesMutation__
 *
 * To run a mutation, you first call `useRemoveUserRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserRolesMutation, { data, loading, error }] = useRemoveUserRolesMutation({
 *   variables: {
 *      email: // value for 'email'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useRemoveUserRolesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserRolesMutation, RemoveUserRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserRolesMutation, RemoveUserRolesMutationVariables>(RemoveUserRolesDocument, options);
      }
export type RemoveUserRolesMutationHookResult = ReturnType<typeof useRemoveUserRolesMutation>;
export type RemoveUserRolesMutationResult = Apollo.MutationResult<RemoveUserRolesMutation>;
export type RemoveUserRolesMutationOptions = Apollo.BaseMutationOptions<RemoveUserRolesMutation, RemoveUserRolesMutationVariables>;
export const SendBroadcastDocument = gql`
    mutation SendBroadcast($input: BroadcastMessageInput!, $signature: String) {
  broadcastMessage(broadcastMessageInput: $input, signature: $signature) {
    id
  }
}
    `;
export type SendBroadcastMutationFn = Apollo.MutationFunction<SendBroadcastMutation, SendBroadcastMutationVariables>;

/**
 * __useSendBroadcastMutation__
 *
 * To run a mutation, you first call `useSendBroadcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBroadcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBroadcastMutation, { data, loading, error }] = useSendBroadcastMutation({
 *   variables: {
 *      input: // value for 'input'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useSendBroadcastMutation(baseOptions?: Apollo.MutationHookOptions<SendBroadcastMutation, SendBroadcastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendBroadcastMutation, SendBroadcastMutationVariables>(SendBroadcastDocument, options);
      }
export type SendBroadcastMutationHookResult = ReturnType<typeof useSendBroadcastMutation>;
export type SendBroadcastMutationResult = Apollo.MutationResult<SendBroadcastMutation>;
export type SendBroadcastMutationOptions = Apollo.BaseMutationOptions<SendBroadcastMutation, SendBroadcastMutationVariables>;
export const SendDirectPushDocument = gql`
    mutation SendDirectPush($input: SendMessageInput!) {
  sendMessage(sendMessageInput: $input)
}
    `;
export type SendDirectPushMutationFn = Apollo.MutationFunction<SendDirectPushMutation, SendDirectPushMutationVariables>;

/**
 * __useSendDirectPushMutation__
 *
 * To run a mutation, you first call `useSendDirectPushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDirectPushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDirectPushMutation, { data, loading, error }] = useSendDirectPushMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendDirectPushMutation(baseOptions?: Apollo.MutationHookOptions<SendDirectPushMutation, SendDirectPushMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDirectPushMutation, SendDirectPushMutationVariables>(SendDirectPushDocument, options);
      }
export type SendDirectPushMutationHookResult = ReturnType<typeof useSendDirectPushMutation>;
export type SendDirectPushMutationResult = Apollo.MutationResult<SendDirectPushMutation>;
export type SendDirectPushMutationOptions = Apollo.BaseMutationOptions<SendDirectPushMutation, SendDirectPushMutationVariables>;
export const SendEmailTargetVerificationRequestDocument = gql`
    mutation SendEmailTargetVerificationRequest($sendTargetConfirmationRequestInput: SendTargetConfirmationRequestInput) {
  sendEmailTargetVerificationRequest(
    sendTargetConfirmationRequestInput: $sendTargetConfirmationRequestInput
  ) {
    emailAddress
    isConfirmed
    id
    name
    createdDate
    updatedDate
  }
}
    `;
export type SendEmailTargetVerificationRequestMutationFn = Apollo.MutationFunction<SendEmailTargetVerificationRequestMutation, SendEmailTargetVerificationRequestMutationVariables>;

/**
 * __useSendEmailTargetVerificationRequestMutation__
 *
 * To run a mutation, you first call `useSendEmailTargetVerificationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailTargetVerificationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailTargetVerificationRequestMutation, { data, loading, error }] = useSendEmailTargetVerificationRequestMutation({
 *   variables: {
 *      sendTargetConfirmationRequestInput: // value for 'sendTargetConfirmationRequestInput'
 *   },
 * });
 */
export function useSendEmailTargetVerificationRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailTargetVerificationRequestMutation, SendEmailTargetVerificationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailTargetVerificationRequestMutation, SendEmailTargetVerificationRequestMutationVariables>(SendEmailTargetVerificationRequestDocument, options);
      }
export type SendEmailTargetVerificationRequestMutationHookResult = ReturnType<typeof useSendEmailTargetVerificationRequestMutation>;
export type SendEmailTargetVerificationRequestMutationResult = Apollo.MutationResult<SendEmailTargetVerificationRequestMutation>;
export type SendEmailTargetVerificationRequestMutationOptions = Apollo.BaseMutationOptions<SendEmailTargetVerificationRequestMutation, SendEmailTargetVerificationRequestMutationVariables>;
export const CreateTenantUserDocument = gql`
    mutation CreateTenantUser($email: String!, $dappId: String, $tenantName: String!, $password: String!, $captchaResponse: String!) {
  createTenant(
    createTenantInput: {email: $email, dappId: $dappId, captchaResponse: $captchaResponse, password: $password, tenantName: $tenantName}
  ) {
    email
    emailConfirmed
    id
  }
}
    `;
export type CreateTenantUserMutationFn = Apollo.MutationFunction<CreateTenantUserMutation, CreateTenantUserMutationVariables>;

/**
 * __useCreateTenantUserMutation__
 *
 * To run a mutation, you first call `useCreateTenantUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantUserMutation, { data, loading, error }] = useCreateTenantUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      dappId: // value for 'dappId'
 *      tenantName: // value for 'tenantName'
 *      password: // value for 'password'
 *      captchaResponse: // value for 'captchaResponse'
 *   },
 * });
 */
export function useCreateTenantUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantUserMutation, CreateTenantUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantUserMutation, CreateTenantUserMutationVariables>(CreateTenantUserDocument, options);
      }
export type CreateTenantUserMutationHookResult = ReturnType<typeof useCreateTenantUserMutation>;
export type CreateTenantUserMutationResult = Apollo.MutationResult<CreateTenantUserMutation>;
export type CreateTenantUserMutationOptions = Apollo.BaseMutationOptions<CreateTenantUserMutation, CreateTenantUserMutationVariables>;
export const UpdateDefaultEmailSenderDisplayNameDocument = gql`
    mutation UpdateDefaultEmailSenderDisplayName($defaultDisplayName: String) {
  updateDefaultEmailSenderDisplayName(
    updateDefaultEmailSenderDisplayNameInput: {defaultDisplayName: $defaultDisplayName}
  ) {
    allowedDomains {
      originAddress
    }
    defaultEmailSenderDisplayName
    defaultEmailSenderDomain {
      dkimTokens
      dnsVerificationToken
      domain
      id
      verificationStatus
    }
    defaultEmailSenderName
  }
}
    `;
export type UpdateDefaultEmailSenderDisplayNameMutationFn = Apollo.MutationFunction<UpdateDefaultEmailSenderDisplayNameMutation, UpdateDefaultEmailSenderDisplayNameMutationVariables>;

/**
 * __useUpdateDefaultEmailSenderDisplayNameMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultEmailSenderDisplayNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultEmailSenderDisplayNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultEmailSenderDisplayNameMutation, { data, loading, error }] = useUpdateDefaultEmailSenderDisplayNameMutation({
 *   variables: {
 *      defaultDisplayName: // value for 'defaultDisplayName'
 *   },
 * });
 */
export function useUpdateDefaultEmailSenderDisplayNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultEmailSenderDisplayNameMutation, UpdateDefaultEmailSenderDisplayNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDefaultEmailSenderDisplayNameMutation, UpdateDefaultEmailSenderDisplayNameMutationVariables>(UpdateDefaultEmailSenderDisplayNameDocument, options);
      }
export type UpdateDefaultEmailSenderDisplayNameMutationHookResult = ReturnType<typeof useUpdateDefaultEmailSenderDisplayNameMutation>;
export type UpdateDefaultEmailSenderDisplayNameMutationResult = Apollo.MutationResult<UpdateDefaultEmailSenderDisplayNameMutation>;
export type UpdateDefaultEmailSenderDisplayNameMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultEmailSenderDisplayNameMutation, UpdateDefaultEmailSenderDisplayNameMutationVariables>;
export const UpdateDefaultEmailSenderDomainDocument = gql`
    mutation UpdateDefaultEmailSenderDomain($defaultDomainId: String) {
  updateDefaultEmailSenderDomain(
    updateDefaultEmailSenderDomainInput: {defaultDomainId: $defaultDomainId}
  ) {
    allowedDomains {
      originAddress
    }
    defaultEmailSenderDisplayName
    defaultEmailSenderDomain {
      dkimTokens
      dnsVerificationToken
      domain
      id
      verificationStatus
    }
    defaultEmailSenderName
  }
}
    `;
export type UpdateDefaultEmailSenderDomainMutationFn = Apollo.MutationFunction<UpdateDefaultEmailSenderDomainMutation, UpdateDefaultEmailSenderDomainMutationVariables>;

/**
 * __useUpdateDefaultEmailSenderDomainMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultEmailSenderDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultEmailSenderDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultEmailSenderDomainMutation, { data, loading, error }] = useUpdateDefaultEmailSenderDomainMutation({
 *   variables: {
 *      defaultDomainId: // value for 'defaultDomainId'
 *   },
 * });
 */
export function useUpdateDefaultEmailSenderDomainMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultEmailSenderDomainMutation, UpdateDefaultEmailSenderDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDefaultEmailSenderDomainMutation, UpdateDefaultEmailSenderDomainMutationVariables>(UpdateDefaultEmailSenderDomainDocument, options);
      }
export type UpdateDefaultEmailSenderDomainMutationHookResult = ReturnType<typeof useUpdateDefaultEmailSenderDomainMutation>;
export type UpdateDefaultEmailSenderDomainMutationResult = Apollo.MutationResult<UpdateDefaultEmailSenderDomainMutation>;
export type UpdateDefaultEmailSenderDomainMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultEmailSenderDomainMutation, UpdateDefaultEmailSenderDomainMutationVariables>;
export const UpdateDefaultEmailSenderNameDocument = gql`
    mutation UpdateDefaultEmailSenderName($defaultName: String) {
  updateDefaultEmailSenderName(
    updateDefaultEmailSenderNameInput: {defaultName: $defaultName}
  ) {
    allowedDomains {
      originAddress
    }
    defaultEmailSenderDisplayName
    defaultEmailSenderDomain {
      dkimTokens
      dnsVerificationToken
      domain
      id
      verificationStatus
    }
    defaultEmailSenderName
  }
}
    `;
export type UpdateDefaultEmailSenderNameMutationFn = Apollo.MutationFunction<UpdateDefaultEmailSenderNameMutation, UpdateDefaultEmailSenderNameMutationVariables>;

/**
 * __useUpdateDefaultEmailSenderNameMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultEmailSenderNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultEmailSenderNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultEmailSenderNameMutation, { data, loading, error }] = useUpdateDefaultEmailSenderNameMutation({
 *   variables: {
 *      defaultName: // value for 'defaultName'
 *   },
 * });
 */
export function useUpdateDefaultEmailSenderNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultEmailSenderNameMutation, UpdateDefaultEmailSenderNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDefaultEmailSenderNameMutation, UpdateDefaultEmailSenderNameMutationVariables>(UpdateDefaultEmailSenderNameDocument, options);
      }
export type UpdateDefaultEmailSenderNameMutationHookResult = ReturnType<typeof useUpdateDefaultEmailSenderNameMutation>;
export type UpdateDefaultEmailSenderNameMutationResult = Apollo.MutationResult<UpdateDefaultEmailSenderNameMutation>;
export type UpdateDefaultEmailSenderNameMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultEmailSenderNameMutation, UpdateDefaultEmailSenderNameMutationVariables>;
export const UpdateEphemeralStorageDocument = gql`
    mutation updateEphemeralStorage($updateEphemeralStorageInput: UpdateEphemeralStorageInput!) {
  updateEphemeralStorage(
    updateEphemeralStorageInput: $updateEphemeralStorageInput
  ) {
    entries {
      key
      value
    }
  }
}
    `;
export type UpdateEphemeralStorageMutationFn = Apollo.MutationFunction<UpdateEphemeralStorageMutation, UpdateEphemeralStorageMutationVariables>;

/**
 * __useUpdateEphemeralStorageMutation__
 *
 * To run a mutation, you first call `useUpdateEphemeralStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEphemeralStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEphemeralStorageMutation, { data, loading, error }] = useUpdateEphemeralStorageMutation({
 *   variables: {
 *      updateEphemeralStorageInput: // value for 'updateEphemeralStorageInput'
 *   },
 * });
 */
export function useUpdateEphemeralStorageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEphemeralStorageMutation, UpdateEphemeralStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEphemeralStorageMutation, UpdateEphemeralStorageMutationVariables>(UpdateEphemeralStorageDocument, options);
      }
export type UpdateEphemeralStorageMutationHookResult = ReturnType<typeof useUpdateEphemeralStorageMutation>;
export type UpdateEphemeralStorageMutationResult = Apollo.MutationResult<UpdateEphemeralStorageMutation>;
export type UpdateEphemeralStorageMutationOptions = Apollo.BaseMutationOptions<UpdateEphemeralStorageMutation, UpdateEphemeralStorageMutationVariables>;
export const UpdateFusionEventDocument = gql`
    mutation updateFusionEvent($fusionEventId: String!, $name: String!, $metadataJson: String) {
  updateFusionEvent(
    input: {fusionEventId: $fusionEventId, name: $name, metadataJson: $metadataJson}
  ) {
    ...FusionEventData
  }
}
    ${FusionEventDataFragmentDoc}`;
export type UpdateFusionEventMutationFn = Apollo.MutationFunction<UpdateFusionEventMutation, UpdateFusionEventMutationVariables>;

/**
 * __useUpdateFusionEventMutation__
 *
 * To run a mutation, you first call `useUpdateFusionEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFusionEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFusionEventMutation, { data, loading, error }] = useUpdateFusionEventMutation({
 *   variables: {
 *      fusionEventId: // value for 'fusionEventId'
 *      name: // value for 'name'
 *      metadataJson: // value for 'metadataJson'
 *   },
 * });
 */
export function useUpdateFusionEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFusionEventMutation, UpdateFusionEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFusionEventMutation, UpdateFusionEventMutationVariables>(UpdateFusionEventDocument, options);
      }
export type UpdateFusionEventMutationHookResult = ReturnType<typeof useUpdateFusionEventMutation>;
export type UpdateFusionEventMutationResult = Apollo.MutationResult<UpdateFusionEventMutation>;
export type UpdateFusionEventMutationOptions = Apollo.BaseMutationOptions<UpdateFusionEventMutation, UpdateFusionEventMutationVariables>;
export const UpdateFusionSourceDocument = gql`
    mutation updateFusionSource($fusionSourceId: String!, $name: String, $filter: String) {
  updateFusionSource(
    input: {fusionSourceId: $fusionSourceId, name: $name, filter: $filter}
  ) {
    ...UpdateFusionSourceResponse
  }
}
    ${UpdateFusionSourceResponseFragmentDoc}`;
export type UpdateFusionSourceMutationFn = Apollo.MutationFunction<UpdateFusionSourceMutation, UpdateFusionSourceMutationVariables>;

/**
 * __useUpdateFusionSourceMutation__
 *
 * To run a mutation, you first call `useUpdateFusionSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFusionSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFusionSourceMutation, { data, loading, error }] = useUpdateFusionSourceMutation({
 *   variables: {
 *      fusionSourceId: // value for 'fusionSourceId'
 *      name: // value for 'name'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateFusionSourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFusionSourceMutation, UpdateFusionSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFusionSourceMutation, UpdateFusionSourceMutationVariables>(UpdateFusionSourceDocument, options);
      }
export type UpdateFusionSourceMutationHookResult = ReturnType<typeof useUpdateFusionSourceMutation>;
export type UpdateFusionSourceMutationResult = Apollo.MutationResult<UpdateFusionSourceMutation>;
export type UpdateFusionSourceMutationOptions = Apollo.BaseMutationOptions<UpdateFusionSourceMutation, UpdateFusionSourceMutationVariables>;
export const UpdateOpenUserRegistrationDocument = gql`
    mutation UpdateOpenUserRegistration($input: UpdateOpenUserRegistrationInput!) {
  updateOpenUserRegistration(input: $input) {
    updateOpenRegistrationResponse {
      clone {
        calculateSize
      }
    }
  }
}
    `;
export type UpdateOpenUserRegistrationMutationFn = Apollo.MutationFunction<UpdateOpenUserRegistrationMutation, UpdateOpenUserRegistrationMutationVariables>;

/**
 * __useUpdateOpenUserRegistrationMutation__
 *
 * To run a mutation, you first call `useUpdateOpenUserRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpenUserRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpenUserRegistrationMutation, { data, loading, error }] = useUpdateOpenUserRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOpenUserRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOpenUserRegistrationMutation, UpdateOpenUserRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOpenUserRegistrationMutation, UpdateOpenUserRegistrationMutationVariables>(UpdateOpenUserRegistrationDocument, options);
      }
export type UpdateOpenUserRegistrationMutationHookResult = ReturnType<typeof useUpdateOpenUserRegistrationMutation>;
export type UpdateOpenUserRegistrationMutationResult = Apollo.MutationResult<UpdateOpenUserRegistrationMutation>;
export type UpdateOpenUserRegistrationMutationOptions = Apollo.BaseMutationOptions<UpdateOpenUserRegistrationMutation, UpdateOpenUserRegistrationMutationVariables>;
export const UpdatePersistentStorageDocument = gql`
    mutation updatePersistentStorage($updatePersistentStorageInput: UpdatePersistentStorageInput!) {
  updatePersistentStorage(
    updatePersistentStorageInput: $updatePersistentStorageInput
  ) {
    entries {
      key
      value
    }
  }
}
    `;
export type UpdatePersistentStorageMutationFn = Apollo.MutationFunction<UpdatePersistentStorageMutation, UpdatePersistentStorageMutationVariables>;

/**
 * __useUpdatePersistentStorageMutation__
 *
 * To run a mutation, you first call `useUpdatePersistentStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersistentStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersistentStorageMutation, { data, loading, error }] = useUpdatePersistentStorageMutation({
 *   variables: {
 *      updatePersistentStorageInput: // value for 'updatePersistentStorageInput'
 *   },
 * });
 */
export function useUpdatePersistentStorageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersistentStorageMutation, UpdatePersistentStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersistentStorageMutation, UpdatePersistentStorageMutationVariables>(UpdatePersistentStorageDocument, options);
      }
export type UpdatePersistentStorageMutationHookResult = ReturnType<typeof useUpdatePersistentStorageMutation>;
export type UpdatePersistentStorageMutationResult = Apollo.MutationResult<UpdatePersistentStorageMutation>;
export type UpdatePersistentStorageMutationOptions = Apollo.BaseMutationOptions<UpdatePersistentStorageMutation, UpdatePersistentStorageMutationVariables>;
export const UploadTemplateDocument = gql`
    mutation UploadTemplate($templateName: String!, $template: String!, $type: TemplateType!) {
  uploadTemplate(templateName: $templateName, template: $template, type: $type)
}
    `;
export type UploadTemplateMutationFn = Apollo.MutationFunction<UploadTemplateMutation, UploadTemplateMutationVariables>;

/**
 * __useUploadTemplateMutation__
 *
 * To run a mutation, you first call `useUploadTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTemplateMutation, { data, loading, error }] = useUploadTemplateMutation({
 *   variables: {
 *      templateName: // value for 'templateName'
 *      template: // value for 'template'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUploadTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UploadTemplateMutation, UploadTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadTemplateMutation, UploadTemplateMutationVariables>(UploadTemplateDocument, options);
      }
export type UploadTemplateMutationHookResult = ReturnType<typeof useUploadTemplateMutation>;
export type UploadTemplateMutationResult = Apollo.MutationResult<UploadTemplateMutation>;
export type UploadTemplateMutationOptions = Apollo.BaseMutationOptions<UploadTemplateMutation, UploadTemplateMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!, $password: String!, $tfaCode: String!) {
  resetPassword(email: $email, password: $password, tfaCode: $tfaCode)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      tfaCode: // value for 'tfaCode'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendResetPasswordEmailDocument = gql`
    mutation sendResetPasswordEmail($email: String!) {
  sendResetPasswordEmail(email: $email)
}
    `;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>(SendResetPasswordEmailDocument, options);
      }
export type SendResetPasswordEmailMutationHookResult = ReturnType<typeof useSendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationResult = Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;
export const AllEmailSendersDocument = gql`
    query allEmailSenders {
  allEmailSenders {
    dkimTokens
    dnsVerificationToken
    domain
    id
    verificationStatus
  }
}
    `;

/**
 * __useAllEmailSendersQuery__
 *
 * To run a query within a React component, call `useAllEmailSendersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEmailSendersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEmailSendersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllEmailSendersQuery(baseOptions?: Apollo.QueryHookOptions<AllEmailSendersQuery, AllEmailSendersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllEmailSendersQuery, AllEmailSendersQueryVariables>(AllEmailSendersDocument, options);
      }
export function useAllEmailSendersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllEmailSendersQuery, AllEmailSendersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllEmailSendersQuery, AllEmailSendersQueryVariables>(AllEmailSendersDocument, options);
        }
export type AllEmailSendersQueryHookResult = ReturnType<typeof useAllEmailSendersQuery>;
export type AllEmailSendersLazyQueryHookResult = ReturnType<typeof useAllEmailSendersLazyQuery>;
export type AllEmailSendersQueryResult = Apollo.QueryResult<AllEmailSendersQuery, AllEmailSendersQueryVariables>;
export const BotsByTypeDocument = gql`
    query BotsByType($GetBotsByTypeInput: GetBotsByTypeInput!) {
  botsByType(getBotsByTypeInput: $GetBotsByTypeInput) {
    botType
    name
    id
    status
  }
}
    `;

/**
 * __useBotsByTypeQuery__
 *
 * To run a query within a React component, call `useBotsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBotsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBotsByTypeQuery({
 *   variables: {
 *      GetBotsByTypeInput: // value for 'GetBotsByTypeInput'
 *   },
 * });
 */
export function useBotsByTypeQuery(baseOptions: Apollo.QueryHookOptions<BotsByTypeQuery, BotsByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BotsByTypeQuery, BotsByTypeQueryVariables>(BotsByTypeDocument, options);
      }
export function useBotsByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BotsByTypeQuery, BotsByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BotsByTypeQuery, BotsByTypeQueryVariables>(BotsByTypeDocument, options);
        }
export type BotsByTypeQueryHookResult = ReturnType<typeof useBotsByTypeQuery>;
export type BotsByTypeLazyQueryHookResult = ReturnType<typeof useBotsByTypeLazyQuery>;
export type BotsByTypeQueryResult = Apollo.QueryResult<BotsByTypeQuery, BotsByTypeQueryVariables>;
export const CampaignPerformanceDocument = gql`
    query CampaignPerformance($id: String!) {
  campaignPerformance(id: $id) {
    campaignId
    created
    executionTime
    fusionEventTypeId
    id
    lastUpdated
    status
    __typename
    statisticsByTarget {
      closed
      delivered
      opened
      sent
      targetType
      __typename
    }
  }
}
    `;

/**
 * __useCampaignPerformanceQuery__
 *
 * To run a query within a React component, call `useCampaignPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignPerformanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignPerformanceQuery(baseOptions: Apollo.QueryHookOptions<CampaignPerformanceQuery, CampaignPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignPerformanceQuery, CampaignPerformanceQueryVariables>(CampaignPerformanceDocument, options);
      }
export function useCampaignPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignPerformanceQuery, CampaignPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignPerformanceQuery, CampaignPerformanceQueryVariables>(CampaignPerformanceDocument, options);
        }
export type CampaignPerformanceQueryHookResult = ReturnType<typeof useCampaignPerformanceQuery>;
export type CampaignPerformanceLazyQueryHookResult = ReturnType<typeof useCampaignPerformanceLazyQuery>;
export type CampaignPerformanceQueryResult = Apollo.QueryResult<CampaignPerformanceQuery, CampaignPerformanceQueryVariables>;
export const CampaignsByTenantIdDocument = gql`
    query CampaignsByTenantId($first: Int, $after: String, $last: Int, $before: String) {
  campaignsByTenantId(first: $first, after: $after, last: $last, before: $before) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    scheduled {
      campaignId
      delivered
      executionTime
      fusionEventTypeId
      id
      opened
      status
      sent
      createdDate
    }
    executing {
      campaignId
      delivered
      executionTime
      fusionEventTypeId
      id
      opened
      status
      sent
    }
    completed {
      campaignId
      delivered
      executionTime
      fusionEventTypeId
      id
      opened
      status
      sent
    }
  }
}
    `;

/**
 * __useCampaignsByTenantIdQuery__
 *
 * To run a query within a React component, call `useCampaignsByTenantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsByTenantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsByTenantIdQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useCampaignsByTenantIdQuery(baseOptions?: Apollo.QueryHookOptions<CampaignsByTenantIdQuery, CampaignsByTenantIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignsByTenantIdQuery, CampaignsByTenantIdQueryVariables>(CampaignsByTenantIdDocument, options);
      }
export function useCampaignsByTenantIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsByTenantIdQuery, CampaignsByTenantIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignsByTenantIdQuery, CampaignsByTenantIdQueryVariables>(CampaignsByTenantIdDocument, options);
        }
export type CampaignsByTenantIdQueryHookResult = ReturnType<typeof useCampaignsByTenantIdQuery>;
export type CampaignsByTenantIdLazyQueryHookResult = ReturnType<typeof useCampaignsByTenantIdLazyQuery>;
export type CampaignsByTenantIdQueryResult = Apollo.QueryResult<CampaignsByTenantIdQuery, CampaignsByTenantIdQueryVariables>;
export const CardConfigDocument = gql`
    query CardConfig($first: Int, $after: String, $type: TenantConfigType = SUBSCRIPTION_CARD) {
  tenantConfigs(first: $first, after: $after, getTenantConfigInput: {type: $type}) {
    nodes {
      id
      type
      dataJson
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useCardConfigQuery__
 *
 * To run a query within a React component, call `useCardConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardConfigQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCardConfigQuery(baseOptions?: Apollo.QueryHookOptions<CardConfigQuery, CardConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardConfigQuery, CardConfigQueryVariables>(CardConfigDocument, options);
      }
export function useCardConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardConfigQuery, CardConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardConfigQuery, CardConfigQueryVariables>(CardConfigDocument, options);
        }
export type CardConfigQueryHookResult = ReturnType<typeof useCardConfigQuery>;
export type CardConfigLazyQueryHookResult = ReturnType<typeof useCardConfigLazyQuery>;
export type CardConfigQueryResult = Apollo.QueryResult<CardConfigQuery, CardConfigQueryVariables>;
export const CommunityManagerPublishTaskDocument = gql`
    query CommunityManagerPublishTask($id: String!) {
  communityManagerPublishTask(id: $id) {
    campaignId
    executionTime
    fusionEventTypeId
    id
    createdDate
    status
    variablesJson
    specificWallets {
      address
      blockchainType
      calculateSize
    }
    __typename
  }
}
    `;

/**
 * __useCommunityManagerPublishTaskQuery__
 *
 * To run a query within a React component, call `useCommunityManagerPublishTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityManagerPublishTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityManagerPublishTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommunityManagerPublishTaskQuery(baseOptions: Apollo.QueryHookOptions<CommunityManagerPublishTaskQuery, CommunityManagerPublishTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityManagerPublishTaskQuery, CommunityManagerPublishTaskQueryVariables>(CommunityManagerPublishTaskDocument, options);
      }
export function useCommunityManagerPublishTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityManagerPublishTaskQuery, CommunityManagerPublishTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityManagerPublishTaskQuery, CommunityManagerPublishTaskQueryVariables>(CommunityManagerPublishTaskDocument, options);
        }
export type CommunityManagerPublishTaskQueryHookResult = ReturnType<typeof useCommunityManagerPublishTaskQuery>;
export type CommunityManagerPublishTaskLazyQueryHookResult = ReturnType<typeof useCommunityManagerPublishTaskLazyQuery>;
export type CommunityManagerPublishTaskQueryResult = Apollo.QueryResult<CommunityManagerPublishTaskQuery, CommunityManagerPublishTaskQueryVariables>;
export const EphemeralStorageDocument = gql`
    query EphemeralStorage($ephemeralStorageQueryInput: EphemeralStorageQueryInput) {
  ephemeralStorage(ephemeralStorageQueryInput: $ephemeralStorageQueryInput) {
    pageInfo {
      hasNextPage
      endCursor
    }
    nodes {
      key
      value
    }
  }
}
    `;

/**
 * __useEphemeralStorageQuery__
 *
 * To run a query within a React component, call `useEphemeralStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEphemeralStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEphemeralStorageQuery({
 *   variables: {
 *      ephemeralStorageQueryInput: // value for 'ephemeralStorageQueryInput'
 *   },
 * });
 */
export function useEphemeralStorageQuery(baseOptions?: Apollo.QueryHookOptions<EphemeralStorageQuery, EphemeralStorageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EphemeralStorageQuery, EphemeralStorageQueryVariables>(EphemeralStorageDocument, options);
      }
export function useEphemeralStorageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EphemeralStorageQuery, EphemeralStorageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EphemeralStorageQuery, EphemeralStorageQueryVariables>(EphemeralStorageDocument, options);
        }
export type EphemeralStorageQueryHookResult = ReturnType<typeof useEphemeralStorageQuery>;
export type EphemeralStorageLazyQueryHookResult = ReturnType<typeof useEphemeralStorageLazyQuery>;
export type EphemeralStorageQueryResult = Apollo.QueryResult<EphemeralStorageQuery, EphemeralStorageQueryVariables>;
export const FusionEventsDocument = gql`
    query FusionEvents($first: Int, $after: String, $fusionEventCategory: FusionEventCategory) {
  fusionEvents(
    first: $first
    after: $after
    fusionEventCategory: $fusionEventCategory
  ) {
    nodes {
      ...FusionEventData
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${FusionEventDataFragmentDoc}`;

/**
 * __useFusionEventsQuery__
 *
 * To run a query within a React component, call `useFusionEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFusionEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFusionEventsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      fusionEventCategory: // value for 'fusionEventCategory'
 *   },
 * });
 */
export function useFusionEventsQuery(baseOptions?: Apollo.QueryHookOptions<FusionEventsQuery, FusionEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FusionEventsQuery, FusionEventsQueryVariables>(FusionEventsDocument, options);
      }
export function useFusionEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FusionEventsQuery, FusionEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FusionEventsQuery, FusionEventsQueryVariables>(FusionEventsDocument, options);
        }
export type FusionEventsQueryHookResult = ReturnType<typeof useFusionEventsQuery>;
export type FusionEventsLazyQueryHookResult = ReturnType<typeof useFusionEventsLazyQuery>;
export type FusionEventsQueryResult = Apollo.QueryResult<FusionEventsQuery, FusionEventsQueryVariables>;
export const FusionExecutionLogsDocument = gql`
    query FusionExecutionLogs($first: Int, $after: String, $getFusionExecutionLogsInput: GetFusionExecutionLogsInput) {
  fusionExecutionLogs(
    first: $first
    after: $after
    getFusionExecutionLogsInput: $getFusionExecutionLogsInput
  ) {
    nodes {
      ...FusionExecutionDetails
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${FusionExecutionDetailsFragmentDoc}`;

/**
 * __useFusionExecutionLogsQuery__
 *
 * To run a query within a React component, call `useFusionExecutionLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFusionExecutionLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFusionExecutionLogsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      getFusionExecutionLogsInput: // value for 'getFusionExecutionLogsInput'
 *   },
 * });
 */
export function useFusionExecutionLogsQuery(baseOptions?: Apollo.QueryHookOptions<FusionExecutionLogsQuery, FusionExecutionLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FusionExecutionLogsQuery, FusionExecutionLogsQueryVariables>(FusionExecutionLogsDocument, options);
      }
export function useFusionExecutionLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FusionExecutionLogsQuery, FusionExecutionLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FusionExecutionLogsQuery, FusionExecutionLogsQueryVariables>(FusionExecutionLogsDocument, options);
        }
export type FusionExecutionLogsQueryHookResult = ReturnType<typeof useFusionExecutionLogsQuery>;
export type FusionExecutionLogsLazyQueryHookResult = ReturnType<typeof useFusionExecutionLogsLazyQuery>;
export type FusionExecutionLogsQueryResult = Apollo.QueryResult<FusionExecutionLogsQuery, FusionExecutionLogsQueryVariables>;
export const FusionPreviewCommunityManagerContentDocument = gql`
    query FusionPreviewCommunityManagerContent($fusionCommunityManagerPreviewInput: FusionCommunityManagerPreviewInput!) {
  fusionPreviewCommunityManagerContent(
    fusionCommunityManagerPreviewInput: $fusionCommunityManagerPreviewInput
  ) {
    content
  }
}
    `;

/**
 * __useFusionPreviewCommunityManagerContentQuery__
 *
 * To run a query within a React component, call `useFusionPreviewCommunityManagerContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFusionPreviewCommunityManagerContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFusionPreviewCommunityManagerContentQuery({
 *   variables: {
 *      fusionCommunityManagerPreviewInput: // value for 'fusionCommunityManagerPreviewInput'
 *   },
 * });
 */
export function useFusionPreviewCommunityManagerContentQuery(baseOptions: Apollo.QueryHookOptions<FusionPreviewCommunityManagerContentQuery, FusionPreviewCommunityManagerContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FusionPreviewCommunityManagerContentQuery, FusionPreviewCommunityManagerContentQueryVariables>(FusionPreviewCommunityManagerContentDocument, options);
      }
export function useFusionPreviewCommunityManagerContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FusionPreviewCommunityManagerContentQuery, FusionPreviewCommunityManagerContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FusionPreviewCommunityManagerContentQuery, FusionPreviewCommunityManagerContentQueryVariables>(FusionPreviewCommunityManagerContentDocument, options);
        }
export type FusionPreviewCommunityManagerContentQueryHookResult = ReturnType<typeof useFusionPreviewCommunityManagerContentQuery>;
export type FusionPreviewCommunityManagerContentLazyQueryHookResult = ReturnType<typeof useFusionPreviewCommunityManagerContentLazyQuery>;
export type FusionPreviewCommunityManagerContentQueryResult = Apollo.QueryResult<FusionPreviewCommunityManagerContentQuery, FusionPreviewCommunityManagerContentQueryVariables>;
export const FusionSourcesDocument = gql`
    query FusionSources($first: Int, $after: String) {
  fusionSources(first: $first, after: $after) {
    nodes {
      ...FusionSourceWithCursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${FusionSourceWithCursorFragmentDoc}`;

/**
 * __useFusionSourcesQuery__
 *
 * To run a query within a React component, call `useFusionSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFusionSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFusionSourcesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFusionSourcesQuery(baseOptions?: Apollo.QueryHookOptions<FusionSourcesQuery, FusionSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FusionSourcesQuery, FusionSourcesQueryVariables>(FusionSourcesDocument, options);
      }
export function useFusionSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FusionSourcesQuery, FusionSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FusionSourcesQuery, FusionSourcesQueryVariables>(FusionSourcesDocument, options);
        }
export type FusionSourcesQueryHookResult = ReturnType<typeof useFusionSourcesQuery>;
export type FusionSourcesLazyQueryHookResult = ReturnType<typeof useFusionSourcesLazyQuery>;
export type FusionSourcesQueryResult = Apollo.QueryResult<FusionSourcesQuery, FusionSourcesQueryVariables>;
export const AvailableTenantStatisticalReportsDocument = gql`
    query availableTenantStatisticalReports($first: Int, $after: String, $last: Int, $before: String, $input: GetAvailableTenantStatisticalReportsInput!) {
  availableTenantStatisticalReports(
    first: $first
    last: $last
    after: $after
    before: $before
    input: $input
  ) {
    ...AvailableTenantStatisticalReportsConnection
  }
}
    ${AvailableTenantStatisticalReportsConnectionFragmentDoc}`;

/**
 * __useAvailableTenantStatisticalReportsQuery__
 *
 * To run a query within a React component, call `useAvailableTenantStatisticalReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTenantStatisticalReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTenantStatisticalReportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailableTenantStatisticalReportsQuery(baseOptions: Apollo.QueryHookOptions<AvailableTenantStatisticalReportsQuery, AvailableTenantStatisticalReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableTenantStatisticalReportsQuery, AvailableTenantStatisticalReportsQueryVariables>(AvailableTenantStatisticalReportsDocument, options);
      }
export function useAvailableTenantStatisticalReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableTenantStatisticalReportsQuery, AvailableTenantStatisticalReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableTenantStatisticalReportsQuery, AvailableTenantStatisticalReportsQueryVariables>(AvailableTenantStatisticalReportsDocument, options);
        }
export type AvailableTenantStatisticalReportsQueryHookResult = ReturnType<typeof useAvailableTenantStatisticalReportsQuery>;
export type AvailableTenantStatisticalReportsLazyQueryHookResult = ReturnType<typeof useAvailableTenantStatisticalReportsLazyQuery>;
export type AvailableTenantStatisticalReportsQueryResult = Apollo.QueryResult<AvailableTenantStatisticalReportsQuery, AvailableTenantStatisticalReportsQueryVariables>;
export const GetEmailSenderDefaultsDocument = gql`
    query GetEmailSenderDefaults {
  emailSenderDefaults {
    defaultEmailSenderName
    defaultEmailSenderDisplayName
    defaultEmailSenderDomainId
  }
}
    `;

/**
 * __useGetEmailSenderDefaultsQuery__
 *
 * To run a query within a React component, call `useGetEmailSenderDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailSenderDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailSenderDefaultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailSenderDefaultsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailSenderDefaultsQuery, GetEmailSenderDefaultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailSenderDefaultsQuery, GetEmailSenderDefaultsQueryVariables>(GetEmailSenderDefaultsDocument, options);
      }
export function useGetEmailSenderDefaultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailSenderDefaultsQuery, GetEmailSenderDefaultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailSenderDefaultsQuery, GetEmailSenderDefaultsQueryVariables>(GetEmailSenderDefaultsDocument, options);
        }
export type GetEmailSenderDefaultsQueryHookResult = ReturnType<typeof useGetEmailSenderDefaultsQuery>;
export type GetEmailSenderDefaultsLazyQueryHookResult = ReturnType<typeof useGetEmailSenderDefaultsLazyQuery>;
export type GetEmailSenderDefaultsQueryResult = Apollo.QueryResult<GetEmailSenderDefaultsQuery, GetEmailSenderDefaultsQueryVariables>;
export const FusionSourceStateDocument = gql`
    query fusionSourceState($input: GetFusionSourceStateInput!) {
  fusionSourceState(input: $input) {
    ...FusionSourceWithCursor
  }
}
    ${FusionSourceWithCursorFragmentDoc}`;

/**
 * __useFusionSourceStateQuery__
 *
 * To run a query within a React component, call `useFusionSourceStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFusionSourceStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFusionSourceStateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFusionSourceStateQuery(baseOptions: Apollo.QueryHookOptions<FusionSourceStateQuery, FusionSourceStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FusionSourceStateQuery, FusionSourceStateQueryVariables>(FusionSourceStateDocument, options);
      }
export function useFusionSourceStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FusionSourceStateQuery, FusionSourceStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FusionSourceStateQuery, FusionSourceStateQueryVariables>(FusionSourceStateDocument, options);
        }
export type FusionSourceStateQueryHookResult = ReturnType<typeof useFusionSourceStateQuery>;
export type FusionSourceStateLazyQueryHookResult = ReturnType<typeof useFusionSourceStateLazyQuery>;
export type FusionSourceStateQueryResult = Apollo.QueryResult<FusionSourceStateQuery, FusionSourceStateQueryVariables>;
export const ListTemplatesDocument = gql`
    query ListTemplates($ListTemplatesInput: ListTemplatesInput!) {
  listTemplates(input: $ListTemplatesInput) {
    templates {
      createdDate
      id
      message
      templateName
      templateType
      tenantId
      updatedDate
    }
  }
}
    `;

/**
 * __useListTemplatesQuery__
 *
 * To run a query within a React component, call `useListTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTemplatesQuery({
 *   variables: {
 *      ListTemplatesInput: // value for 'ListTemplatesInput'
 *   },
 * });
 */
export function useListTemplatesQuery(baseOptions: Apollo.QueryHookOptions<ListTemplatesQuery, ListTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTemplatesQuery, ListTemplatesQueryVariables>(ListTemplatesDocument, options);
      }
export function useListTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTemplatesQuery, ListTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTemplatesQuery, ListTemplatesQueryVariables>(ListTemplatesDocument, options);
        }
export type ListTemplatesQueryHookResult = ReturnType<typeof useListTemplatesQuery>;
export type ListTemplatesLazyQueryHookResult = ReturnType<typeof useListTemplatesLazyQuery>;
export type ListTemplatesQueryResult = Apollo.QueryResult<ListTemplatesQuery, ListTemplatesQueryVariables>;
export const GetMessageDeliveryStatisticsDocument = gql`
    query GetMessageDeliveryStatistics {
  messageDeliveryStatistics {
    messagesByTarget {
      stats {
        key
        value {
          messages
          users
        }
      }
    }
    messagesByAttributes {
      stats {
        key
        value {
          messages
          users
        }
      }
    }
  }
}
    `;

/**
 * __useGetMessageDeliveryStatisticsQuery__
 *
 * To run a query within a React component, call `useGetMessageDeliveryStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageDeliveryStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageDeliveryStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMessageDeliveryStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<GetMessageDeliveryStatisticsQuery, GetMessageDeliveryStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageDeliveryStatisticsQuery, GetMessageDeliveryStatisticsQueryVariables>(GetMessageDeliveryStatisticsDocument, options);
      }
export function useGetMessageDeliveryStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageDeliveryStatisticsQuery, GetMessageDeliveryStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageDeliveryStatisticsQuery, GetMessageDeliveryStatisticsQueryVariables>(GetMessageDeliveryStatisticsDocument, options);
        }
export type GetMessageDeliveryStatisticsQueryHookResult = ReturnType<typeof useGetMessageDeliveryStatisticsQuery>;
export type GetMessageDeliveryStatisticsLazyQueryHookResult = ReturnType<typeof useGetMessageDeliveryStatisticsLazyQuery>;
export type GetMessageDeliveryStatisticsQueryResult = Apollo.QueryResult<GetMessageDeliveryStatisticsQuery, GetMessageDeliveryStatisticsQueryVariables>;
export const GetOpenUserRegistrationsDocument = gql`
    query GetOpenUserRegistrations {
  openUserRegistrations {
    nodes {
      id
      name
      fusionEventIds
      beginTime {
        toDateTime
      }
      endTime {
        toDateTime
      }
      userRegistrationExecutionEndpoints {
        ... on EmailUserRegistrationExecutionEndpoint {
          targetType
          postUrl
        }
        ... on TelegramUserRegistrationExecutionEndpoint {
          targetType
          userActionUrl
          groupUserActionUrl
        }
      }
    }
  }
}
    `;

/**
 * __useGetOpenUserRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetOpenUserRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenUserRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenUserRegistrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOpenUserRegistrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOpenUserRegistrationsQuery, GetOpenUserRegistrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOpenUserRegistrationsQuery, GetOpenUserRegistrationsQueryVariables>(GetOpenUserRegistrationsDocument, options);
      }
export function useGetOpenUserRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOpenUserRegistrationsQuery, GetOpenUserRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOpenUserRegistrationsQuery, GetOpenUserRegistrationsQueryVariables>(GetOpenUserRegistrationsDocument, options);
        }
export type GetOpenUserRegistrationsQueryHookResult = ReturnType<typeof useGetOpenUserRegistrationsQuery>;
export type GetOpenUserRegistrationsLazyQueryHookResult = ReturnType<typeof useGetOpenUserRegistrationsLazyQuery>;
export type GetOpenUserRegistrationsQueryResult = Apollo.QueryResult<GetOpenUserRegistrationsQuery, GetOpenUserRegistrationsQueryVariables>;
export const PublicDappMetadataDocument = gql`
    query publicDappMetadata($input: GetPublicDappMetadataInput!) {
  publicDappMetadata(input: $input) {
    iconUrl
  }
}
    `;

/**
 * __usePublicDappMetadataQuery__
 *
 * To run a query within a React component, call `usePublicDappMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicDappMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicDappMetadataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicDappMetadataQuery(baseOptions: Apollo.QueryHookOptions<PublicDappMetadataQuery, PublicDappMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicDappMetadataQuery, PublicDappMetadataQueryVariables>(PublicDappMetadataDocument, options);
      }
export function usePublicDappMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicDappMetadataQuery, PublicDappMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicDappMetadataQuery, PublicDappMetadataQueryVariables>(PublicDappMetadataDocument, options);
        }
export type PublicDappMetadataQueryHookResult = ReturnType<typeof usePublicDappMetadataQuery>;
export type PublicDappMetadataLazyQueryHookResult = ReturnType<typeof usePublicDappMetadataLazyQuery>;
export type PublicDappMetadataQueryResult = Apollo.QueryResult<PublicDappMetadataQuery, PublicDappMetadataQueryVariables>;
export const TemplateDataDocument = gql`
    query TemplateData($GetTemplateDataInput: GetTemplateDataInput!) {
  templateData(input: $GetTemplateDataInput) {
    template {
      createdDate
      id
      message
      templateName
      templateType
      tenantId
      updatedDate
    }
  }
}
    `;

/**
 * __useTemplateDataQuery__
 *
 * To run a query within a React component, call `useTemplateDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateDataQuery({
 *   variables: {
 *      GetTemplateDataInput: // value for 'GetTemplateDataInput'
 *   },
 * });
 */
export function useTemplateDataQuery(baseOptions: Apollo.QueryHookOptions<TemplateDataQuery, TemplateDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateDataQuery, TemplateDataQueryVariables>(TemplateDataDocument, options);
      }
export function useTemplateDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateDataQuery, TemplateDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateDataQuery, TemplateDataQueryVariables>(TemplateDataDocument, options);
        }
export type TemplateDataQueryHookResult = ReturnType<typeof useTemplateDataQuery>;
export type TemplateDataLazyQueryHookResult = ReturnType<typeof useTemplateDataLazyQuery>;
export type TemplateDataQueryResult = Apollo.QueryResult<TemplateDataQuery, TemplateDataQueryVariables>;
export const GetTenantAvailableReportsDocument = gql`
    query GetTenantAvailableReports {
  tenantAvailableReports {
    reports
  }
}
    `;

/**
 * __useGetTenantAvailableReportsQuery__
 *
 * To run a query within a React component, call `useGetTenantAvailableReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantAvailableReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantAvailableReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantAvailableReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantAvailableReportsQuery, GetTenantAvailableReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantAvailableReportsQuery, GetTenantAvailableReportsQueryVariables>(GetTenantAvailableReportsDocument, options);
      }
export function useGetTenantAvailableReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantAvailableReportsQuery, GetTenantAvailableReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantAvailableReportsQuery, GetTenantAvailableReportsQueryVariables>(GetTenantAvailableReportsDocument, options);
        }
export type GetTenantAvailableReportsQueryHookResult = ReturnType<typeof useGetTenantAvailableReportsQuery>;
export type GetTenantAvailableReportsLazyQueryHookResult = ReturnType<typeof useGetTenantAvailableReportsLazyQuery>;
export type GetTenantAvailableReportsQueryResult = Apollo.QueryResult<GetTenantAvailableReportsQuery, GetTenantAvailableReportsQueryVariables>;
export const GetTenantBillingPlanDocument = gql`
    query getTenantBillingPlan {
  billingPlan {
    billingPlanType
  }
}
    `;

/**
 * __useGetTenantBillingPlanQuery__
 *
 * To run a query within a React component, call `useGetTenantBillingPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantBillingPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantBillingPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantBillingPlanQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantBillingPlanQuery, GetTenantBillingPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantBillingPlanQuery, GetTenantBillingPlanQueryVariables>(GetTenantBillingPlanDocument, options);
      }
export function useGetTenantBillingPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantBillingPlanQuery, GetTenantBillingPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantBillingPlanQuery, GetTenantBillingPlanQueryVariables>(GetTenantBillingPlanDocument, options);
        }
export type GetTenantBillingPlanQueryHookResult = ReturnType<typeof useGetTenantBillingPlanQuery>;
export type GetTenantBillingPlanLazyQueryHookResult = ReturnType<typeof useGetTenantBillingPlanLazyQuery>;
export type GetTenantBillingPlanQueryResult = Apollo.QueryResult<GetTenantBillingPlanQuery, GetTenantBillingPlanQueryVariables>;
export const TenantDeliveryReportDocument = gql`
    query TenantDeliveryReport($GetTenantDeliveryReportRequestInput: GetTenantDeliveryReportRequestInput!) {
  tenantDeliveryReport(input: $GetTenantDeliveryReportRequestInput) {
    preSignedUrls {
      key
      value
    }
  }
}
    `;

/**
 * __useTenantDeliveryReportQuery__
 *
 * To run a query within a React component, call `useTenantDeliveryReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantDeliveryReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantDeliveryReportQuery({
 *   variables: {
 *      GetTenantDeliveryReportRequestInput: // value for 'GetTenantDeliveryReportRequestInput'
 *   },
 * });
 */
export function useTenantDeliveryReportQuery(baseOptions: Apollo.QueryHookOptions<TenantDeliveryReportQuery, TenantDeliveryReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantDeliveryReportQuery, TenantDeliveryReportQueryVariables>(TenantDeliveryReportDocument, options);
      }
export function useTenantDeliveryReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantDeliveryReportQuery, TenantDeliveryReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantDeliveryReportQuery, TenantDeliveryReportQueryVariables>(TenantDeliveryReportDocument, options);
        }
export type TenantDeliveryReportQueryHookResult = ReturnType<typeof useTenantDeliveryReportQuery>;
export type TenantDeliveryReportLazyQueryHookResult = ReturnType<typeof useTenantDeliveryReportLazyQuery>;
export type TenantDeliveryReportQueryResult = Apollo.QueryResult<TenantDeliveryReportQuery, TenantDeliveryReportQueryVariables>;
export const TenantDiscordBotConfigurationDocument = gql`
    query tenantDiscordBotConfiguration {
  tenantDiscordBotConfiguration {
    discordClientId
    discordGuildId
    discordServerInviteLink
    redirectUrl
  }
}
    `;

/**
 * __useTenantDiscordBotConfigurationQuery__
 *
 * To run a query within a React component, call `useTenantDiscordBotConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantDiscordBotConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantDiscordBotConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantDiscordBotConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<TenantDiscordBotConfigurationQuery, TenantDiscordBotConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantDiscordBotConfigurationQuery, TenantDiscordBotConfigurationQueryVariables>(TenantDiscordBotConfigurationDocument, options);
      }
export function useTenantDiscordBotConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantDiscordBotConfigurationQuery, TenantDiscordBotConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantDiscordBotConfigurationQuery, TenantDiscordBotConfigurationQueryVariables>(TenantDiscordBotConfigurationDocument, options);
        }
export type TenantDiscordBotConfigurationQueryHookResult = ReturnType<typeof useTenantDiscordBotConfigurationQuery>;
export type TenantDiscordBotConfigurationLazyQueryHookResult = ReturnType<typeof useTenantDiscordBotConfigurationLazyQuery>;
export type TenantDiscordBotConfigurationQueryResult = Apollo.QueryResult<TenantDiscordBotConfigurationQuery, TenantDiscordBotConfigurationQueryVariables>;
export const TenantFeatureFlagDocument = gql`
    query tenantFeatureFlag($TenantFeatureFlagInput: TenantFeatureFlagInput!) {
  tenantFeatureFlag(input: $TenantFeatureFlagInput) {
    name
    value
  }
}
    `;

/**
 * __useTenantFeatureFlagQuery__
 *
 * To run a query within a React component, call `useTenantFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantFeatureFlagQuery({
 *   variables: {
 *      TenantFeatureFlagInput: // value for 'TenantFeatureFlagInput'
 *   },
 * });
 */
export function useTenantFeatureFlagQuery(baseOptions: Apollo.QueryHookOptions<TenantFeatureFlagQuery, TenantFeatureFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantFeatureFlagQuery, TenantFeatureFlagQueryVariables>(TenantFeatureFlagDocument, options);
      }
export function useTenantFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantFeatureFlagQuery, TenantFeatureFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantFeatureFlagQuery, TenantFeatureFlagQueryVariables>(TenantFeatureFlagDocument, options);
        }
export type TenantFeatureFlagQueryHookResult = ReturnType<typeof useTenantFeatureFlagQuery>;
export type TenantFeatureFlagLazyQueryHookResult = ReturnType<typeof useTenantFeatureFlagLazyQuery>;
export type TenantFeatureFlagQueryResult = Apollo.QueryResult<TenantFeatureFlagQuery, TenantFeatureFlagQueryVariables>;
export const TenantMessagesByMonthDocument = gql`
    query tenantMessagesByMonth {
  totalMessagesPerMonthByTenant {
    messagesPerMonth {
      key
      value
    }
  }
}
    `;

/**
 * __useTenantMessagesByMonthQuery__
 *
 * To run a query within a React component, call `useTenantMessagesByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantMessagesByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantMessagesByMonthQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantMessagesByMonthQuery(baseOptions?: Apollo.QueryHookOptions<TenantMessagesByMonthQuery, TenantMessagesByMonthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantMessagesByMonthQuery, TenantMessagesByMonthQueryVariables>(TenantMessagesByMonthDocument, options);
      }
export function useTenantMessagesByMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantMessagesByMonthQuery, TenantMessagesByMonthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantMessagesByMonthQuery, TenantMessagesByMonthQueryVariables>(TenantMessagesByMonthDocument, options);
        }
export type TenantMessagesByMonthQueryHookResult = ReturnType<typeof useTenantMessagesByMonthQuery>;
export type TenantMessagesByMonthLazyQueryHookResult = ReturnType<typeof useTenantMessagesByMonthLazyQuery>;
export type TenantMessagesByMonthQueryResult = Apollo.QueryResult<TenantMessagesByMonthQuery, TenantMessagesByMonthQueryVariables>;
export const TenantMessagesByTopicDocument = gql`
    query tenantMessagesByTopic($cursor: String, $startDate: DateTime, $endDate: DateTime) {
  totalMessagesPerTopicByTenant(
    input: {startDate: $startDate, endDate: $endDate}
    after: $cursor
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    nodes {
      topicName
      eventTypeId
      subscriberCount
      messageCount
    }
  }
}
    `;

/**
 * __useTenantMessagesByTopicQuery__
 *
 * To run a query within a React component, call `useTenantMessagesByTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantMessagesByTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantMessagesByTopicQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useTenantMessagesByTopicQuery(baseOptions?: Apollo.QueryHookOptions<TenantMessagesByTopicQuery, TenantMessagesByTopicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantMessagesByTopicQuery, TenantMessagesByTopicQueryVariables>(TenantMessagesByTopicDocument, options);
      }
export function useTenantMessagesByTopicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantMessagesByTopicQuery, TenantMessagesByTopicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantMessagesByTopicQuery, TenantMessagesByTopicQueryVariables>(TenantMessagesByTopicDocument, options);
        }
export type TenantMessagesByTopicQueryHookResult = ReturnType<typeof useTenantMessagesByTopicQuery>;
export type TenantMessagesByTopicLazyQueryHookResult = ReturnType<typeof useTenantMessagesByTopicLazyQuery>;
export type TenantMessagesByTopicQueryResult = Apollo.QueryResult<TenantMessagesByTopicQuery, TenantMessagesByTopicQueryVariables>;
export const UrlsForTenantStatisticalReportsDocument = gql`
    query urlsForTenantStatisticalReports($input: GetUrlsForTenantStatisticalReportsInput!) {
  urlsForTenantStatisticalReports(input: $input) {
    ...GetUrlsForTenantStatisticalReportsOutput
  }
}
    ${GetUrlsForTenantStatisticalReportsOutputFragmentDoc}`;

/**
 * __useUrlsForTenantStatisticalReportsQuery__
 *
 * To run a query within a React component, call `useUrlsForTenantStatisticalReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUrlsForTenantStatisticalReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUrlsForTenantStatisticalReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUrlsForTenantStatisticalReportsQuery(baseOptions: Apollo.QueryHookOptions<UrlsForTenantStatisticalReportsQuery, UrlsForTenantStatisticalReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UrlsForTenantStatisticalReportsQuery, UrlsForTenantStatisticalReportsQueryVariables>(UrlsForTenantStatisticalReportsDocument, options);
      }
export function useUrlsForTenantStatisticalReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UrlsForTenantStatisticalReportsQuery, UrlsForTenantStatisticalReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UrlsForTenantStatisticalReportsQuery, UrlsForTenantStatisticalReportsQueryVariables>(UrlsForTenantStatisticalReportsDocument, options);
        }
export type UrlsForTenantStatisticalReportsQueryHookResult = ReturnType<typeof useUrlsForTenantStatisticalReportsQuery>;
export type UrlsForTenantStatisticalReportsLazyQueryHookResult = ReturnType<typeof useUrlsForTenantStatisticalReportsLazyQuery>;
export type UrlsForTenantStatisticalReportsQueryResult = Apollo.QueryResult<UrlsForTenantStatisticalReportsQuery, UrlsForTenantStatisticalReportsQueryVariables>;
export const GetUserTopicsDocument = gql`
    query GetUserTopics {
  usersByTopic {
    usersByTopic {
      key
      value
    }
  }
}
    `;

/**
 * __useGetUserTopicsQuery__
 *
 * To run a query within a React component, call `useGetUserTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserTopicsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserTopicsQuery, GetUserTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTopicsQuery, GetUserTopicsQueryVariables>(GetUserTopicsDocument, options);
      }
export function useGetUserTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTopicsQuery, GetUserTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTopicsQuery, GetUserTopicsQueryVariables>(GetUserTopicsDocument, options);
        }
export type GetUserTopicsQueryHookResult = ReturnType<typeof useGetUserTopicsQuery>;
export type GetUserTopicsLazyQueryHookResult = ReturnType<typeof useGetUserTopicsLazyQuery>;
export type GetUserTopicsQueryResult = Apollo.QueryResult<GetUserTopicsQuery, GetUserTopicsQueryVariables>;
export const Is2faDocument = gql`
    query is2fa {
  totp2FAStatus {
    isAdded
    isRequired
    isEnforced
  }
}
    `;

/**
 * __useIs2faQuery__
 *
 * To run a query within a React component, call `useIs2faQuery` and pass it any options that fit your needs.
 * When your component renders, `useIs2faQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIs2faQuery({
 *   variables: {
 *   },
 * });
 */
export function useIs2faQuery(baseOptions?: Apollo.QueryHookOptions<Is2faQuery, Is2faQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Is2faQuery, Is2faQueryVariables>(Is2faDocument, options);
      }
export function useIs2faLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Is2faQuery, Is2faQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Is2faQuery, Is2faQueryVariables>(Is2faDocument, options);
        }
export type Is2faQueryHookResult = ReturnType<typeof useIs2faQuery>;
export type Is2faLazyQueryHookResult = ReturnType<typeof useIs2faLazyQuery>;
export type Is2faQueryResult = Apollo.QueryResult<Is2faQuery, Is2faQueryVariables>;
export const PersistentStorageDocument = gql`
    query PersistentStorage($PersistentStorageQueryInput: PersistentStorageQueryInput, $first: Int, $after: String) {
  persistentStorage(
    persistentStorageQueryInput: $PersistentStorageQueryInput
    first: $first
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    nodes {
      createdDate {
        calculateSize
        nanos
        seconds
        toDateTime
        toDateTimeOffset
        toDiagnosticString
      }
      key
      updatedDate {
        calculateSize
        nanos
        seconds
        toDateTime
        toDateTimeOffset
        toDiagnosticString
      }
      value
      version
    }
  }
}
    `;

/**
 * __usePersistentStorageQuery__
 *
 * To run a query within a React component, call `usePersistentStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersistentStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersistentStorageQuery({
 *   variables: {
 *      PersistentStorageQueryInput: // value for 'PersistentStorageQueryInput'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePersistentStorageQuery(baseOptions?: Apollo.QueryHookOptions<PersistentStorageQuery, PersistentStorageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersistentStorageQuery, PersistentStorageQueryVariables>(PersistentStorageDocument, options);
      }
export function usePersistentStorageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersistentStorageQuery, PersistentStorageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersistentStorageQuery, PersistentStorageQueryVariables>(PersistentStorageDocument, options);
        }
export type PersistentStorageQueryHookResult = ReturnType<typeof usePersistentStorageQuery>;
export type PersistentStorageLazyQueryHookResult = ReturnType<typeof usePersistentStorageLazyQuery>;
export type PersistentStorageQueryResult = Apollo.QueryResult<PersistentStorageQuery, PersistentStorageQueryVariables>;
export const PreviewCommunityManagerContentDocument = gql`
    query previewCommunityManagerContent($previewInput: CommunityManagerPreviewInput!) {
  previewCommunityManagerContent(communityManagerPreviewInput: $previewInput) {
    content
  }
}
    `;

/**
 * __usePreviewCommunityManagerContentQuery__
 *
 * To run a query within a React component, call `usePreviewCommunityManagerContentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewCommunityManagerContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewCommunityManagerContentQuery({
 *   variables: {
 *      previewInput: // value for 'previewInput'
 *   },
 * });
 */
export function usePreviewCommunityManagerContentQuery(baseOptions: Apollo.QueryHookOptions<PreviewCommunityManagerContentQuery, PreviewCommunityManagerContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewCommunityManagerContentQuery, PreviewCommunityManagerContentQueryVariables>(PreviewCommunityManagerContentDocument, options);
      }
export function usePreviewCommunityManagerContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewCommunityManagerContentQuery, PreviewCommunityManagerContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewCommunityManagerContentQuery, PreviewCommunityManagerContentQueryVariables>(PreviewCommunityManagerContentDocument, options);
        }
export type PreviewCommunityManagerContentQueryHookResult = ReturnType<typeof usePreviewCommunityManagerContentQuery>;
export type PreviewCommunityManagerContentLazyQueryHookResult = ReturnType<typeof usePreviewCommunityManagerContentLazyQuery>;
export type PreviewCommunityManagerContentQueryResult = Apollo.QueryResult<PreviewCommunityManagerContentQuery, PreviewCommunityManagerContentQueryVariables>;
export const ServiceIdentifierDocument = gql`
    query serviceIdentifier {
  serviceIdentifier {
    id
    secret
  }
}
    `;

/**
 * __useServiceIdentifierQuery__
 *
 * To run a query within a React component, call `useServiceIdentifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceIdentifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceIdentifierQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceIdentifierQuery(baseOptions?: Apollo.QueryHookOptions<ServiceIdentifierQuery, ServiceIdentifierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceIdentifierQuery, ServiceIdentifierQueryVariables>(ServiceIdentifierDocument, options);
      }
export function useServiceIdentifierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceIdentifierQuery, ServiceIdentifierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceIdentifierQuery, ServiceIdentifierQueryVariables>(ServiceIdentifierDocument, options);
        }
export type ServiceIdentifierQueryHookResult = ReturnType<typeof useServiceIdentifierQuery>;
export type ServiceIdentifierLazyQueryHookResult = ReturnType<typeof useServiceIdentifierLazyQuery>;
export type ServiceIdentifierQueryResult = Apollo.QueryResult<ServiceIdentifierQuery, ServiceIdentifierQueryVariables>;
export const TenantApprovalStatusDocument = gql`
    query TenantApprovalStatus {
  tenantApprovalStatus
}
    `;

/**
 * __useTenantApprovalStatusQuery__
 *
 * To run a query within a React component, call `useTenantApprovalStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantApprovalStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantApprovalStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantApprovalStatusQuery(baseOptions?: Apollo.QueryHookOptions<TenantApprovalStatusQuery, TenantApprovalStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantApprovalStatusQuery, TenantApprovalStatusQueryVariables>(TenantApprovalStatusDocument, options);
      }
export function useTenantApprovalStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantApprovalStatusQuery, TenantApprovalStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantApprovalStatusQuery, TenantApprovalStatusQueryVariables>(TenantApprovalStatusDocument, options);
        }
export type TenantApprovalStatusQueryHookResult = ReturnType<typeof useTenantApprovalStatusQuery>;
export type TenantApprovalStatusLazyQueryHookResult = ReturnType<typeof useTenantApprovalStatusLazyQuery>;
export type TenantApprovalStatusQueryResult = Apollo.QueryResult<TenantApprovalStatusQuery, TenantApprovalStatusQueryVariables>;
export const TenantUsersDocument = gql`
    query TenantUsers($filterRoles: [String!]) {
  tenantUsers(tenantUsersInput: {filterRoles: $filterRoles}) {
    nodes {
      id
      email
      userName
      roles
    }
  }
}
    `;

/**
 * __useTenantUsersQuery__
 *
 * To run a query within a React component, call `useTenantUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantUsersQuery({
 *   variables: {
 *      filterRoles: // value for 'filterRoles'
 *   },
 * });
 */
export function useTenantUsersQuery(baseOptions?: Apollo.QueryHookOptions<TenantUsersQuery, TenantUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantUsersQuery, TenantUsersQueryVariables>(TenantUsersDocument, options);
      }
export function useTenantUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantUsersQuery, TenantUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantUsersQuery, TenantUsersQueryVariables>(TenantUsersDocument, options);
        }
export type TenantUsersQueryHookResult = ReturnType<typeof useTenantUsersQuery>;
export type TenantUsersLazyQueryHookResult = ReturnType<typeof useTenantUsersLazyQuery>;
export type TenantUsersQueryResult = Apollo.QueryResult<TenantUsersQuery, TenantUsersQueryVariables>;
export const TopicsDocument = gql`
    query topics($first: Int, $after: String) {
  topics(first: $first, after: $after) {
    nodes {
      targetCollections
      targetTemplate
      topicName
      name
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useTopicsQuery__
 *
 * To run a query within a React component, call `useTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTopicsQuery(baseOptions?: Apollo.QueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
      }
export function useTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
        }
export type TopicsQueryHookResult = ReturnType<typeof useTopicsQuery>;
export type TopicsLazyQueryHookResult = ReturnType<typeof useTopicsLazyQuery>;
export type TopicsQueryResult = Apollo.QueryResult<TopicsQuery, TopicsQueryVariables>;
export const UserProfileDocument = gql`
    query UserProfile {
  userProfile {
    tenantInfo {
      dappId
      name
    }
    id
  }
}
    `;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const Totp2FaRequiredForEmailLoginsDocument = gql`
    query totp2FARequiredForEmailLogins {
  totp2FARequiredForEmailLogins {
    totp2faRequired
  }
}
    `;

/**
 * __useTotp2FaRequiredForEmailLoginsQuery__
 *
 * To run a query within a React component, call `useTotp2FaRequiredForEmailLoginsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotp2FaRequiredForEmailLoginsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotp2FaRequiredForEmailLoginsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotp2FaRequiredForEmailLoginsQuery(baseOptions?: Apollo.QueryHookOptions<Totp2FaRequiredForEmailLoginsQuery, Totp2FaRequiredForEmailLoginsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Totp2FaRequiredForEmailLoginsQuery, Totp2FaRequiredForEmailLoginsQueryVariables>(Totp2FaRequiredForEmailLoginsDocument, options);
      }
export function useTotp2FaRequiredForEmailLoginsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Totp2FaRequiredForEmailLoginsQuery, Totp2FaRequiredForEmailLoginsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Totp2FaRequiredForEmailLoginsQuery, Totp2FaRequiredForEmailLoginsQueryVariables>(Totp2FaRequiredForEmailLoginsDocument, options);
        }
export type Totp2FaRequiredForEmailLoginsQueryHookResult = ReturnType<typeof useTotp2FaRequiredForEmailLoginsQuery>;
export type Totp2FaRequiredForEmailLoginsLazyQueryHookResult = ReturnType<typeof useTotp2FaRequiredForEmailLoginsLazyQuery>;
export type Totp2FaRequiredForEmailLoginsQueryResult = Apollo.QueryResult<Totp2FaRequiredForEmailLoginsQuery, Totp2FaRequiredForEmailLoginsQueryVariables>;