import { RefObject, useCallback, useEffect } from 'react';


 const useClickOutside = (ref: RefObject<HTMLDivElement>, callback: () => void) => {
  const handleClick = useCallback((e: MouseEvent) => {
    if (ref.current && !ref.current.contains((e.target as HTMLDivElement))) {
      callback();
    }
  }, [callback, ref]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);
};


export default useClickOutside