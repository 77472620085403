import DOMPurify from 'dompurify';

export function isValidEmail(email: string): boolean {
  return email.indexOf('@') === email.lastIndexOf('@');
}

export function isEmpty(str?: string | null): str is null | undefined {
  if (str == null || str === undefined || str.length === 0 || str === '') {
    return true;
  }
  return false;
}

/**
 * Sanitizes string input utility function
 * @param str input string
 * @returns trimmed and lowercased string
 **/

export function sanitizeStringInput(str: string): string {
  return str.trim().toLowerCase();
}

export function sanitizeHTML(str: string): string {
  return DOMPurify.sanitize(str);
}

export function toCamelCase(name: string) {
  const words = name.replace(/[\s_]+/g, ' ').split(' ');
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (i === 0) {
      words[i] = word.toLowerCase();
    } else {
      words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  }
  return words.join('');
}

export function replaceUnderline(input: string): string {
  return input.replace('_', ' ');
}

export function toEllipse(
  str: string,
  index?: number,
  indexStart?: number,
): string {
  const idx = index || 8;
  const idxStart = indexStart || 8;
  if (!str) return '';
  return `${str.slice(0, idxStart)}...${str.slice(str.length - idx)}`;
}

export const isValidJson = (json: string) => {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
};

export const isTOTPBackupCode = (str: string) => {
  const pattern = /[A-Z0-9]{5}-[A-Z0-9]{5}/;
  return pattern.test(str);
};

export const extractMonthAndYearFromUrl = (
  url: string,
): { month: string; year: string } | null => {
  const regex = /\/([A-Za-z]+)_(\d{4})\.csv/;
  const match = url.match(regex);

  if (match && match[1] && match[2]) {
    return { month: match[1], year: match[2] };
  }

  return null;
};

export function combineCampaigns(data: any) {
  const {
    scheduled = [],
    executing = [],
    completed = [],
  } = data?.campaignsByTenantId || {};

  // Combine the arrays into a single array of campaigns 
  return [...scheduled, ...executing, ...completed];
}
