import { gql } from '@apollo/client';

export const GetOpenUserRegistrationsQuery = gql`
  query GetOpenUserRegistrations {
    openUserRegistrations {
      nodes {
        id
        name
        fusionEventIds
        beginTime {
          toDateTime
        }
        endTime {
          toDateTime
        }
        userRegistrationExecutionEndpoints {
          ... on EmailUserRegistrationExecutionEndpoint {
            targetType
            postUrl
          }
          ... on TelegramUserRegistrationExecutionEndpoint {
            targetType
            userActionUrl
            groupUserActionUrl
          }
        }
      }
    }
  }
`;
