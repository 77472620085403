import { TenantConfigType } from 'src/services/gql/generated';
import {
  SubscriptionCardConfigItemV1,
  createSubscriptionDefault as createSubscriptionDefaultV1,
  IntercomCardConfigItemV1,
  createIntercomDefault as createIntercomDefaultV1,
} from './CardConfigItemV1';

export type CardConfigItem =
  | SubscriptionCardConfigItemV1
  | IntercomCardConfigItemV1;

export const createDefaultCardConfigItem = (
  name: string,
  type: TenantConfigType,
): CardConfigItem => {
  switch (type) {
    case TenantConfigType.SUBSCRIPTION_CARD:
      return createSubscriptionDefaultV1(name);
    case TenantConfigType.INTERCOM_CARD:
      return createIntercomDefaultV1(name);
  }
};

export const cardConfigItemFromJson = (
  id: string | null,
  json: string | null,
): CardConfigItem | null => {
  if (json === null) {
    return null;
  }

  const obj = JSON.parse(json);
  if (typeof obj !== 'object' || obj === null) {
    return null;
  }

  const name = obj.name;
  if (typeof name !== 'string') {
    return null;
  }

  switch (obj['version']) {
    case 'v1': {
      return {
        ...createSubscriptionDefaultV1(name),
        ...obj,
        id: obj.id ?? id,
      } as SubscriptionCardConfigItemV1;
    }
    case 'IntercomV1': {
      return {
        ...createIntercomDefaultV1(name),
        ...obj,
        id: obj.id ?? id,
      } as IntercomCardConfigItemV1;
    }
    default:
      return null;
  }
};
