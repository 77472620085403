import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  dusk,
  heather,
  midnight,
  seaglassDarker,
  white,
} from 'src/constants/colors';
import './index.css';

type Props = {
  data: { id: number; value: string; isRemovable: boolean }[];
  handleInsertData: () => void;
  handleDataChange: (args: { value: string; id: number }) => void;
  handleRemoveData: (id: number) => void;
  title: string;
  actionText: string;
  inputLabel: string;
};

const AddFilterData = ({
  data,
  actionText,
  title,
  handleInsertData,
  handleDataChange,
  handleRemoveData,
  inputLabel,
}: Props) => {
  const refToLastElementView = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    refToLastElementView?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [data.length]);

  return (
    <>
      <Stack
        mt={3}
        borderRadius={1}
        border={'1px solid'}
        borderColor={heather}
      >
        <Stack
          alignItems={'center'}
          width="100%"
          py={2}
          sx={{
            ...(data.length > 0 && {
              borderBottom: '1px solid',
              borderColor: heather,
            }),
          }}
        >
          <Stack
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width="100%"
            px={2}
          >
            <Typography
              sx={{
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: '700',
                color: midnight,
              }}
            >
              {title}
            </Typography>

            <Typography
              sx={{
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: '700',
                color: seaglassDarker,
                cursor: 'pointer',
              }}
              onClick={handleInsertData}
            >
              {actionText}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          className="custom-scrollbar"
          width={'100%'}
          alignItems={'center'}
          maxHeight={'210px'}
          sx={{
            overflowY: 'auto',
            overscrollBehavior: 'contain',
          }}
        >
          <Stack width="100%" px={2}>
            {data.map(({ id, value, isRemovable }, i, arr) => (
              <Stack
                mt={1.5}
                key={id}
                mb={arr.length - 1 === i ? 2 : 0}
              >
                <InputLabel
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    fontFamily: 'Rota',
                    color: dusk,
                    textAlign: 'left',
                    mb: 0.125,
                  }}
                >
                  {inputLabel}
                </InputLabel>

                <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                  <TextField
                    autoComplete="off"
                    value={value}
                    ref={arr.length - 1 === i ? refToLastElementView : null}
                    sx={{
                      flexGrow: 1,
                    }}
                    onChange={(e) => {
                      if (!isRemovable) return;
                      const value = e.target.value;
                      handleDataChange({ value, id });
                    }}
                  />

                  <IconButton
                    sx={{
                      flexShrink: 0,
                      p: 0,
                      backgroundColor: white,
                      borderRadius: '45px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: isRemovable ? 'pointer' : 'not-allowed',
                      '&:hover': {
                        backgroundColor: white,
                      },
                    }}
                    onClick={() => {
                      if (isRemovable) handleRemoveData(id);
                    }}
                  >
                    <DeleteIcon
                      sx={{ color: isRemovable ? dusk : '#dfdfed' }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default AddFilterData;
