import { ContactInfoConfig, DEFAULT_CONTACT_INFO_CONFIG } from './ContactInfo';
import { LabelsConfig } from './LabelType';
import { DEFAULT_LABEL_CONFIG } from '../form/labels/LabelTypeItems';
import { TopicTypeConfig } from './TopicTypeItem';
import { InputsConfig } from './InputItem';

export type SubscriptionCardConfigItemV1 = Readonly<{
  version: 'v1';
  id: string | null;
  name: string;
  eventTypes: TopicTypeConfig;
  inputs: InputsConfig;
  contactInfo: ContactInfoConfig;
  titles: TitleSubtitleConfig;
  isContactInfoRequired: boolean;
  walletChain: string;
}>;

export type TitleSubtitleConfigInactive = Readonly<{ active: false }>;

export type TitleSubtitleConfigActive = Readonly<{
  active: true;
  editView: string;
  previewView: string;
  historyView: string;
  signupView: string;
  expiredView: string;
  alertDetailsView: string;
  verifyWalletsView: string;
}>;

export type TitleSubtitleConfig =
  | TitleSubtitleConfigActive
  | TitleSubtitleConfigInactive;

export type IntercomCardConfigItemV1 = Readonly<{
  version: 'IntercomV1';
  id: string | null;
  name: string;
  labels: LabelsConfig;
  contactInfo: ContactInfoConfig;
  walletChain: string;
}>;

export type CardVersion =
  | SubscriptionCardConfigItemV1['version']
  | IntercomCardConfigItemV1['version'];

export const createSubscriptionDefault = (
  name: string,
): SubscriptionCardConfigItemV1 => {
  return {
    version: 'v1',
    id: null,
    name,
    eventTypes: [],
    inputs: [],
    contactInfo: DEFAULT_CONTACT_INFO_CONFIG,
    titles: { active: false },
    isContactInfoRequired: true,
    walletChain: '',
  };
};

export const createIntercomDefault = (
  name: string,
): IntercomCardConfigItemV1 => {
  return {
    version: 'IntercomV1',
    id: null,
    name,
    labels: DEFAULT_LABEL_CONFIG,
    contactInfo: DEFAULT_CONTACT_INFO_CONFIG,
    walletChain: '',
  };
};
