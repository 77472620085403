import { gql } from '@apollo/client';

export const RemoveUserRoles = gql`
  mutation RemoveUserRoles($email: String!, $roles: [String!]!) {
    removeTenantUserRoles(
      removeTenantUserRolesInput: { email: $email, roles: $roles }
    ) {
      id
      email
      roles
    }
  }
`;
