import { gql } from '@apollo/client';

export const SignUpMutation = gql`
  mutation CreateTenantUser(
    $email: String!
    $dappId: String
    $tenantName: String!
    $password: String!
    $captchaResponse: String!
  ) {
    createTenant(
      createTenantInput: {
        email: $email
        dappId: $dappId
        captchaResponse: $captchaResponse
        password: $password
        tenantName: $tenantName
      }
    ) {
      email
      emailConfirmed
      id
    }
  }
`;
