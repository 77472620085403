import React, { createContext, useState, useContext, PropsWithChildren } from 'react';

interface GlobalModalContextType {
  isModalOpen: boolean;
  openModal: ((submitHandler: SubmitHandler) => void)|null;
  closeModal: (() => void) | null;
  onSubmit: SubmitHandler | null;
  setError: ((error: string) => void) | null;
  error: string;
  isLoading: boolean;
  setIsLoading: any
}
const defaultContextValue: GlobalModalContextType = {
  isModalOpen: false,
  openModal: null,
  closeModal: null,
  onSubmit: null,
  setError: null,
  error: '',
  isLoading: false,
  setIsLoading: null
};

const GlobalModalContext = createContext(defaultContextValue);

type SubmitHandler = (twoFactorAnswer?: string, isCanceled?: boolean) => void

export const Global2FaAuthModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onSubmit, setOnSubmit] = useState<SubmitHandler | null>(null);
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const openModal = (submitHandler: SubmitHandler) => {
    setOnSubmit(() => submitHandler);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setOnSubmit(null);
  };

  return (
    <GlobalModalContext.Provider value={{ isModalOpen, openModal, closeModal, onSubmit, error, setError, isLoading, setIsLoading }}>
      {children}
    </GlobalModalContext.Provider>
  );
};

export const useGlobal2FaAuthModal = () => useContext(GlobalModalContext);
