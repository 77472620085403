import { gql } from '@apollo/client';

export const GenerateRefreshAuthorization = gql`
mutation GenerateRefreshAuthorization($input: GenerateRefreshAuthorizationInput!) {
  generateRefreshAuthorization(input: $input){
    authorization {
      expiry
      serialNumber
      tenantInfo {
        allowedDomains {
          originAddress
        }
      }
      token
    }
  }
}
`;


