import 'react-quill/dist/quill.snow.css';

import React, { useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import InsertModal from './InsertModal';
import 'react-quill-emoji/dist/quill-emoji.css';
import quillEmoji from 'react-quill-emoji';
import './styles.css';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';

const CustomLink = () => (
  <div>
    <svg
      width="20"
      height="10"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.9 5C1.9 3.29 3.29 1.9 5 1.9H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8.1H5C3.29 8.1 1.9 6.71 1.9 5ZM6 6H14V4H6V6ZM15 0H11V1.9H15C16.71 1.9 18.1 3.29 18.1 5C18.1 6.71 16.71 8.1 15 8.1H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0Z"
        fill="#F5F6FB"
      />
    </svg>
  </div>
);

const EmailCustomToolbar = () => (
  <div id="email-toolbar">
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-openEditInsertLink">
      <CustomLink />
    </button>
    <button className="ql-list" value="bullet" />
    <button className="ql-list" value="ordered" />
    <button className="ql-image" />
    <button className="ql-emoji" />
  </div>
);

const TelegramCustomToolbar = () => (
  <div id="telegram-toolbar">
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-openEditInsertLink">
      <CustomLink />
    </button>
    <button className="ql-emoji" />
  </div>
);

const SMSCustomToolbar = () => <div id="SMS-toolbar"></div>;

const DiscordCustomToolbar = () => (
  <div id="discord-toolbar">
    <button className="ql-emoji" />
  </div>
);

const AlertHistoryCustomToolbar = () => (
  <div id="alert-history-toolbar">
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-openEditInsertLink">
      <CustomLink />
    </button>
    <button className="ql-emoji" />
  </div>
);

const SlackCustomToolbar = () => (
  <div id="slack-toolbar">
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-openEditInsertLink">
      <CustomLink />
    </button>
    <button className="ql-list" value="bullet" />
    <button className="ql-list" value="ordered" />
  </div>
);

const BrowserCustomToolbar = () => (
  <div id="browser-toolbar">
    <button className="ql-emoji" />
  </div>
);

const Inline = Quill.import('blots/inline');

class ButtonLink extends Inline {
  static create(value: string) {
    const node = super.create(value);
    node.setAttribute('href', value.link);
    node.setAttribute('contenteditable', false);
    return node;
  }

  static formats(domNode: { getAttribute: (arg0: string) => unknown }) {
    return domNode.getAttribute('href');
  }
}

ButtonLink.blotName = 'buttonlink';
ButtonLink.className = 'ql-buttonlink';
ButtonLink.tagName = 'a';

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = [
  'sans',
  'serif',
  'fixed',
  'wide',
  'narrow',
  'comic',
  'garamond',
  'georgia',
  'tahoma',
  'trebuchet',
  'verdana',
];
Quill.register(Font, true);

Quill.register({ 'formats/buttonlink': ButtonLink });
// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['small', 'medium', 'large'];
Quill.register(Size, true);

Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
    'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
  },
  true,
);

const icons = Quill.import('ui/icons');
icons.link = `
  <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.9 5C1.9 3.29 3.29 1.9 5 1.9H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8.1H5C3.29 8.1 1.9 6.71 1.9 5ZM6 6H14V4H6V6ZM15 0H11V1.9H15C16.71 1.9 18.1 3.29 18.1 5C18.1 6.71 16.71 8.1 15 8.1H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0Z" fill="#F5F6FB"/>
  </svg>
`;

icons.image = `
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16 2V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM10 11L8.5 14L6 10.14L3 14H15L11.14 8.86L10 11Z" fill="#F5F6FB"/>
  </svg>
`;

icons.list.bullet = `<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 6.5C1.17 6.5 0.5 7.17 0.5 8C0.5 8.83 1.17 9.5 2 9.5C2.83 9.5 3.5 8.83 3.5 8C3.5 7.17 2.83 6.5 2 6.5ZM2 0.5C1.17 0.5 0.5 1.17 0.5 2C0.5 2.83 1.17 3.5 2 3.5C2.83 3.5 3.5 2.83 3.5 2C3.5 1.17 2.83 0.5 2 0.5ZM2 12.5C1.17 12.5 0.5 13.18 0.5 14C0.5 14.82 1.18 15.5 2 15.5C2.82 15.5 3.5 14.82 3.5 14C3.5 13.18 2.83 12.5 2 12.5ZM5 15H19V13H5V15ZM5 9H19V7H5V9ZM5 1V3H19V1H5Z" fill="#F5F6FB"/>
  </svg>`;

icons.list.ordered = `<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 13H2V13.5H1V14.5H2V15H0V16H3V12H0V13ZM1 4H2V0H0V1H1V4ZM0 7H1.8L0 9.1V10H3V9H1.2L3 6.9V6H0V7ZM5 1V3H19V1H5ZM5 15H19V13H5V15ZM5 9H19V7H5V9Z" fill="#F5F6FB"/>
  </svg>`;

icons.size = `<svg width="33" height="19" viewBox="0 0 33 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.0469 3.625V15H8.90625V3.625H11.0469ZM14.5781 3.625V5.32031H5.41406V3.625H14.5781Z" fill="#262949"/>
  <path d="M4.2666 7.89062V15H2.67969V7.89062H4.2666ZM6.42969 7.89062V9.12598H0.560547V7.89062H6.42969Z" fill="#262949"/>
  <g clip-path="url(#clip0_12890_336939)">
  <path d="M21.6666 8.66797L25 12.0013L28.3333 8.66797H21.6666Z" fill="#262949"/>
  </g>
  <defs>
  <clipPath id="clip0_12890_336939">
  <rect width="16" height="16" fill="white" transform="translate(17 2)"/>
  </clipPath>
  </defs>
  </svg>`;

icons.emoji = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM13.5 9C14.33 9 15 8.33 15 7.5C15 6.67 14.33 6 13.5 6C12.67 6 12 6.67 12 7.5C12 8.33 12.67 9 13.5 9ZM6.5 9C7.33 9 8 8.33 8 7.5C8 6.67 7.33 6 6.5 6C5.67 6 5 6.67 5 7.5C5 8.33 5.67 9 6.5 9ZM10 15.5C12.33 15.5 14.31 14.04 15.11 12H4.89C5.69 14.04 7.67 15.5 10 15.5Z" fill="white"/>
  </svg>`;

const emailFormats = [
  'bold',
  'italic',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'emoji',
];

const telegramFormats = ['bold', 'italic', 'indent', 'link', 'emoji'];

const discordFormats = ['indent', 'emoji'];

const slackFormats = [
  'bold',
  'italic',
  'list',
  'bullet',
  'indent',
  'link',
  'emoji',
];

const alertHistoryFormats = ['bold', 'italic', 'link', 'emoji'];

const SMSFormats = ['emoji'];

const browserFormats = ['emoji'];

const emojiIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM13.5 9C14.33 9 15 8.33 15 7.5C15 6.67 14.33 6 13.5 6C12.67 6 12 6.67 12 7.5C12 8.33 12.67 9 13.5 9ZM6.5 9C7.33 9 8 8.33 8 7.5C8 6.67 7.33 6 6.5 6C5.67 6 5 6.67 5 7.5C5 8.33 5.67 9 6.5 9ZM10 15.5C12.33 15.5 14.31 14.04 15.11 12H4.89C5.69 14.04 7.67 15.5 10 15.5Z" fill="white"/>
</svg>`;

type Destination =
  | 'email'
  | 'telegram'
  | 'discord'
  | 'SMS'
  | 'alertHistory'
  | 'slack'
  | 'walletAlert'
  | 'browser';
type Props = {
  value: string;
  setValue: (value: string) => void;
  destination: Destination;
  isCommunityManager: boolean;
  maxLimit?: number;
  isDisabled?: boolean;
};
const ContentEditor: React.FC<Props> = ({
  setValue,
  value,
  destination,
  isCommunityManager,
  maxLimit = 0,
  isDisabled = false,
}) => {
  const { isAP2Enabled } = useFeatureFlag();

  const [isAddLinkModalOpen, setIsAddLinkModalOpen] = useState<boolean>(false);
  const [isAddButtonModalOpen, setIsAddButtonModalOpen] =
    useState<boolean>(false);
  const [isInsertPhotoModalOpen, setIsInsertPhotoModalOpen] =
    useState<boolean>(false);
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [linkText, setLinkText] = useState<string>('');
  const [buttonUrl, setButtonUrl] = useState<string>('');
  const [buttonText, setButtonText] = useState<string>('');
  const [photoUrl, setPhotoUrl] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorRef = useRef<any>();

  const updateUrlValid = (url: string) => {
    try {
      const parsed = new URL(url);
      return parsed.href;
    } catch (error) {
      return new URL('https://' + url).href;
    }
  };

  const handleInsertLink = () => {
    if (editorRef?.current) {
      const editor = editorRef.current.editor;
      if (editor) {
        const position = editor.getSelection(true).index;
        editor.insertText(position, linkText, {
          link: updateUrlValid(linkUrl),
        });
        editor.setSelection(position + linkText.length, 0);
        setIsAddLinkModalOpen(false);
        setLinkText('');
        setLinkUrl('');
      }
    }
  };

  const handleInsertButton = () => {
    if (editorRef?.current) {
      const editor = editorRef.current.editor;
      if (editor) {
        const position = editor.getSelection(true).index;
        editor.insertText(position, buttonText, 'buttonlink', {
          link: buttonUrl,
        });
        editor.setSelection(position + buttonText.length, 0);
        setIsAddButtonModalOpen(false);
        setButtonText('');
        setButtonUrl('');
      }
    }
  };

  const handleInsertPhoto = () => {
    if (editorRef?.current) {
      const editor = editorRef.current.editor;
      if (editor) {
        const position = editor.getSelection(true).index;
        editorRef.current.getEditor().insertEmbed(position, 'image', photoUrl);
        editorRef.current.getEditor().setSelection(position + 1, 0);
        setIsInsertPhotoModalOpen(false);
        setPhotoUrl('');
      }
    }
  };

  const emailModules = useMemo(() => {
    return {
      toolbar: {
        container: '#email-toolbar',
        handlers: {
          image: function () {
            setIsInsertPhotoModalOpen(true);
          },
          openEditInsertButton: function () {
            setIsAddButtonModalOpen(true);
          },
          openEditInsertLink: function () {
            setIsAddLinkModalOpen(true);
          },
        },
      },
      'emoji-toolbar': {
        buttonIcon: emojiIcon,
      },
      'emoji-shortname': true,
    };
  }, [setIsAddLinkModalOpen]);

  const telegramModules = useMemo(() => {
    return {
      toolbar: {
        container: '#telegram-toolbar',
        handlers: {
          openEditInsertLink: function () {
            setIsAddLinkModalOpen(true);
          },
        },
      },
      'emoji-toolbar': {
        buttonIcon: emojiIcon,
      },
      'emoji-shortname': true,
    };
  }, [setIsAddLinkModalOpen]);

  const SMSModules = useMemo(() => {
    return {
      toolbar: {
        container: '#SMS-toolbar',
      },
      'emoji-toolbar': {
        buttonIcon: emojiIcon,
      },
      'emoji-shortname': true,
    };
  }, []);

  const discordModules = useMemo(() => {
    return {
      toolbar: {
        container: '#discord-toolbar',
      },
      'emoji-toolbar': {
        buttonIcon: emojiIcon,
      },
      'emoji-shortname': true,
    };
  }, []);

  const alertHistoryModules = useMemo(() => {
    return {
      toolbar: {
        container: '#alert-history-toolbar',
        handlers: {
          openEditInsertLink: function () {
            setIsAddLinkModalOpen(true);
          },
        },
      },
      'emoji-toolbar': {
        buttonIcon: emojiIcon,
      },
      'emoji-shortname': true,
    };
  }, []);

  const slackModules = useMemo(() => {
    return {
      toolbar: {
        container: '#slack-toolbar',
        handlers: {
          openEditInsertLink: function () {
            setIsAddLinkModalOpen(true);
          },
        },
      },
      'emoji-toolbar': {
        buttonIcon: emojiIcon,
      },
      'emoji-shortname': true,
    };
  }, []);

  const browserModules = useMemo(() => {
    return {
      toolbar: {
        container: '#browser-toolbar',
      },
      'emoji-toolbar': {
        buttonIcon: emojiIcon,
      },
      'emoji-shortname': true,
    };
  }, []);

  // Function to check character count
  const characterCountValidator = (event: any, limit: number) => {
    const unprivilegedEditor = editorRef.current.unprivilegedEditor;
    if (unprivilegedEditor.getLength() > limit && event.key !== 'Backspace')
      event.preventDefault();
  };

  const editorToShow = useMemo(() => {
    switch (destination) {
      case 'email':
        return (
          <>
            <ReactQuill
              onKeyDown={(event) => {
                if (maxLimit) {
                  characterCountValidator(event, maxLimit);
                }
              }}
              formats={emailFormats}
              modules={emailModules}
              readOnly={isDisabled}
              onChange={setValue}
              placeholder={
                isCommunityManager
                  ? 'Write your content...'
                  : 'Enter your HTML content...'
              }
              ref={editorRef}
              value={value}
            />
            {isCommunityManager ? (
              <EmailCustomToolbar />
            ) : (
              <div id="email-toolbar"></div>
            )}
          </>
        );
      case 'telegram':
        return (
          <>
            <ReactQuill
              onKeyDown={(event) => {
                if (maxLimit) {
                  characterCountValidator(event, maxLimit);
                }
              }}
              formats={telegramFormats}
              modules={telegramModules}
              readOnly={isDisabled}
              onChange={setValue}
              placeholder={
                isCommunityManager
                  ? 'Write your content...'
                  : 'Enter your HTML content...'
              }
              ref={editorRef}
              value={value}
            />
            {isCommunityManager ? (
              <TelegramCustomToolbar />
            ) : (
              <div id="telegram-toolbar"></div>
            )}{' '}
          </>
        );
      case 'SMS':
      case 'walletAlert':
        return (
          <>
            <ReactQuill
              onKeyDown={(event) => {
                if (maxLimit) {
                  characterCountValidator(event, maxLimit);
                }
              }}
              formats={SMSFormats}
              modules={SMSModules}
              readOnly={isDisabled}
              onChange={setValue}
              placeholder={
                isCommunityManager
                  ? 'Write your content...'
                  : 'Enter your Plain Text content...'
              }
              ref={editorRef}
              value={value}
            />
            {isCommunityManager ? (
              <SMSCustomToolbar />
            ) : (
              <div id="SMS-toolbar"></div>
            )}{' '}
          </>
        );
      case 'discord':
        return (
          <>
            <ReactQuill
              formats={discordFormats}
              modules={discordModules}
              readOnly={isDisabled}
              onChange={setValue}
              placeholder={
                isCommunityManager
                  ? 'Write your content...'
                  : 'Enter your Markdown content...'
              }
              ref={editorRef}
              value={value}
              onKeyDown={(event) => {
                if (maxLimit) {
                  characterCountValidator(event, maxLimit);
                }
              }}
            />
            {isCommunityManager ? (
              <DiscordCustomToolbar />
            ) : (
              <div id="discord-toolbar"></div>
            )}{' '}
          </>
        );
      case 'alertHistory':
        return (
          <>
            <ReactQuill
              formats={alertHistoryFormats}
              modules={alertHistoryModules}
              readOnly={isDisabled}
              onChange={setValue}
              placeholder={
                isCommunityManager
                  ? 'Write your content...'
                  : 'Enter your Markdown content...'
              }
              ref={editorRef}
              value={value}
              onKeyDown={(event) => {
                if (maxLimit) {
                  characterCountValidator(event, maxLimit);
                }
              }}
            />

            {isCommunityManager ? (
              <AlertHistoryCustomToolbar />
            ) : (
              <div id="alert-history-toolbar"></div>
            )}
          </>
        );
      case 'slack':
        return (
          <>
            <ReactQuill
              formats={slackFormats}
              modules={slackModules}
              readOnly={isDisabled}
              onChange={setValue}
              placeholder={
                isCommunityManager
                  ? 'Write your content...'
                  : 'Enter your Markdown content...'
              }
              ref={editorRef}
              value={value}
              onKeyDown={(event) => {
                if (maxLimit) {
                  characterCountValidator(event, maxLimit);
                }
              }}
            />

            {isCommunityManager ? (
              <SlackCustomToolbar />
            ) : (
              <div id="slack-toolbar"></div>
            )}
          </>
        );
      case 'browser':
        return (
          <>
            <ReactQuill
              formats={browserFormats}
              modules={browserModules}
              onChange={setValue}
              placeholder={
                isCommunityManager
                  ? 'Write your content...'
                  : 'Enter your Markdown content...'
              }
              ref={editorRef}
              value={value}
              onKeyDown={(event) => {
                if (maxLimit) {
                  characterCountValidator(event, maxLimit);
                }
              }}
            />

            {isCommunityManager ? (
              <BrowserCustomToolbar />
            ) : (
              <div id="browser-toolbar"></div>
            )}
          </>
        );
      default:
        return null;
    }
  }, [
    destination,
    emailModules,
    setValue,
    isCommunityManager,
    value,
    telegramModules,
    SMSModules,
    discordModules,
    alertHistoryModules,
    slackModules,
    browserModules,
    maxLimit,
  ]);

  return (
    <div
      className={
        isAP2Enabled
          ? `${isDisabled && 'isViewDisabled'} isAP2Enabled`
          : 'isAP2NotEnabled'
      }
    >
      <div className="text-editor">
        {editorToShow}
        {/* insert modal for add link */}
        <InsertModal
          disabled={false}
          handleClose={() => setIsAddLinkModalOpen(false)}
          handleConfirm={handleInsertLink}
          label="Text to display"
          message="To what URL should this link go?"
          open={isAddLinkModalOpen}
          secondLable="URL"
          setText={setLinkText}
          setUrl={setLinkUrl}
          text={linkText}
          title="Add Link"
          url={linkUrl}
        />
        {/* insert modal for add button */}
        <InsertModal
          disabled={false}
          handleClose={() => setIsAddButtonModalOpen(false)}
          handleConfirm={handleInsertButton}
          label="Button text to display"
          message="To what URL should this  button go?"
          open={isAddButtonModalOpen}
          placeholder="Button URL"
          setText={setButtonText}
          setUrl={setButtonUrl}
          text={buttonText}
          title="Edit Button"
          url={buttonUrl}
        />
        {/* insert modal for insert photo */}
        <InsertModal
          disabled={false}
          handleClose={() => setIsInsertPhotoModalOpen(false)}
          handleConfirm={handleInsertPhoto}
          noTextInput={true}
          open={isInsertPhotoModalOpen}
          secondLable="Enter the URL for your photo"
          primaryButtonText="Insert"
          setUrl={setPhotoUrl}
          text={buttonText}
          title="Insert Photo"
          url={photoUrl}
          setText={setButtonText}
          tipText="Maximum supported photo width: 600px"
        />
      </div>
    </div>
  );
};

export default ContentEditor;
