import { gql } from '@apollo/client';

export const CardConfigQuery = gql`
  query CardConfig(
    $first: Int
    $after: String
    $type: TenantConfigType = SUBSCRIPTION_CARD
  ) {
    tenantConfigs(
      first: $first
      after: $after
      getTenantConfigInput: { type: $type }
    ) {
      nodes {
        id
        type
        dataJson
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
