import React, { useMemo, useState } from 'react';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  DialogContent,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  dusk,
  eggshell,
  heather,
  midnight,
  blueglass,
  white,
} from 'src/constants/colors';
import { FormMessageBar } from '@components/FormMessageBar';
import { Footer } from '@components/footer';
import {
  FusionEventsDocument,
  FusionSourceWithCursor,
  useFusionEventsQuery,
  useUpdateFusionSourceMutation,
} from 'src/services/gql/generated';
import { Transition } from './../../../components/alertManager/CreateNonCMTopicDialog';
import { availableBlockchain } from '@components/alertManager/OnOffChainTopicLayout';
import { WalletBlockchain } from './../../../services/gql/generated';
import { checkboxCustomCSS } from '@components/cardConfig/common/CheckboxCSS';
import AddFilterData from '@components/alertManager/AddFilterData';
import useListManagement from 'src/hooks/useListManagement';
import useFrequencyByUnit from 'src/hooks/useFrequencyByUnit';
import CronInput from '@components/alertManager/CronInput';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type Props = {
  handleClose: () => void;
  open: boolean;
  fusionSource: FusionSourceWithCursor;
};

type FilterType = {
  TopicHashes?: string[];
  ContractAddresses?: string[];
  EventTypes?: string[];
  TransactionModules?: string[];
  ProgramId?: string[];
  CronExecutionInterval?: string;
};

const EditParserDialog = ({ handleClose, open, fusionSource }: Props) => {
  const [parserName, setParserName] = useState(fusionSource.name);
  const [selectedBlockchain, setSelectedBlockchain] = useState(
    fusionSource.blockchainSource,
  );
  const [updateImpl] = useUpdateFusionSourceMutation();
  const [isCronEvent, setIsCronEvent] = useState<boolean>(false);
  const isSolanaBlockchain = selectedBlockchain === WalletBlockchain.SOLANA;
  const isSuiBlockchain = selectedBlockchain === WalletBlockchain.SUI;
  const { data } = useFusionEventsQuery({
    variables: {
      first: 50,
    },
  });
  const topicUsingParser = useMemo(
    () =>
      data?.fusionEvents?.nodes?.filter(
        (item) => item.fusionSource?.id === fusionSource.id,
      ),
    [data, fusionSource.id],
  );

  // Same hook will be used for storing following details -
  // For all type of blockchain - Stores Topic / Event #s and Contract Addresses
  // For Solana - Stores {"ProgramIds":[]}
  // For sui - Stores {"EventTypes": [], "TransactionModules": []}
  const {
    handleInsertItem: handleInsertEvent,
    handleItemChange: handleEventChange,
    handleRemoveItem: handleRemoveEvent,
    handleInsertDefaultItems: setDefaultEvents,
    list: events,
  } = useListManagement();

  const {
    handleInsertItem: handleInsertContractAddress,
    handleItemChange: handleContractAddressChange,
    handleRemoveItem: handleRemoveContractAddress,
    handleInsertDefaultItems: setDefaultContractAddresses,
    list: contractAddresses,
  } = useListManagement();

  const {
    frequency,
    unit,
    setFrequency,
    setUnit,
    cronExecutionInterval,
    cronToFrequencyAndUnit,
  } = useFrequencyByUnit();

  const handleBlockchainChange = (value: WalletBlockchain) => {
    setSelectedBlockchain(value);
  };

  const submitData = async () => {
    try {
      if (!fusionSource) return;
      let filter = {} as FilterType;
      if (isCronEvent) {
        filter = { CronExecutionInterval: cronExecutionInterval };
      }
      if (!isCronEvent) {
        const key1 = isSolanaBlockchain
          ? 'ProgramId'
          : isSuiBlockchain
          ? 'EventTypes'
          : 'TopicHashes';
        const key2 = isSolanaBlockchain
          ? null
          : isSuiBlockchain
          ? 'TransactionModules'
          : 'ContractAddresses';
        filter[key1] = events.map((i) => i.value).filter(Boolean);

        if (key2)
          filter[key2] = contractAddresses.map((i) => i.value).filter(Boolean);
      }
      await updateImpl({
        variables: {
          fusionSourceId: fusionSource.id,
          name: parserName,
          filter: JSON.stringify(filter),
        },
        refetchQueries: [
          {
            query: FusionEventsDocument,
          },
        ],
      });
    } catch (error) {
      console.log('err', error);
    }

    handleClose();
  };

  React.useEffect(() => {
    // It'll prefill all the data from selected parser
    const selectedParser = fusionSource;
    const filter: FilterType = JSON.parse(selectedParser?.filter || '{}');

    let data = filter?.TopicHashes || filter?.EventTypes || filter?.ProgramId;
    setDefaultEvents(
      data?.map((value, id) => ({
        isRemovable: false,
        value,
        id,
      })) ?? [],
    );

    data = filter?.ContractAddresses || filter?.TransactionModules;
    setDefaultContractAddresses(
      data?.map((value, id) => ({
        isRemovable: false,
        value,
        id,
      })) ?? [],
    );

    setIsCronEvent(!!filter?.CronExecutionInterval);
    setSelectedBlockchain(selectedParser?.blockchainSource);
    if (filter?.CronExecutionInterval) {
      cronToFrequencyAndUnit(filter?.CronExecutionInterval);
    }
    return;
  }, [fusionSource]);

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen
      onClose={handleClose}
      open={open}
      sx={{
        '&.MuiPaper-root &.MuiDialog-paper': {
          backgroundColor: 'transparent',
        },
        backgroundColor: '#F3F3FA',
      }}
    >
      <DialogContent
        sx={{
          backgroundColor: '#F3F3FA',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 0,
        }}
      >
        <Stack
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Stack width={'100%'} mt={6} mb={5}>
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="100%"
              gap="20px"
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                flexGrow="1"
                flexShrink="1"
              >
                <Typography variant="h1">Edit Parser</Typography>
              </Stack>
              <IconButton
                sx={{
                  flexShrink: 0,
                  backgroundColor: white,
                  borderRadius: '45px',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: white,
                  },
                }}
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon sx={{ color: dusk }} />
              </IconButton>
            </Stack>
          </Stack>
          <FormMessageBar />
          <Stack flexDirection="row" gap={2}>
            <Box sx={{ width: '367px', position: 'relative' }}>
              <Box
                sx={{
                  position: 'fixed',
                  backgroundColor: '#FEFEFE',
                  width: '367px',
                  borderRadius: '8px',
                  p: 4,
                }}
              >
                <Typography
                  fontSize={'16px'}
                  color={midnight}
                  sx={{
                    cursor: 'pointer',
                    position: 'relative',
                    mb: 2,
                  }}
                >
                  Topics using this parser:
                </Typography>
                {topicUsingParser?.map((item, index) => (
                  <Stack
                    key={index}
                    direction={'row'}
                    alignItems={'center'}
                    gap={1}
                  >
                    <Box
                      sx={{
                        width: '2px',
                        height: '2px',
                        borderRadius: '50%',
                        backgroundColor: dusk,
                      }}
                    />
                    <Typography
                      fontSize={'16px'}
                      color={dusk}
                      sx={{
                        cursor: 'pointer',
                        position: 'relative',
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Stack>
                ))}

                <Box
                  sx={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: heather,
                    my: 2.5,
                  }}
                />

                <Stack direction={'row'} gap={2}>
                  <Typography variant="body2" color={midnight} fontWeight={700}>
                    Need some guidance?
                  </Typography>
                  <Stack
                    sx={{ display: 'flex', flexDirection: 'row' }}
                    onClick={() =>
                      window.open(
                        'https://docs.notifi.network/docs/create-topics/notifi-monitored',
                        '_blank',
                      )
                    }
                  >
                    <Typography
                      sx={{
                        color: blueglass,
                        fontSize: '14px',
                        fontWeight: 700,
                        p: 0,
                        cursor: 'pointer',
                      }}
                    >
                      Docs
                    </Typography>
                    <OpenInNewIcon
                      sx={{
                        color: blueglass,
                        fontSize: '16px',
                        p: 0,
                        cursor: 'pointer',
                        marginLeft: '6px',
                        fontWeight: 'bold',
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Box>
            <Stack position={'relative'} mb={3}>
              <Stack
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundColor: white,
                  borderRadius: '8px',
                  p: 4,
                }}
              >
                <Stack width={'360px'}>
                  {/* Parser Name */}
                  <Stack mb={1.5}>
                    <InputLabel
                      id="parser-name"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        fontFamily: 'Rota',
                        color: dusk,
                        textAlign: 'left',
                        mb: 0.125,
                      }}
                    >
                      Name Parser
                    </InputLabel>

                    <TextField
                      autoComplete="off"
                      value={parserName}
                      onChange={(e) => {
                        setParserName(e.target.value);
                      }}
                    />
                  </Stack>

                  <Stack mb={1.5}>
                    <InputLabel
                      id="blockchain-info"
                      sx={{
                        mb: 0.125,
                        fontFamily: 'Rota',
                        fontWeight: 700,
                        fontSize: '14px',
                      }}
                    >
                      Select blockchain
                    </InputLabel>

                    <Select
                      disabled={true}
                      labelId="blockchain-info"
                      value={selectedBlockchain}
                      onChange={(e) => {
                        handleBlockchainChange(
                          e.target.value as WalletBlockchain,
                        );
                      }}
                      IconComponent={() => null}
                      sx={{
                        backgroundColor: eggshell,
                        ':before': { border: 'none' },
                        '&.MuiSelect-iconStandard': { color: 'red' },
                      }}
                    >
                      {availableBlockchain.map(({ label, value, icon }, i) => (
                        <MenuItem
                          key={i}
                          value={value}
                          disabled={label === 'Sui'}
                        >
                          <Stack
                            gap={'14px'}
                            flexDirection={'row'}
                            alignItems={'center'}
                          >
                            <img
                              src={icon}
                              width={22}
                              height={22}
                              alt="chain-icon"
                            />

                            <Typography
                              sx={{
                                fontFamily: 'Rota',
                                fontSize: '16px',
                                fontWeight: 600,
                                color: midnight,
                              }}
                            >
                              {label}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}

                      <Typography
                        sx={{
                          fontFamily: 'Rota',
                          fontSize: '14px',
                          fontWeight: 700,
                          color: dusk,
                          textAlign: 'center',
                          my: 2,
                        }}
                      >
                        ⚡️Other blockchains coming soon
                      </Typography>
                    </Select>
                  </Stack>
                  {/* Cron | On-chain event option */}
                  <Stack>
                    <InputLabel
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: dusk,
                        textAlign: 'left',
                      }}
                    >
                      How would you like to trigger your event?
                    </InputLabel>

                    <Stack flexDirection="row" marginTop="3px" gap={1.2}>
                      {!isCronEvent && (
                        <Tooltip
                          title={
                            <Box
                              sx={{
                                backgroundColor: eggshell,
                                p: 0,
                                m: 0,
                                width: '192px',
                              }}
                            >
                              <Typography
                                sx={{
                                  p: 1.2,
                                  fontFamily: 'Rota',
                                  fontSize: '12px',
                                  fontWeight: 700,
                                  color: midnight,
                                }}
                              >
                                The existing parser you selected for this topic
                                is configured for cron interval. This cannot be
                                changed. To change this selection, select or
                                create a new parser.
                              </Typography>
                            </Box>
                          }
                          arrow
                          placement="left"
                          sx={{ p: 0, m: 0 }}
                        >
                          <FormControlLabel
                            sx={{ mr: 0 }}
                            label={
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  mt: '2px',
                                  fontSize: '16px',
                                  fontFamily: 'Rota',
                                }}
                              >
                                On-chain event
                              </Typography>
                            }
                            control={
                              <Radio
                                checked={!isCronEvent}
                                onChange={(e) => {
                                  if (e.target.checked) setIsCronEvent(false);
                                }}
                                sx={{
                                  ...checkboxCustomCSS,
                                  '&.Mui-disabled': {
                                    color: dusk,
                                    opacity: 0.5,
                                    cursor: 'not-allowed',
                                    pointerEvents: 'unset',
                                  },
                                }}
                              />
                            }
                          />
                        </Tooltip>
                      )}

                      {isCronEvent && (
                        <Tooltip
                          title={
                            <Box
                              sx={{
                                backgroundColor: eggshell,
                                p: 0,
                                m: 0,
                                width: '192px',
                              }}
                            >
                              <Typography
                                sx={{
                                  p: 1.2,
                                  fontFamily: 'Rota',
                                  fontSize: '12px',
                                  fontWeight: 700,
                                  color: midnight,
                                }}
                              >
                                The existing parser you selected for this topic
                                is configured for on-chain event. This cannot be
                                changed. To change this selection, select or
                                create a new parser.
                              </Typography>
                            </Box>
                          }
                          arrow
                          placement="right"
                          sx={{ p: 0, m: 0 }}
                        >
                          <FormControlLabel
                            sx={{ mx: 0 }}
                            label={
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  mt: '2px',
                                  fontSize: '16px',
                                  fontFamily: 'Rota',
                                }}
                              >
                                Cron
                              </Typography>
                            }
                            control={
                              <Radio
                                checked={isCronEvent}
                                onChange={(e) => {
                                  setIsCronEvent(e.target.checked);
                                }}
                                sx={{
                                  ...checkboxCustomCSS,
                                  '&.Mui-disabled': {
                                    color: dusk,
                                    opacity: 0.5,
                                    cursor: 'not-allowed',
                                    pointerEvents: 'unset',
                                  },
                                }}
                              />
                            }
                          />
                        </Tooltip>
                      )}
                    </Stack>
                    {isCronEvent && (
                      <CronInput
                        unit={unit}
                        setUnit={setUnit}
                        frequency={frequency}
                        setFrequency={setFrequency}
                      />
                    )}

                    {!isSolanaBlockchain && !isSuiBlockchain && !isCronEvent ? (
                      <>
                        <AddFilterData
                          title="Topic / Event #s"
                          actionText="+ Insert Event"
                          inputLabel="Event"
                          data={events}
                          handleDataChange={handleEventChange}
                          handleInsertData={handleInsertEvent}
                          handleRemoveData={handleRemoveEvent}
                        />

                        <AddFilterData
                          title="Contract Addresses"
                          actionText="+ Insert Address"
                          inputLabel="Address"
                          data={contractAddresses}
                          handleDataChange={handleContractAddressChange}
                          handleInsertData={handleInsertContractAddress}
                          handleRemoveData={handleRemoveContractAddress}
                        />
                      </>
                    ) : null}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {/* Filter Preview Card UI */}
            <Box sx={{ width: '367px', position: 'relative' }}>
              <Box
                sx={{
                  position: 'fixed',
                  backgroundColor: '#FEFEFE',
                  width: '367px',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
                }}
              ></Box>
            </Box>
          </Stack>

          <PrimaryButton
            customSx={{ width: '156px', mb: 4 }}
            buttonTitle={'Save Parser'}
            onClick={submitData}
          />
        </Stack>
        <Footer sx={{ background: eggshell }} />
      </DialogContent>
    </Dialog>
  );
};

export default EditParserDialog;
