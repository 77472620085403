import { gql } from '@apollo/client';

export const MessageDeliveryStatisticsQuery = gql`
  query GetMessageDeliveryStatistics {
    messageDeliveryStatistics {
      messagesByTarget {
        stats {
          key
          value {
            messages
            users
          }
        }
      }
      messagesByAttributes {
        stats {
          key
          value {
            messages
            users
          }
        }
      }
    }
  }
`;
