import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Grid, IconButton, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  DISCORD_URL,
  GITHUB_URL,
  LINKEDIN_URL,
  MEDIUM_NEWS_URL,
  TWITTER_URL,
} from './constants/urls';
import React from 'react';
import { heather, midnight } from './constants/colors';

type Props = {
  color?: string;
  columnGap?: number;
  justifyContent?: 'center' | 'flex-end' | 'flex-start';
  size?: 'small' | 'medium' | 'large';
  wrap?: 'nowrap' | 'wrap';
};

const TwitterIconLink = ({ color = heather, size = 'medium' }: Props) => {
  const onClick = () => {
    window.open(TWITTER_URL, '_blank');
  };

  return (
    <Grid item>
      <IconButton
        aria-label="Twitter"
        component="span"
        onClick={onClick}
        size={size}
      >
        <TwitterIcon sx={{ color: color }} />
      </IconButton>
    </Grid>
  );
};

const DiscordIconLink = ({ color = heather, size = 'medium' }: Props) => {
  const onClick = () => {
    window.open(DISCORD_URL, '_blank');
  };

  return (
    <Grid item sx={{ overflow: 'unset' }}>
      <IconButton
        aria-label="Discord"
        component="span"
        onClick={onClick}
        size={size}
      >
        <svg
          fill={color}
          height="18"
          viewBox="0 -1 27 21"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_90_579)">
            <path
              d="M22.2457 1.81009C20.571 1.04166 18.7751 0.475516 16.8974 0.151263C16.8632 0.145005 16.8291 0.160644 16.8115 0.191922C16.5805 0.602712 16.3247 1.13862 16.1455 1.55984C14.1259 1.25749 12.1167 1.25749 10.1385 1.55984C9.95935 1.12926 9.69423 0.602712 9.46223 0.191922C9.44461 0.161687 9.41045 0.146048 9.37626 0.151263C7.4996 0.47448 5.70373 1.04062 4.02799 1.81009C4.01348 1.81634 4.00105 1.82678 3.99279 1.84032C0.586404 6.92939 -0.346749 11.8934 0.111025 16.7958C0.113096 16.8198 0.12656 16.8427 0.145202 16.8573C2.39264 18.5078 4.56967 19.5098 6.70627 20.1739C6.74046 20.1844 6.77669 20.1718 6.79845 20.1437C7.30387 19.4535 7.7544 18.7257 8.14069 17.9604C8.16348 17.9156 8.14172 17.8624 8.09513 17.8447C7.38051 17.5736 6.70005 17.2431 6.0455 16.8678C5.99372 16.8375 5.98958 16.7635 6.03721 16.728C6.17495 16.6248 6.31273 16.5174 6.44425 16.409C6.46805 16.3892 6.50121 16.385 6.52919 16.3975C10.8293 18.3608 15.4847 18.3608 19.7341 16.3975C19.7621 16.384 19.7953 16.3881 19.8201 16.4079C19.9517 16.5164 20.0894 16.6248 20.2282 16.728C20.2758 16.7635 20.2727 16.8375 20.2209 16.8678C19.5664 17.2504 18.8859 17.5736 18.1703 17.8437C18.1237 17.8614 18.103 17.9156 18.1258 17.9604C18.5203 18.7247 18.9709 19.4524 19.467 20.1426C19.4877 20.1718 19.5249 20.1844 19.5591 20.1739C21.7061 19.5098 23.8831 18.5078 26.1306 16.8573C26.1502 16.8427 26.1627 16.8208 26.1647 16.7969C26.7126 11.1291 25.2471 6.20582 22.2799 1.84136C22.2726 1.82678 22.2602 1.81634 22.2457 1.81009ZM8.78284 13.8107C7.4882 13.8107 6.42146 12.6222 6.42146 11.1625C6.42146 9.70278 7.46752 8.51421 8.78284 8.51421C10.1085 8.51421 11.1649 9.71322 11.1442 11.1625C11.1442 12.6222 10.0981 13.8107 8.78284 13.8107ZM17.5137 13.8107C16.219 13.8107 15.1523 12.6222 15.1523 11.1625C15.1523 9.70278 16.1983 8.51421 17.5137 8.51421C18.8393 8.51421 19.8957 9.71322 19.875 11.1625C19.875 12.6222 18.8393 13.8107 17.5137 13.8107Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_90_579">
              <rect fill={color} height="20.3542" width="26.2754" />
            </clipPath>
          </defs>
        </svg>
      </IconButton>
    </Grid>
  );
};

const LinkedInIconLink = ({ color = 'secondary', size = 'medium' }: Props) => {
  const onClick = () => {
    window.open(LINKEDIN_URL, '_blank');
  };

  return (
    <Grid item>
      <IconButton
        aria-label="LinkedIn"
        component="span"
        onClick={onClick}
        size={size}
      >
        <LinkedInIcon sx={{ color: color }} />
      </IconButton>
    </Grid>
  );
};

const MediumIconLink = ({ color = 'secondary', size = 'medium' }: Props) => {
  const onClick = () => {
    window.open(MEDIUM_NEWS_URL, '_blank');
  };

  return (
    <Grid item>
      <IconButton
        aria-label="Medium"
        component="span"
        onClick={onClick}
        size={size}
      >
        <svg
          fill={color}
          height="18"
          viewBox="0 0 22 22"
          width="18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M22 0H0V22H22V0Z" fill={color} />
          <path
            d="M5.25012 7.35744C5.26848 7.17601 5.1993 6.99669 5.06383 6.87459L3.68385 5.21219V4.96387H7.96866L11.2806 12.2274L14.1923 4.96387H18.277V5.21219L17.0972 6.34345C16.9955 6.42098 16.945 6.54842 16.9661 6.67455V14.9865C16.945 15.1126 16.9955 15.2401 17.0972 15.3176L18.2494 16.4489V16.6972H12.4536V16.4489L13.6472 15.29C13.7645 15.1728 13.7645 15.1383 13.7645 14.9589V8.24037L10.4457 16.6696H9.99722L6.1333 8.24037V13.8898C6.10109 14.1273 6.17997 14.3664 6.3472 14.5382L7.89967 16.4213V16.6696H3.49756V16.4213L5.05003 14.5382C5.21604 14.3661 5.29032 14.1254 5.25012 13.8898V7.35744Z"
            fill={midnight}
          />
        </svg>
      </IconButton>
    </Grid>
  );
};
const GithubIconLink = ({ color = heather, size = 'medium' }: Props) => {
  const onClick = () => {
    window.open(GITHUB_URL, '_blank');
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const width = isMobile ? '22px' : '24px';
  const height = isMobile ? '23px' : '24px';

  return (
    <Grid item>
      <IconButton
        aria-label="GitHub"
        component="span"
        onClick={onClick}
        size={size}
      >
        <svg
          fill={color}
          height={height}
          viewBox="0 0 24 24"
          width={width}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0C5.374 0 0 5.373 0 12C0 17.302 3.438 21.8 8.207 23.387C8.806 23.498 9 23.126 9 22.81V20.576C5.662 21.302 4.967 19.16 4.967 19.16C4.421 17.773 3.634 17.404 3.634 17.404C2.545 16.659 3.717 16.675 3.717 16.675C4.922 16.759 5.556 17.912 5.556 17.912C6.626 19.746 8.363 19.216 9.048 18.909C9.155 18.134 9.466 17.604 9.81 17.305C7.145 17 4.343 15.971 4.343 11.374C4.343 10.063 4.812 8.993 5.579 8.153C5.455 7.85 5.044 6.629 5.696 4.977C5.696 4.977 6.704 4.655 8.997 6.207C9.954 5.941 10.98 5.808 12 5.803C13.02 5.808 14.047 5.941 15.006 6.207C17.297 4.655 18.303 4.977 18.303 4.977C18.956 6.63 18.545 7.851 18.421 8.153C19.191 8.993 19.656 10.064 19.656 11.374C19.656 15.983 16.849 16.998 14.177 17.295C14.607 17.667 15 18.397 15 19.517V22.81C15 23.129 15.192 23.504 15.801 23.386C20.566 21.797 24 17.3 24 12C24 5.373 18.627 0 12 0Z"
            fill={color}
          />
        </svg>
      </IconButton>
    </Grid>
  );
};

export const SocialMediaButtonGroup = ({
  color = heather,
  columnGap = 1,
  justifyContent = 'center',
  size = 'medium',
  wrap = 'nowrap',
}: Props) => {
  return (
    <Grid
      alignItems="center"
      columnGap={columnGap}
      container
      flexWrap={wrap}
      item
      paddingBottom="30px"
      justifyContent={justifyContent}
    >
      <MediumIconLink color={color} size={size} />
      <TwitterIconLink color={color} size={size} />
      <DiscordIconLink color={color} size={size} />
      <LinkedInIconLink color={color} size={size} />
      <GithubIconLink color={color} size={size} />
    </Grid>
  );
};

export { DiscordIconLink, LinkedInIconLink, MediumIconLink, TwitterIconLink };
