export const ROLES = {
  developer: {
    value: 'TenantDeveloper',
    label: 'Developer',
  },
  marketer: {
    value: 'UserMessenger',
    label: 'Marketer',
  },
  admin: {
    value: 'TenantConfigAdmin',
    label: 'Admin',
  },
};

export enum BotTypes {
  TELEGRAM = 'Telegram',
  DISCORD = 'Discord',
  // WHATSAPP = 'WhatsApp',
}

export const TABS = {
  topic: {
    value: 0,
    path: '/alert',
  },
  uiconfig: {
    value: 1,
    path: '/alert/configs',
  },
  storage: {
    value: 2,
    path: '/alert/storage',
  },
};
