import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { NotifiTooltip } from '@components/NotifiTooltip';
import { InputLabel, SxProps, TextField, InputAdornment } from '@mui/material';
import React from 'react';
import { cloud, dusk } from 'src/constants/colors';

type Props = {
  isEdit?: boolean;
  value: string | number;
  customSx?: SxProps;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tooltipContent?: string;
  label: string;
  customLabelSx?: SxProps;
  disabled?: boolean;
  type?: string;
};

export const InputWithLabel: React.FC<Props> = ({
  isEdit,
  value,
  handleChange,
  tooltipContent,
  label,
  customSx,
  customLabelSx,
  disabled,
  type,
}: Props) => {
  const { clearMessageBarState } = useFormMessageBarContext();
  return (
    <>
      {isEdit || disabled ? (
        <InputLabel
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            color: dusk,
            marginTop: '6px',
            textAlign: 'left',
            width: '360px',
            lineHeight: '18px',
            ...customLabelSx,
          }}
        >
          {label}
          {tooltipContent ? (
            <NotifiTooltip
              tooltipContent={tooltipContent}
              buttonSx={{ ml: '4px' }}
              tooltipSx={{ color: dusk }}
              boxSx={{
                pl: '20px',
                borderRadius: '8px',
                border: `1px solid ${cloud}`,
              }}
            />
          ) : null}
        </InputLabel>
      ) : null}
      <TextField
        type={type}
        disabled={disabled}
        label={isEdit || disabled ? '' : label}
        InputProps={{
          endAdornment: !isEdit && !disabled && tooltipContent && (
            <InputAdornment position="end">
              <NotifiTooltip
                tooltipContent={tooltipContent}
                buttonSx={{ ml: '4px' }}
                tooltipSx={{ color: dusk }}
                boxSx={{
                  pl: '20px',
                  borderRadius: '8px',
                  border: `1px solid ${cloud}`,
                }}
              />
            </InputAdornment>
          ),
        }}
        sx={{
          ...customSx,
        }}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          clearMessageBarState();
          handleChange(e);
        }}
      />
    </>
  );
};
