import { useQuery } from '@apollo/client';
import { useAuthContext } from '@components/auth/AuthContext';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import DeliveryStatusCard from '@components/community/DeliveryStatusCard';
import { BaseContainer } from '@components/containers/BaseContainer';
import { Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { dusk, eggshell, midnightLight, white } from 'src/constants/colors';
import { isCommunityManagerEnabled } from 'src/pages/featureFlags';
import { MessageDeliveryStatisticsQuery } from 'src/services/gql/queries/GetMessageDeliveryStatisticQuery.gql';

type GetMessageDeliveryStatisticsResult = {
  messageDeliveryStatistics: {
    messagesByTarget: {
      stats: {
        key: string;
        value: { messages: number; users: number };
      }[];
    };
  };
};

export const SummaryStatisticDashboard: React.FC = () => {
  const authState = useAuthContext();
  const navigate = useNavigate();
  const { data, loading } = useQuery<GetMessageDeliveryStatisticsResult>(
    MessageDeliveryStatisticsQuery,
  );
  const hasUserMessengerRole = useMemo(() => {
    if (authState.type === 'loggedIn') {
      return Boolean(authState.roles?.includes('UserMessenger'));
    }
    return false;
  }, [authState]);

  const totalSubscribedUsers =
    data?.messageDeliveryStatistics?.messagesByTarget?.stats?.find(
      (stat) => stat.key === 'Total',
    )?.value.users || 'N/A';

  return (
    <BaseContainer sx={{ mb: 3, flex: 1 }}>
      <Stack
        justifyContent="space-around"
        flexDirection="row"
        sx={{ width: '100%' }}
      >
        {!loading ? (
          <>
            <Stack sx={{ color: eggshell }}>
              <Stack
                sx={{
                  height: '88px',
                  width: '265px',
                  backgroundColor: midnightLight,
                  margin: '32px 32px 24px 0',
                  borderRadius: '8px',
                }}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'Rota',
                    fontWeight: 900,
                    fontSize: '30px',
                    py: 1,
                  }}
                >
                  {totalSubscribedUsers}
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'Rota',
                    fontWeight: 700,
                    fontSize: '14px',
                    color: dusk,
                  }}
                >
                  Total Unique Subscribers
                </Typography>
              </Stack>
              {hasUserMessengerRole && isCommunityManagerEnabled ? (
                <PrimaryButton
                  buttonTitle="+ Compose"
                  customSx={{
                    paddingX: '25px',
                    color: white,
                    width: '265px',
                    marginBottom: 4,
                  }}
                  onClick={() => navigate('/community')}
                />
              ) : null}
            </Stack>
            <Stack
              flexDirection="row"
              flexWrap="wrap"
              sx={{ maxWidth: '600px', marginTop: '32px' }}
            >
              {data?.messageDeliveryStatistics?.messagesByTarget?.stats?.map(
                (deliveryDestination, index) => {
                  if (
                    ['Email', 'Sms', 'Telegram', 'Discord'].includes(
                      deliveryDestination.key,
                    )
                  ) {
                    return (
                      <DeliveryStatusCard
                        key={index}
                        subscribersNumber={
                          deliveryDestination.value.users || 'N/A'
                        }
                        messageSentNumber={
                          deliveryDestination.value.messages || 'N/A'
                        }
                        deliveryDestination={deliveryDestination.key || ''}
                      />
                    );
                  }
                  return null;
                },
              )}
            </Stack>
          </>
        ) : (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: '220px' }}
          >
            loading...
          </Stack>
        )}
      </Stack>
    </BaseContainer>
  );
};

export default SummaryStatisticDashboard;
