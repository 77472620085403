import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as IconChevronDown } from '../../../assets/icn-chevron-down.svg';
import { WalletBlockchain } from 'src/services/gql/generated';
import {
  useGetFusionTopicsData,
  useExistingParserData,
  useTopicsDataContext,
} from '@components/alertManager/TopicsDataContext';
import { useParams } from 'react-router-dom';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import {
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import { dusk, eggshell, midnight, white } from 'src/constants/colors';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const ChangeParserModal: React.FC<Props> = ({ open, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { parserData } = useExistingParserData();
  const { items: topics } = useGetFusionTopicsData();
  const { addFusionEventToFusionSource, removeFusionEventFromFusionSource } =
    useTopicsDataContext();
  const params = useParams();

  const topic = useMemo(() => {
    return topics?.find((item) => item?.id === params?.cardId);
  }, [topics, params]);

  const [selectedParser, setSelectedParser] = useState(
    topic?.fusionSource?.id || '',
  );

  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();

  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };

  const existingParser = React.useMemo(
    () =>
      topic?.fusionSource?.blockchainSource === WalletBlockchain.OFF_CHAIN
        ? parserData.filter(
            (parser) => parser.blockchainSource === WalletBlockchain.OFF_CHAIN,
          )
        : parserData.filter(
            (parser) => parser.blockchainSource === topic?.fusionSource?.blockchainSource,
          ),
    [parserData, topic?.fusionSource?.blockchainSource],
  );

  const handleCloseChangeParserModal = () => {
    if (isLoading) return;
    handleClose();
    setSelectedParser(topic?.fusionSource?.id || '');
  };

  const handleParserChange = (parserId: string) => {
    setSelectedParser(
      existingParser?.find(({ id }) => id === parserId)?.id ?? '',
    );
  };

  const handleChangeParserRequest = async () => {
    if (isLoading || !topic || !selectedParser) return;
    setIsLoading(true);
    clearMessageBarState();

    try {
      if (selectedParser !== topic.fusionSource?.id) {
        await removeFusionEventFromFusionSource({ fusionEventId: topic?.id });
        await addFusionEventToFusionSource({
          fusionEventId: topic?.id,
          fusionSourceId: selectedParser,
        });
      }
      handleClose();
    } catch (err) {
      setErrorMessage((err as Error).message);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedParser(topic?.fusionSource?.id || '');
  }, [topic]);

  const title = (
    <Stack p={0} m={0}>
      <Stack position={'relative'}>
        <Stack flexDirection={'row'} alignItems={'center'}>
          <Typography
            sx={{
              flexGrow: 1,
              width: '100%',
              fontFamily: 'Rota',
              fontWeight: 700,
              letterSpacing: '-0.185px',
              fontSize: '18px',
              color: midnight,
              p: 0,
            }}
          >
            Change Parser
          </Typography>
        </Stack>

        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translate(0, -50%)',
            flexShrink: 0,
            backgroundColor: white,
            p: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={handleCloseChangeParserModal}
        >
          <CloseIcon sx={{ color: dusk }} />
        </IconButton>
      </Stack>

      <Stack flexDirection={'row'} justifyContent={'center'}>
        <Typography
          sx={{
            maxWidth: '475px',
            fontFamily: 'Rota',
            fontWeight: 700,
            color: dusk,
            fontSize: '14px',
            mt: '18px',
            mb: 1,
          }}
        >
          When selecting a new parser, make sure to update the parser you are
          switching to so that the appropriate topic data is emitted.
        </Typography>
      </Stack>
    </Stack>
  );

  return (
    <ConfirmDialog
      disabled={isLoading}
      title={title}
      open={open}
      handleClose={handleCloseChangeParserModal}
      handleConfirm={handleChangeParserRequest}
      ctaTitle="Save"
      sx={{ width: '600px', pt: 3 }}
      titleSx={{ p: 0 }}
    >
      <Stack width={'100%'} height={'100%'} alignItems={'center'}>
        <Stack width={'360px'}>
          <InputLabel
            sx={{
              mb: 0.125,
              fontFamily: 'Rota',
              fontWeight: 700,
              fontSize: '14px',
            }}
          >
            Select parser to emit this topic from
          </InputLabel>

          <Select
            value={selectedParser}
            onChange={(e) => {
              handleParserChange(e.target.value);
            }}
            IconComponent={(props) => {
              return (
                <IconChevronDown
                  {...props}
                  style={{
                    marginRight: '12px',
                    top: 'calc(50% - .7em)',
                    fill: dusk,
                  }}
                />
              );
            }}
            sx={{
              backgroundColor: eggshell,
              ':before': { border: 'none' },
              '&.MuiSelect-iconStandard': { color: 'red' },
            }}
          >
            {existingParser.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                <Typography
                  sx={{
                    fontFamily: 'Rota',
                    fontSize: '16px',
                    fontWeight: 600,
                    color: midnight,
                  }}
                >
                  {name}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
    </ConfirmDialog>
  );
};

export default ChangeParserModal;
