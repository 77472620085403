import { gql } from '@apollo/client';

export const CreateCardConfigMutation = gql`
  mutation CreateCardConfig(
    $id: String
    $type: TenantConfigType = SUBSCRIPTION_CARD
    $json: String!
  ) {
    createTenantConfig(
      createTenantConfigInput: { id: $id, type: $type, data: $json }
    ) {
      id
      type
      dataJson
    }
  }
`;
