import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import {
  dusk,
  eggshell,
  midnightDarker,
  midnightLight,
} from 'src/constants/colors';
import { ReactComponent as EmailIcon } from '../../assets/icn-email.svg';
import { ReactComponent as SMSIcon } from '../../assets/icn-sms.svg';
import { ReactComponent as TelegramIcon } from '../../assets/icn-telegram.svg';
import { ReactComponent as DiscordIcon } from '../../assets/icn-discord.svg';

export const NumberTextPairing = ({
  number,
  text,
}: {
  number: string | number;
  text: string | number;
}) => {
  return (
    <Stack flexDirection="column" justifyContent="center" alignItems="center">
      <Typography
        component="span"
        sx={{
          fontFamily: 'Rota',
          fontWeight: 800,
          fontSize: '16px',
          color: eggshell,
        }}
      >
        {number}
      </Typography>
      <Typography
        component="span"
        sx={{
          fontFamily: 'Rota',
          fontWeight: 700,
          fontSize: '14px',
          color: dusk,
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

const iconToShow = (destination: string) => {
  switch (destination) {
    case 'Email':
      return <EmailIcon />;
    case 'Sms':
      return <SMSIcon />;
    case 'Telegram':
      return <TelegramIcon />;
    case 'Discord':
      return <DiscordIcon />;
    default:
      return null;
  }
};

type DeliveryStatusCardProps = Readonly<{
  subscribersNumber: string | number;
  messageSentNumber: string | number;
  deliveryDestination: string;
}>;

export const DeliveryStatusCard: React.FC<DeliveryStatusCardProps> = ({
  subscribersNumber,
  messageSentNumber,
  deliveryDestination,
}) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      flexWrap="nowrap"
      sx={{
        backgroundColor: midnightDarker,
        borderRadius: '8px',
        height: '64px',
        width: '266px',
        mr: 3,
      }}
    >
      <Box
        sx={{
          backgroundColor: midnightLight,
          borderRadius: '8px',
          height: '64px',
          width: '66px',
        }}
      >
        <Stack
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '64px', width: '66px' }}
        >
          {iconToShow(deliveryDestination)}
          <Typography
            component="span"
            sx={{
              fontFamily: 'Rota',
              fontWeight: 800,
              fontSize: '12px',
              color: dusk,
              marginTop: '5px',
            }}
          >
            {deliveryDestination}
          </Typography>
        </Stack>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ width: '100%', height: '100%' }}
      >
        <NumberTextPairing text="Subscribers" number={subscribersNumber} />
        <NumberTextPairing text="Msgs sent" number={messageSentNumber} />
      </Stack>
    </Stack>
  );
};

export default DeliveryStatusCard;
