import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { midnight, white } from 'src/constants/colors';
import { PrimaryButton } from '@components/buttons/PrimaryButton';

type Props = {
  title: string;
  img: React.FC;
  handleClick: () => void;
  isFlagVersion19?: boolean;
  isFlagVersion195?: boolean;
};

const CreateTopicCard: React.FC<Props> = ({
  title,
  img: Image,
  handleClick,
  isFlagVersion19,
  isFlagVersion195,
}) => {
  return (
    <Stack
      onClick={handleClick}
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{
        background: '#FEFEFE',
        borderRadius: '30px',
        bottom: '8px',
        height: '100px',
        width: '600px',
        pr: 4,
        mb: 2,
        cursor: isFlagVersion19 || isFlagVersion195 ? 'default' : 'pointer',
      }}
    >
      <Box
        sx={{
          backgroundColor: white,
          borderRadius: '30px',
          width: '100px',
          height: '100px',
          boxShadow:
            '4.29787px 10.74468px 21.48936px 0px rgba(215, 173, 232, 0.30)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image />
      </Box>
      <Typography
        component="span"
        sx={{
          color: midnight,
          fontFamily: 'Rota',
          fontSize: '20px',
          fontWeight: 700,
          width: '290px',
        }}
      >
        {title}
      </Typography>
      <PrimaryButton
        buttonTitle={
          isFlagVersion19 || isFlagVersion195 ? 'Coming soon' : 'Continue'
        }
        customSx={{
          width: isFlagVersion19 || isFlagVersion195 ? '130px' : '100px',
          cursor: isFlagVersion19 || isFlagVersion195 ? 'default' : 'pointer',
        }}
        onClick={handleClick}
      />
    </Stack>
  );
};

export default CreateTopicCard;
