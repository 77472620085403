export const DISCORD_URL = 'https://discord.gg/nAqR3mk3rv';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/notifinetwork';
export const MEDIUM_NEWS_URL = 'https://medium.com/notifi';
export const TWITTER_URL = 'https://twitter.com/NotifiNetwork';
export const GITHUB_URL = 'https://github.com/notifi-network';
export const GUIDE_URL =
  'https://www.figma.com/proto/EDRHnzmXsLRrmM1VBc57Hw/Notifi-Slides?page-id=3859%3A1161&type=design&node-id=3859-1287&viewport=633%2C494%2C0.12&scaling=min-zoom&starting-point-node-id=3859%3A1287';
export const VIDEO_TOUR_URL =
  'https://www.youtube.com/watch?v=fxgl97NgpvY&ab_channel=NotifiNetwork';
export const LEARN_URL =
  'https://docs.notifi.network/docs/alert-subscribe/react-card/create-card-id';
export const BOT_SETUP_URL = 'https://admin.dev.notifi.network/settings';
export const FAQ_URL = 'https://docs.notifi.network/docs/faq';
export const DOCS_URL = 'https://docs.notifi.network/docs/';
export const HOW_TO_GUIDE =
  'https://www.figma.com/proto/EDRHnzmXsLRrmM1VBc57Hw/Notifi-Slides?page-id=3859%3A1161&type=design&node-id=6961-944&viewport=633%2C494%2C0.12&scaling=min-zoom&starting-point-node-id=3859%3A1287&t=hkhkfMiDC4donqu7-1';
