import { useEffect, useState } from 'react';
import { useGlobal2FaAuthModal } from './GlobalModalContext';

const useAuthenticatorCode = () => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const { error: apiValidationError, setError: setApiValidationErrors } = useGlobal2FaAuthModal();
    const hasEmptyFields = code === ''

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setCode(value);
    };

    const clearState = () => {
        setCode('')
        setError('')
        setApiValidationErrors && setApiValidationErrors('')
    }

    const clearError = () => {
        setError('')
        setApiValidationErrors && setApiValidationErrors('')
    }

    useEffect(() => {
        if (apiValidationError) {
            setError(apiValidationError)
        }
    }, [apiValidationError])

    useEffect(() => {
        return () => {
            clearState()
        }
    }, [])

    return {
        code,
        error,
        handleChange,
        setError,
        clearState,
        clearError,
        hasEmptyFields
    };
};

export default useAuthenticatorCode;
