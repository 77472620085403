import * as React from 'react';
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import {
  dusk,
  eggshell,
  midnight,
  seaglassDarker,
  white,
} from 'src/constants/colors';
import { ReactComponent as IconChevronDown } from '../../assets/icn-chevron-down.svg';
import { useState } from 'react';
import './index.css';
import useListManagement from 'src/hooks/useListManagement';
import useAvailableEvents from 'src/hooks/useAvailableEvents';
import {
  createNewParser,
  useOnOffChainFormContext,
} from './OnOffChainFormContext';
import { WalletBlockchain } from 'src/services/gql/generated';
import useFrequencyByUnit from 'src/hooks/useFrequencyByUnit';
import CronInput from './CronInput';
import { checkboxCustomCSS } from '@components/cardConfig/common/CheckboxCSS';
import AddFilterData from './AddFilterData';

type FilterType = {
  TopicHashes?: string[];
  ContractAddresses?: string[];
  EventTypes?: string[];
  TransactionModules?: string[];
  ProgramId?: string[];
  CronExecutionInterval?: string;
};


const CreateOnChainTopicStep2 = () => {

  const [isCronEvent, setIsCronEvent] = useState<boolean>(false);
  const [isManualEntry, setIsManualEntry] = useState<boolean>(false);
  const [isContractAddressVerified, setIsContractAddressVerified] =
    useState<boolean>(true);
  const [isSameContractTransaction] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<string[]>([]);
  const [manualLoadABI, setManualLoadABI] = useState<string>('');
  const [contractAddress, setContractAddress] = useState<string>('');
  const {
    selectedParser,
    parserName,
    setIsSaveTopicButtonEnabled,
    setFusionSourceData,
    selectedBlockchain,
    setSelectedBlockchain
  } = useOnOffChainFormContext();

  // Same hook will be used for storing following details -
  // For all type of blockchain - Stores Topic / Event #s and Contract Addresses
  // For Solana - Stores {"ProgramIds":[]}
  // For sui - Stores {"EventTypes": [], "TransactionModules": []}
  const {
    handleInsertItem: handleInsertEvent,
    handleItemChange: handleEventChange,
    handleRemoveItem: handleRemoveEvent,
    handleInsertDefaultItems: setDefaultEvents,
    list: events,
  } = useListManagement();

  const {
    handleInsertItem: handleInsertContractAddress,
    handleItemChange: handleContractAddressChange,
    handleRemoveItem: handleRemoveContractAddress,
    handleInsertDefaultItems: setDefaultContractAddresses,
    list: contractAddresses,
  } = useListManagement();
  // ^^

  const {
    availableEvents,
    setContractAddress: sendContractAddress,
    setABI: sendABI,
    setSelectedChain,
  } = useAvailableEvents();

  const {
    frequency,
    unit,
    setFrequency,
    setUnit,
    cronExecutionInterval,
    cronToFrequencyAndUnit,
  } = useFrequencyByUnit();

  const handleSelectedEventChange = (value: string | string[]) => {
    setSelectedEvent(typeof value === 'string' ? value.split(',') : value);
  };

  const isExistingParser = selectedParser.id !== createNewParser;
  const isCronParser = React.useMemo(
    () => !!JSON.parse(selectedParser?.filter || '{}')?.CronExecutionInterval,
    [selectedParser],
  );

  React.useEffect(() => {
    // It'll prefill all the data from selected parser
    if (isExistingParser) {
      const filter: FilterType = JSON.parse(selectedParser?.filter || '{}');

      let data = filter?.TopicHashes || filter?.EventTypes || filter?.ProgramId;
      setDefaultEvents(
        data?.map((value, id) => ({
          isRemovable: false,
          value,
          id,
        })) ?? [],
      );

      data = filter?.ContractAddresses || filter?.TransactionModules;
      setDefaultContractAddresses(
        data?.map((value, id) => ({
          isRemovable: false,
          value,
          id,
        })) ?? [],
      );

      setIsCronEvent(!!filter?.CronExecutionInterval);
      setSelectedBlockchain(selectedParser?.blockchainSource);
      setIsManualEntry(true);
      if (filter?.CronExecutionInterval) {
        cronToFrequencyAndUnit(filter?.CronExecutionInterval);
      }
      return;
    }
    // It'll reset all local state if selected parser changed
    else {
      setSelectedBlockchain(WalletBlockchain.ETHEREUM);
      setIsManualEntry(false);
      setDefaultContractAddresses([]);
      setDefaultEvents([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExistingParser, selectedParser]);

  React.useEffect(() => {
    setSelectedEvent([]); // Reset selected event if ABI or contract address value gets changed
    sendContractAddress(isContractAddressVerified ? contractAddress : '');
    sendABI(isContractAddressVerified ? '' : manualLoadABI);
    if (isContractAddressVerified) setSelectedChain(selectedBlockchain);
  }, [
    manualLoadABI,
    contractAddress,
    isContractAddressVerified,
    sendContractAddress,
    sendABI,
    selectedBlockchain,
    setSelectedChain,
  ]);

  // It'll keep the local form state in sync with the Form-Context-Provider for on-chain events.
  React.useEffect(() => {
    if (isCronEvent) return;

    if (!isExistingParser && !parserName) {
      setIsSaveTopicButtonEnabled(false);
      return;
    }

    // If user creates new parser -
    if (!isExistingParser) {
      let filter = {} as FilterType;

      // Set filter for Guided Entry
      if (!isManualEntry && selectedEvent.length > 0) {
        filter = {
          TopicHashes: selectedEvent,
          ContractAddresses:
            isSameContractTransaction && contractAddress
              ? [contractAddress]
              : [],
        };
      }

      // Set filter for Manual Entry
      if (isManualEntry) {
        const key1 = isSolanaBlockchain
          ? 'ProgramId'
          : isSuiBlockchain
          ? 'EventTypes'
          : 'TopicHashes';
        const key2 = isSolanaBlockchain
          ? null
          : isSuiBlockchain
          ? 'TransactionModules'
          : 'ContractAddresses';
        filter[key1] = events.map((i) => i.value).filter(Boolean);

        if (key2)
          filter[key2] = contractAddresses.map((i) => i.value).filter(Boolean);
      }

      if (
        (filter?.TopicHashes && filter?.ContractAddresses) ||
        filter?.ProgramId ||
        (filter?.EventTypes && filter?.TransactionModules)
      ) {
        const fusionSourceData = {
          name: parserName,
          blockchainType: selectedBlockchain,
          filter: JSON.stringify(filter),
        };
        setFusionSourceData(fusionSourceData);
        setIsSaveTopicButtonEnabled(true);
        return;
      }
    }
    // If user selects existing parser -
    else {
      let filter = {} as FilterType;
      const selectedParserFilter: FilterType = JSON.parse(
        selectedParser?.filter,
      );
      const existingTopicHashes = selectedParserFilter?.TopicHashes || [];
      const existingContractAddresses =
        selectedParserFilter?.ContractAddresses || [];

      // Set filter for Guided Entry
      if (
        !isManualEntry &&
        (selectedEvent.length > 0 || existingTopicHashes.length > 0)
      ) {
        filter = {
          TopicHashes: [...existingTopicHashes, ...selectedEvent],
          ContractAddresses:
            isSameContractTransaction && contractAddress
              ? [...existingContractAddresses, contractAddress]
              : existingContractAddresses,
        };
      }

      // Set filter for Manual Entry
      if (isManualEntry) {
        const key1 = isSolanaBlockchain
          ? 'ProgramId'
          : isSuiBlockchain
          ? 'EventTypes'
          : 'TopicHashes';
        const key2 = isSolanaBlockchain
          ? null
          : isSuiBlockchain
          ? 'TransactionModules'
          : 'ContractAddresses';
        filter[key1] = events.map((i) => i.value).filter(Boolean);

        if (key2)
          filter[key2] = contractAddresses.map((i) => i.value).filter(Boolean);
      }

      if (
        (filter?.TopicHashes && filter?.ContractAddresses) ||
        filter?.ProgramId ||
        (filter?.EventTypes && filter?.TransactionModules)
      ) {
        const fusionSourceData = {
          name: selectedParser.name,
          blockchainType: selectedParser.blockchainSource,
          filter: JSON.stringify(filter),
        };
        setFusionSourceData(fusionSourceData);
        setIsSaveTopicButtonEnabled(true);
        return;
      }
    }

    setIsSaveTopicButtonEnabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCronEvent,
    selectedParser,
    parserName,
    setIsSaveTopicButtonEnabled,
    isManualEntry,
    contractAddress,
    selectedEvent,
    isSameContractTransaction,
    selectedBlockchain,
    setFusionSourceData,
    contractAddresses,
    events,
    isExistingParser,
  ]);

  // It'll keep the local form state in sync with the Form-Context-Provider for cron events.
  React.useEffect(() => {
    if (!isCronEvent) return;

    if (!frequency || (!isExistingParser && !parserName)) {
      setIsSaveTopicButtonEnabled(false);
      return;
    }

    const filter = { CronExecutionInterval: cronExecutionInterval };
    const fusionSourceData = {
      name: isExistingParser ? selectedParser.name : parserName,
      blockchainType: isExistingParser
        ? selectedParser.blockchainSource
        : selectedBlockchain,
      filter: JSON.stringify(filter),
    };

    setFusionSourceData(fusionSourceData);
    setIsSaveTopicButtonEnabled(true);
  }, [
    isCronEvent,
    cronExecutionInterval,
    frequency,
    parserName,
    selectedParser,
    setFusionSourceData,
    setIsSaveTopicButtonEnabled,
    isExistingParser,
    selectedBlockchain,
  ]);

  React.useEffect(() => {
    if (selectedBlockchain !== WalletBlockchain.ETHEREUM) setIsManualEntry(true);
  }, [selectedBlockchain]);

  const disableGuidedEntry = selectedBlockchain !== WalletBlockchain.ETHEREUM;
  const isSolanaBlockchain = selectedBlockchain === WalletBlockchain.SOLANA;
  const isSuiBlockchain = selectedBlockchain === WalletBlockchain.SUI;

  return (
    <Stack>
      {/* Cron | On-chain event option */}
      <Stack mb={1.5}>
        <InputLabel
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            color: dusk,
            textAlign: 'left',
          }}
        >
          How would you like to trigger your event?
        </InputLabel>

        <Stack flexDirection="row" marginTop="3px" gap={1.2}>
          <Tooltip
            title={
              isExistingParser && isCronEvent ? (
                <Box
                  sx={{
                    backgroundColor: eggshell,
                    p: 0,
                    m: 0,
                    width: '192px',
                  }}
                >
                  <Typography
                    sx={{
                      p: 1.2,
                      fontFamily: 'Rota',
                      fontSize: '12px',
                      fontWeight: 700,
                      color: midnight,
                    }}
                  >
                    The existing parser you selected for this topic is
                    configured for cron interval. This cannot be changed. To
                    change this selection, select or create a new parser.
                  </Typography>
                </Box>
              ) : (
                ''
              )
            }
            arrow
            placement="left"
            sx={{ p: 0, m: 0 }}
          >
            <FormControlLabel
              sx={{ mr: 0 }}
              label={
                <Typography
                  sx={{
                    fontWeight: 600,
                    mt: '2px',
                    fontSize: '16px',
                    fontFamily: 'Rota',
                    ...(isExistingParser &&
                      isCronParser && { opacity: 0.5, cursor: 'not-allowed' }),
                  }}
                >
                  On-chain event
                </Typography>
              }
              control={
                <Radio
                  disabled={isExistingParser && isCronParser}
                  checked={!isCronEvent}
                  onChange={(e) => {
                    if (e.target.checked) setIsCronEvent(false);
                  }}
                  sx={{
                    ...checkboxCustomCSS,
                    '&.Mui-disabled': {
                      color: dusk,
                      opacity: 0.5,
                      cursor: 'not-allowed',
                      pointerEvents: 'unset',
                    },
                  }}
                />
              }
            />
          </Tooltip>

          <Tooltip
            title={
              isExistingParser && !isCronEvent ? (
                <Box
                  sx={{
                    backgroundColor: eggshell,
                    p: 0,
                    m: 0,
                    width: '192px',
                  }}
                >
                  <Typography
                    sx={{
                      p: 1.2,
                      fontFamily: 'Rota',
                      fontSize: '12px',
                      fontWeight: 700,
                      color: midnight,
                    }}
                  >
                    The existing parser you selected for this topic is
                    configured for on-chain event. This cannot be changed. To
                    change this selection, select or create a new parser.
                  </Typography>
                </Box>
              ) : (
                ''
              )
            }
            arrow
            placement="right"
            sx={{ p: 0, m: 0 }}
          >
            <FormControlLabel
              sx={{ mx: 0 }}
              label={
                <Typography
                  sx={{
                    fontWeight: 600,
                    mt: '2px',
                    fontSize: '16px',
                    fontFamily: 'Rota',
                    ...(isExistingParser &&
                      !isCronParser && { opacity: 0.5, cursor: 'not-allowed' }),
                  }}
                >
                  Cron
                </Typography>
              }
              control={
                <Radio
                  disabled={isExistingParser && !isCronParser}
                  checked={isCronEvent}
                  onChange={(e) => {
                    setIsCronEvent(e.target.checked);
                  }}
                  sx={{
                    ...checkboxCustomCSS,
                    '&.Mui-disabled': {
                      color: dusk,
                      opacity: 0.5,
                      cursor: 'not-allowed',
                      pointerEvents: 'unset',
                    },
                  }}
                />
              }
            />
          </Tooltip>
        </Stack>
      </Stack>

      {isCronEvent && selectedParser.id === createNewParser ? (
        <CronInput
          unit={unit}
          setUnit={setUnit}
          frequency={frequency}
          setFrequency={setFrequency}
          disabled={isExistingParser}
        />
      ) : selectedParser.id === createNewParser ? (
        <>
          {/* Guided or manual entry */}
          <Stack mb={1.5}>
            <InputLabel
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                color: dusk,
                textAlign: 'left',
              }}
            >
              Guided or manual entry
            </InputLabel>

            <Stack
              flexDirection="row"
              marginTop="3px"
              justifyContent={'space-between'}
            >
              <FormControlLabel
                sx={{ mr: 0 }}
                label={
                  <Typography
                    sx={{
                      fontWeight: 600,
                      mt: '2px',
                      fontSize: '16px',
                      fontFamily: 'Rota',
                      ...(disableGuidedEntry && {
                        opacity: 0.5,
                        cursor: 'not-allowed',
                      }),
                    }}
                  >
                    Guided
                  </Typography>
                }
                control={
                  <Radio
                    disabled={disableGuidedEntry}
                    checked={!isManualEntry}
                    onChange={(e) => {
                      if (e.target.checked) setIsManualEntry(false);
                    }}
                    sx={{
                      ...checkboxCustomCSS,
                      '&.Mui-disabled': {
                        color: dusk,
                        opacity: 0.5,
                        cursor: 'not-allowed',
                        pointerEvents: 'unset',
                      },
                    }}
                  />
                }
              />

              <FormControlLabel
                sx={{ mx: 0 }}
                label={
                  <Typography
                    sx={{
                      fontWeight: 600,
                      mt: '2px',
                      fontSize: '16px',
                      fontFamily: 'Rota',
                    }}
                  >
                    Manual entry (advanced)
                  </Typography>
                }
                control={
                  <Radio
                    checked={isManualEntry}
                    onChange={(e) => {
                      setIsManualEntry(e.target.checked);
                    }}
                    sx={{
                      ...checkboxCustomCSS,
                    }}
                  />
                }
              />
            </Stack>
          </Stack>

          {!isManualEntry ? (
            <>
              {/* Contract Address */}
              <Stack mb={1.5}>
                <InputLabel
                  id="Contract-Address"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    fontFamily: 'Rota',
                    color: dusk,
                    textAlign: 'left',
                    mb: 0.125,
                  }}
                >
                  Enter contract address
                </InputLabel>

                <TextField
                  autoComplete="off"
                  value={contractAddress}
                  onChange={(e) => {
                    setContractAddress(e.target.value);
                  }}
                />

                {/* Radio button for - Contract Address Verification */}
                <Stack flexDirection="column" mt={2} gap={1}>
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          fontWeight: 600,
                          mt: '2px',
                          fontSize: '16px',
                          fontFamily: 'Rota',
                        }}
                      >
                        This address is verified by a block explorer
                      </Typography>
                    }
                    control={
                      <Radio
                        checked={isContractAddressVerified}
                        onChange={(e) => {
                          if (e.target.checked)
                            setIsContractAddressVerified(true);
                        }}
                        sx={{
                          ...checkboxCustomCSS,
                        }}
                      />
                    }
                  />

                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          fontWeight: 600,
                          mt: '2px',
                          fontSize: '16px',
                          fontFamily: 'Rota',
                        }}
                      >
                        Manually load ABI
                      </Typography>
                    }
                    control={
                      <Radio
                        checked={!isContractAddressVerified}
                        onChange={(e) => {
                          if (e.target.checked)
                            setIsContractAddressVerified(false);
                        }}
                        sx={{
                          ...checkboxCustomCSS,
                        }}
                      />
                    }
                  />
                </Stack>

                {/* Textarea for manual load ABI */}
                {isContractAddressVerified ? null : (
                  <Stack mt={2}>
                    <InputLabel
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        fontFamily: 'Rota',
                        color: dusk,
                        textAlign: 'left',
                        mb: 0.125,
                      }}
                    >
                      Manually load ABI here
                    </InputLabel>

                    <TextareaAutosize
                      style={{
                        background: eggshell,
                        border: 0,
                        color: midnight,
                        fontFamily: 'Rota',
                        fontSize: '16px',
                        fontWeight: '600',
                        padding: '16px 14px',
                        minHeight: '108px',
                        minWidth: '360px',
                        maxWidth: '360px',
                        height: '108px',
                        outline: 'none',
                      }}
                      value={manualLoadABI}
                      onChange={(e) => {
                        const value = e?.target?.value;
                        setManualLoadABI(value);
                      }}
                    />
                  </Stack>
                )}
              </Stack>

              {/* Available Events */}
              <Stack mb={1.5}>
                <Select
                  autoComplete="off"
                  multiple
                  value={selectedEvent}
                  onChange={(e) => {
                    handleSelectedEventChange(e.target.value);
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0)
                      return (
                        <Typography
                          sx={{
                            fontFamily: 'Rota',
                            fontSize: '16px',
                            fontWeight: 600,
                            color: dusk,
                            mt: '2px',
                          }}
                        >
                          Select available events
                        </Typography>
                      );

                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              availableEvents.find(
                                (item) => item.eventHash === value,
                              )?.name
                            }
                            sx={{
                              backgroundColor: seaglassDarker,
                              color: white,
                            }}
                          />
                        ))}
                      </Box>
                    );
                  }}
                  IconComponent={(props) => {
                    return (
                      <IconChevronDown
                        {...props}
                        style={{
                          marginRight: '12px',
                          top: 'calc(50% - .7em)',
                          fill: dusk,
                        }}
                      />
                    );
                  }}
                  sx={{
                    backgroundColor: eggshell,
                    ':before': { border: 'none' },
                    '&.MuiSelect-iconStandard': { color: 'red' },
                  }}
                >
                  {availableEvents.map(({ name, eventHash }, i) => (
                    <MenuItem key={i} value={eventHash}>
                      <Checkbox
                        checked={selectedEvent.indexOf(eventHash) > -1}
                        sx={{
                          py: 0,
                        }}
                      />
                      <Typography
                        sx={{
                          fontFamily: 'Rota',
                          fontSize: '16px',
                          fontWeight: 600,
                          color: dusk,
                        }}
                      >
                        {name}
                      </Typography>
                    </MenuItem>
                  ))}

                  {availableEvents?.length > 0 ? null : (
                    <Typography
                      px={2.8}
                      py={4}
                      sx={{
                        fontFamily: 'Rota',
                        fontSize: '14px',
                        fontWeight: 700,
                        color: dusk,
                        textAlign: 'center',
                      }}
                    >
                      We were unable to load this data, select manual entry
                    </Typography>
                  )}
                </Select>
              </Stack>

              {/* Removed this for AP-2.0 */}
              {/* Checkbox for verifying transactions */}
              {/* {contractAddress ? (
                <Stack flexDirection="row" alignItems="start" mt={3}>
                  <FormControlLabel
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600',
                      fontFamily: 'Rota',
                      mx: 0,
                    }}
                    control={
                      <Checkbox
                        checked={isSameContractTransaction}
                        onChange={(e) =>
                          setIsSameContractTransaction(e.target.checked)
                        }
                        sx={{
                          mr: 1.5,
                          p: 0,
                          color: dusk,
                          '&.Mui-checked': {
                            color: seaglassDarker,
                          },
                        }}
                      />
                    }
                    label="Only activate if transaction is from this or many contract addresses."
                  />
                </Stack>
              ) : null} */}
            </>
          ) : (
            <>
              {/* Topics and Contract addresses */}
              {!isSolanaBlockchain && !isSuiBlockchain ? (
                <>
                  <AddFilterData
                    title="Topic / Event #s"
                    actionText="+ Insert Event"
                    inputLabel="Event"
                    data={events}
                    handleDataChange={handleEventChange}
                    handleInsertData={handleInsertEvent}
                    handleRemoveData={handleRemoveEvent}
                  />

                  <AddFilterData
                    title="Contract Addresses"
                    actionText="+ Insert Address"
                    inputLabel="Address"
                    data={contractAddresses}
                    handleDataChange={handleContractAddressChange}
                    handleInsertData={handleInsertContractAddress}
                    handleRemoveData={handleRemoveContractAddress}
                  />
                </>
              ) : null}

              {/* Program Ids */}
              {isSolanaBlockchain ? (
                <AddFilterData
                  title="Program Ids"
                  actionText="+ Insert Program Id"
                  inputLabel="Program Id"
                  data={events}
                  handleDataChange={handleEventChange}
                  handleInsertData={handleInsertEvent}
                  handleRemoveData={handleRemoveEvent}
                />
              ) : null}

              {/* Event Types and Transaction Modules */}
              {isSuiBlockchain ? (
                <>
                  <AddFilterData
                    title="Event Types"
                    actionText="+ Insert Event Type"
                    inputLabel="Event Type"
                    data={events}
                    handleDataChange={handleEventChange}
                    handleInsertData={handleInsertEvent}
                    handleRemoveData={handleRemoveEvent}
                  />

                  <AddFilterData
                    title="Transaction Modules"
                    actionText="+ Insert Transaction Module"
                    inputLabel="Transaction Module"
                    data={contractAddresses}
                    handleDataChange={handleContractAddressChange}
                    handleInsertData={handleInsertContractAddress}
                    handleRemoveData={handleRemoveContractAddress}
                  />
                </>
              ) : null}
            </>
          )}
        </>
      ): null}
    </Stack>
  );
};

export default CreateOnChainTopicStep2;
