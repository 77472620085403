import React, { useCallback, useState } from 'react';
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { dusk, midnight, white } from 'src/constants/colors';
import { Delete, Edit } from '@mui/icons-material';
import {
  EphemeralStorageEntry,
  useUpdateEphemeralStorageMutation,
} from 'src/services/gql/generated';
import ModifyKeyDialog from './ModifyKeyDialog';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import { ReactComponent as IconStorage } from 'src/assets/icn-storage.svg';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';

type Props = {
  data?: Array<EphemeralStorageEntry>;
  refetchQuery: () => void;
};

const EphemeralStorageTable: React.FC<Props> = ({ data, refetchQuery }) => {
  const { isAP2Enabled } = useFeatureFlag();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [mKey, setMKey] = useState<string>('');
  const [mValue, setMValue] = useState<string>('');
  const [isConfirmRemoveOpen, setIsConfirmRemoveOpen] = useState(false);
  const [keyToRemove, setKeyToRemove] = useState<string>('');
  const [updateEphemeralStorageMutation] = useUpdateEphemeralStorageMutation();

  const { setErrorMessage } = useFormMessageBarContext();

  const handleEdit = ({ key, value }: { key: string; value: string }) => {
    setMKey(key);
    setMValue(value);
    setIsEditOpen(true);
  };

  const handleConfirmRemove = async (keyToRemove: string) => {
    try {
      await updateEphemeralStorageMutation({
        variables: {
          updateEphemeralStorageInput: {
            entries: [{ key: keyToRemove }],
          },
        },
      });
      refetchQuery();
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsConfirmRemoveOpen(false);
    }
  };

  const submitEdit = useCallback(
    async ({ value }: { value: string }) => {
      try {
        await updateEphemeralStorageMutation({
          variables: {
            updateEphemeralStorageInput: {
              entries: [{ key: mKey, value }],
            },
          },
        });
        refetchQuery();
      } catch (error: unknown) {
        setErrorMessage((error as Error).message);
        return;
      }
    },
    [mKey, refetchQuery, setErrorMessage, updateEphemeralStorageMutation],
  );

  return (
    <Box sx={{ mt: '15px' }}>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: isAP2Enabled ? white : midnight,
          backgroundImage: 'none',
          borderRadius: '4px',
          maxHeight: 300,
          overflowY: 'scroll',
        }}
      >
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                '& .MuiTableCell-root': {
                  fontSize: 14,
                  fontWeight: 700,
                  backgroundColor: white,
                  pb: 0,
                },
              }}
            >
              <TableCell align="left" width="20" />
              <TableCell
                width="20"
                align="left"
                sx={{
                  color: dusk,
                }}
              >
                Results
              </TableCell>
              <TableCell
                width="150"
                align="left"
                sx={{
                  color: dusk,
                }}
              />
              <TableCell
                width="150"
                align="left"
                sx={{
                  color: dusk,
                }}
              />
              <TableCell
                width="190"
                align="left"
                sx={{
                  color: dusk,
                }}
              />
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data?.length > 0 ? (
              data?.map((item) => (
                <TableRow
                  key={item.key}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '& .MuiTableCell-root': {
                      fontSize: 16,
                      fontWeight: 600,
                    },
                    overflowWrap: 'anywhere',
                  }}
                >
                  <TableCell
                    align="left"
                    sx={{
                      width: '20px',
                    }}
                  />
                  <TableCell align="left" width="20">
                    <IconStorage />
                  </TableCell>
                  <TableCell align="left">key: {item.key}</TableCell>
                  <TableCell align="left">value: {item.value}</TableCell>
                  <TableCell align="left">TTL: 1239102930129</TableCell>
                  <TableCell align="left">
                    <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          cursor: 'pointer',
                          mr: 2,
                        }}
                        onClick={() =>
                          handleEdit({
                            key: item.key || '',
                            value: item.value || '',
                          })
                        }
                      >
                        <Edit sx={{ color: dusk }} />
                      </Box>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setKeyToRemove(item.key || '');
                          setIsConfirmRemoveOpen(true);
                        }}
                      >
                        <Delete sx={{ color: dusk, mr: 2 }} />
                      </Box>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '& .MuiTableCell-root': {
                    fontSize: 16,
                    fontWeight: 600,
                  },
                  overflowWrap: 'anywhere',
                }}
              >
                <TableCell align="left" width="20" />
                <TableCell align="center" width="100%">
                  <Typography sx={{ fontSize: '16px', color: dusk }}>
                    No result found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {isEditOpen ? (
        <ModifyKeyDialog
          open={isEditOpen}
          handleClose={() => setIsEditOpen(false)}
          mKey={mKey}
          mValue={mValue}
          onSubmit={submitEdit}
        />
      ) : null}

      <ConfirmDialog
        title="Confirm that you would like to delete this key"
        open={isConfirmRemoveOpen}
        handleClose={() => setIsConfirmRemoveOpen(false)}
        handleConfirm={() => handleConfirmRemove(keyToRemove)}
        ctaTitle="Delete"
      />
    </Box>
  );
};

export default EphemeralStorageTable;
