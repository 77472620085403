import { SxProps } from '@mui/material';
import React from 'react';
import { SecondaryButton } from './SecondaryButton';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { blueglass, midnight, midnightDarker } from 'src/constants/colors';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';

type Props = {
  customSx?: SxProps;
  disabled: boolean;
  handlePreview: () => void;
  isDefineTemplate?: boolean;
};

export const PreviewButton: React.FC<Props> = ({
  customSx,
  disabled,
  handlePreview,
  isDefineTemplate = true,
}: Props) => {
  const { isAP2Enabled } = useFeatureFlag();
  return (
    <>
      <SecondaryButton
        disabled={disabled}
        onClick={handlePreview}
        startIcon={
          <RemoveRedEyeIcon
            sx={{
              color: blueglass,
              fontSize: '40px',
            }}
          />
        }
        customSx={{
          mr: isDefineTemplate ? 0 : 2,
          my: 2,
          width: '135px',
          ...(isAP2Enabled
            ? {
                fontWeight: 800,
                color: midnight,
                background: 'transparent',
                borderColor: blueglass,
              }
            : null),
          '&:disabled': {
            color: isAP2Enabled ? midnight : midnightDarker,
            background: isAP2Enabled ? 'transparent' : 'rgba(154,163,255,.4)',
            borderColor: blueglass,
            opacity: isAP2Enabled ? 0.4 : 1,
          },
          ...customSx,
        }}
        buttonTitle="Preview"
      />
    </>
  );
};
