import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { Box, Dialog, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import {
  dusk,
  midnight,
  midnightLight,
  white,
} from 'src/constants/colors';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { Close } from '@mui/icons-material';

type Props = {
  open: boolean;
  handleClose: () => void;
  message?: string;
};

export const AlertFiltersChangeModal: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  handleClose,
}) => {
  const { isAP2Enabled } = useFeatureFlag();

  return (
    <Dialog
      fullWidth={false}
      maxWidth="lg"
      open={open}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: isAP2Enabled ? white : midnightLight,
          borderRadius: '4px',
          width: '650px',
        },
      }}
    >
      <Stack
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        sx={{
          backgroundColor: isAP2Enabled ? white : midnightLight,
          fontFamily: 'Rota',
          py: 4,
          px: 6,
        }}
      >
        <Typography
          textAlign={'center'}
          fontWeight={900}
          fontSize={18}
          color={midnight}
          mb={3}
          fontFamily={'Rota'}
        >
          Changing Alert Filters
        </Typography>

        <Box
          sx={{
            width: '25px',
            height: '25px',
            cursor: 'pointer',
            position: 'absolute',
            top: '25px',
            right: '25px',
          }}
          onClick={handleClose}
        >
          <Close sx={{ color: dusk }} />
        </Box>

        <Typography
          textAlign={'center'}
          fontWeight={600}
          fontSize={16}
          color={dusk}
          mb={3}
          fontFamily={'Rota'}
        >
          When you make changes to the user facing alert filters, any selections your users have made for this alert will be removed, as will their subscription to this topic. Your end users will receive a notification informing them that this topic has been changed, and they need to re-subscribe.
        </Typography>

        <PrimaryButton customSx={{ px: 8 }} buttonTitle="Got it" onClick={handleClose} />
      </Stack>
    </Dialog >
  );
};
