import { FormMessageBarProvider } from '@components/cardConfig/form/context/FormMessageBarContext';
import React, {
  createContext,
  Dispatch,
  useContext,
  useReducer,
  useState,
} from 'react';
import {
  AppContextAction,
  appContextReducer,
  AppState,
} from './appContextReducer';
import { ActiveMenuName } from '@components/navigationBar/NavDrawer';

type AppContextState = Readonly<{
  state: AppState;
  dispatch: Dispatch<AppContextAction>;
  isEditBroadCastPage: boolean;
  setIsEditBroadCastPage: React.Dispatch<React.SetStateAction<boolean>>;
  isUnsavedModal: boolean;
  setIsUnsavedModal: React.Dispatch<React.SetStateAction<boolean>>;
  navPath: {
    path: string;
    activeMenu: number | null;
  };
  setNavPath: React.Dispatch<
    React.SetStateAction<{
      path: string;
      activeMenu: number | null;
    }>
  >;
  isrefetchTopicsData: boolean;
  setRefetchTopicsData: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const defaultSetIsViewBroadcastPage = (): never => {
  throw new Error('setIsViewBroadcastPage function is not initialized');
};
const defaultSetNavPath = (): never => {
  throw new Error('setNavPath function is not initialized');
};

const AppContext = createContext<AppContextState>({
  state: {} as AppState,
  dispatch: () => {
    throw new Error('Unimplemented');
  },
  isEditBroadCastPage: false,
  setIsEditBroadCastPage: defaultSetIsViewBroadcastPage,
  isUnsavedModal: false,
  setIsUnsavedModal: defaultSetIsViewBroadcastPage,
  navPath: {
    path: '',
    activeMenu: null,
  },
  setNavPath: defaultSetNavPath,
  isrefetchTopicsData: false,
  setRefetchTopicsData: () => null,
});

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(appContextReducer, {
    hasUnsavedChange: false,
  });
  const [isEditBroadCastPage, setIsEditBroadCastPage] =
    useState<boolean>(false);
  const [isUnsavedModal, setIsUnsavedModal] = useState<boolean>(false);
  const [navPath, setNavPath] = useState<{
    path: string;
    activeMenu: ActiveMenuName | null;
  }>({
    path: '',
    activeMenu: null,
  });
  const [isrefetchTopicsData, setRefetchTopicsData] = React.useState(false);

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        isEditBroadCastPage,
        setIsEditBroadCastPage,
        isUnsavedModal,
        setIsUnsavedModal,
        navPath,
        setNavPath,
        isrefetchTopicsData,
        setRefetchTopicsData,
      }}
    >
      <FormMessageBarProvider>{children}</FormMessageBarProvider>
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextState => {
  return useContext(AppContext);
};
