import {
  Box,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { cloud, dusk, eggshell, midnight, white } from 'src/constants/colors';
import { Footer } from '@components/footer';
import CloseIcon from '@mui/icons-material/Close';
import { LeftMenuButton } from '@components/navigationBar/LeftMenuButton';
import { ReactComponent as DiscordIcon } from '../../assets/icn-discord.svg';
import { AlertHistoryComposer } from '@components/community/AlertHistoryComposer';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { EmailComposer } from '@components/community/EmailComposer';
import DiscordComposer from '@components/community/DiscordComposer';
import { TelegramComposer } from '@components/community/TelegramComposer';
import { SMSComposer } from '@components/community/SMSComposer';
import { ReactComponent as CheckIcon } from '../../assets/icn-check-seaglass.svg';
import { ReactComponent as AlertHistoryIcon } from '../../assets/icn-alert-history.svg';
import { ReactComponent as EmailIcon } from '../../assets/icn-email.svg';
import { ReactComponent as TelegramIcon } from '../../assets/icn-telegram.svg';
import { ReactComponent as SMSIcon } from '../../assets/icn-sms.svg';
import { ReactComponent as SlackIcon } from '../../assets/icn-slack.svg';
import { ReactComponent as WalletIcon } from '../../assets/icn-wallet.svg';
import { ReactComponent as BrowserIcon } from '../../assets/icn-browser.svg';
import {
  GenericEventIconHint,
  OpenUserRegistration,
  TemplateType,
} from 'src/services/gql/generated';
import useMessageService from 'src/pages/Community/MessageService';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { useGetListTemplates, useTopicsDataContext } from './TopicsDataContext';
import { NotifiTooltip } from '@components/NotifiTooltip';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import { useGetSubscriptionConfigData } from '@components/cardConfig/CardConfigDataContext';
import { CustomFusionEventData } from 'src/pages/AlertManager/UIConfigTab';
import SlackComposer from '@components/community/SlackComposer';
import { FusionEventMetadata } from './CreateNonCMTopicDialog';
import { WalletAlertComposer } from '@components/community/WalletAlertComposer';
import { BrowserComposer } from '@components/community/BrowserComposer';
import { useAppContext } from 'src/context/AppContext';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const convertLeftMenuNameToTemplateType = (name: string): TemplateType => {
  switch (name) {
    case 'Alert History':
      return TemplateType.EVENT_DETAIL_BODY;
    case 'Email':
      return TemplateType.EMAIL;
    case 'Telegram':
      return TemplateType.TELEGRAM;
    case 'SMS':
      return TemplateType.SMS;
    case 'Discord':
      return TemplateType.DISCORD;
    case 'Slack':
      return TemplateType.SLACK_CHANNEL;
    case 'Wallet':
      return TemplateType.WEB3;
    case 'Browser':
      return TemplateType.BROWSER_PUSH;
    default:
      return TemplateType.EVENT_DETAIL_BODY;
  }
};

type NavigationNames =
  | 'Notification Inbox'
  | 'Email'
  | 'SMS'
  | 'Telegram'
  | 'Discord'
  | 'Slack'
  | 'Wallet'
  | 'Browser';

interface NavigationItem {
  name: NavigationNames;
  activeName: ActiveMenuName;
}

export enum ActiveMenuName {
  AlertHistory,
  Email,
  Discord,
  Telegram,
  SMS,
  Slack,
  WalletAlert,
  Browser,
}

const Icons: Record<
  NavigationNames,
  React.FC<React.SVGProps<SVGSVGElement>>
> = {
  'Notification Inbox': AlertHistoryIcon,
  Email: EmailIcon,
  Discord: DiscordIcon,
  Telegram: TelegramIcon,
  SMS: SMSIcon,
  Slack: SlackIcon,
  Wallet: WalletIcon,
  Browser: BrowserIcon,
};

const topNavigationItems: (NavigationItem | null)[] = [
  {
    name: 'Notification Inbox',
    activeName: ActiveMenuName.AlertHistory,
  },
  {
    name: 'Email',
    activeName: ActiveMenuName.Email,
  },
  {
    name: 'Discord',
    activeName: ActiveMenuName.Discord,
  },
  {
    name: 'Telegram',
    activeName: ActiveMenuName.Telegram,
  },
  {
    name: 'SMS',
    activeName: ActiveMenuName.SMS,
  },
  {
    name: 'Slack',
    activeName: ActiveMenuName.Slack,
  },
  {
    name: 'Wallet',
    activeName: ActiveMenuName.WalletAlert,
  },
  {
    name: 'Browser',
    activeName: ActiveMenuName.Browser,
  },
];

type Props = {
  handleClose: () => void;
  open: boolean;
  topic?: CustomFusionEventData | OpenUserRegistration;
};

const DefineTemplatesDialog = ({ handleClose, open, topic }: Props) => {
  const { update } = useTopicsDataContext();
  const isFusionEventDataTopic = topic?.__typename === 'FusionEventData';
  let metaData = {} as FusionEventMetadata;
  if (isFusionEventDataTopic) {
    // Safely access metadata only if the topic is of type CustomFusionEventData
    metaData = JSON.parse(topic.metadata ?? '{}');
  }
  const { items: configs } = useGetSubscriptionConfigData();
  const [activeMenu, setActiveMenu] = React.useState<ActiveMenuName>(
    isFusionEventDataTopic ? ActiveMenuName.AlertHistory : ActiveMenuName.Email,
  );

  const isEasySubscribeTopic = topic?.__typename === 'OpenUserRegistration';

  const filteredTopNavigationItems = isEasySubscribeTopic
    ? topNavigationItems.filter(
        (item) => item?.name === 'Email' || item?.name === 'Telegram',
      )
    : topNavigationItems;
  const [alertHistoryMessageContent, setAlertHistoryMessageContent] =
    React.useState<string>('');
  const [alertHistorySubject, setAlertHistorySubject] =
    React.useState<string>('');
  const [emailMessageContent, setEmailMessageContent] = React.useState('');
  const [selectedFormat, setSelectedFormat] =
    React.useState<string>('Custom HTML');
  const [subject, setSubject] = React.useState('');
  const [customHTML, setCustomHTML] = React.useState<string>('');
  const [unstyledMessage, setUnstyledMessage] = React.useState<string>('');
  const [discordMessageContent, setDiscordMessageContent] =
    React.useState<string>('');
  const [telegramMessageContent, setTelegramMessageContent] =
    React.useState<string>('');
  const [SMSMessageContent, setSMSMessageContent] = React.useState<string>('');
  const [SlackMessageContent, setSlackMessageContent] =
    React.useState<string>('');
  const [walletAlertMessageContent, setWalletAlertMessageContent] =
    React.useState<string>('');
  const [browserMessageContent, setBrowserMessageContent] =
    React.useState<string>('');
  const [browserSubject, setBrowserSubject] = React.useState<string>('');
  const [customIcon, setCustomIcon] = React.useState<
    GenericEventIconHint | string
  >('Chart');
  const [iconUrl, setIconUrl] = React.useState<string>('');
  const [openConfirmModal, setOpenConfirmModal] =
    React.useState<boolean>(false);
  const [, setVariables] = React.useState<string[]>([]);
  const { uploadTemplate } = useMessageService();
  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();
  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };
  const savedIcon = React.useRef('');
  const { setRefetchTopicsData } = useAppContext();

  // const activeTargets = {
  //   sms: false,
  //   email: false,
  //   alertHistory: true,
  //   telegram: false,
  //   discord: false,
  // };

  // const cardsWithTopic = configs.filter((config) =>
  //   config.eventTypes.find((event) => event.fusionEventId === topic?.id),
  // );
  // cardsWithTopic.forEach((card) => {
  //   const targets = Object.keys(card.contactInfo);
  //   targets.forEach((target) => {
  //     if (card.contactInfo[target].active === true) {
  //       activeTargets.target = true;
  //     }
  //   });
  // });
  const { items: listTemplates, refetchListTemplates } = useGetListTemplates(
    topic?.id ?? '',
  );
  const uploadedTemplateTypes = listTemplates?.map(
    (template) => template.templateType,
  );

  React.useEffect(() => {
    if (listTemplates) {
      listTemplates.forEach((template) => {
        const matches = template.message.match(/{{(.*?)}}/g);

        if (matches && matches.length > 0) {
          setVariables((prevVariables) => {
            const newVariables = [...prevVariables, ...matches];
            return newVariables;
          });
        }
      });
    }
  }, [listTemplates]);

  const clearInputs = () => {
    setAlertHistoryMessageContent('');
    setAlertHistorySubject('');
    setEmailMessageContent('');
    setSelectedFormat('Custom HTML');
    setSubject('');
    setCustomHTML('');
    setUnstyledMessage('');
    setDiscordMessageContent('');
    setTelegramMessageContent('');
    setSlackMessageContent('');
    setWalletAlertMessageContent('');
    setSMSMessageContent('');
    setBrowserMessageContent('');
    setBrowserSubject('');
    setIconUrl('');
    setCustomIcon('Chart');
    setActiveMenu(
      isFusionEventDataTopic
        ? ActiveMenuName.AlertHistory
        : ActiveMenuName.Email,
    );
    handleClear();
  };

  const handleUploadTemplate = async () => {
    let template = '';
    let templateType = TemplateType.SMS;
    if (activeMenu === ActiveMenuName.AlertHistory) {
      template = alertHistoryMessageContent
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '\n')
        .replace(/<\/?br>/g, '');
      let newMetaData = {} as FusionEventMetadata;
      const newIcon = { icon: customIcon, customIconUrl: iconUrl };
      const newUiConfigOverride = { ...metaData.uiConfigOverride, ...newIcon };
      newMetaData = { ...metaData, uiConfigOverride: newUiConfigOverride };
      try {
        const metaDataJson = JSON.stringify(newMetaData);
        await update(topic?.name ?? '', topic?.id ?? '', metaDataJson);
      } catch (err) {
        setErrorMessage((err as Error).message);
        return;
      }
    } else if (
      activeMenu === ActiveMenuName.Email &&
      selectedFormat === 'Basic Notifi Template'
    ) {
      template = emailMessageContent;
      templateType = TemplateType.EMAIL;
    } else if (
      activeMenu === ActiveMenuName.Email &&
      selectedFormat === 'Custom HTML'
    ) {
      template = customHTML;
      templateType = TemplateType.EMAIL;
    } else if (activeMenu === ActiveMenuName.Discord) {
      template = discordMessageContent
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '\n')
        .replace(/<\/?br>/g, '');
      templateType = TemplateType.DISCORD;
    } else if (activeMenu === ActiveMenuName.Telegram) {
      template = telegramMessageContent
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '\n')
        .replace(/<br>/g, '')
        .replace(/<\/?br>/g, '');

      templateType = TemplateType.TELEGRAM;
    } else if (activeMenu === ActiveMenuName.SMS) {
      template = SMSMessageContent.replace(/<p>/g, '')
        .replace(/<\/p>/g, '')
        .replace(/<\/?br>/g, '');
      templateType = TemplateType.SMS;
    } else if (activeMenu === ActiveMenuName.Slack) {
      template = SlackMessageContent.replace(/<p>/g, '')
        .replace(/<\/p>/g, '')
        .replace(/<\/?br>/g, '');
      templateType = TemplateType.SLACK_CHANNEL;
    } else if (activeMenu === ActiveMenuName.WalletAlert) {
      template = walletAlertMessageContent.trim();
      templateType = TemplateType.WEB3;
    } else if (activeMenu === ActiveMenuName.Browser) {
      template = browserMessageContent.trim();
      templateType = TemplateType.BROWSER_PUSH;
    }
    try {
      if (topic?.id) {
        if (activeMenu === ActiveMenuName.AlertHistory) {
          await uploadTemplate(
            topic.id,
            alertHistoryMessageContent,
            TemplateType.EVENT_DETAIL_BODY,
          );
          await uploadTemplate(
            topic.id,
            alertHistorySubject,
            TemplateType.EVENT_DETAIL_TITLE,
          );
          savedIcon.current = customIcon;
        } else if (activeMenu === ActiveMenuName.Email) {
          await uploadTemplate(topic.id, template, templateType);
          await uploadTemplate(topic.id, subject, TemplateType.EMAIL_SUBJECT);
        } else if (activeMenu === ActiveMenuName.Browser) {
          await uploadTemplate(topic.id, template, templateType);
          await uploadTemplate(
            topic.id,
            browserSubject,
            TemplateType.BROWSER_PUSH_SUBJECT,
          );
        } else {
          await uploadTemplate(topic.id, template, templateType);
        }
        refetchListTemplates();
        setRefetchTopicsData(true);
        setOpenConfirmModal(true);
      } else {
        setErrorMessage('Failed to upload template. 🖊️');
      }
    } catch (err) {
      setErrorMessage('Failed to upload template. 🖊️');
    }
  };

  const createNavigationItem = (item: NavigationItem) => {
    const { name, activeName } = item;
    const handleClick = (activeName: ActiveMenuName) => {
      clearMessageBarState();
      setActiveMenu(activeName);
    };
    const IconEle = Icons[name];

    return (
      <LeftMenuButton
        handleClick={() => handleClick(activeName)}
        customSx={{
          width: '311px',
          mt: 0,
          backgroundColor: activeMenu === activeName ? 'white' : 'transparent',
          color: activeMenu === activeName ? midnight : dusk,
          '&:hover': {
            backgroundColor: white,
          },
        }}
        textSx={{
          color: activeMenu === activeName ? midnight : dusk,
        }}
        icon={<IconEle color={dusk} style={{ width: '18px' }} />}
        key={name}
        size="sm"
        isActive={activeMenu === activeName}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Stack
            flexDirection="row"
            gap="13px"
            justifyContent="center"
            alignItems="center"
          >
            {name}
            {name === 'Notification Inbox' ? (
              <Typography
                sx={{ fontSize: '12px', fontWeight: 800, color: dusk }}
              >
                Required
              </Typography>
            ) : null}
          </Stack>
          {uploadedTemplateTypes &&
          uploadedTemplateTypes.includes(
            convertLeftMenuNameToTemplateType(name),
          ) ? (
            <CheckIcon />
          ) : requiredTemplates[name] ? (
            <NotifiTooltip
              isDefineTemplate={true}
              tooltipContent={
                'This destination is supported by a card that this topic is in. Define the template for this topic.'
              }
            />
          ) : null}
        </Stack>
      </LeftMenuButton>
    );
  };

  let isSaveButtonDisabled = true;
  if (
    activeMenu === ActiveMenuName.AlertHistory &&
    alertHistoryMessageContent.replace(/<[^>]*>/g, '').replace(/\s+/g, '') &&
    alertHistorySubject.trim() &&
    customIcon
  )
    isSaveButtonDisabled = false;
  else if (activeMenu === ActiveMenuName.Email && subject.trim()) {
    if (
      selectedFormat === 'Basic Notifi Template' &&
      emailMessageContent.replace(/<[^>]*>/g, '').replace(/\s+/g, '')
    )
      isSaveButtonDisabled = false;
    else if (customHTML.trim()) isSaveButtonDisabled = false;
  } else if (
    activeMenu === ActiveMenuName.Discord &&
    discordMessageContent.replace(/<[^>]*>/g, '').replace(/\s+/g, '')
  )
    isSaveButtonDisabled = false;
  else if (
    activeMenu === ActiveMenuName.Telegram &&
    telegramMessageContent.replace(/<[^>]*>/g, '').replace(/\s+/g, '')
  )
    isSaveButtonDisabled = false;
  else if (
    activeMenu === ActiveMenuName.SMS &&
    SMSMessageContent.replace(/<[^>]*>/g, '').replace(/\s+/g, '')
  )
    isSaveButtonDisabled = false;
  else if (
    activeMenu === ActiveMenuName.Slack &&
    SlackMessageContent.replace(/<[^>]*>/g, '').replace(/\s+/g, '')
  )
    isSaveButtonDisabled = false;
  else if (
    activeMenu === ActiveMenuName.WalletAlert &&
    walletAlertMessageContent.replace('\n', '').trim()
  )
    isSaveButtonDisabled = false;
  else if (
    activeMenu === ActiveMenuName.Browser &&
    browserMessageContent.replace('\n', '').trim() &&
    browserSubject.trim()
  )
    isSaveButtonDisabled = false;
  else {
    isSaveButtonDisabled = true;
  }

  // Prefill Alert History saved icon
  React.useEffect(() => {
    if (isFusionEventDataTopic) {
      const metadata = JSON.parse(topic.metadata || '{}');
      const icon = metadata?.uiConfigOverride?.icon || '';
      const iconUrl = metadata?.uiConfigOverride?.customIconUrl || '';

      if (iconUrl.length > 0) {
        setIconUrl(iconUrl);
      }

      savedIcon.current = icon;
      setCustomIcon(icon);
    }
  }, [isFusionEventDataTopic, topic]);

  // Prefill form
  const preFillFormState = React.useCallback(() => {
    if (!listTemplates) return;

    listTemplates.forEach(({ message, templateType }) => {
      switch (templateType) {
        case TemplateType.EMAIL: {
          if (message.startsWith('<p>') && message.endsWith('</p>')) {
            setSelectedFormat('Custom HTML');
            setEmailMessageContent(message);
            return;
          } else {
            setSelectedFormat('Custom HTML');
            setCustomHTML(message);
            return;
          }
        }
        case TemplateType.EMAIL_SUBJECT:
          return setSubject(message);
        case TemplateType.EVENT_DETAIL_TITLE:
          return setAlertHistorySubject(message);
        case TemplateType.EVENT_DETAIL_BODY:
          return setAlertHistoryMessageContent(message);
        case TemplateType.TELEGRAM:
          return setTelegramMessageContent(message.replace(/\n/g, '\n'));
        case TemplateType.SMS:
          return setSMSMessageContent(message.replace(/\n/g, ''));
        case TemplateType.DISCORD:
          return setDiscordMessageContent(message);
        case TemplateType.SLACK_CHANNEL:
          return setSlackMessageContent(message);
        case TemplateType.WEB3:
          return setWalletAlertMessageContent(message);
        case TemplateType.BROWSER_PUSH:
          return setBrowserMessageContent(message);
        case TemplateType.BROWSER_PUSH_SUBJECT:
          return setBrowserSubject(message);
        default:
          return null;
      }
    });
  }, [listTemplates]);

  React.useEffect(() => {
    preFillFormState();
  }, [preFillFormState]);

  React.useEffect(() => {
    if (!open) clearInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClear = () => {
    setCustomIcon((savedIcon.current || '') as GenericEventIconHint);
    preFillFormState();
  };

  const savedTemplates = React.useMemo(
    () => listTemplates?.map((v) => (v.message ? v.templateType : null)),
    [listTemplates],
  );

  const getTemplateMessageByTemplateType = (type: TemplateType) =>
    listTemplates?.find((v) => v.templateType === type)?.message;

  // Show the Clear Button only when changes have been made to a saved template.
  let showClearButton = false;
  if (
    activeMenu === ActiveMenuName.AlertHistory &&
    savedTemplates?.includes(TemplateType.EVENT_DETAIL_BODY)
  ) {
    const body = getTemplateMessageByTemplateType(
      TemplateType.EVENT_DETAIL_BODY,
    );
    const subject = getTemplateMessageByTemplateType(
      TemplateType.EVENT_DETAIL_TITLE,
    );

    if (
      subject !== alertHistorySubject ||
      body !== alertHistoryMessageContent ||
      customIcon !== savedIcon.current
    )
      showClearButton = true;
  } else if (
    activeMenu === ActiveMenuName.Email &&
    savedTemplates?.includes(TemplateType.EMAIL)
  ) {
    const body = getTemplateMessageByTemplateType(TemplateType.EMAIL) || '';
    const emailSubject = getTemplateMessageByTemplateType(
      TemplateType.EMAIL_SUBJECT,
    );
    let format = selectedFormat;
    if (body.startsWith('<p>') && body.endsWith('</p>'))
      format = 'Basic Notifi Template';
    else format = 'Custom HTML';

    if (subject !== emailSubject || format !== selectedFormat)
      showClearButton = true;
    else if (format === 'Basic Notifi Template' && body !== emailMessageContent)
      showClearButton = true;
    else if (format === 'Custom HTML' && body !== customHTML)
      showClearButton = true;
  } else if (
    activeMenu === ActiveMenuName.Discord &&
    savedTemplates?.includes(TemplateType.DISCORD)
  ) {
    const body = getTemplateMessageByTemplateType(TemplateType.DISCORD);
    if (body !== discordMessageContent.replace(/<[^>]*>/g, ''))
      showClearButton = true;
  } else if (
    activeMenu === ActiveMenuName.Telegram &&
    savedTemplates?.includes(TemplateType.TELEGRAM)
  ) {
    const body = getTemplateMessageByTemplateType(TemplateType.TELEGRAM);
    if (body !== telegramMessageContent) showClearButton = true;
  } else if (
    activeMenu === ActiveMenuName.SMS &&
    savedTemplates?.includes(TemplateType.SMS)
  ) {
    const body = getTemplateMessageByTemplateType(TemplateType.SMS);
    if (body !== SMSMessageContent) showClearButton = true;
  } else if (
    activeMenu === ActiveMenuName.Slack &&
    savedTemplates?.includes(TemplateType.SLACK_CHANNEL)
  ) {
    const body = getTemplateMessageByTemplateType(TemplateType.SLACK_CHANNEL);
    if (body !== SlackMessageContent) showClearButton = true;
  } else if (
    activeMenu === ActiveMenuName.WalletAlert &&
    savedTemplates?.includes(TemplateType.WEB3)
  ) {
    const body = getTemplateMessageByTemplateType(TemplateType.WEB3);
    if (body !== walletAlertMessageContent) showClearButton = true;
  } else if (
    activeMenu === ActiveMenuName.Browser &&
    savedTemplates?.includes(TemplateType.BROWSER_PUSH)
  ) {
    const body = getTemplateMessageByTemplateType(TemplateType.BROWSER_PUSH);
    const subject = getTemplateMessageByTemplateType(
      TemplateType.BROWSER_PUSH_SUBJECT,
    );
    if (body !== browserMessageContent || subject !== browserSubject)
      showClearButton = true;
  } else showClearButton = false;
  // End of block: Clear Button logic

  const requiredTemplates: { [key in NavigationNames]: boolean } =
    React.useMemo(() => {
      const result = {
        'Notification Inbox': false,
        Email: false,
        SMS: false,
        Telegram: false,
        Discord: false,
        Slack: false,
        Wallet: false,
        Browser: false,
      };

      configs?.forEach((v) => {
        const isTopicAdded = v?.eventTypes?.find(
          (x: any) => x?.fusionEventId?.value === topic?.id,
        );
        if (isTopicAdded) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { discord, sms, email, telegram, wallet, slack } =
            v?.contactInfo || {};

          result['Notification Inbox'] = true;
          result.Discord = result.Discord || Boolean(discord?.active);
          result.Email = result.Email || Boolean(email?.active);
          result.Telegram = result.Telegram || Boolean(telegram?.active);
          result.SMS = result.SMS || Boolean(sms?.active);
          result.Slack = result.Slack || Boolean(slack?.active);
          result['Wallet'] = result['Wallet'] || Boolean(wallet?.active);
          result.Browser = true;
        }
      });

      return result;
    }, [topic?.id, configs]);

  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        fullScreen
        onClose={handleClose}
        open={open}
        sx={{
          '&.MuiPaper-root &.MuiDialog-paper': {
            backgroundColor: 'transparent',
          },
          backgroundColor: '#F3F3FA',
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: '#F3F3FA',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 0,
          }}
        >
          <Stack
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              sx={{
                backgroundColor: white,
                borderRadius: '45px',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                position: 'absolute',
                right: '15%',
                top: '56px',
                '&:hover': {
                  backgroundColor: white,
                },
              }}
              onClick={() => {
                clearInputs();
                handleClose();
              }}
            >
              <CloseIcon sx={{ color: dusk }} />
            </IconButton>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                mt: 6,
                mb: 3,
                width: '100%',
              }}
            >
              <Typography variant="h1">
                {isFusionEventDataTopic
                  ? 'Customize alert templates'
                  : 'Customize confirmation templates'}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  mt: 1,
                }}
              >
                {isFusionEventDataTopic
                  ? topic?.name ?? ''
                  : `Easy Subscribe: ${topic?.name ?? ''}`}
              </Typography>
            </Stack>
            <Stack flexDirection="row" alignItems="start" gap={2}>
              <Stack
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {filteredTopNavigationItems.map((item) =>
                    item ? createNavigationItem(item) : null,
                  )}
                </Box>
              </Stack>
              <Stack
                sx={{
                  borderRadius: '8px',
                  backgroundColor: white,
                  width: '700px',
                  minHeight: '624px',
                  p: '38px 32px',
                }}
                justifyContent="space-between"
              >
                <Stack>
                  {activeMenu === ActiveMenuName.AlertHistory ? (
                    <AlertHistoryComposer
                      selectedTopicKey={topic?.id ?? ''}
                      alertHistoryMessageContent={alertHistoryMessageContent}
                      setAlertHistoryMessageContent={
                        setAlertHistoryMessageContent
                      }
                      alertHistorySubject={alertHistorySubject}
                      setAlertHistorySubject={setAlertHistorySubject}
                      isDefineTemplate={true}
                      customIcon={customIcon}
                      setCustomIcon={setCustomIcon}
                      iconUrl={iconUrl}
                      setIconUrl={setIconUrl}
                      isCommunityManager={false}
                    />
                  ) : null}
                  {activeMenu === ActiveMenuName.Email ? (
                    <EmailComposer
                      selectedTopicKey={topic?.id ?? ''}
                      selectedFormat={selectedFormat}
                      setSelectedFormat={setSelectedFormat}
                      subject={subject}
                      setSubject={setSubject}
                      emailMessageContent={emailMessageContent}
                      setEmailMessageContent={setEmailMessageContent}
                      customHTML={customHTML}
                      setCustomHTML={setCustomHTML}
                      unstyledMessage={unstyledMessage}
                      setUnstyledMessage={setUnstyledMessage}
                      isDefineTemplate={true}
                      isCommunityManager={false}
                    />
                  ) : null}
                  {activeMenu === ActiveMenuName.Discord ? (
                    <DiscordComposer
                      selectedTopicKey={topic?.id ?? ''}
                      discordMessageContent={discordMessageContent}
                      setDiscordMessageContent={setDiscordMessageContent}
                      isDefineTemplate={true}
                      isCommunityManager={false}
                    />
                  ) : null}
                  {activeMenu === ActiveMenuName.Telegram ? (
                    <TelegramComposer
                      selectedTopicKey={topic?.id ?? ''}
                      telegramMessageContent={telegramMessageContent}
                      setTelegramMessageContent={setTelegramMessageContent}
                      isDefineTemplate={true}
                      isCommunityManager={false}
                    />
                  ) : null}
                  {activeMenu === ActiveMenuName.SMS ? (
                    <SMSComposer
                      selectedTopicKey={topic?.id ?? ''}
                      setSMSMessageContent={setSMSMessageContent}
                      SMSMessageContent={SMSMessageContent}
                      isDefineTemplate={true}
                      isCommunityManager={false}
                    />
                  ) : null}
                  {activeMenu === ActiveMenuName.Slack ? (
                    <SlackComposer
                      selectedTopicKey={topic?.id ?? ''}
                      setSlackMessageContent={setSlackMessageContent}
                      slackMessageContent={SlackMessageContent}
                      isDefineTemplate={true}
                      isCommunityManager={false}
                    />
                  ) : null}
                  {activeMenu === ActiveMenuName.WalletAlert ? (
                    <WalletAlertComposer
                      setWalletAlertMessageContent={
                        setWalletAlertMessageContent
                      }
                      walletAlertMessageContent={walletAlertMessageContent}
                      selectedTopicKey={topic?.id ?? ''}
                      isCommunityManager={false}
                      isDefineTemplate={true}
                    />
                  ) : null}
                  {activeMenu === ActiveMenuName.Browser ? (
                    <BrowserComposer
                      setBrowserSubject={setBrowserSubject}
                      browserSubject={browserSubject}
                      setBrowserMessageContent={setBrowserMessageContent}
                      browserMessageContent={browserMessageContent}
                      selectedTopicKey={topic?.id ?? ''}
                      isCommunityManager={false}
                      isDefineTemplate={true}
                    />
                  ) : null}
                </Stack>
                <Stack>
                  <Divider
                    sx={{
                      borderColor: cloud,
                      borderWidth: '1.5px',
                      mb: 3,
                    }}
                  />
                  <Stack
                    flexDirection="row"
                    justifyContent="end"
                    alignItems="center"
                  >
                    {/* TODO: Clear button logic to be fixed*/}
                    {/* HIDDEN BUTTON LOGIC SHOULD BE CHANGED TO VISIBLE TO RE-ENABLE THE CLEAR BUTTON.*/}
                    <SecondaryButton
                      onClick={clearInputs}
                      disabled={true}
                      buttonTitle="Clear"
                      customSx={{
                        width: '127px',
                        visibility: showClearButton ? 'hidden' : 'hidden',
                      }}
                    />
                    <PrimaryButton
                      disabled={isSaveButtonDisabled}
                      buttonTitle="Save"
                      customSx={{ width: '127px' }}
                      onClick={handleUploadTemplate}
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  borderRadius: '8px',
                  backgroundColor: white,
                  width: '310px',
                  height: '195px',
                }}
                px={7}
                justifyContent={'center'}
                alignItems="center"
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: '22px',
                    fontWeight: 900,
                    color: midnight,
                    textAlign: 'center',
                  }}
                >
                  {isFusionEventDataTopic
                    ? 'Topic data'
                    : 'How are these templates used?'}
                </Typography>

                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: dusk,
                    textAlign: 'center',
                    mt: 1.25,
                  }}
                >
                  {isFusionEventDataTopic
                    ? 'Learn how to add more variables to your parser'
                    : 'These templates will be received by users who subscribe to topics using the ‘Easy Subscribe’ config.'}
                </Typography>

                {isFusionEventDataTopic && (
                  <SecondaryButton
                    onClick={() =>
                      window.open(
                        'https://docs.notifi.network/docs/styling-your-notifications',
                        '_blank',
                      )
                    }
                    buttonTitle="Docs"
                    customSx={{ width: '127px', mt: 1.25 }}
                  />
                )}

                {/* {uploadedTemplateTypes && uploadedTemplateTypes.length > 0 ? (
                  <>
                    <Stack
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: '100%' }}
                    >
                      <Stack
                        flexDirection="row"
                        justifyContent="start"
                        alignItems="center"
                        sx={{ width: '100%', pt: 3, pl: 4 }}
                      >
                        <Typography
                          sx={{ fontSize: '22px', fontWeight: 900, mr: 1 }}
                        >
                          Topic data
                        </Typography>
                        <NotifiTooltip
                          tooltipContent={
                            'Run your parser to view the last 5 runs here. This data can help inform your template designs.'
                          }
                        />
                      </Stack>
                      <Divider
                        sx={{
                          borderColor: cloud,
                          borderWidth: '1.5px',
                          width: '100%',
                          my: 3,
                        }}
                      />
                      <Box
                        sx={{
                          minHeight: '111px',
                          width: '240px',
                          backgroundColor: eggshell,
                          borderRadius: '4px',
                          p: 2,
                        }}
                      >
                        <Typography>{variables.slice(0, 5)}</Typography>
                      </Box>
                    </Stack>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{ p: 7 }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 700,
                          color: dusk,
                          textAlign: 'center',
                          mt: 3,
                        }}
                      >
                        Learn how to add more variables to your parser
                      </Typography>
                      <SecondaryButton
                        buttonTitle="Docs"
                        customSx={{ width: '127px', mt: 1 }}
                      />
                    </Stack>
                  </>
                ) : (
                  <>
                    <Typography variant="h1">Topic data</Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: dusk,
                        textAlign: 'center',
                        mt: 1,
                      }}
                    >
                      Run your parser to view the last 5 runs here. This data
                      can help inform your template designs.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: dusk,
                        textAlign: 'center',
                        mt: 3,
                      }}
                    >
                      Learn how to add more variables to your parser
                    </Typography>
                    <SecondaryButton
                      buttonTitle="Docs"
                      customSx={{ width: '127px', mt: 1 }}
                    />
                  </>
                )} */}
              </Stack>
            </Stack>
          </Stack>
          <Footer sx={{ background: eggshell }} />
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        ctaTitle="Got it"
        handleClose={() => setOpenConfirmModal(false)}
        handleConfirm={() => setOpenConfirmModal(false)}
        hideSecondaryAction={true}
        open={openConfirmModal}
        sx={{
          backgroundColor: eggshell,
        }}
        titleSx={{ color: midnight }}
        title="Template has been uploaded"
      />
    </div>
  );
};

export default DefineTemplatesDialog;
