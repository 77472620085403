import React from 'react';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import {
  dusk,
  eggshell,
  heather,
  midnight,
  midnightDarker,
  seaglassDarker,
  white,
} from 'src/constants/colors';

const RedirectLoginModal = () => {
  return (
    <Modal
      open={true}
      sx={{
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: white,
          backdropFilter: 'blur(17.5px)',
          width: '760px',
          height: '420px',
          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            backgroundColor: midnightDarker,
            height: '100px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ fontSize: '30px', fontWeight: 900, color: white }}>
            Are you in the right place?
          </Typography>
        </Box>
        <Stack
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
          sx={{ width: '470px' }}
        >
          {' '}
          <Typography
            sx={{
              fontSize: '22px',
              fontWeight: 900,
              color: midnight,
              textAlign: 'center',
            }}
          >
            This is the Notifi Admin Development Environment
          </Typography>{' '}
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 700,
              color: dusk,
              textAlign: 'center',
            }}
          >
            This is the dev environment login area. Customers looking for their
            Notifi Admin account can use the URL below.
          </Typography>
          <Box
            sx={{
              backgroundColor: eggshell,
              height: '84px',
              width: '346px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
            }}
          >
            <Typography
              sx={{ fontSize: '22px', fontWeight: 900, color: heather }}
            >
              https://
              <span style={{ color: seaglassDarker }}>
                admin.notifi.network/
              </span>
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() =>
              window.open('https://admin.notifi.network/', '_blank')
            }
            sx={{
              backgroundColor: midnightDarker,
              textTransform: 'none',
              fontSize: '16px',
              fontWeight: 700,
              mt: 1,
              mb: 4,
              color: white,
              '&:hover': {
                backgroundColor: midnightDarker,
              },
            }}
          >
            Continue to Notifi Admin
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default RedirectLoginModal;
