import { SubscriptionCardConfigItemV1 } from '@components/cardConfig/common/CardConfigItemV1';
import { ContactInfoConfig } from '@components/cardConfig/common/ContactInfo';
import { isTopicInConfig } from './topics';

type ActiveContactInfo = keyof ContactInfoConfig;

const getActiveDestinations = (contactInfo: ContactInfoConfig): ActiveContactInfo[] => {
    return Object.entries(contactInfo)
      .filter(([, value]) => value.active) // Use a conventional placeholder name
      .map(([key]) => key as ActiveContactInfo);
  };

export const activeDestinationList = (
    configs: SubscriptionCardConfigItemV1[],
    topic: string
  ): Set<ActiveContactInfo> => {
    const activeContactInfos = new Set<ActiveContactInfo>();
  
    configs.forEach(config => {
      if (isTopicInConfig(config, topic)) {
        const activeInfos = getActiveDestinations(config.contactInfo);
        activeInfos.forEach(destination => activeContactInfos.add(destination));
      }
    });
  
    return activeContactInfos;
  }