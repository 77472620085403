import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { dusk, eggshell, white } from 'src/constants/colors';
import { AlertFilter } from './CreateNonCMTopicDialog';

type Props = {
  filter: AlertFilter;
};

const CreateNonNotifiHostedTopicStep2 = ({ filter }: Props) => {
  return (
    <div>
      <Stack flexDirection="row" gap={2}>
        <Stack
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '920px',
            backgroundColor: white,
            borderRadius: '8px',
            mb: 3,
            pb: '40px',
          }}
        >
          {' '}
          <Typography variant="h2" sx={{ my: 3 }}>
            Send an alert triggered by an API call from my server
          </Typography>
          {filter.userInputParams[0].options.length <= 0 ? (
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                background: eggshell,
                p: '24px 40px',
                width: '620px',
                height: '112px',
                borderRadius: '8px',
              }}
            >
              <div>
                <Typography variant="h3" sx={{ mb: 1 }}>
                  How to publish messages to this topic
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', color: dusk, width: '380px' }}
                >
                  Go to the Notifi SDK to learn how to publish messages and see
                  an example API call with the parameters filled out for
                  reference.
                </Typography>
              </div>
              <PrimaryButton
                buttonTitle="Notifi SDK"
                onClick={() =>
                  window.open(
                    'https://docs.notifi.network/docs/alert-trigger/node-client',
                    '_blank',
                  )
                }
              />
            </Stack>
          ) : (
            <>
              {/* <Typography sx={{ fontSize: '16px', color: dusk, mt: 1, mb: 2 }}>
                Finish configuring your alerts and filters on your end
              </Typography> */}
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  background: eggshell,
                  p: '24px 40px',
                  width: '620px',
                  height: '78px',
                  borderRadius: '8px',
                }}
              >
                <div>
                  <Typography variant="h3" sx={{ mb: 1 }}>
                    Use the NodeJS SDK to trigger an alert for this topic
                  </Typography>
                </div>
                <PrimaryButton
                  buttonTitle="Notifi SDK"
                  onClick={() =>
                    window.open(
                      'https://docs.notifi.network/docs/alert-trigger/node-client',
                      '_blank',
                    )
                  }
                />
              </Stack>
              {/* <Typography sx={{ fontSize: '16px', color: dusk, mt: 1, mb: 2 }}>
                - OR -
              </Typography> */}
              {/* <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  background: eggshell,
                  p: '24px 40px',
                  width: '620px',
                  height: '78px',
                  borderRadius: '8px',
                }}
              >
                <div>
                  <Typography variant="h3" sx={{ mb: 1 }}>
                    Implement a Source and Alert Filter
                  </Typography>
                </div>
                <PrimaryButton buttonTitle="Learn More" />
              </Stack> */}
            </>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default CreateNonNotifiHostedTopicStep2;
