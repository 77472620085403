import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Stack,
  Box,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import {
  dusk,
  eggshell,
  midnightDarker,
  midnightLight,
  white,
} from 'src/constants/colors';
import { Close } from '@mui/icons-material';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';

export enum KeyTypes {
  PERMANENT = 'Permanent',
}

type Props = {
  open: boolean;
  handleClose: () => void;
  mKey: string;
  mValue: string;
  onSubmit: ({ value }: { value: string }) => Promise<void>;
};

const ModifyKeyDialog: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  handleClose,
  onSubmit,
  mKey,
  mValue,
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  const [value, setValue] = React.useState('');

  const resetStorageState = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const submit = useCallback(async () => {
    try {
      await onSubmit({ value });
    } catch (error: unknown) {
      if (error instanceof Error) {
        resetStorageState();
      }
    }
  }, [onSubmit, resetStorageState, value]);

  useEffect(() => {
    setValue(mValue);
  }, [mKey, mValue]);

  return (
    <Dialog
      fullWidth={false}
      maxWidth="lg"
      open={open}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: isAP2Enabled ? white : midnightLight,
          borderRadius: '4px',
          maxWidth: '513px',
        },
      }}
    >
      <Stack
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        sx={{
          backgroundColor: isAP2Enabled ? white : midnightLight,
          fontFamily: 'Rota',
          p: 4,
        }}
      >
        <Grid
          container
          flexWrap="nowrap"
          alignItems="start"
          justifyContent="center"
        >
          <DialogTitle
            sx={{
              fontSize: '18px',
              lineHeight: '22px',
              p: 0,
              textAlign: 'center',
              width: '100%',
              flex: '1 1 auto',
              ml: '64px',
              mr: '42px',
            }}
          >
            Modify Key
          </DialogTitle>

          <Box
            sx={{ width: '25px', height: '25px', cursor: 'pointer' }}
            onClick={() => {
              resetStorageState();
            }}
          >
            <Close sx={{ color: dusk }} />
          </Box>
        </Grid>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            maxHeight: '550px',
            overflow: 'auto',
            width: '100%',
          }}
        >
          <DialogContent
            sx={{
              maxHeight: '550px',
              width: '400px',
              overflow: 'unset',
              pb: 0,
              px: 0,
            }}
          >
            <TextField
              fullWidth
              value={mKey}
              label="Key"
              disabled
              sx={{
                mt: '31px',
                backgroundColor: isAP2Enabled ? eggshell : midnightDarker,
              }}
            />
            <TextField
              fullWidth
              value={value}
              label="Value"
              sx={{
                mt: '31px',
                backgroundColor: isAP2Enabled ? eggshell : midnightDarker,
              }}
              onChange={(e) => setValue((e.target as HTMLInputElement).value)}
            />
          </DialogContent>
        </Stack>
        <Stack
          alignItems="center"
          direction={'row'}
          gap={3}
          sx={{ mt: 4, width: '100%' }}
        >
          <SecondaryButton
            customSx={{
              width: '50%',
            }}
            buttonTitle="Cancel"
            onClick={resetStorageState}
          />

          <PrimaryButton
            customSx={{
              width: '50%',
            }}
            buttonTitle="Save"
            onClick={async () => {
              await submit();
              resetStorageState();
            }}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ModifyKeyDialog;
