import { Button, CircularProgress, SxProps } from '@mui/material';
import React from 'react';
import { white } from 'src/constants/colors';

type Props = {
  buttonTitle?: string;
  customSx?: SxProps;
  disabled?: boolean;
  onClick?: () => void;
  startIcon?: React.ReactNode;
  isLoading?: boolean;
};

export const PrimaryButton: React.FC<Props> = ({
  buttonTitle,
  customSx,
  disabled,
  onClick,
  startIcon,
  isLoading = false,
}: Props) => {
  return (
    <Button
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      variant="contained"
      startIcon={startIcon}
      sx={{
        height: '40px',
        textTransform: 'none',
        ...customSx,
      }}
    >
      {isLoading ? (
        <CircularProgress size={25} style={{ color: white }} />
      ) : (
        buttonTitle
      )}
    </Button>
  );
};
