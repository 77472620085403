import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import { AuthProvider } from '@components/auth/AuthContext';
import { NotifiEnvProvider } from '@components/auth/NotifiEnvContext';
import { Global2FaAuthModalProvider } from '@components/auth/GlobalModalContext';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <NotifiEnvProvider>
    <BrowserRouter>
      <Global2FaAuthModalProvider>
        <AuthProvider>
          {/* <React.StrictMode> */}
            <App />
          {/* </React.StrictMode> */}
        </AuthProvider>
      </Global2FaAuthModalProvider>
    </BrowserRouter>
  </NotifiEnvProvider>,
);

reportWebVitals();
