import { useState } from 'react';

type ListItem = {
  isRemovable: boolean;
  id: number;
  value: string;
};

const useListManagement = () => {
  const [list, setList] = useState<ListItem[]>([]);

  const handleInsertItem = () => {
    const id = (list.at(-1)?.id || 0) + 1;
    setList((currentList) => [
      ...currentList,
      { id, isRemovable: true, value: '' },
    ]);
  };

  const handleItemChange = (args: { value: string; id: number }) => {
    const { value, id } = args;

    setList((currentList) =>
      currentList.map((item) => (item.id === id ? { ...item, value } : item)),
    );
  };

  const handleRemoveItem = (id: number) => {
    setList((currentList) => currentList.filter((item) => item.id !== id));
  };

  const handleInsertDefaultItems = (args: ListItem[]) => {
    setList([...args]);
  };

  return {
    list,
    handleInsertItem,
    handleItemChange,
    handleRemoveItem,
    handleInsertDefaultItems,
  };
};

export default useListManagement;
