import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type ParamName =
  | 'email'
  | 'token'
  | 'edit'
  | 'ftu'
  | 'redirect'
  | 'environment'
  | 'selected'
  | 'edit_card';

/**
 * Returns the string value of a query param
 * Because URLSearchParams interprets '+' as a space,
 * this hook replaces the '+' after parsing the param value.
 * @param {ParamName} paramName The name of the param
 * @returns {string} The string value of the param or "" if it does not exist in the query string
 */
function useQueryParam(paramName: ParamName): string {
  const [searchParams] = useSearchParams();
  const [paramValue, setParamValue] = useState('');

  useEffect(() => {
    const paramValue = (searchParams.get(paramName) ?? '').replace(/ /g, '+');
    setParamValue(paramValue);
  }, [searchParams, paramName]);

  return paramValue;
}

export default useQueryParam;
